import React, { useContext, useState } from "react";
import { Box, Divider, Modal, Avatar, Badge } from "@mui/material";
import {
  ModalClose,
  PrimeButton,
  SecButton,
  StyledIconButton,
} from "../../../styles/styles";
import CloseIcon from "@mui/icons-material/Close";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleDown } from "@fortawesome/free-regular-svg-icons";
import { siteProforma } from "../../../data/SiteData";
import { parseCSVAndSetCoords } from "../helperFunctions";
import { PlatformData } from "../../../imports/Context";

function SiteBuilderUpload({ uploadOpen, setUploadOpen }) {
  const { platformData, setPlatformData } = useContext(PlatformData);
  const [file, setFile] = useState(null);
  var date = new Date();
  const dateTime =
    date.getDate() +
    "/" +
    (date.getMonth() + 1) +
    "/" +
    date.getFullYear() +
    " @ " +
    date.getHours() +
    ":" +
    date.getMinutes() +
    ":" +
    date.getSeconds();

  const handleUploadedCoords = (event) => {
    setFile(event.target.files[0]);
  };

  return (
    <Modal
      open={uploadOpen}
      onClose={() => setUploadOpen(false)}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          className="modal-popups"
          sx={{
            inset: "30%",
            overflow: "hidden",
          }}
        >
          <header>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <h2 style={{ margin: "0 0 16px 0" }}>Upload Site</h2>
              <ModalClose onClick={() => setUploadOpen(false)}>
                <StyledIconButton>
                  <CloseIcon />
                </StyledIconButton>
              </ModalClose>
            </div>
            <Divider
              style={{ marginBottom: "16px" }}
              sx={{ bgcolor: "black" }}
            />
          </header>

          <div style={{ paddingBottom: "25px" }}>
            <div>
              <input
                id="selectDataPoint"
                onChange={handleUploadedCoords}
                accept=".csv"
                type="file"
              />
            </div>
            <div style={{ paddingTop: "16px" }}>
              <CSVLink
                data={siteProforma}
                filename={"SiteData.csv"}
                style={{ textDecoration: "none", paddingTop: "16px" }}
              >
                <PrimeButton style={{ width: "100%" }}>
                  <FontAwesomeIcon
                    size="xl"
                    color="white"
                    icon={faCircleDown}
                  />
                  Template
                </PrimeButton>
              </CSVLink>
            </div>
          </div>

          <footer
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "16px",
            }}
          >
            <PrimeButton
              style={{ marginRight: "8px" }}
              onClick={() => {
                if (file) {
                  parseCSVAndSetCoords(file, setPlatformData); // Call the helper function
                  setFile(null);
                  setUploadOpen(false);
                } else {
                  setUploadOpen(false);
                }
              }}
            >
              {file ? "Upload" : "Close"}
            </PrimeButton>
          </footer>
        </Box>
      </div>
    </Modal>
  );
}

export default SiteBuilderUpload;

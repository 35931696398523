/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import SearchBar from "./SearchBar";
import { searchable_projects as searchable } from "./data";
import { useMap } from "react-leaflet";
import { addQueryLayer } from "../helperFunctions";
import MentionsTextBox from "./MentionsTextBox";

import { PlatformData } from "../../../imports/Context";

import $ from "jquery";
import jstree from "jstree";

const SearchBox = styled.div`
  position: fixed;
  top: 10px;
  right: 65px;
  width: 400px;

  border-radius: 3px;
  box-sizing: border-box;
  /* border:1px solid lightgray; */
  z-index: 600;
  /* background-color: #ffffff; */
`;

function WindfarmSearch({ map }) {
  // const map = useMap()
  const { platformData, setPlatformData } = useContext(PlatformData);
  const [selectedMentionGroup, setSelectedMentionGroup] = useState(null); // State to store the selected object
  const { lng, setLng } = useState("");
  const { lat, setLat } = useState("");
  const { name, setName } = useState("");

  // useEffect(() => {
  //   map.createPane("windfarm-search")
  // }, [])

  const handleItemSelected = (item, addmention) => {
    // Clear all layers from the 'windfarm_search' pane
    map.eachLayer(function (layer) {
      if (
        layer._name === "Renewable_Projects:GLOBAL_Wind-Power-Tracker" ||
        layer._name === "Renewable_Projects:GLOBAL_Solar-Power-Tracker" ||
        layer._name === "Renewable_Projects:GLOBAL_Hydropower-Tracker" ||
        layer._name === "Renewable_Projects:GLOBAL_Geothermal-Power-Tracker" ||
        layer._name === "Renewable_Projects:GLOBAL_Nuclear-Power-Tracker"
      ) {
        map.removeLayer(layer);
      }
    });

    if (item !== "") {
      const active = platformData.layerControl.activeLayers;

      if (
        active.filter(
          (layer) => layer.id === "Wind_Farms:EUR_Offshore-Wind-Farm-Points"
        ).length === 0
      ) {
        active.push({
          type: "wms",
          name: "EUR Offshore Wind Farms",
          id: "Wind_Farms:EUR_Offshore-Wind-Farm-Points",
          opacity: 100,
          range: [null, null],
          query: false,
          url: process.env.REACT_APP_GEO_SERVER_URL + "Wind_Farms/wms?",
        });
      }

      if (
        active.filter(
          (layer) =>
            layer.id === "Power_Infrastructure_-_OSM:GLOBAL_Power-Plant-Polygon"
        ).length === 0
      ) {
        active.push({
          type: "wms",
          name: "GLOBAL Power Plant Polygon",
          id: "Power_Infrastructure_-_OSM:GLOBAL_Power-Plant-Polygon",
          opacity: 100,
          range: [null, null],
          query: false,
          url:
            process.env.REACT_APP_GEO_SERVER_URL +
            "Power_Infrastructure_-_OSM/wms?",
        });
      }

      if (
        active.filter(
          (layer) =>
            layer.id === "Power_Infrastructure_-_OSM:GLOBAL_Power-Plant-Onshore"
        ).length === 0
      ) {
        active.push({
          type: "wms",
          name: "GLOBAL Power Plant Onshore",
          id: "Power_Infrastructure_-_OSM:GLOBAL_Power-Plant-Onshore",
          opacity: 100,
          range: [null, null],
          query: false,
          url:
            process.env.REACT_APP_GEO_SERVER_URL +
            "Power_Infrastructure_-_OSM/wms?",
        });
      }

      setPlatformData((platformData) => ({
        ...platformData,
        layerControl: {
          ...platformData.layerControl,
          activeLayers: active,
        },
      }));

      let parent = null;
      let path = null;

      if (selectedMentionGroup === "Wind") {
        parent = "Renewable_Projects";
        path = "GLOBAL_Wind-Power-Tracker";
      } else if (selectedMentionGroup === "Solar") {
        parent = "Renewable_Projects";
        path = "GLOBAL_Solar-Power-Tracker";
      } else if (selectedMentionGroup === "Hydro") {
        parent = "Renewable_Projects";
        path = "GLOBAL_Hydropower-Tracker";
      } else if (selectedMentionGroup === "Geothermal") {
        parent = "Renewable_Projects";
        path = "GLOBAL_Geothermal-Power-Tracker";
      } else if (selectedMentionGroup === "Nuclear") {
        parent = "Renewable_Projects";
        path = "GLOBAL_Nuclear-Power-Tracker";
      }

      addQueryLayer(
        [`${parent}:${path}`],
        "windfarm-search",
        502,
        map,
        `Project='${item.name}'`
      );

      const center = { lng: item.lng, lat: item.lat };
      const zoom = 12;
      map.setView(center, zoom);
    } else {
      const active = platformData.layerControl.activeLayers;

      setPlatformData((platformData) => ({
        ...platformData,
        layerControl: {
          ...platformData.layerControl,
          activeLayers: active.filter(
            (layer) =>
              layer.id !== "Wind_Farms:EUR_Offshore-Wind-Farm-Points" &&
              layer.id !==
                "Power_Infrastructure_-_OSM:GLOBAL_Power-Plant-Polygon" &&
              layer.id !==
                "Power_Infrastructure_-_OSM:GLOBAL_Power-Plant-Onshore"
          ),
        },
      }));
    }
  };

  return (
    <SearchBox className="windfarmSearch">
      <MentionsTextBox
        onItemSelected={handleItemSelected}
        selectedMentionGroup={selectedMentionGroup}
        setSelectedMentionGroup={setSelectedMentionGroup}
        map={map}
      />
      {/* <SearchBar
        infoTitle={"Windfarms"}
        placeholder={"Search Windfarm"}
        items={searchable}
        onItemSelected={handleItemSelected}
      /> */}
    </SearchBox>
  );
}

export default WindfarmSearch;

import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PrimeButton, SecButton } from "../../../styles/styles";
import styled from "styled-components";
import * as XLSX from "xlsx";
import {
  elevationAnalysisAPI,
  elevationSiteAPI,
} from "../../widgets/elevationAnalysis/helperFunctions";
import {
  convertToGeoJson,
  convertToOldOSP,
  convertToOldSite,
} from "../../convertors";
import { placeFoundations } from "../drawFunctions";

const Container = styled.div`
  text-align: left;
  width: 100%;
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 15px;
  width: 100%;
`;

const Th = styled.th`
  padding: 8px;
  background-color: #f2f2f2;
  text-align: left; /* Center the column headings */
`;

const Td = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;

function SiteInfo({ siteInfo, popup, configRef }) {
  const [totalLength, setTotalLength] = useState(0);
  const [foundationView, setFoundationView] = useState({
    anchorExclusion: false,
    fullExclusion: true,
    radialExclusion: false,
    wtgExclusion: false,
  });

  useEffect(() => {
    for (let i = 0; i < siteInfo.siteCables.length; i++) {
      const element = siteInfo.siteCables[i];

      setTotalLength((totalLength) => totalLength + element.length);
    }

    popup.on("remove", () => {
      console.log("Reset current site in configref");

      configRef.current.currentSite = null;
    });
  }, [siteInfo]);

  useEffect(() => {
    if (siteInfo.floating) {
      configRef.current.map.eachLayer((layer) => {
        if (
          (layer.options.pane === "site-anchor-exclusion" ||
            layer.options.pane === "site-radial-exclusion" ||
            layer.options.pane === "site-wtg-exclusion" ||
            layer.options.pane === "site-full-exclusion") &&
          layer.options.className === configRef.current.currentSite
        ) {
          configRef.current.map.removeLayer(layer);
        }
      });

      placeFoundations(
        configRef.current.currentSite,
        siteInfo.floating,
        Object.keys(foundationView).filter((item) => foundationView[item]),
        Object.values(foundationView),
        configRef
      );
    }
  }, [foundationView]);

  const handleFoundations = async (type, status) => {
    setFoundationView((foundationView) => ({
      ...foundationView,
      [type]: status,
    }));
  };

  const handleDownload = async () => {
    try {
      const wb = XLSX.utils.book_new();
      let elevation = [];
      if (
        siteInfo.siteTurbs.filter((turb) => turb.elevation === 1).length > 0 ||
        siteInfo.siteOSP.filter((osp) => osp.elevation === 1).length > 0
      ) {
        elevation = await elevationSiteAPI(
          convertToGeoJson(
            {},
            [...siteInfo.siteTurbs, ...siteInfo.siteOSP],
            "osp"
          ).geometry
        );
      }

      let combinedCoords = [...siteInfo.siteTurbs, ...siteInfo.siteOSP];
      if (elevation.length > 0) {
        combinedCoords = [...convertToOldSite(JSON.parse(elevation[0]))];
      }

      convertToGeoJson({}, combinedCoords, "osp");

      // Add 'coords' sheet
      const wsCoords = XLSX.utils.json_to_sheet(combinedCoords);
      XLSX.utils.book_append_sheet(wb, wsCoords, "Turbines");

      // Add 'cables' sheet
      // const wsCables = XLSX.utils.json_to_sheet(site.cables);
      for (let i = 0; i < siteInfo.siteCables.length; i++) {
        siteInfo.siteCables[i].path = String(siteInfo.siteCables[i].path);
        siteInfo.siteCables[i].geo = String(
          siteInfo.siteCables[i].geo.map(
            (coord) => `[${coord.lng}, ${coord.lat}]`
          )
        );
      }

      let wsCables = XLSX.utils.json_to_sheet(siteInfo.siteCables);
      XLSX.utils.book_append_sheet(wb, wsCables, "Cables");

      XLSX.writeFile(wb, siteInfo.siteSettings.siteName + "_siteInfo.xlsx");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <header>
        <p style={{ margin: "0px" }}>Site Name: </p>
        <h4 style={{ margin: "8px", fontWeight: "600" }}>
          {siteInfo.siteSettings.siteName}
        </h4>
      </header>
      <Divider sx={{ bgcolor: "black" }} style={{ marginBottom: "16px" }} />

      <div style={{ display: "flex", gap: "16px" }}>
        <Container>
          <Table>
            <tbody>
              <tr>
                <Th>Site information</Th>
                <Th></Th>
              </tr>
              <tr>
                <Td>Turbine Selection</Td>
                <Td>{siteInfo.siteSettings.turb || "No Turbine Selected"}</Td>
              </tr>
              <tr>
                <Td>Number of Turbines</Td>
                <Td>{siteInfo.siteTurbs.length}</Td>
              </tr>
              <tr>
                <Td>Site Rating</Td>
                <Td>
                  ~{" "}
                  {siteInfo.siteSettings.turb &&
                    siteInfo.siteTurbs.length *
                      siteInfo.siteSettings.turb.split("MW")[0]}{" "}
                  MW
                </Td>
              </tr>
              <tr>
                <Td>Turbine Spacing</Td>
                <Td>
                  {siteInfo.siteSettings.minDist
                    ? siteInfo.siteSettings.minDist + "m"
                    : "Unknown "}
                </Td>
              </tr>
              {/* <tr>
              <Td>Cable Pull (%)</Td>
              <Td>{totalLength.toFixed(2)} km</Td>
            </tr>
            <tr>
              <Td>Total Cable Length</Td>
              <Td>{totalLength.toFixed(2)} km</Td>
            </tr> */}
              <tr>
                <Td>Total Cable Length</Td>
                <Td>{totalLength.toFixed(2)} km</Td>
              </tr>
            </tbody>
          </Table>
        </Container>

        {siteInfo.floating && (
          <div
            style={{
              width: "35%",
              minWidth: "35%",
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
          >
            <label>Foundation View Controls</label>

            <div
              className="input"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <input
                type="checkbox"
                checked={foundationView.fullExclusion}
                onChange={(e) =>
                  handleFoundations("fullExclusion", e.currentTarget.checked)
                }
              />
              <span>Full Exclusion</span>
            </div>
            <div
              className="input"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <input
                type="checkbox"
                checked={foundationView.wtgExclusion}
                onChange={(e) =>
                  handleFoundations("wtgExclusion", e.currentTarget.checked)
                }
              />
              <span>Turbine Exclusion</span>
            </div>
            <div
              className="input"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <input
                type="checkbox"
                checked={foundationView.anchorExclusion}
                onChange={(e) =>
                  handleFoundations("anchorExclusion", e.currentTarget.checked)
                }
              />
              <span>Anchor Point Exclusion</span>
            </div>
            <div
              className="input"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <input
                type="checkbox"
                checked={foundationView.radialExclusion}
                onChange={(e) =>
                  handleFoundations("radialExclusion", e.currentTarget.checked)
                }
              />
              <span>Mooring Line Exclusion</span>
            </div>

            <SecButton
              style={{ width: "100%" }}
              onClick={() =>
                configRef.current.setPlatformData((platformData) => ({
                  ...platformData,
                  siteBuilder: {
                    ...platformData.siteBuilder,
                    [configRef.current.currentSite]: {
                      ...platformData.siteBuilder[
                        configRef.current.currentSite
                      ],
                      floating: null,
                    },
                  },
                }))
              }
            >
              Remove Anchoring System
            </SecButton>
          </div>
        )}
      </div>

      <footer style={{ display: "flex", gap: "8px" }}>
        <SecButton
          style={{ width: "50%" }}
          disabled={siteInfo.siteCables.length === 0}
          onClick={() => {
            console.log(configRef.current);

            configRef.current.setSiteManagement((siteManagement) => ({
              ...siteManagement,
              activeSite: configRef.current.currentSite,
              activateLayouts: true,
            }));
          }}
        >
          View Site Layout Options
        </SecButton>
        <PrimeButton style={{ width: "50%" }} onClick={handleDownload}>
          Download Site Info
        </PrimeButton>
      </footer>
    </div>
  );
}

export default SiteInfo;

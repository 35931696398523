import { Box, Divider, Modal, Typography } from "@mui/material";
import { InputNumber } from "primereact/inputnumber";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  PrimeButton,
  StandardContainerRow,
  StyledIconButton,
} from "../../../styles/styles";
import Select, { components } from "react-select";

import { MultiSelect } from "primereact/multiselect";

import L from "leaflet";
// import { handleCables, highlightSelected, selectLayer } from "../drawFunctions";
import { InputText } from "primereact/inputtext";
import {
  convertToGeoJson,
  convertToOldCables,
  convertToSplitSite,
} from "../../convertors";

import { toast } from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { placeFoundations } from "../drawFunctions";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  font-size: 1.2rem;

  .input {
    padding-bottom: 5px;
    font-family: "Montserrat";
    input {
      padding-left: 5px;
    }

    select {
      padding-left: 5px;
    }
  }
`;

const Title = styled(Typography)`
  color: #00a0c6;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 8px;
`;

const NavOption = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  /* background: ${(props) => (props.active ? "#00A0C6" : "none")}; */
  font-weight: ${(props) => (props.active && !props.disabled ? "600" : "100")};
  opacity: ${(props) => (props.disabled ? "0.7" : "1")};
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  border-bottom: ${(props) =>
    props.active && !props.disabled ? "3px solid #00A0C6" : "3px solid white"};
  /* color: ${(props) => (props.active ? "white" : "black")}; */
  color: black;

  padding: 8px;
  /* border-radius: 8px; */
`;

const apiHeaders = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
  Accept: "application/json",
  "Content-Type": "application/json",
};

function SiteCablesMenu({ configRef, popup, spinner }) {
  const [exclusionZoneList, setExclusionZoneList] = useState([]);
  const [anchorExclusionIds, setAnchorExclusionIds] = useState([]);
  const [exclusionZoneSelected, setExclusionZoneSelected] = useState([]);
  const [layoutType, setLayoutType] = useState("Radial");
  const [allLayouts, setAllLayouts] = useState([]);
  const [settings, setSettings] = useState({
    num_iter: [100],
    noPerString: [5],
    exZone: [
      JSON.stringify({
        type: "FeatureCollection",
        features: [],
      }),
    ],
    buffer: [0],
    parallel: [true],
    calledFrom: ["api"],
  });
  const siteInfo =
    configRef.current.platformData.siteBuilder[configRef.current.currentSite];

  const endpoints = {
    Radial: "runCableOptimisation",
    Ring: "runCableOptimisation",
    Star: "runStarOptimisation",
    fishbone: "",
  };

  // Custom Single Option Component
  const CustomOption = (props) => {
    const { data, innerRef, innerProps, isFocused, isSelected } = props;

    const handleClick = (e) => {
      e.stopPropagation(); // Stops the event propagation
      console.log(`Clicked on ${data.label}`);
      innerProps.onClick(e); // Ensure the select logic works
    };

    return (
      <div
        ref={innerRef}
        {...innerProps}
        onClick={handleClick}
        style={{
          backgroundColor: isFocused ? "#f0f8ff" : "white", // Default focus color
          color: isSelected ? "white" : "black", // Default selected text color
          padding: "10px", // Mimic default padding
          cursor: "pointer",
          ...innerProps.style, // Preserve any additional styles passed down
        }}
        className={innerProps.className} // Use React-Select's default classes
      >
        {data.label}
      </div>
    );
  };

  useEffect(() => {
    const tempExclusion = [];
    const anchorExclusion = [];

    configRef.current.map.eachLayer((layer) => {
      console.log(layer.options.pane);
      console.log(
        layer.options.pane === "site-anchor-exclusion" &&
          layer instanceof L.Polygon
      );

      // Check if the layer is in the desired pane
      if (
        layer.options.pane === "site-temp-exclusion" &&
        layer instanceof L.Polygon
      ) {
        tempExclusion.push({
          value: layer._leaflet_id,
          label: "Exclusion Zone " + (tempExclusion.length + 1),
        });
      }
      // else if (
      //   layer.options.pane === "site-anchor-exclusion" &&
      //   // layer.options.pane === "site-radial-exclusion" ||
      //   // layer.options.pane === "site-wtg-exclusion" ||
      //   // layer.options.pane === "site-full-exclusion"
      //   layer instanceof L.Polygon
      // ) {
      //   anchorExclusion.push(layer._leaflet_id);
      // }
    });

    setExclusionZoneList([
      ...tempExclusion,
      ...(siteInfo.floating
        ? [{ label: "Anchor Exclusions", value: "anchorExclusion" }]
        : []),
    ]);

    popup.on("remove", () => {
      configRef.current.map.eachLayer((layer) => {
        // Check if the layer is in the desired pane
        if (
          layer.options.pane === "site-temp-exclusion" &&
          layer instanceof L.Polygon
        ) {
          layer.setStyle({
            fillOpacity: configRef.current.style.fillOpacity,
          });
        }
      });

      setExclusionZoneList([]);
      setExclusionZoneSelected([]);
      setAnchorExclusionIds([]);
    });
  }, []);

  const handleExclusionZones = async (zones, e) => {
    const tempExclusion = [];
    const anchorExclusion = [];

    if (zones.filter((zone) => zone.label === "Anchor Exclusions").length > 0) {
      await placeFoundations(
        configRef.current.currentSite,
        siteInfo.floating,
        ["anchorExclusion"],
        true,
        configRef
      );

      configRef.current.map.eachLayer((layer) => {
        // Check if the layer is in the desired pane
        if (
          layer.options.pane === "site-anchor-exclusion" &&
          layer instanceof L.Polygon
        ) {
          anchorExclusion.push(layer._leaflet_id);
        }
      });
    }

    console.log({ zones });
    console.log({ anchorExclusion });

    configRef.current.map.eachLayer((layer) => {
      // Check if the layer is in the desired pane
      if (
        (layer.options.pane === "site-temp-exclusion" ||
          layer.options.pane === "site-anchor-exclusion") &&
        layer instanceof L.Polygon
      ) {
        zones.forEach((zone) => {
          const formattedCoords = [];
          const layerCoords = layer.getLatLngs()[0];

          layerCoords.forEach((latlng) => {
            formattedCoords.push([latlng.lng, latlng.lat]);
          });

          console.log(zone.value);

          if (
            layer._leaflet_id === zone.value ||
            (zone.value === "anchorExclusion" &&
              anchorExclusion.indexOf(layer._leaflet_id) > -1)
          ) {
            layer.setStyle({
              fillOpacity: 0.9,
            });

            tempExclusion.push([...formattedCoords, formattedCoords[0]]);
          }
        });

        if (
          zones.findIndex((item) => item.value === layer._leaflet_id) === -1
        ) {
          layer.setStyle({
            fillOpacity: configRef.current.style.fillOpacity,
          });
        }
      }
    });

    setExclusionZoneSelected(tempExclusion);
  };

  const handleCables = async () => {
    const config = configRef.current;
    const turbines = siteInfo.siteTurbs;
    const OSPS = siteInfo.siteOSP;
    const stars = [];
    const siteID = configRef.current.currentSite;

    spinner.addTo(configRef.current.map);

    console.log(
      convertToGeoJson(null, exclusionZoneSelected, "polygon").geometry
    );

    if (turbines.length / OSPS.length <= 100) {
      let result = "Duplicate Turbines";
      let site = { siteTurbs: turbines, siteOSP: OSPS, siteStars: stars };

      try {
        const response = await fetch(
          //   `https://vekwin-hiibq2h6dq-ez.a.run.app/${siteOptions.fillType}`,
          `https://arrayopti-405606309488.europe-west2.run.app/${endpoints[layoutType]}`,
          {
            method: "POST",
            headers: apiHeaders,
            body: JSON.stringify(
              convertToGeoJson(
                {
                  ...settings,
                  exZone: [
                    JSON.stringify(
                      convertToGeoJson(null, exclusionZoneSelected, "polygon")
                        .geometry
                    ),
                  ],
                  ...(layoutType === "Ring" && { structure: layoutType }),
                },
                [...turbines, ...OSPS],
                "cables"
              )
            ),
          }
        ).catch((error) => {
          console.log(error);
          // spinner.remove();
        });
        result = await response.json();

        if (layoutType === "Radial" || layoutType === "Ring") {
          console.log({ result });
          let cables = [];

          console.log(Object.keys(result).length);

          if (Object.keys(result).length > 1) {
            // Object.keys(result).map((cluster, i) => {
            //   console.log({ cluster });
            const tempLayouts = [];
            result["1"].forEach((layout, j) => {
              console.log({ layout });
              const tempRes = { ...result };
              delete tempRes["1"];

              Object.keys(tempRes).map((cluster) => {
                console.log({ cluster });
                console.log(tempRes[cluster]);

                layout.features = [
                  ...layout.features,
                  ...tempRes[cluster][j].features,
                ];
              });

              tempLayouts.push(layout);
            });

            cables.push(tempLayouts);
            // });

            console.log(cables);
          } else {
            console.log(result[0]);
            console.log(result[0] === undefined);
            console.log(result[0] === "undefined");

            cables =
              result[0] === undefined
                ? [result[1]]
                : result[0][0] === undefined
                ? [result]
                : result;
          }

          console.log({ cables });

          configRef.current.setSiteManagement({
            activeSite: siteID,
            activateLayouts: true,
            activateSiteLayouts: cables[0],
          });

          console.log(cables[0]);

          result = { lines: JSON.stringify(cables[0][0]) };
        }

        if (layoutType === "Star") site = convertToSplitSite(result.coords);

        const cables = convertToOldCables(JSON.parse(result.lines), siteID);

        console.log(configRef.current.currentSite);
        console.log(siteID);
        console.log({ exclusionZoneSelected });
        console.log(
          convertToGeoJson(null, exclusionZoneSelected, "polygon").geometry
        );

        config.setPlatformData((platformData) => ({
          ...platformData,
          siteBuilder: {
            ...platformData.siteBuilder,
            [siteID]: {
              ...platformData.siteBuilder[siteID],
              ...site,
              siteCables: cables,
              siteExclusions: [
                JSON.stringify(
                  convertToGeoJson(null, exclusionZoneSelected, "polygon")
                    .geometry
                ),
              ],
            },
          },
        }));

        // Clear existing layers from the specified pane
        configRef.current.map.eachLayer((layer) => {
          if (layer.options.pane === "site-temp-exclusion") {
            configRef.current.map.removeLayer(layer);
          }
        });

        spinner.remove();
      } catch (err) {
        spinner.remove();

        console.log({ err, result });

        const message =
          typeof result === "string"
            ? result
            : result?.error
            ? "Something went wrong, please ensure your site has OSP(s) and all OSPs have turbines assigned to them"
            : result.map((item) => item + ", ");

        toast.error(message, {
          icon: "✋",
          duration: 5000,
          id: 'cabling',
          style: {
            fontSize: "18px",
            minWidth: "300px",
          },
        });
        return;
      }
    } else {
      spinner.remove();
      toast.error(
        "A site must have at least one OSP per 100 turbines - Please place OSP",
        {
          icon: "✋",
          duration: 5000,
          id: 'ospRatio',
          style: {
            fontSize: "18px",
            minWidth: "300px",
          },
        }
      );
      return;
    }
  };

  return (
    <Container id="siteCables_options" className="siteCables_options">
      <Title variant="h7" component="h2" align="center">
        AUTOMATIC CABLE OPTIONS
      </Title>

      <nav
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: "8px",
          width: "100%",
          textAlign: "center",
        }}
      >
        <NavOption
          active={layoutType === "Radial"}
          onClick={() => setLayoutType("Radial")}
        >
          Radial
        </NavOption>
        <NavOption
          disabled={true}
          active={layoutType === "Ring"}
          title="Coming Soon"
        >
          Ring (Coming Soon)
        </NavOption>
        <NavOption
          active={layoutType === "Star"}
          onClick={() => {
            setLayoutType("Star");
          }}
        >
          Star
        </NavOption>
        <NavOption
          disabled={true}
          active={layoutType === "fishbone"}
          title="Coming Soon"
          // onClick={() => {
          //   setLayoutType("fishbone");
          // }}
        >
          Fishbone (Coming Soon)
        </NavOption>
      </nav>

      {/* <div className="input" style={{ width: "100%" }}>
        <label>Number of turbines per string</label>
        <InputNumber
          style={{ width: "100%" }}
          min={5}
          max={6}
          placeholder="Min - 5, Max - 6"
          value={settings.noPerString[0]}
          onChange={(e) =>
            setSettings((settings) => ({
              ...settings,
              noPerString: [e.value || 5],
            }))
          }
        />
      </div> */}
      {(layoutType === "Radial" || layoutType === "Ring") && (
        <div>
          <label>
            Radial Count -{" "}
            {Math.round(siteInfo.siteTurbs.length / settings.noPerString)}
          </label>
          {/* {(siteInfo.siteTurbs.length / settings.noPerString) % 2 !== 0 && (
            <span>&nbsp;(Odd Radial Count, Default to Radial Layout)</span>
          )} */}
        </div>
      )}

      {(layoutType === "Radial" || layoutType === "Ring") && (
        <div style={{ width: "100%" }}>
          <label>Number of iterations</label>
          <Select
            placeholder=""
            isSearchable
            value={{ label: settings.num_iter, value: settings.num_iter }}
            options={[
              { label: 50, value: 50 },
              { label: 100, value: 100 },
              { label: 250, value: 250 },
            ]}
            components={{ Option: CustomOption }}
            onChange={(e) =>
              setSettings((settings) => ({
                ...settings,
                num_iter: [e.value || 50],
              }))
            }
          />
          {/* <InputNumber
            min={1}
            max={100}
            placeholder="Min - 1, Max - 100"
            value={settings.num_iter[0]}
            style={{ width: "100%" }}
            onChange={(e) =>
              setSettings((settings) => ({
                ...settings,
                num_iter: [e.value || 1],
              }))
            }
          /> */}
        </div>
      )}

      {(layoutType === "Radial" || layoutType === "Ring") && (
        <div id="exclusion-zones" style={{ width: "100%" }}>
          <label>Exclusion zone(s)</label>
          <Select
            placeholder=""
            isSearchable
            isClearable
            isMulti
            name="color"
            options={exclusionZoneList}
            components={{ Option: CustomOption }}
            onChange={handleExclusionZones}
          />
        </div>
      )}

      {(layoutType === "Radial" || layoutType === "Ring") && (
        <div className="input" style={{ width: "100%" }}>
          <label>Exclusion zone buffer (m)</label>
          <InputNumber
            style={{ width: "100%" }}
            min={0}
            value={settings.buffer[0]}
            placeholder="e.g 100"
            onChange={(e) =>
              setSettings((settings) => ({
                ...settings,
                buffer: [e.value || 1],
              }))
            }
          />
        </div>
      )}

      <Divider />

      <PrimeButton
        style={{
          width: "100%",
          flexGrow: 1,
          fontSize: "14px", // Adjust the font size for a larger button
          //   color: "#00A0C6", // Add this line to change the text color
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          console.log(configRef.current);
          handleCables(
            configRef.current,
            settings,
            exclusionZoneSelected,
            endpoints[layoutType]
          );
          popup.remove();
        }}
      >
        Generate Cables
      </PrimeButton>
    </Container>
  );
}

export default SiteCablesMenu;

import React, {
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { BounceLoader } from "react-spinners";
import base64 from "react-native-base64";
import { PlatformData } from "../../../imports/Context";

import L from "leaflet";
// import LatLngGraticule from 'react-leaflet-lat-lng-graticule';
import "./leaflet.latlng-graticule";
import { HexAlphaColorPicker } from "react-colorful";

import northArrow from "../../../media/northArrow.png";
import dashedBorder from "../../../media/strippedBorder.png";
import { addNewGroup, createJSTree, formatLayers } from "./helperFunctions";

import {
  MapContainer,
  LayersControl,
  TileLayer,
  LayerGroup,
  useMapEvents,
  WMSTileLayer,
  Popup,
  Pane,
  useMap,
  FeatureGroup,
  Circle,
  Rectangle,
  ScaleControl,
} from "react-leaflet";
import { addUploadedLayer } from "../helperFunctions";
import { downloadMap } from "./helperFunctions";
import Board from "../Board";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Select from "react-select";

// import { MiniMap } from "leaflet-minimap";
import "leaflet.sync";
import { WMSTileLayerWithHeader } from "../customWMS";
import { PrimeButton, SecButton } from "../../../styles/styles";
import { auth, storage } from "../../../firebase";
import { getDownloadURL, uploadBytesResumable, ref } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 95%;
`;

const MapDiv = styled.div`
  display: flex;
  justify-content: space-between;
  width: 75%;
  height: 100%;
  padding: 8px;
`;

const SettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: 100%;
  padding: 16px;
  overflow-y: scroll;
`;

const MainMap = styled.div`
  width: 75%;
  height: 100%;
  border: solid 1px #000; /*optional*/

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-size: 3px 100%, 100% 3px, 3px 100%, 100% 3px;
  background-repeat: no-repeat;

  // /* the initial value of border-style is "none", so it must be set to a different value for the border-image to show*/
  /* border-style: dashed; */

  // /* Individual border image properties */
  /* border-image-source: url(./svg/strippedBorder.png);
  border-image-slice: 2;
  border-image-repeat: round; */
`;

const SideContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24%;
  height: 100%;
  padding: 6px;
  border: 1px solid black;
`;

const MapPDF = forwardRef(function MapPDF({ mainMap }, mapScreenshot) {
  const [user, loading, error] = useAuthState(auth);
  const formattedData = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    return yyyy + "-" + mm + "-" + dd;
  };

  const { platformData, setPlatformData } = useContext(PlatformData);

  const [layerTree, setLayerTree] = useState({});
  const [mapRender, setMapRender] = useState({
    mainURL: false,
    miniURL: false,
  });
  const [customOptions, setCustomOptions] = useState({
    border: true,
    northArrow: {
      included: true,
      position: "topRight",
    },
    grid: {
      included: true,
      color: "#000000",
      weight: 1,
      opacity: 1,
    },
    coords: {
      included: true,
      colorBG: "#000000",
      colorText: "#000000",
    },
    miniMap: true,
    legend: true,
    metaData: {
      included: true,
      title: "Insert Title",
      figure: "1",
      date: formattedData(),
      by: "Input Name",
      scale: "4",
      version: "1",
      datum: "EPSG:3857",
      projections: "WGS 84 / Pseudo-Mercator",
    },
    companyLogo: {
      included: true,
      company: "../svg/vekta-group-logo-blue_height.png",
      client: "../svg/vekta-group-logo-blue_height.png",
    },
  });

  const [board, setBoard] = useState({
    // Geometries
    points: [],
    lines: [],
    polygons: [],

    // Active Geometries ID's
    activePoint: null,
    activeLine: null,
    activePolygon: null,

    // SpecialPolygon
    activeRectangle: null,

    // Utilities
    mode: "default",
    cursor: "default",
    hoveredPoint: null,

    // Edit Tools
    multiMove: null,
    cursorPos: null,
    moveStartPos: null,
    // Icon Styles
    // iconTypes: loadIcons(),
  });

  const [grid, setGrid] = useState(false);
  const position = [55.9533, -3.1883];
  const { BaseLayer, Overlay } = LayersControl;
  const miniMap = useRef();
  const [readytoRender, setReadytoRender] = useState(false);

  const [uploadedData, setUploadedData] = useState(null);

  const [miniBound, setMiniBound] = useState({
    bounding: [
      [12, 20],
      [20, 12],
    ],
    zoom: 3,
    center: position,
  });
  const blackOptions = { color: "#FF0000", opacity: "0.5" };
  const tileSize = 256;

  const arrowPositions = [
    { value: "topRight", label: "Top Right" },
    { value: "topLeft", label: "Top Left" },
    { value: "bottomRight", label: "Bottom Right" },
    { value: "bottomLeft", label: "Bottom Left" },
  ];

  useEffect(() => {
    if (platformData.layerControl.activeLayers.length !== 0) {
      const jstreeData = formatLayers(platformData);

      createJSTree(jstreeData);
      setLayerTree(jstreeData);
    } else {
      createJSTree({});
      setLayerTree({});
    }
  }, []);

  /**
   *
   * Save all points, lines and polygons to platform data for saving
   *
   */
  useEffect(() => {
    if (
      board.points.length > 0 ||
      board.lines.length > 0 ||
      board.polygons.length > 0
    ) {
      setPlatformData({
        ...platformData,
        drawnLayers: {
          points: board.points,
          lines: board.lines,
          polygons: board.polygons,
        },
      });
    }
  }, [board.points, board.lines, board.polygons]);

  useEffect(() => {
    if (mapScreenshot.current && mainMap) {
      /**
       *
       * Creation of geoJson object for uploaded shapefiles
       *
       */
      setUploadedData(
        L.geoJSON(null, {
          style: {
            stroke: "#FF0000",
            color: "#FF0000",
          },
          pane: "uploaded-layer",
          pointToLayer: function (point, latlng) {
            L.circle([latlng.lat, latlng.lng], 1000, {
              stroke: "#007592",
              color: "#009ec6",
              pane: "uploaded-layer",
            })
              .bindTooltip(function () {
                var popupInfo = "";

                Object.keys(point.properties).map((propVal) => {
                  popupInfo =
                    popupInfo +
                    "<strong>" +
                    propVal +
                    "</strong>" +
                    ": " +
                    point.properties[propVal] +
                    "<br>";
                });

                return popupInfo;
              })
              .addTo(mapScreenshot.current);
          },
        })
      );

      mainMap.sync(mapScreenshot.current);

      const bounding = mapScreenshot.current.getBounds();
      setMiniBound({
        bounding: [
          [bounding._northEast.lat, bounding._northEast.lng],
          [bounding._southWest.lat, bounding._southWest.lng],
        ],
      });

      miniMap.current.setView(
        mapScreenshot.current.getCenter(),
        mapScreenshot.current.getZoom() - 3
      );

      mapScreenshot.current.on("moveend", function () {
        const bounding = mapScreenshot.current.getBounds();
        setMiniBound({
          bounding: [
            [bounding._northEast.lat, bounding._northEast.lng],
            [bounding._southWest.lat, bounding._southWest.lng],
          ],
        });

        miniMap.current.setView(
          mapScreenshot.current.getCenter(),
          mapScreenshot.current.getZoom() - 3
        );
      });
    }
  }, [mapScreenshot.current]);

  /**
   *
   * Add the uploaded shapefile onto the leaflet map
   *
   */
  useEffect(() => {
    if (uploadedData && mapScreenshot.current) {
      addUploadedLayer(
        uploadedData,
        setUploadedData,
        mapScreenshot.current,
        platformData.uploadedLayers
      );
    }
  }, [platformData.uploadedLayers, uploadedData]);

  useEffect(() => {
    if (mapScreenshot.current) {
      mapScreenshot.current.invalidateSize();

      if (customOptions.grid.included) {
        if (grid) mapScreenshot.current.removeLayer(grid);

        const layer = L.latlngGraticule({
          showLabel: customOptions.coords.included,
          color: customOptions.grid.color,
          weight: customOptions.grid.weight,
          opacity: customOptions.grid.opacity,
        }).addTo(mapScreenshot.current);

        setGrid(layer);
      } else {
        mapScreenshot.current.removeLayer(grid);
        setGrid(false);
      }
    }
  }, [customOptions, readytoRender]);

  const handleLogo = (logo, placement) => {
    const image = logo.target.files[0];

    if (!image) return;

    const storageRef = ref(storage, `images/logos/${user.uid}/${placement}`);
    const uploadTask = uploadBytesResumable(storageRef, image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // console.log(snapshot);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
          setCustomOptions((customOptions) => ({
            ...customOptions,
            companyLogo: {
              ...customOptions.companyLogo,
              [placement]: downloadURL,
            },
          }));
        });
      }
    );
  };

  return (
    <Container>
      <MapDiv id="mapScreenshot_output" className="mapContainer">
        <MainMap
          style={
            customOptions.border
              ? {
                  backgroundImage:
                    "repeating-linear-gradient(0deg, #333333, #333333 24px, #ffffff 24px, #ffffff 50px, #333333 50px), repeating-linear-gradient(90deg, #333333, #333333 24px, #ffffff 24px, #ffffff 50px, #333333 50px), repeating-linear-gradient(180deg, #333333, #333333 24px, #ffffff 24px, #ffffff 50px, #333333 50px), repeating-linear-gradient(270deg, #333333, #333333 24px, #ffffff 24px, #ffffff 50px, #333333 50px)",
                }
              : {}
          }
          className="mainMap"
        >
          <div
            style={customOptions.northArrow.included ? {} : { display: "none" }}
            className={customOptions.northArrow.position + " northArrow"}
          >
            <img width={50} src={northArrow} />
          </div>

          <MapContainer
            ref={mapScreenshot}
            center={position}
            preferCanvas={true}
            renderer={0}
            zoom={3}
            style={{ height: "99%", width: "99%" }}
            doubleClickZoom={false}
            maxBoundsViscosity={1}
            maxBounds={[
              [-90, -180],
              [90, 180],
            ]}
            zoomControl={false}
            id="screenshot_map"
            whenReady={() => {
              setReadytoRender(true);
            }}
          >
            <Pane name="uploaded-layer" style={{ zIndex: 389 }}></Pane>
            <Pane name="active-wms" style={{ zIndex: 389 }}></Pane>
            <Pane name="ais-data" style={{ zIndex: 489 }}></Pane>
            <Pane name="port-data" style={{ zIndex: 489 }}></Pane>
            <Pane name="era5-grid" style={{ zIndex: 500 }}></Pane>
            <Pane name="era5-point" style={{ zIndex: 500 }}></Pane>
            <Pane name="site-builder" style={{ zIndex: 501 }}></Pane>
            <Pane name="site-cables" style={{ zIndex: 502 }}></Pane>
            <Pane name="measure-lines" style={{ zIndex: 501 }}></Pane>
            <Pane name="map-name-labels" style={{ zIndex: 699 }}></Pane>
            <Pane name="windfarm-search" style={{ zIndex: 399 }}></Pane>
            <Pane name="Board" style={{ zIndex: 300 }}></Pane>
            <Pane name="grid" style={{ zIndex: 500 }}></Pane>
            <ScaleControl />
            {platformData.layerControl.activeLayers.map((val, key) => {
              var zIndex =
                -platformData.layerControl.layerOrder[0].layer.indexOf(val.id);
              if (
                val.id === "Bathymetry:GLOBAL_Bathymetry" ||
                val.id === "Bathymetry:GLOBAL_Offshore-Contours"
              ) {
                zIndex = -platformData.layerControl.layerOrder[0].layer.length;
              }

              const url =
                process.env.REACT_APP_GEO_SERVER_URL +
                val.id.split(":")[0] +
                "/wms?";

              return ((val.type === "wms" && !val.query) ||
                (val.type === "legend" && !val.query) ||
                (val.type === "cog" && !val.query)) &&
                !platformData.uploadedLayers.layerNames.includes(val) &&
                url === val.url ? (
                // <WMSLayer layer={val} />
                <WMSTileLayer
                  key={val.id}
                  pane="active-wms"
                  url={"https://" + url}
                  layers={val.id}
                  className={val.id.split(":")[1] + "_active"}
                  format="image/png"
                  //checkempty={true}
                  transparent
                  tileSize={256}
                  minZoom={3}
                  detectRetina={true}
                  maxZoom={18}
                  noWrap={true}
                  opacity={val.opacity / 100}
                  zIndex={zIndex}
                />
              ) : null;
              // <WMSTileLayerWithHeader
              //   key={val.id}
              //   pane="active-wms"
              //   url={"https://" + val.url}
              //   layers={val.id}
              //   className={val.id.split(":")[1] + "_active"}
              //   params={layerParams}
              //   format="image/png"
              //   //checkempty={true}
              //   transparent
              //   tileSize={tileSize}
              //   minZoom={3}
              //   detectRetina={true}
              //   maxZoom={18}
              //   noWrap={true}
              //   opacity={val.opacity / 100}
              //   zIndex={zIndex}
              //   headers={{
              //     Authorization: `Basic ${base64.encode(
              //       `${process.env.REACT_APP_GEO_SERVER_USERNAME}:${process.env.REACT_APP_GEO_SERVER_PASSWORD}`
              //     )}`,
              //   }}
              // />
            })}
            ;
            {/* FIXME - Drawn shapes aren't appearing on the screenshot map. */}
            <Board board={board} setBoard={setBoard} />
            <TileLayer
              minZoom={3}
              maxZoom={18}
              pane="map-name-labels"
              noWrap={true}
              bounds={[
                [-90, -180],
                [90, 180],
              ]}
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}@2x.png"
            />
            <TileLayer
              minZoom={3}
              maxZoom={18}
              noWrap={true}
              detectRetina={true}
              bounds={[
                [-90, -180],
                [90, 180],
              ]}
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}@2x.png"
            />
          </MapContainer>
          {/* </div> */}
        </MainMap>

        <SideContent className="sideContent">
          <div
            style={customOptions.miniMap ? {} : { display: "none" }}
            className="miniMap"
          >
            <MapContainer
              ref={miniMap}
              center={position}
              preferCanvas={true}
              maxZoom={
                mapScreenshot.current && mapScreenshot.current.getZoom() - 3
              }
              zoom={
                mapScreenshot.current && mapScreenshot.current.getZoom() - 3
              }
              renderer={0}
              attributionControl={false}
              style={{ height: "100%", width: "100%" }}
              doubleClickZoom={false}
              maxBoundsViscosity={1}
              maxBounds={[
                [-90, -180],
                [90, 180],
              ]}
              zoomControl={false}
              id="screenshot_map"
            >
              <Rectangle
                bounds={miniBound.bounding}
                pathOptions={blackOptions}
              />

              <TileLayer
                minZoom={3}
                maxZoom={18}
                noWrap={true}
                detectRetina={true}
                bounds={[
                  [-90, -180],
                  [90, 180],
                ]}
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_nolabels/{z}/{x}/{y}@2x.png"
              />
            </MapContainer>
          </div>
          <div className="legendControl">
            <h5
              style={
                customOptions.legend
                  ? { textAlign: "center" }
                  : { display: "none" }
              }
            >
              Legend
            </h5>
            <div
              className="legendControl_layers"
              style={customOptions.legend ? {} : { display: "none" }}
            >
              <div className="legend_layoutTree"></div>
            </div>
          </div>

          <div
            style={customOptions.metaData.included ? {} : { display: "none" }}
            className="metaData"
          >
            <h5 style={{ textAlign: "center" }}>
              {customOptions.metaData.title}
            </h5>
            <div className="metaData_options">
              <p style={{ width: "100%" }}>
                <strong>Figure :</strong> {customOptions.metaData.figure}
              </p>
              <p>
                <strong>Date :</strong> {customOptions.metaData.date}
              </p>
              <p>
                <strong>By :</strong> {customOptions.metaData.by}
              </p>
              <p>
                <strong>Scale :</strong> {customOptions.metaData.scale}
              </p>
              <p>
                <strong>Version :</strong> {customOptions.metaData.version}
              </p>
              <p>
                <strong>Datum :</strong> {customOptions.metaData.datum}
              </p>
              <p style={{ width: "100%" }}>
                <strong>Projection :</strong>{" "}
                {customOptions.metaData.projections}
              </p>
            </div>
          </div>

          <div className="metaData_extra">
            <div className="scaleBar"></div>

            <div
              style={
                customOptions.companyLogo.included ? {} : { display: "none" }
              }
              className="logo compnayLogo"
            >
              <img width={50} src={customOptions.companyLogo.company} />
            </div>
            <div
              style={
                customOptions.companyLogo.included ? {} : { display: "none" }
              }
              className="logo clientLogo"
            >
              <img width={50} src={customOptions.companyLogo.client} />
            </div>
          </div>
        </SideContent>
      </MapDiv>

      <SettingsContainer className="settingsContainer">
        <h3 style={{ margin: "0 0 16px 0" }}>Customise Map Drawing</h3>
        <div className="mapOveraly_settings">
          <div className="miniMap_settings settings_section">
            <div className="mapOption">
              <h4>Border</h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label>Include :</label>
                <input
                  checked={customOptions.border}
                  onChange={(e) => {
                    setCustomOptions((customOptions) => ({
                      ...customOptions,
                      ["border"]: e.target.checked,
                    }));
                  }}
                  type="checkbox"
                />
              </div>
            </div>
          </div>

          <div className="northArrow_settings settings_section">
            <div className="mapOption">
              <h4>North Arrow</h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label>Include :</label>
                <input
                  checked={customOptions.northArrow.included}
                  onChange={(e) => {
                    setCustomOptions((customOptions) => ({
                      ...customOptions,
                      ["northArrow"]: {
                        ...customOptions.northArrow,
                        included: e.target.checked,
                      },
                    }));
                  }}
                  type="checkbox"
                />
              </div>
            </div>

            <Select
              style={
                customOptions.northArrow.included ? {} : { display: "none" }
              }
              onChange={(e) => {
                setCustomOptions((customOptions) => ({
                  ...customOptions,
                  ["northArrow"]: {
                    ...customOptions.northArrow,
                    position: e.value,
                  },
                }));
              }}
              isSearchable
              value={arrowPositions.filter(
                (item) => item.value === customOptions.northArrow.position
              )}
              options={arrowPositions}
            />
          </div>

          <div className="grid_settings settings_section">
            <div className="mapOption">
              <h4>Include Grid</h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label>Include :</label>
                <input
                  checked={customOptions.grid.included}
                  onChange={(e) => {
                    setCustomOptions((customOptions) => ({
                      ...customOptions,
                      ["grid"]: {
                        ...customOptions.grid,
                        included: e.target.checked,
                      },
                    }));
                  }}
                  type="checkbox"
                />
              </div>
            </div>

            <div
              style={customOptions.grid.included ? {} : { display: "none" }}
              className="grid_options"
            >
              <div className="input">
                <label>Grid Line Colour</label>
                <HexAlphaColorPicker
                  color={customOptions.grid.color}
                  style={{ width: "100%", marginBottom: "16px" }}
                  onChange={(e) => {
                    setCustomOptions((customOptions) => ({
                      ...customOptions,
                      ["grid"]: {
                        ...customOptions.grid,
                        color: e,
                      },
                    }));
                  }}
                />
              </div>

              <div className="grid_input">
                <div className="input">
                  <label>Grid Line Width</label>
                  <Slider
                    min={0.5}
                    step={0.5}
                    max={5}
                    defaultValue={customOptions.grid.weight}
                    onChange={(e) => {
                      setCustomOptions((customOptions) => ({
                        ...customOptions,
                        ["grid"]: {
                          ...customOptions.grid,
                          weight: e,
                        },
                      }));
                    }}
                  />
                </div>
                <input
                  type="number"
                  value={customOptions.grid.weight}
                  onChange={(e) => {
                    setCustomOptions((customOptions) => ({
                      ...customOptions,
                      ["grid"]: {
                        ...customOptions.grid,
                        weight: e.target.value,
                      },
                    }));
                  }}
                />
              </div>
            </div>
          </div>

          <div className="coords_settings settings_section">
            <div className="mapOption">
              <h4>Coordinates</h4>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <label>Include :</label>
                <input
                  checked={customOptions.coords.included}
                  onChange={(e) => {
                    setCustomOptions((customOptions) => ({
                      ...customOptions,
                      ["coords"]: {
                        ...customOptions.coords,
                        included: e.target.checked,
                      },
                    }));
                  }}
                  type="checkbox"
                />
              </div>
            </div>

            {/*<div style={customOptions.coords.included ? {} : { display: "none" }} className='coords_options'>
              <div>
                <label>Background Colour</label>
                <HexAlphaColorPicker color={customOptions.coords.colorBG} onChange={(e) => {
                  setCustomOptions(customOptions => ({
                    ...customOptions,
                    ["coords"]: {
                      ...customOptions.coords,
                      colorBG: e,
                    }
                  }))
                }} />
              </div>

              <div>
                <label>Text Colour</label>
                <HexAlphaColorPicker color={customOptions.coords.colorText} onChange={(e) => {
                  setCustomOptions(customOptions => ({
                    ...customOptions,
                    ["coords"]: {
                      ...customOptions.coords,
                      colorText: e,
                    }
                  }))
                }} />
              </div>
            </div>*/}
          </div>
        </div>

        <div className="miniMap_settings settings_section">
          <div className="mapOption">
            <h4>Mini Map</h4>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label>Include :</label>
              <input
                checked={customOptions.miniMap}
                onChange={(e) => {
                  setCustomOptions((customOptions) => ({
                    ...customOptions,
                    ["miniMap"]: e.target.checked,
                  }));
                }}
                type="checkbox"
              />
            </div>
          </div>
        </div>

        <div className="legend_settings settings_section">
          <div className="mapOption">
            <h4>Legend</h4>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label>Include :</label>
              <input
                checked={customOptions.legend}
                onChange={(e) => {
                  setCustomOptions((customOptions) => ({
                    ...customOptions,
                    ["legend"]: e.target.checked,
                  }));
                }}
                type="checkbox"
              />
            </div>
          </div>

          <SecButton style={{ width: "100%" }} onClick={() => addNewGroup()}>
            Add New Section
          </SecButton>
        </div>

        <div className="metaData_settings settings_section">
          <div className="mapOption">
            <h4>MetaData</h4>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label>Include :</label>
              <input
                checked={customOptions.metaData.included}
                onChange={(e) => {
                  setCustomOptions((customOptions) => ({
                    ...customOptions,
                    ["metaData"]: {
                      ...customOptions.metaData,
                      included: e.target.checked,
                    },
                  }));
                }}
                type="checkbox"
              />
            </div>
          </div>

          <div
            style={customOptions.metaData.included ? {} : { display: "none" }}
            className="metaData_settingInputs"
          >
            <div className="input">
              <label>Title</label>
              <input
                type="text"
                // value={customOptions.metaData.title}
                placeholder={customOptions.metaData.title}
                onChange={(e) => {
                  setCustomOptions((customOptions) => ({
                    ...customOptions,
                    ["metaData"]: {
                      ...customOptions.metaData,
                      title: e.target.value,
                    },
                  }));
                }}
              />
            </div>
            <div className="input">
              <label>Figure Number</label>
              <input
                type="text"
                placeholder={customOptions.metaData.figure}
                onChange={(e) => {
                  setCustomOptions((customOptions) => ({
                    ...customOptions,
                    ["metaData"]: {
                      ...customOptions.metaData,
                      figure: e.target.value,
                    },
                  }));
                }}
              />
            </div>
            <div className="input">
              <label>Date Created</label>
              <input
                type="date"
                value={customOptions.metaData.date}
                onChange={(e) => {
                  setCustomOptions((customOptions) => ({
                    ...customOptions,
                    ["metaData"]: {
                      ...customOptions.metaData,
                      date: e.target.value,
                    },
                  }));
                }}
              />
            </div>
            <div className="input">
              <label>By</label>
              <input
                type="text"
                placeholder={customOptions.metaData.by}
                onChange={(e) => {
                  setCustomOptions((customOptions) => ({
                    ...customOptions,
                    ["metaData"]: {
                      ...customOptions.metaData,
                      by: e.target.value,
                    },
                  }));
                }}
              />
            </div>
            <div className="input">
              <label>Scale Used</label>
              <input
                type="text"
                placeholder={customOptions.metaData.scale}
                onChange={(e) => {
                  setCustomOptions((customOptions) => ({
                    ...customOptions,
                    ["metaData"]: {
                      ...customOptions.metaData,
                      scale: e.target.value,
                    },
                  }));
                }}
              />
            </div>
            <div className="input">
              <label>Version</label>
              <input
                type="text"
                placeholder={customOptions.metaData.version}
                onChange={(e) => {
                  setCustomOptions((customOptions) => ({
                    ...customOptions,
                    ["metaData"]: {
                      ...customOptions.metaData,
                      version: e.target.value,
                    },
                  }));
                }}
              />
            </div>
            <div className="input">
              <label>Datum</label>
              <input
                type="text"
                value={customOptions.metaData.datum}
                disabled
              />
            </div>
            <div className="input">
              <label>Projection</label>
              <input
                type="text"
                value={customOptions.metaData.projections}
                disabled
              />
            </div>
          </div>
        </div>

        <div className="companyInfo_settings settings_section">
          <div className="mapOption">
            <h4>Company Logos</h4>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <label>Include :</label>
              <input
                checked={customOptions.companyLogo.included}
                onChange={(e) => {
                  setCustomOptions((customOptions) => ({
                    ...customOptions,
                    ["companyLogo"]: {
                      ...customOptions.companyLogo,
                      included: e.target.checked,
                    },
                  }));
                }}
                type="checkbox"
              />
            </div>
          </div>

          <div
            style={
              customOptions.companyLogo.included
                ? {
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                  }
                : { display: "none" }
            }
            className="companyLogo_uploads"
          >
            <div className="input">
              <label>Your Logo</label>
              <input type="file" onChange={(e) => handleLogo(e, "company")} />
            </div>
            <div className="input">
              <label>Client Logo</label>
              <input type="file" onChange={(e) => handleLogo(e, "client")} />
            </div>
          </div>
        </div>
      </SettingsContainer>

      <PrimeButton
        className="downloadMap_button"
        style={{ width: "auto" }}
        onClick={() => downloadMap(customOptions.metaData)}
      >
        Download Map
      </PrimeButton>
    </Container>
  );
});

export default MapPDF;

import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Divider, Modal } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  PrimeButton,
  SecButton,
  StandardContainerRow,
  StyledIconButton,
} from "../../../styles/styles";
import { Chart } from "primereact/chart";
import Plot from "react-plotly.js";
import Plotly from "plotly.js-basic-dist-min";
import * as turf from "@turf/turf";
import { Carousel } from "primereact/carousel";
import styled from "styled-components";
import { PlatformData } from "../../../imports/Context";
import { convertToOldCables } from "../../convertors";

const StyledSpan = styled("span")`
  &:hover {
    border: 2px solid #00a0c6;
    border-radius: 8px;
  }
`;

function LayoutOptions({ open, options, siteID, setBoard }) {
  const { platformData, setPlatformData } = useContext(PlatformData);
  const [layouts, setLayouts] = useState(options);
  const [usedColours, setUsedColours] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    plot: null,
    index: 0,
    data: null,
  });
  const [totalLength, setTotalLength] = useState(0);
  const [processedData, setProcessedData] = useState([]);

  var layout = {
    height: "250px",
    autosize: true,
    showlegend: false,
    font: { size: 11 },
    plot_bgcolor: "rgba(0,0,0,0)",
    paper_bgcolor: "rgba(0,0,0,0)",
    margin: {
      l: 30,
      r: 10,
      b: 30,
      t: 30,
    },
  };

  const config = {
    responsive: true,
    displayModeBar: false,
    toImageButtonOptions: {
      format: "png", // one of png, svg, jpeg, webp
      filename: "ArrayCableLayout",
      height: 500,
      width: 700,
      scale: 1, // Multiply title/legend/axis/canvas sizes by this factor
    },
  };

  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const processData = (layout) => {
    const datasets = [];

    layout.map((array, i) => {
      const x = [];
      const y = [];

      array.geometry.coordinates.forEach((element) => {
        x.push(element[0]);
        y.push(element[1]);
      });

      datasets.push({
        name: `Array ${i + 1}`,
        x: x,
        y: y,
        type: "scatter",
        line: {
          color: usedColours[i],
          width: 3,
        },
      });
    });

    return { datasets };
  };

  const reset = () => {
    setLayouts([]);
    setSelectedOption({
      plot: null,
      index: 0,
      data: null,
    });
    setUsedColours([]);
    setProcessedData([]);
    setTotalLength(0);
    setBoard((board) => ({
      ...board,
      activeSite: null,
      activateLayouts: false,
    }));
  };

  const handlePlot = () => {
    setPlatformData((platformData) => ({
      ...platformData,
      siteBuilder: {
        ...platformData.siteBuilder,
        [siteID]: {
          ...platformData.siteBuilder[siteID],
          siteCables: convertToOldCables(selectedOption.data, siteID),
        },
      },
    }));

    reset();
  };

  const plots = (option) => {
    return (
      <div
        className="layout-option"
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          background: "rgba(211, 211, 211, 0.3294117647)",
          borderRadius: "8px",
          margin: "8px",
        }}
      >
        <StyledSpan
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: "1000",
          }}
          onClick={() =>
            setSelectedOption({
              plot: (
                <Plot
                  //   ref={plotRef}
                  // useResizeHandler
                  style={{ width: "100%", height: "450px" }}
                  data={processedData[layouts.indexOf(option)].datasets}
                  layout={layout}
                  config={config}
                />
              ),
              index: layouts.indexOf(option),
              data: layouts[layouts.indexOf(option)],
            })
          }
        />
        {processedData.length > 0 && (
          <Plot
            //   ref={plotRef}
            // useResizeHandler
            // className="layout-option"
            style={{ width: "200px", height: "200px" }}
            data={processedData[layouts.indexOf(option)].datasets}
            layout={{
              ...layout,
              title: `Layout ${layouts.indexOf(option) + 1}`,
              width: 200,
              height: 200,
              autosize: false,
              xaxis: {
                showgrid: false,
                zeroline: false,
                autorange: true,
                visible: false,
              },
              yaxis: {
                showgrid: false,
                zeroline: false,
                autorange: true,
                visible: false,
              },
            }}
            config={config}
          />
        )}
      </div>
    );
  };

  useEffect(() => {
    setLayouts(options);
  }, [options]);

  useEffect(() => {
    if (layouts.length > 0) {
      layouts[0].features.map((line) => {
        setTotalLength((totalLength) => totalLength + turf.length(line));
        setUsedColours((usedColours) => [...usedColours, getRandomColor()]);
      });
    }
  }, [layouts]);

  useEffect(() => {
    setTotalLength(0);

    if (selectedOption.data) {
      selectedOption.data.features.map((line) => {
        setTotalLength((totalLength) => totalLength + turf.length(line));
      });
    }
  }, [selectedOption]);

  useEffect(() => {
    if (
      layouts.length > 0 &&
      usedColours.length === layouts[0].features.length
    ) {
      layouts.map((layout) => {
        // console.log(layout);

        setProcessedData((processedData) => [
          ...processedData,
          processData(layout.features),
        ]);
      });

      setSelectedOption({
        plot: (
          <Plot
            //   ref={plotRef}
            useResizeHandler
            style={{ width: "100%", height: "450px" }}
            data={processData(layouts[0].features).datasets}
            layout={{ ...layout, height: 450 }}
            config={config}
          />
        ),
        index: 0,
        data: layouts[0],
      });
    }
  }, [usedColours]);

  return (
    <Modal
      open={open}
      onClose={() => {
        reset();
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div
        // className="project-dashboard-popup"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0,0,0,0.6)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "background.paper",
            borderRadius: "8px",
            padding: 2,
            position: "fixed",
            inset: "10% 20%",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
          }}
        >
          <header>
            <StandardContainerRow style={{ overflow: "hidden" }}>
              <h2 style={{ margin: "0" }}>Layout Options</h2>
              <StyledIconButton
                onClick={() => {
                  reset();
                }}
              >
                <FontAwesomeIcon icon={faClose} />
              </StyledIconButton>
            </StandardContainerRow>
            <Divider
              style={{ marginBottom: "16px" }}
              sx={{ bgcolor: "black" }}
            />
          </header>

          {layouts.length > 0 ? (
            <body
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  alignItems: "center",
                  height: "70%",
                }}
              >
                <div style={{ width: "50%" }}>{selectedOption.plot}</div>

                <div
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <p>
                    Selected Option:{" "}
                    <strong>Layout {selectedOption.index + 1}</strong>
                  </p>

                  <ul style={{ maxHeight: "300px", overflow: "scroll" }}>
                    {layouts.length > 0 &&
                      layouts[selectedOption.index].features.map((line, i) => {
                        const length = turf.length(line);
                        return (
                          <li style={{ display: "flex", gap: "8px" }}>
                            <div
                              style={{
                                width: "50px",
                                height: "20px",
                                background: usedColours[i],
                                borderRadius: "8px",
                              }}
                            ></div>{" "}
                            <p>
                              Array {i + 1}, Length -{" "}
                              <strong>{length.toFixed(2)} km</strong>
                            </p>
                          </li>
                        );
                      })}
                  </ul>

                  <p>
                    Total Length - <strong>{totalLength.toFixed(2)} km</strong>
                  </p>

                  <span>
                    * Some layouts might be the same due to no potential other
                    layouts possible
                  </span>
                </div>
              </div>

              <Divider />

              <Carousel
                style={{ height: "35%" }}
                value={layouts}
                numVisible={3}
                numScroll={3}
                //   responsiveOptions={responsiveOptions}
                itemTemplate={plots}
              />
            </body>
          ) : (
            <body
              style={{
                width: "100%",
                height: "80%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <p>
                <strong>No layouts to be displayed -</strong> Run cable
                optimiser on site{" "}
              </p>
            </body>
          )}

          {layouts.length > 0 && (
            <footer style={{ padding: "8px" }}>
              <PrimeButton
                style={{ width: "fit-content", float: "right" }}
                onClick={handlePlot}
              >
                Plot on Map
              </PrimeButton>
            </footer>
          )}
        </Box>
      </div>
    </Modal>
  );
}

export default LayoutOptions;

import React, { useState } from "react";
import Modal from "react-modal";
import { PrimeButton, SecButton, StyledIconButton } from "../../../styles/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestion } from "@fortawesome/free-solid-svg-icons";


Modal.setAppElement("#root"); // Set the root element for accessibility

function GeographicalCentreOfEnergyProduction() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const buttons = [
    { id: 1, label: "Floating Offshore Wind", content: "" },
  ];

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent("");
  };

  return (
    <div className="elevationAnalysis">
    <div style={{ display: "flex"}}>
      <h2></h2>
      <StyledIconButton className="widgetTour">
      </StyledIconButton>
    </div>
    <hr />
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: "20px", paddingLeft:"40px" }}>
      {buttons.map((button) => (
        <PrimeButton
          key={button.id}
          onClick={() => openModal(button.content)}
        >
          {button.label}
        </PrimeButton>
      ))}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Chart Modal"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        <h2>{modalContent}</h2>
        <iframe
          title=""
          width="1200px"
          height="800px"
          src="https://observablehq.com/embed/@mark-cherrie/the-geographical-centre-of-energy-production@1317?cell=*"
          style={{ border: "none", borderRadius:"15px" }}
        />
        <SecButton onClick={closeModal}>
          Close
        </SecButton>
      </Modal>
    </div>
    </div>
  );
}

export default GeographicalCentreOfEnergyProduction;
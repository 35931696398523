/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React, { useState } from "react";
import { useEffect } from "react";
import { useMap } from "react-leaflet";
import { v4 as uuidv4 } from "uuid";
import L from "leaflet";
import { PlatformData, LoadingStatus } from "../../imports/Context";
import { useLocation, useNavigate } from "react-router-dom";

import {
  addLocationMarker,
  drawPoint,
  drawPolygon,
  drawPolyline,
  drawRectangle,
  extractGeometries,
  getSelectedCoords,
  getSelectedLatLngs,
  getSelectedType,
  guideLine,
  guideRectangle,
  guideTriangle,
  highlightSelected,
  logGeometriesFromPane,
  renderGeometries,
  renderSiteCables,
  resetDrawingLayer,
  resetGuideLine,
  resetGuideRectangle,
  resetGuideTriangle,
  updateElevationAnalysis,
  updateElevationAnalysisData,
  createSitePopup,
  placeTurbine,
  extractSiteCables,
  clearMeasurements,
  clusterTurbines,
  renderSiteExclusions,
  placeFoundations,
} from "./drawFunctions";

import * as turf from "@turf/turf";

import { useRef, useContext } from "react";
import toast from "react-hot-toast";

function Board({ board, setBoard }) {
  const { platformData, setPlatformData } = useContext(PlatformData);
  const { loadingStatus, setLoadingStatus } = useContext(LoadingStatus);
  const location = useLocation();
  const [mapReady, setMapReady] = useState(false);
  const [sitePopup, setSitePopup] = useState(null);
  const navigate = useNavigate();
  const map = useMap();
  const [siteManagement, setSiteManagement] = useState({
    activeSite: null,
    activateLayouts: false,
    activateSiteLayouts: [],
  });
  const configRef = useRef({
    map: map,
    mode: board.mode,
    pane: "Board",

    interactions: {
      move: {
        alreaydMoving: false,
      },
    },
    layers: {
      selected: null,
      drawing: uuidv4(),
      guide: "guide",
    },
    style: {
      radius: 5,
      radiusH: 7,
      weight: 3,
      weightH: 5,
      color: "#009ec6",
      fillColor: "#009ec6",
      fillOpacity: 0.3,
      fillOpacityH: 0.6,
      zIndex: 499,
    },
    currentSite: null,
    platformData: platformData,
    setPlatformData: setPlatformData,
    setLoadingStatus: setLoadingStatus,
    setSiteManagement: setSiteManagement,
    updateBoard: () => {
      // EXTRACT GEOMETRIES:
      const geometries = extractGeometries(map, configRef.current.pane);
      setBoard((old_board) => ({
        ...old_board,
        ["points"]: geometries.points,
        ["lines"]: geometries.lines,
        ["polygons"]: geometries.polygons,
      }));

      setPlatformData((platformData) => ({
        ...platformData,
        ["drawnLayers"]: {
          ["points"]: geometries.points,
          ["lines"]: geometries.lines,
          ["polygons"]: geometries.polygons,
        },
      }));
    },
    navigate: navigate,
  });

  const lasso = L.lasso(configRef.current.map, { intersect: true });

  useEffect(() => {
    configRef.current.platformData = platformData;
    configRef.current.setPlatformData = setPlatformData;
  }, [
    setPlatformData,
    platformData,
    configRef.current.platformData.siteBuilder,
  ]);

  useEffect(() => {
    const config = configRef.current;
    const boardPane = map.createPane(config.pane);
    boardPane.style.zIndex = config.style.zIndex;

    configRef.current.map.whenReady(() => {
      setMapReady(true);
    });
  }, []);

  useEffect(() => {
    console.log({ siteManagement });

    setBoard((board) => ({
      ...board,
      ...siteManagement,
    }));
  }, [siteManagement]);

  /**
   *
   * On load of a saved project, re-populate the points, lines and polygons
   * with the saved values.
   *
   */
  useEffect(() => {
    const points = platformData.drawnLayers.points;
    const lines = platformData.drawnLayers.lines;
    const polygons = platformData.drawnLayers.polygons;

    // Removed as a fix for when a save doesn't contain drawn geometry
    // if (points.length > 0 || lines.length > 0 || polygons.length > 0) {
    if (
      JSON.stringify(points) !== JSON.stringify(board.points) ||
      JSON.stringify(lines) !== JSON.stringify(board.lines) ||
      JSON.stringify(polygons) !== JSON.stringify(board.polygons)
    ) {
      if (map && configRef.current) {
        setBoard({
          ...board,
          points: platformData.drawnLayers.points,
          lines: platformData.drawnLayers.lines,
          polygons: platformData.drawnLayers.polygons,
        });

        renderGeometries(
          configRef,
          configRef.current.pane,
          platformData.drawnLayers
        );
      }
      // }
    }
  }, [
    platformData.drawnLayers.points,
    platformData.drawnLayers.polygons,
    platformData.drawnLayers.lines,
    map,
    configRef.current,
  ]);

  useEffect(() => {
    const uploadpoint = platformData.weatherAnalysis.uploadpoint;

    if (JSON.stringify(uploadpoint) !== JSON.stringify(board.uploadpoint)) {
      if (map && configRef.current) {
        setBoard({
          ...board,
          uploadpoint: platformData.weatherAnalysis.uploadpoint,
        });

        renderGeometries(configRef, configRef.current.pane, {
          points: uploadpoint,
          lines: [],
          polygons: [],
        });
      }
    }
  }, [platformData.weatherAnalysis.uploadpoint, map, configRef.current]);

  useEffect(() => {
    configRef.current.mode = board.mode;
    resetDrawingLayer(configRef, map);
    resetGuideLine(configRef);
    resetGuideTriangle(configRef);
    resetGuideRectangle(configRef);
  }, [board.mode]);

  useEffect(() => {
    const mode = board.mode;

    const lassoStatusCheck = (e) => {
      if (mode === "cluster_draw") {
        lasso.enable();
      }
    };

    lassoStatusCheck();

    const onClick = (e) => {
      switch (mode) {
        case "point":
          drawPoint(e, configRef);
          break;
        case "line":
          drawPolyline(e, configRef);
          guideLine(e, configRef);
          break;
        case "polygon":
          drawPolygon(e, configRef);
          guideTriangle(e, configRef);
          break;
        case "rectangle":
          drawRectangle(e, configRef);
          guideRectangle(e, configRef);
          resetGuideRectangle(configRef);
          break;
        case "select":
          highlightSelected(configRef);

          if (platformData.activeWidget === "ElevationAnalysis") {
            map.eachLayer((layer) => {
              if (layer._leaflet_id === configRef.current.selected) {
                var elevationArea = 0,
                  elevationLength = 0;
                if (
                  layer instanceof L.Polygon ||
                  layer instanceof L.CircleMarker
                ) {
                  elevationArea = turf.area(layer.toGeoJSON()) / 1000000;
                } else {
                  elevationLength = turf.length(layer.toGeoJSON());
                }

                if (elevationArea <= 1000 && elevationLength <= 750) {
                  updateElevationAnalysisData(
                    configRef,
                    setPlatformData,
                    setLoadingStatus
                  );
                } else {
                  alert(
                    "The selected shape is too big! Please try again with a smaller shape"
                  );
                }
              }
            });
          }
          break;
        case "siteBuilder":
          setSitePopup(createSitePopup(configRef, e.latlng, "Board"));
          break;
        case "exclusion":
          const style = {
            radius: 5,
            radiusH: 7,
            weight: 3,
            weightH: 5,
            color: "#FFA500",
            fillColor: "#FFA500",
            fillOpacity: 0.3,
            fillOpacityH: 0.6,
            zIndex: 500,
          };

          drawPolygon(e, configRef, style, "site-temp-exclusion");
          guideTriangle(e, configRef, "site-temp-exclusion");
          break;
        case "locationMarker":
          addLocationMarker(e, configRef);
          break;
        case "measure":
          highlightSelected(configRef);

          // addMeasureToShape()
          break;
        default:
          break;
      }

      configRef.current.updateBoard();
    };

    const onMove = (e) => {
      switch (mode) {
        case "point":
          break;
        case "line":
          guideLine(e, configRef);
          break;
        case "polygon":
          guideTriangle(e, configRef);
          break;
        case "rectangle":
          guideRectangle(e, configRef);
          break;
      }
    };

    const onRightClick = (e) => {
      resetDrawingLayer(configRef);
      resetGuideTriangle(configRef);
      resetGuideLine(configRef);
      resetGuideRectangle(configRef);

      configRef.current.updateBoard();

      if (configRef.current.currentSite) {
        const cables = extractSiteCables(configRef.current.map);

        const siteCables = [];
        cables.forEach((cable, i) => {
          if (cable.connectedTo.includes(configRef.current.currentSite)) {
            cable.id = "array " + (siteCables.length + 1);
            siteCables.push(cable);
          }
        });

        setPlatformData((platformData) => ({
          ...platformData,
          ["siteBuilder"]: {
            ...platformData.siteBuilder,
            [configRef.current.currentSite]: {
              ...platformData.siteBuilder[configRef.current.currentSite],
              ["siteCables"]: siteCables,
            },
          },
        }));
      }
    };

    const resetMode = (e) => {
      setBoard((board) => ({
        ...board,
        ["mode"]: "default",
      }));
    };

    if (mode === "clearMeasure") {
      clearMeasurements(configRef.current.map, configRef.current.pane);
    }

    // map.on('polylinemeasure:start', resetMode);
    map.on("click", onClick);
    map.on("mousemove", onMove);
    map.on("contextmenu", onRightClick);
    map.on("lasso.finished", (event) => {
      console.log("Finish");

      clusterTurbines(configRef, "draw", null, event);
    });
    map.on("lasso.disabled", () =>
      setBoard((board) => ({ ...board, ["mode"]: "cluster" }))
    );
    return () => {
      map.off("click", onClick);
      map.off("mousemove", onMove);
      map.off("contextmenu", onRightClick);
      map.off("lasso.finished", (event) => {
        // clusterTurbines(configRef, "draw", null, event);
      });
      map.on("lasso.disabled", () => {
        // setBoard((board) => ({ ...board, ["mode"]: "cluster" }))
      });
      // map.off('polylinemeasure:toogle', resetMode);
    };
  }, [map, board.mode]);

  useEffect(() => {
    const turbines = platformData.siteBuilder;

    configRef.current.map.eachLayer((layer) => {
      if (
        layer.options.pane === "site-builder" ||
        layer.options.pane === "site-boundary" ||
        layer.options.pane === "site-cables" ||
        layer.options.pane === "site-exclusion" ||
        layer.options.pane === "site-anchor-exclusion" ||
        layer.options.pane === "site-radial-exclusion" ||
        layer.options.pane === "site-wtg-exclusion" ||
        layer.options.pane === "site-full-exclusion"
      ) {
        configRef.current.map.removeLayer(layer);
      }
    });

    if (sitePopup) {
      sitePopup.remove();
      setSitePopup(null);
    }

    Object.keys(turbines).map((uid) => {
      if (turbines[uid] && mapReady) {
        if (
          turbines[uid].siteCables.length > 0 &&
          turbines[uid].siteTurbs.length > 0
        ) {
          const siteCables = [];
          turbines[uid].siteCables.forEach((cable) => {
            siteCables.push(cable);
          });

          renderSiteCables(configRef, siteCables, uid);
        }

        placeTurbine(
          turbines[uid].siteTurbs,
          turbines[uid],
          configRef,
          uid,
          turbines[uid].siteOSP,
          turbines[uid].siteStars,
          location.pathname
        );

        if (
          turbines[uid].siteExclusions &&
          turbines[uid].siteExclusions.length > 0 &&
          JSON.parse(turbines[uid].siteExclusions).features.length > 0
        ) {
          renderSiteExclusions(
            JSON.parse(turbines[uid].siteExclusions),
            configRef,
            uid
          );
        }
      }
    });
  }, [
    platformData.siteBuilder,
    Object.keys(platformData.siteBuilder).length,
    mapReady,
  ]);

  return null;
}

export default Board;

const test = {
  elevationJson: {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          coordinates: [
            [
              [-3.186619245329363, 55.966390814516345],
              [-3.21307154517571, 55.94504688151061],
              [-3.1824968089896704, 55.94004570047028],
            ],
          ],
          type: "Polygon",
        },
      },
    ],
  },
  geoJSONtest: {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          coordinates: [
            [
              [-2.223687478460306, 56.41117407644691],
              [-2.223687478460306, 55.93144394643514],
              [-1.2445339343534556, 55.93144394643514],
              [-1.2445339343534556, 56.41117407644691],
              [-2.223687478460306, 56.41117407644691],
            ],
          ],
          type: "Polygon",
        },
      },
    ],
  },
};

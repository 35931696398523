/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

const apiHeaders = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
};

export const elevationAnalysisAPI = async (inputJSON) => {
  try {
    const response = await fetch(
      "https://vekdig-hiibq2h6dq-nw.a.run.app/elevation",
      {
        method: "POST",
        headers: apiHeaders,
        body: JSON.stringify(inputJSON),
      }
    );

    return await response.json()
  } catch (err) {
    console.error(err)
  }
};

export const elevationSiteAPI = async (inputJSON) => {
  try {
    const response = await fetch(
      "https://vekdig-hiibq2h6dq-nw.a.run.app/siteElevation",
      {
        method: "POST",
        headers: apiHeaders,
        body: JSON.stringify(inputJSON),
      }
    );

    return await response.json()
  } catch (err) {
    console.error(err)
  }
};

/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import LoginForm from "./vektaDigital/Login";
import LandingPage from "./landing/index";
import Tool from "./vektaDigital/Tool";
import { doc, getDoc } from "firebase/firestore";

import LayerControl from "./vektaDigital/widgets/layerControl/LayerControl";
import WeatherAnalysis from "./vektaDigital/widgets/weatherAnalysis/WeatherAnalysis";
import Yield from "./vektaDigital/widgets/yield/Yield";

import ElevationAnalysis from "./vektaDigital/widgets/elevationAnalysis/ElevationAnalysis";
import DataSearch from "./vektaDigital/widgets/dataSearch/DataSearch";
import Insights from "./vektaDigital/widgets/insights/Insights";



import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  Navigate,

} from "react-router-dom";
import { onAuthStateChanged } from "@firebase/auth";
import { auth, accessList, db } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { CookiesProvider } from "react-cookie";

import "primereact/resources/themes/lara-light-cyan/theme.css";

function App() {
  const [authorized, setAuthorized] = useState(false);
  const [page, setPage] = useState("home");
  const [user, loading, error] = useAuthState(auth);

  useEffect(() => {
    if (user) {
      const checkAuthorization = async () => {
        if (user) {
          const userAccount = await getDoc(doc(db, "userAccounts", user.uid));

          userAccount.data().owned_tools.forEach(async (tool) => {
            if (tool.active) {
              const productInfo = await getDoc(tool.prodID);

              if (productInfo.data().name === "Vekta Digital +") {
                window.location.replace(
                  "https://vekta-digitalplus.web.app/"
                );
              }
            }
          });
        }
      };

      checkAuthorization();
    }
  }, [user]);

  const ProtectedRoute = ({ isAuthorized, redirectPath = "/", children }) => {
    if (!isAuthorized) {
      return <Navigate to={redirectPath} replace />;
    }

    return children;
  };

  return (
    <CookiesProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          {/* {authorized ? ( */}
          <Route exact path="vekta-digital" element={<Tool />}>
            <Route exect path="LayerControl" element={<LayerControl />} />
            <Route exect path="WeatherAnalysis" element={<WeatherAnalysis />} />
            <Route
              exect
              path="ElevationAnalysis"
              element={<ElevationAnalysis />}
            />
            {/* <Route exect path="Insights" element={<Insights />} /> */}
            <Route exect path="DataSearch" element={<DataSearch />} />
            <Route exect path="Yield" element={<Yield />} />
          </Route>
        </Routes>
      </Router>
    </CookiesProvider>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { PrimeButton } from "../../../styles/styles";
import { Divider, Typography } from "@mui/material";
import { InputNumber } from "primereact/inputnumber";
import styled from "styled-components";
import { convertToGeoJson, convertToSplitSite } from "../../convertors";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  font-size: 1.2rem;

  .input {
    padding-bottom: 5px;
    font-family: "Montserrat";
    input {
      padding-left: 5px;
    }

    select {
      padding-left: 5px;
    }
  }
`;

const Title = styled(Typography)`
  color: #00a0c6;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 8px;
`;

const apiHeaders = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
  Accept: "application/json",
  "Content-Type": "application/json",
};

function SiteOSPMenu({ configRef, popup, spinner }) {
  const [settings, setSettings] = useState({
    noOSPs: 1,
  });
  const [megawatts, setMegawatts] = useState(0);
  const [osps, setOsps] = useState(1);

  const [useMessage, setUseMessage] = useState("standard");
  const messages = {
    standard: (
      <span>
        Based on site rating, ~ {megawatts}MW -{" "}
        <strong>{osps} OSP(s) Recommended</strong>
      </span>
    ),
    trub: <span>No turbine associated with this site for recommendation</span>,
    ospMax: <span>Max OSPs reached, please plot Manually</span>,
  };

  const handleOSP = async () => {
    const siteID = configRef.current.currentSite;

    spinner.addTo(configRef.current.map);

    try {
      const response = await fetch(
        //   `https://vekwin-hiibq2h6dq-ez.a.run.app/${siteOptions.fillType}`,
        `https://arrayopti-405606309488.europe-west2.run.app/placeOSPs`,
        {
          method: "POST",
          headers: apiHeaders,
          body: JSON.stringify(
            convertToGeoJson(
              settings,
              configRef.current.platformData.siteBuilder[siteID].siteTurbs,
              "osp"
            )
          ),
        }
      ).catch((error) => {
        console.log(error);
        spinner.remove();
        return;
      });

      const result = await response.json();
      console.log(JSON.parse(result));

      const site = convertToSplitSite(result);

      const temp = site.siteOSP.map((osp, i) => {
        osp.ids = "osp" + (i + 1);
        osp.cluster = i + 1;
        return osp;
      });

      console.log(temp);

      configRef.current.setPlatformData((platformData) => ({
        ...platformData,
        siteBuilder: {
          ...platformData.siteBuilder,
          [siteID]: {
            ...platformData.siteBuilder[siteID],
            siteOSP: temp,
            siteTurbs: site.siteTurbs,
          },
        },
      }));

      spinner.remove();
    } catch (err) {
      console.log(err);
      // alert("Sorry! Something went wrong, please try again later")
      spinner.remove();
      return;
    }
  };

  useEffect(() => {
    const siteInfo =
      configRef.current.platformData.siteBuilder[configRef.current.currentSite];

    if (siteInfo.siteSettings.turb) {
      setMegawatts(
        siteInfo.siteTurbs.length * siteInfo.siteSettings.turb.split("MW")[0]
      );

      const noOsps = Math.ceil(
        (siteInfo.siteTurbs.length *
          siteInfo.siteSettings.turb.split("MW")[0]) /
          750
      );
      setOsps(noOsps);

      if (noOsps > 10) {
        setUseMessage("ospMax");
      }

      setUseMessage("standard");
      return;
    }

    setUseMessage("turb");
  }, []);

  return (
    <Container id="siteCables_options" className="siteCables_options">
      <Title variant="h7" component="h2" align="center">
        AUTOMATIC OSP OPTIONS
      </Title>

      <div className="input" style={{ width: "100%" }}>
        <label>Number of OSPs</label>
        <InputNumber
          style={{ width: "100%" }}
          min={1}
          max={10}
          placeholder="Min - 1, Max - 10"
          value={settings.noOSPs}
          onChange={(e) =>
            setSettings((settings) => ({
              ...settings,
              noOSPs: e.value,
            }))
          }
        />
      </div>

      {messages[useMessage]}

      <Divider />

      <PrimeButton
        style={{
          width: "100%",
          flexGrow: 1,
          fontSize: "14px", // Adjust the font size for a larger button
          //   color: "#00A0C6", // Add this line to change the text color
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          console.log(configRef.current);
          handleOSP();

          popup.remove();
        }}
      >
        Place OSPs
      </PrimeButton>
    </Container>
  );
}

export default SiteOSPMenu;

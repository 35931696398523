/**
 * 
 * This code is written, owned and maintained by 
 * Vekta Group Energy Division.
 * 
 * © 2023, Vekta Group Energy Division.
 * 
 */

import styled from "styled-components";
import { Typography, Button } from "@mui/material";
///import WindPowerIcon from '../../../svg/weatherAnalysis.svg';
import WindPowerIcon from '@mui/icons-material/WindPower';
////import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
///import CloudIcon from "@mui/icons-material/Cloud";

import {
  highlightSelected,
  updateElevationAnalysisData,
  getSelectedLatLngs,
  placeTurbine,
  createSitePopup,
  renderGeometries
} from "../drawFunctions";
import { useContext, useEffect, useState } from "react";
import L from "leaflet"
import * as turf from "@turf/turf";
import Modal from 'react-modal';

import elevation from "../../../media/ElevationAnalysis.svg"
import weather from "../../../media/WeatherAnalysis.svg"
import yieldIcon from "../../../media/Yield.svg"
import { useNavigate } from "react-router-dom";

const CustomWindPowerIcon = (props) => {
  return (
    <WindPowerIcon {...props} style={{ fill: '#000000' }}>
      {/* Your SVG path or other elements go here */}
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 14h2v-6h-2zm0-8h2v-2h-2z" />
    </WindPowerIcon>
  );
};

const ApiMenu = ({ configRef, popup, layer, coords }) => {
  const [turbLocations, setTurbLocations] = useState(null)
  const [sitePopup, setSitePopup] = useState(null)

  // const navigate = useNavigate();

  const platformData = configRef.current.platformData
  const setPlatformData = configRef.current.setPlatformData;

  useEffect(() => {
    configRef.current.selected = layer._leaflet_id;
    highlightSelected(configRef);
  }, []);

  useEffect(() => {
    configRef.current.platformData = platformData
    configRef.current.setPlatformData = setPlatformData
  }, [setPlatformData, platformData])

  const elevationAPI = (configRef) => {
    highlightSelected(configRef);

    let setPlatformData = configRef.current.setPlatformData;
    let setLoadingStatus = configRef.current.setLoadingStatus;
    const navigate = configRef.current.navigate;

    navigate("ElevationAnalysis");

    setPlatformData((platformData) => {
      return {
        ...platformData,
        ["activeWidget"]: "ElevationAnalysis",
        ["sidePanelOpen"]: true,
      };
    });

    var elevationArea = 0, elevationLength = 0
    if (layer instanceof L.Polygon || layer instanceof L.CircleMarker) {
      elevationArea = turf.area(layer.toGeoJSON()) / 1000000
    } else {
      elevationLength = turf.length(layer.toGeoJSON())
    }

    if (elevationArea <= 1000 && elevationLength <= 750) {
      updateElevationAnalysisData(configRef, setPlatformData, setLoadingStatus);
    } else {
      alert("The selected shape is too big! Please try again with a smaller shape")
    }
  };

  const weatherAPI = () => {
    const config = configRef.current;
    const platformData = config.platformData
    const setPlatformData = config.setPlatformData;
    const navigate = config.navigate;
    let setLoadingStatus = configRef.current.setLoadingStatus;

    setLoadingStatus({ loading: true, loadingMsg: "Processing ERA5 Data . . ." })
    navigate("WeatherAnalysis");

    if (!(layer instanceof L.CircleMarker)) {
      alert("not a circle marker")
      return
    }
    const latlng = layer.getLatLng()
    setPlatformData((platformData) => ({
      ...platformData,
      ["activeWidget"]: "WeatherAnalysis",
      ["sidePanelOpen"]: true,

    }));

    const apiHeaders = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
      'Access-Control-Request-Method': 'GET, POST, DELETE, PUT, OPTIONS',
    }
    const weatherJson = {
      "type": "Feature",
      "geometry": {
        "type": "Point",
        "coordinates": [
          latlng.lng,
          latlng.lat
        ]
      },
      "properties": {
        "name": "Sample Point",
        "dataloc": "gcp",
        "weatherdatatype": "windrose",
        "windheight": (isNaN(platformData.weatherAnalysis.windHeight)) ? 100 : Number(platformData.weatherAnalysis.windHeight)
      }
    }

    config.map.eachLayer((layer) => {
      if (layer.options.pane === "era5-point") {
        config.map.removeLayer(layer);
      }
    });

    fetch("https://vekdig-hiibq2h6dq-nw.a.run.app/weather", {
      method: "POST",
      headers: apiHeaders,
      body: JSON.stringify(weatherJson)

    })
      .then((response) => {
        if (response.status === 401) {

        } else if (response.status === 200) {
          response.json().then((windRose) => {
            weatherJson.properties.weatherdatatype = "avgwindspeed"
            fetch("https://vekdig-hiibq2h6dq-nw.a.run.app/weather", {
              method: "POST",
              headers: apiHeaders,
              body: JSON.stringify(weatherJson)

            })
              .then((response) => {
                if (response.status === 401) {

                } else if (response.status === 200) {
                  response.json().then((windSpeed) => {
                    setPlatformData(platformData => ({
                      ...platformData, weatherAnalysis: {
                        ...platformData.weatherAnalysis, pointClick: [
                          parseFloat(windRose.ERALong[0]),
                          parseFloat(windRose.ERALat[0])
                        ], windSpeedData: windSpeed, windRoseData: windRose
                      }
                    }))

                    L.circle([windRose.ERALat[0], windRose.ERALong[0]], { radius: 1500, color: "yellow", pane: "era5-point" }).bindTooltip("Selected Weather Point").openTooltip().addTo(config.map);
                    setLoadingStatus({ loading: false, loadingMsg: "" })
                  });
                }
              })
              .catch((err) => {
                console.log(err);
              });
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const siteBuilderAPI = () => {
    if (layer instanceof L.CircleMarker) {
      alert("Site builder can't be run on a point.")
      return
    }

    if (layer.options.pane !== "site-boundary") {
      setSitePopup(createSitePopup(configRef, coords, "API", layer))
    } else {
      alert("Cant run site builder on an existing site.")
    }
  }

  // const yieldAPI = (event) => {
  //   // Define constants
  //   // const deficitModel = ["NOJDeficit"];
  //   // const superposition = ["LinearSum"];
  //   const hub = [turbineparams.turb_hub];
  //   // const dia = [turbineparams.turb_diameter];
  //   const turb_name = [turbineparams.turb_name];
  //   setLoadingStatus({ loading: true, loadingMsg: "Processing Site . . ." })

  //   //
  //   // const ERA5point = platformData.weatherAnalysis.pointClick /////

  //   var weatherPoint = null
  //   console.log(selectedWeatherTypeOption);
  //   if (selectedWeatherTypeOption.value === "Manual") {
  //     console.log(platformData.weatherAnalysis.pointClick);

  //     if (Array.isArray(platformData.weatherAnalysis.pointClick)) {
  //       weatherPoint = {
  //         lon: platformData.weatherAnalysis.pointClick[0],
  //         lat: platformData.weatherAnalysis.pointClick[1]
  //       };
  //     }
  //   }

  //   // Combine the data into the desired JSON structure
  //   const combinedData = {
  //     coords: coordsData.coords,
  //     // deficitModel: deficitModel,
  //     // superposition: superposition,
  //     hub: hub,
  //     turbName: turb_name,
  //     // dia: dia,
  //     // powerCurve: powerCurveData
  //     ...(weatherPoint && { weatherPoint: weatherPoint })
  //   };

  //   setMeta({ weatherPoint: combinedData.weatherPoint });

  //   const formattedcoords = formatDataToGeoJSON(coordsData.coords)
  //   callElevationAPI(formattedcoords, setSiteElevation)

  //   // Convert the combined data to JSON format
  //   const combinedJSON = JSON.stringify(combinedData);
  //   sendVektaWindAPIRequest(combinedJSON, setVektaWindResponse, setLoadingStatus)
  // };

  return (
    <Container>
      <Title variant="h7" component="h2" align="center">
        WIDGET MENU
      </Title>

      <FlexCenter>
        <IconContainer>
          <img src={weather} alt="Weather Analysis" />
          <div
            fontSize="small"
            color="primary" />
        </IconContainer>
        <StyledButton
          variant="outlined"
          color="primary"
          style={{
            flexGrow: 1,
            fontSize: '12px', // Adjust the font size for a larger button
            color: '#00A0C6', // Add this line to change the text color
          }}
          onClick={(e) => {
            weatherAPI();
            e.stopPropagation();
            e.preventDefault();
            popup.remove();
          }}
        >
          Weather
        </StyledButton>
      </FlexCenter>

      <FlexCenter>
        <IconContainer>
          <img src={elevation} alt="Elevation Analysis" />
          <div
            fontSize="small"
            color="primary"
          />
        </IconContainer>
        <StyledButton
          variant="outlined"
          color="primary"
          style={{
            flexGrow: 1,
            fontSize: '12px', // Adjust the font size for a larger button
            color: '#00A0C6', // Add this line to change the text color
          }}
          onClick={(e) => {
            elevationAPI(configRef);
            e.stopPropagation();
            e.preventDefault();
            popup.remove();
          }}
        >
          Elevation
        </StyledButton>
      </FlexCenter>



      {(layer.options.pane !== "site-boundary" && layer instanceof L.Polygon) &&
        <FlexCenter>
          <IconContainer>
            <CustomWindPowerIcon fontSize="large" color="primary" />
          </IconContainer>
          <Button
            variant="outlined"
            color="primary"
            style={{
              flexGrow: 1,
              fontSize: '12px', // Adjust the font size for a larger button
              color: '#00A0C6', // Add this line to change the text color
            }}
            onClick={(e) => {
              siteBuilderAPI();
              e.stopPropagation();
              e.preventDefault();
              popup.remove();
            }}
          >
            Site Builder
          </Button>
        </FlexCenter>
      }

      {/* {(layer.options.pane === "site-builder" && (layer instanceof L.Polygon || layer instanceof L.GeoJSON)) &&
        <FlexCenter>
          <IconContainer>
            <img src={yieldIcon} />
          </IconContainer>
          <Button
            variant="outlined"
            color="primary"
            style={{
              flexGrow: 1,
              fontSize: '12px', // Adjust the font size for a larger button
              color: '#00A0C6', // Add this line to change the text color
            }}
            onClick={(e) => {
              // navigate("/vekta-digital/Yield");
              e.stopPropagation();
              e.preventDefault();
              popup.remove();
            }}
          >
            Yield
          </Button>
        </FlexCenter>
      } */}

      <Divider />
      <FlexCenter>
        <Button
          variant="text"
          size="small"
          color="primary"
          style={{
            flexGrow: 1,
            fontSize: '12px', // Adjust the font size for a larger button
            color: '#00A0C6', // Add this line to change the text color
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            popup.remove();
          }}
        >
          Close
        </Button>
      </FlexCenter>
    </Container>
  );
};

export default ApiMenu;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
`;

const Title = styled(Typography)`
  text-transform: uppercase;
  font-family: Montserrat;
  font-weight: bold;
  font-size: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 8px;
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #f0f0f0;
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 5px;
  flex-grow: 1;
  justify-content: flex-start; // Align the text and icon to the left
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: -5px;
`;

import styled from "styled-components";
import { Button, IconButton } from "@mui/material";

const space = 16;
const footerHeight = 123;

export const siteColours = [
  "#167707",
  "#e18db9",
  "#3fd8ad",
  "#8149af",
  "#911b48",
  "#2581b4",
  "#e28018",
  "#72fb04",
  "#d40e40",
  "#d4d722",
];

export const Section = styled("div")`
  position: relative;
  height: calc(50% - ${space / 2}px);
  min-width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${space}px;

  border-radius: 4px;
  overflow: hidden; // Ensure the pseudo-element's blur doesn't extend outside

  h1 {
    margin: 0;
  }
`;
export const CableCollection = styled.div`
  height: calc(100% - ${footerHeight}px);
  width: 100%;
  overflow-y: scroll; //Show scrollbar only if necessary
  position: relative;
  display: flex;
  flex-direction: column;

  color: black;
  padding-bottom: 30px;

  background-color: #ffffff;

  border: 1.5px solid lightgrey;
  border-radius: 4px;
`;
export const Cable = styled.div`
  position: relative;
  /* height: 50%; */
  width: calc(100% - ${space / 2}px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  padding: 10px;

  background-color: white;

  border-radius: 4px;

  .cableName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100% - 160px);

    &:hover {
      overflow: visible;
      white-space: wrap;
    }
  }
`;
export const CableName = styled.div`
  color: white;
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: medium;
  max-width: calc(100% - 80px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 1; /* Allow it to shrink if necessary */
`;

export const ApplyButton = styled("button")`
  font-family: "Montserrat";
  width: 8rem;
  padding: 5px;
  height: 3rem;

  background-color: #007592;
  color: white;
  border: none;
  border-radius: 8px;

  font-size: 16px;
  font-weight: 600;

  &:hover {
    background-color: white;
    color: #009ec6;
    box-shadow: 0px 0px 8px 0px #939393;
  }

  &.subAction {
    margin-right: 16px;
    background-color: white;
    color: #009ec6;
    border: 2px solid #007592;

    &:hover {
      background-color: #007592;
      color: white;
    }
  }
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 8px;

  width: 100%;
`;
export const SectionFooterRight = styled.div`
  position: absolute;
  right: ${space}px;
  bottom: ${space}px;
`;
export const SectionFooterLeft = styled.div`
  /* position: absolute; */
  /* left: ${space}px; */
  /* bottom: ${space}px; */
  /* min-width: calc( 100% - 180px ); */
  /* max-width: calc( 100% - 180px ); */

  font-size: 1rem;
  font-weight: 600;
`;

export const SelectionButton = styled("button")`
  width: 100%;
  height: 5rem;

  font-weight: 600;

  border: 2px solid #00a0c6;
  border-radius: 4px;
`;

export const PrimeButton = styled("button")`
  border: ${(props) =>
    props.disabled ? "2px solid lightgrey" : "2px solid #00A0C6"};
  border-radius: 8px;
  background: #00a0c6;

  opacity: ${(props) => (props.disabled ? "0.5" : "1")};

  padding: 8px;

  color: #ffffff;
  font-family: "Montserrat";

  width: 8rem;

  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;

    ${props => !props.disabled &&
      "&:hover {background-color: #009ec641;}"
    }
`;

export const SecButton = styled("button")`
    border: 2px solid #00A0C6;
    border-radius: 8px;
    background: ${props => props.active ? "#009ec641" : "white"};
    outline: none !important;

  padding: 8px;

  color: #00a0c6;
  font-family: "Montserrat";

  width: 8rem;

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    ${props => !props.disabled &&
    "&:hover { background-color: #f4f4f4;}"
  }

    opacity: ${props => props.disabled && "0.6"};
`;

export const Row8pxGap = styled("div")`
  display: flex;
  gap: 8px;
`;

export const ModalClose = styled(IconButton)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  color: black;
  font-size: initial;

  &:hover {
    background-color: #009ec641 !important;
    transform: scale(1.1);
  }
`;



export const StyledIconButton = styled(IconButton)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #009ec641 !important;
    transform: scale(1.1);
  }
`;

export const StandardContainerColumn = styled("div")`
  height: 50%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StandardContainerRow = styled("div")`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PillButton = styled("button")`
  background-color: ${(props) =>
    props.selected ? "#009ec6" : "#d9d9d9"} !important;

  border-radius: 50px;
  border: none;

  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")} !important;

  color: ${(props) => (props.selected ? "white" : "black")} !important;

  padding: 8px;
  width: 100%;
`;

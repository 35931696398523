/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React, { useEffect, useRef, useState } from "react";
import { Panel, PanelGroup, PanelResizeHandle } from "react-resizable-panels";
import { Routes, Route, Link, Outlet } from "react-router-dom";
import { v4 as uuid } from "uuid";
import Plotly from "plotly.js-basic-dist-min";

import {
  Adjust as PointIcon,
  Grain as MultiSelectIcon,
  Timeline as LineIcon,
  ControlCamera as MoveIcon,
  OpenWith as MultiMoveIcon,
  WindPower as WindPowerIcon,
} from "@mui/icons-material";

import {
  faDrawPolygon as DrawPolygonIcon,
  faMousePointer as SelectIcon,
  faMapMarkerAlt as LocationIcon,
  faTrash as DeleteIcon, // Import the trash (delete) icon
  faRuler as MeasureIcon,
  faAngleDoubleUp as MinimiseIcon,
} from "@fortawesome/free-solid-svg-icons";
import {
  faSquare as RectangleIcon,
  faCircleXmark as ClearMeasureIcon,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useNavigate } from "react-router-dom";
import "leaflet/dist/leaflet.css";

import Joyride, { STATUS } from "react-joyride";

// Tool Compontent Imports
import Map from "./map/Map";
import Sidebar from "./sidebar/Sidebar";
import {
  ActiveBtnContext,
  StoreWidgets,
  PlatformData,
  LoadingStatus,
} from "../imports/Context";

import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

function Tool() {
  // Swap for HTTP call to firestore for the widgest on the store to populate the list of tools
  const navigate = useNavigate();
  const [user, loading, error] = useAuthState(auth);
  const [contentWidth, setContentWidth] = useState("middle");

  const [widgets, setWidgets] = useState(
    [
      {
        wid: uuid(),
        name: "LayerControl",
        svg: "LayerControl.svg",
        title: "Layer Control"
      },
      {
        wid: uuid(),
        name: "WeatherAnalysis",
        svg: "weatherAnalysis.svg",
        title: "Weather Analysis"
      },
      {
        wid: uuid(),
        name: "ElevationAnalysis",
        svg: "elevationAnalysis.svg",
        title: "Elevation Analysis"
      },
      {
        wid: uuid(),
        name: "DataSearch",
        svg: "dataSearch.svg",
        title: "Data Search"
      },
      {
        wid: uuid(),
        name: "Yield",
        svg: "yield.svg",
        title: "Yield"
      },
      // {
      //   wid: uuid(),
      //   name: "Insights",
      //   svg: "insights.svg",
      //   title: "Insights"
      // },
    ]
  );

  const platformStructure = {
    layerControl: {
      activeLayers: [],
      layerOrder: [{ layer: [], tree: [] }],
    },
    weatherAnalysis: {
      renderERA5: true,
      windHeight: 100,
      pointClick: [], // [lng,lat]
      windRoseData: [],
      persistanceData: [],
      persistanceOptions: {
        window: { label: "1000", value: "1000", __isNew__: true },
        wind: { label: "1000", value: "1000", __isNew__: true },
        wave: { label: "1000", value: "1000", __isNew__: true },
        tp: { label: "1000", value: "1000", __isNew__: true },
      },
    },
    elevationAnalysis: {
      inputJSON: {},
      outputJSON: null,
    },
    drawnLayers: {
      points: [],
      polygons: [],
      lines: [],
    },
    siteBuilder: {},
    uploadedLayers: { data: [], layerNames: [], shown: [], bbox: [] },
    dataSearch: {
      change: true,
      cqlFilter: {
        country: { value: null, label: "Select..." },
        harborsize: { value: null, label: "Select..." },
        harbortype: { value: null, label: "Select..." },
        shelter: { value: null, label: "Select..." },
        lift_100_: { value: null, label: "Select..." },
        lift50_100: { value: null, label: "Select..." },
        lift_25_49: { value: null, label: "Select..." },
        cranefixed: { value: null, label: "Select..." },
        cranemobil: { value: null, label: "Select..." },
        cranefloat: { value: null, label: "Select..." },
      },
    },
    mapStatus: { zoom: null, ready: false },
    activeWidget: "LayerControl",
    sidePanelOpen: true,
    showTutorial: true,
  };

  const validateLocalstorage = () => {
    const itemStr = localStorage.getItem("platformData");

    // if the item doesn't exist, return null
    if (!itemStr) {
      return platformStructure;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem("platformData");
      return platformStructure;
    }
    return item.value;
  };

  const [activeBtn, setActiveBtn] = useState(null);
  const [platformData, setPlatformData] = useState(validateLocalstorage);

  const [sidebarContent, setSidebarContent] = useState(() => {
    if (platformData.activeWidget) {
      return true;
    }

    return false;
  });

  const [loadingStatus, setLoadingStatus] = useState({
    loading: false,
    loadingMsg: "Loading...",
  });

  const [runJoyride, setRunJoyride] = useState(false);

  const [{ run, steps }, setJoyride] = useState({
    run: false,
    steps: [
      {
        title: "Welcome to Vekta Digital",
        content: <p>This tour will guide you through key platform features.</p>,
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "center",
        target: "body",
        disableBeacon: true,
      },
      {
        title: "Platform Navigation",
        content: (
          <p>
            Use the vertical sidebar to navigate through the different features
            of the platform.
          </p>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "right",
        target: ".sidebarButtons",
      },
      {
        title: "Widget Button",
        content: (
          <p>
            Select one of the widgets to fully access its its functionality or
            hide/show the 'Widget Panel'.
          </p>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "right",
        target: ".LayerControl ",
      },
      {
        title: "Widget Panel",
        content: (
          <p>
            Click on a widget icon to expand the widget's panel and interact
            with its functionality.{" "}
          </p>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "right",
        target: ".main_sidebar",
      },
      {
        title: "Widget Tour",
        content: (
          <p>
            Each widget has their own guide so if you are ever stuck give this
            button a click.
          </p>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "right",
        target: ".widgetTour",
      },
      {
        title: "The Map",
        content: (
          <p>
            View and visualise data like global bathymetry, uploaded shapefiles,
            and demo wind farm sites on the map.
          </p>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".leaflet-container",
      },
      {
        title: "Draw Tools",
        content: (
          <p>
            Use these tools to draw theoretical site boundaries, cable routes,
            or coordinate markers.
          </p>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "left",
        target: ".drawControls",
      },
      {
        title: "Advanced Search",
        content: (
          <p>
            Start typing here to search for existing Wind, Solar, Hydro,
            Geothermal or Nuclear farms/plants. The map will automatically zoom
            to its location. Click the data point to view the farm/plant
            details.
          </p>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "left",
        target: "#advanced_search",
      },
      {
        title: "Base Maps",
        content: (
          <p>
            Hover over the icon to view base map options. Select your desired
            base map.
          </p>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "left",
        target: ".leaflet-control-layers",
      },
      {
        title: "Measuring Tool",
        content: (
          <p>Easily measure cable lengths by drawing a line with this tool.</p>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "left",
        target: ".measureTool",
      },
      {
        title: "Layer Legend",
        content: (
          <p>
            Layers with this icon have a legend providing more information.
            Click the icon to expand the panel and view legends for checked
            layers.
          </p>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "top",
        target: ".leafet_legendContaier",
      },
    ],
  });

  const [{ runMap, stepsMap }, setJoyride_map] = useState({
    runMap: false,
    stepsMap: [
      {
        title: "Select",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/drawSelect.gif"
            />
            <br></br>
            <br></br>
            <p>
              Select/highlight a drawn shape with this tool.
              <br></br>
              <br></br>
              Use this to do further analysis on the shape e.g. elevation
              analysis
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "left",
        target: ".draw_select",
        disableBeacon: true,
      },
      {
        title: "Move",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/drawMove.gif"
            />
            <br></br>
            <br></br>
            <p>
              This tool allows a shape to be moved freely around the map.
              <br></br>
              <br></br>
              Select the shape you wish to move,
              <br></br>
              <br></br>
              the selected shape will follow the the mouse until dropped in the
              new location.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "left",
        target: ".draw_move",
      },
      {
        title: "Point",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/drawPoint.gif"
            />
            <br></br>
            <br></br>
            <p>Place a point anywhere on the map</p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "left",
        target: ".draw_point",
      },
      {
        title: "Line",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/drawLine.gif"
            />
            <br></br>
            <br></br>
            <p>
              With this tool active, click the map to start drawing a line.
              <br></br>
              <br></br>
              Every click following will create a new node in the line.
              <br></br>
              <br></br>
              To finish the line right click or click the end node.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "left",
        target: ".draw_line",
      },
      {
        title: "Rectangle",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/drawRectangle.gif"
            />
            <br></br>
            <br></br>
            <p>
              While active, click the map to start drawing a rectangle.
              <br></br>
              <br></br>
              The rectangle will spawn from the first mouse click.
              <br></br>
              <br></br>
              To finish the shape, click on the map again.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "left",
        target: ".draw_rectangle",
      },
      {
        title: "Polygon",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/drawPolygon.gif"
            />
            <br></br>
            <br></br>
            <p>
              Use these tools to draw theoretical site boundaries, cable routes,
              or coordinate markers.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "left",
        target: ".draw_polygon",
      },
      {
        title: "Site Builder",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/drawSite.gif"
            />
            <br></br>
            <br></br>
            <p>
              Select anywhere on the map to build a site.
              <br></br>
              <br></br>
              Fill in the site specification using the popup.
              <br></br>
              <br></br>
              Click create site and a site will be generated in its place.
              <br></br>
              <br></br>
              Draw inter-array cables while this tool is active by chain
              clicking the turbines.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "left",
        target: ".draw_site",
      },
      {
        title: "Location Marker",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/drawLocation.gif"
            />
            <br></br>
            <br></br>
            <p>
              Place a marker anywhere to get the coordinates in multiple
              different coordinate systems.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "left",
        target: ".draw_location",
      },
      {
        title: "Delete",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/drawDelete.gif"
            />
            <br></br>
            <br></br>
            <p>Select any drawn shape or site to delete.</p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "left",
        target: ".draw_delete",
      },
      {
        title: "Right Click",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/mapRightClick.gif"
            />
            <br></br>
            <br></br>
            <p>Right click a drawn shape to preform additional analysis.</p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".leaflet-container",
      },
    ],
  });

  const [runJoyrideSteps, setRunJoyrideSteps] = useState(steps);

  useEffect(() => {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: platformData,
      expiry: now.getTime() + 18000000,
    };

    try {
      localStorage.setItem("platformData", JSON.stringify(item));
    } catch (error) {
      alert("File size too big please use a smaller file")
    }
  }, [platformData]);

  // useEffect(() => {
  //   //if (platformData.activeWidget) {
  //   navigate(`/vekta-digital/LayerControl`)
  //   setPlatformData(platformData => ({
  //     ...platformData,
  //     ["activeWidget"]: "LayerControl"
  //   }))
  //   //navigate(platformData.activeWidget)
  //   //}

  // }, [])

  useEffect(() => {
    const main = document.getElementById("main_sidebar").classList;
    const plots = document.getElementsByClassName("js-plotly-plot");

    // switch (size) {
    //   case "less":
    //     main.remove("middleSize")
    //     main.remove("moreSize")
    //     break;
    //   case "middle":
    //     main.remove("lessSize")
    //     main.remove("moreSize")
    //     break;
    //   case "more":
    main.remove("lessSize");
    main.remove("middleSize");
    main.remove("moreSize");
    //     break;
    // }
    main.add(contentWidth + "Size");

    var width;
    if (contentWidth === "less") {
      width = "300px";
    } else if (contentWidth === "middle") {
      width = "400px";
    } else if (contentWidth === "more") {
      width = "500px";
    }

    for (let i = 0; i < plots.length; i++) {
      Plotly.relayout(plots[i], { width: width });
    }
  }, [contentWidth]);

  useEffect(() => {
    if (user) {
      var userCreated = user.metadata.creationTime;
      const current = new Date();
      const month = current.toLocaleString("default", { month: "short" });

      userCreated = userCreated.split(" ");
      const userTime = userCreated[4].split(":");
      const currentTime = [
        current.getHours(),
        current.getMinutes(),
        current.getSeconds(),
      ];

      if (
        String(current.getDate()) !== userCreated[1] &&
        month !== userCreated[2] &&
        String(current.getFullYear()) !== userCreated[3]
      ) {
        setRunJoyride(false);
      } else {
        if (Number(userTime[0]) == currentTime[0] && (currentTime[1] >= Number(userTime[1]) && currentTime[1] <= (Number(userTime[1]) + 5))) {
          setRunJoyride(true);
        } else {
          setRunJoyride(false);
        }
      }
    }
  }, [user, loading]);

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunJoyride(false);
    }
  };

  return (
    <div>
      <Joyride
        continuous
        callback={handleJoyrideCallback}
        run={runJoyride}
        steps={runJoyrideSteps}
        showSkipButton
        showProgress
        styles={{
          options: {
            primaryColor: "#009ec6",
            zIndex: 10000,
          },
        }}
      />

      <PlatformData.Provider value={{ platformData, setPlatformData }}>
        <LoadingStatus.Provider value={{ loadingStatus, setLoadingStatus }}>
          <StoreWidgets.Provider value={widgets}>
            <ActiveBtnContext.Provider value={{ activeBtn, setActiveBtn }}>
              <div style={{ display: "flex" }} className="GIS_Basic">
                <Sidebar
                  sidebarContent={sidebarContent}
                  setSidebarContent={setSidebarContent}
                  setRunJoyride={setRunJoyride}
                  setRunJoyrideSteps={setRunJoyrideSteps}
                  steps={steps}
                  stepsMap={stepsMap}
                  platformStructure={platformStructure}
                />

                <div
                  id="main_sidebar"
                  className={
                    !sidebarContent ? "main_sidebar" : "main_sidebar middleSize"
                  }
                >
                  <Outlet />
                  <div
                    className="resizeButtons toggle_resizeButtons"
                    id="sidebar_sizer"
                  >
                    <button
                      title="Small Sidebar"
                      onClick={() => setContentWidth("less")}
                    >
                      &lt;
                    </button>
                    <button
                      title="Medium Sidebar"
                      onClick={() => setContentWidth("middle")}
                    >
                      =
                    </button>
                    <button
                      title="Big Sidebar"
                      onClick={() => setContentWidth("more")}
                    >
                      &gt;
                    </button>
                  </div>
                </div>

                <Map platformStructure={platformStructure}/>

              </div>
            </ActiveBtnContext.Provider>
          </StoreWidgets.Provider>
        </LoadingStatus.Provider>
      </PlatformData.Provider>
    </div>
  );
}

export default Tool;

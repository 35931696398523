/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/GISindex.css";
import "../node_modules/leaflet/dist/leaflet.css";
import "../node_modules/leaflet.markercluster/dist/MarkerCluster.Default.css";
import "../node_modules/leaflet.markercluster/dist/MarkerCluster.css";
import "../node_modules/leaflet-measure-path/leaflet-measure-path.css";
//import "../node_modules/leaflet-draw/dist/leaflet.draw.css"
// import "../node_modules/leaflet.markercluster/dist/leaflet.markercluster-src"
// import "../node_modules/leaflet.markercluster/dist/leaflet.markercluster"
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

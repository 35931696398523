import React from "react";
import { PrimeButton } from "../../../styles/styles";
import { Divider } from "rsuite";
import styled from "styled-components";
import { Typography } from "@mui/material";
import VDPlus from "../../..//media/VD_plus_logo_04.png";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  font-size: 1.2rem;

  .input {
    padding-bottom: 5px;
    font-family: "Montserrat";
    input {
      padding-left: 5px;
    }

    select {
      padding-left: 5px;
    }
  }
`;

const Title = styled(Typography)`
  color: #00a0c6;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 8px;
`;

function VdUpgrade({ popup }) {
  return (
    <Container className="siteBuilder_options">
      <Title variant="h7" component="h2" align="center">
        UPGRADE TO VEKTA DIGITAL +
      </Title>

      <img
        src={VDPlus}
        width={"100%"}
        style={{ background: "#00A0C6", borderRadius: "8px" }}
      />

      <p>
        Gain full access to site builders functionality by upgrading to Vekta
        Digital +
      </p>

      <Divider />

      <PrimeButton
        style={{
          width: "100%",
          fontSize: "14px", // Adjust the font size for a larger button
          // color: "#00A0C6", // Add this line to change the text color
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          document.getElementById("upgrade-vd-plus").click();

          popup.remove();
        }}
      >
        Upgrade Now
      </PrimeButton>
    </Container>
  );
}

export default VdUpgrade;

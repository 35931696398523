import * as turf from "@turf/turf";

export const convertToGeoJson = (settings, original_geo, type) => {
  const newAPI = {
    settings: settings,
    geometry: null,
  };

  // Convert point clicked to geoJSON for site builder
  if (type === "palceAtPoint") {
    newAPI.geometry = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            coordinates: [original_geo[0].lon, original_geo[0].lat],
            type: "Point",
          },
        },
      ],
    };

    return newAPI;
  }

  // Convert polygon clicked to geoJSON for site builder
  if (type === "fillPolygon" || type === "placeInPolygon") {
    newAPI.geometry = {
      type: "FeatureCollection",
      features: [
        {
          type: "Feature",
          properties: {},
          geometry: {
            coordinates: [original_geo.map((coord) => [coord.lon, coord.lat])],
            type: "Polygon",
          },
        },
      ],
    };

    return newAPI;
  }

  if (type === "yield" || type === "cables" || type === "osp") {
    newAPI.geometry = {
      type: "FeatureCollection",
      features: original_geo.map((item) => {
        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [item.lon, item.lat],
          },
          properties: {
            ids: item.ids,
            ...(item.elevation && {
              elevation: item.elevation,
            }),
            ...(item.aep && {
              aep: item.aep,
            }),
            ...(item.gross && {
              gross: item.gross,
            }),
            ...(item.class && {
              class: item.class,
            }),
            ...(item.cluster && {
              cluster: item.cluster,
            }),
            ...(item.angleUsed && {
              angleUsed: item.angleUsed,
            }),
            // cluster: "1",
          },
        };
      }),
    };

    console.log({ newAPI });

    return newAPI;
  }

  if (type === "polygon") {
    newAPI.geometry = {
      type: "FeatureCollection",
      features: original_geo.map((item) => {
        return {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [item],
          },
          properties: {
            ids: item.ids,
            ...(item.elevation && {
              elevation: item.elevation,
            }),
            ...(item.aep && {
              aep: item.aep,
            }),
            ...(item.gross && {
              gross: item.gross,
            }),
            ...(item.class && {
              class: item.class,
            }),
            ...(item.cluster && {
              cluster: item.cluster,
            }),
            // cluster: "1",
          },
        };
      }),
    };

    console.log({ newAPI });

    return newAPI;
  }
};

export const convertToOldSite = (geoJsonSite) => {
  let site;
  try {
    site = JSON.parse(geoJsonSite);
  } catch (error) {
    console.error("Error parsing JSON:", error.message);
    // Handle the error gracefully, e.g., return an empty array or null
    return [];
  }

  console.log(JSON.parse(geoJsonSite));

  const turbs = [];
  for (let i = 0; i < site.features.length; i++) {
    const feature = site.features[i];

    turbs.push({
      ids: feature.properties.ids,
      lon: feature.geometry.coordinates[0],
      lat: feature.geometry.coordinates[1],
      ...(feature.properties.elevation && {
        elevation: feature.properties.elevation,
      }),
      ...(feature.properties.aep && {
        aep: feature.properties.aep,
      }),
      ...(feature.properties.gross && {
        gross: feature.properties.gross,
      }),
      ...(feature.properties.class && {
        class: feature.properties.class,
      }),
      ...(feature.properties.cluster && {
        cluster: feature.properties.cluster,
      }),
    });
  }

  return turbs;
};

export const convertToOldCables = (geoJsonCables, siteID) => {
  const site = geoJsonCables;
  // const site = JSON.parse(geoJsonCables);
  const cables = [];

  console.log({ site });

  for (let i = 0; i < site.features.length; i++) {
    const feature = site.features[i];

    if (Object.keys(feature).length > 0) {
      cables.push({
        id: feature.properties.name,
        geo: feature.geometry.coordinates.map((item) => ({
          lng: item[0],
          lat: item[1],
        })),
        path: feature.properties.path,
        connectedTo: siteID,
        length: turf.length(feature),
      });
    }
  }

  return cables;
};

export const convertToSplitSite = (geoJSONSite) => {
  const turbs = convertToOldSite(geoJSONSite);
  const osp = turbs.filter((item) => item.class === "OSP");
  const star = turbs.filter((item) => item.class === "star");

  for (let i = 0; i < star.length; i++) {
    turbs.splice(
      turbs.findIndex((item) => item.class === "star"),
      1
    );
  }

  for (let i = 0; i < osp.length; i++) {
    turbs.splice(
      turbs.findIndex((item) => item.class === "OSP"),
      1
    );
  }

  return {
    siteTurbs: turbs,
    siteStars: star,
    siteOSP: osp,
  };
};
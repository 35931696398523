import React, { useRef, useEffect, useState } from 'react';
import { Modal, Box, Divider } from '@mui/material';
import { IconButton } from '@mui/material';
import styled from 'styled-components';
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const storage = getStorage();

// Style components for the grids
const Grid = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
`;

const GridItem = styled.div`
  background: #f9f9f9;
  max-width: 400px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  cursor: pointer;
`;

const Container = styled.div`
  height: ${({ introTextHeight }) => `calc(74vh - ${introTextHeight}px)`};
`;

const IntroText = styled.p`
  margin-bottom: 16px;
  padding: 16px;
`;

const StyledDivider = styled(Divider)`
  margin-bottom: 16px;
  width: 90%;
  margin: 0 auto;
  background-color: grey;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ModalBack = styled(IconButton)`
  padding: 0.25rem 0.25rem; // Add padding to create a larger clickable area
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  color: black;
  font-size: initial;

  &:hover {
    background-color: #009ec641 !important;
    transform: scale(1.1);
  }
`;

export const ModalClose = styled(IconButton)`
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  color: black;
  font-size: initial;

  &:hover {
    background-color: #009ec641 !important;
    transform: scale(1.1);
  }
`;



const InsightModal = ({ modalIsOpen, closeModal, selectedInsight, setSelectedInsight, dataInsights, handleThumbnailClick, calculateDaysSince }) => {
  const introTextRef = useRef(null);
  const [introTextHeight, setIntroTextHeight] = useState(0);
  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    dataInsights.forEach(insight => {
      const imageRef = ref(storage, `appInsightsThumbnails/${insight.name}.png`);
      getDownloadURL(imageRef)
        .then((url) => {
          setImageUrls(prevState => ({ ...prevState, [insight.name]: url }));
        })
        .catch((error) => {
          console.error("Error getting image URL:", error);
        });
    });
  }, [dataInsights]);

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        setIntroTextHeight(entry.contentRect.height);
      }
    });

    if (introTextRef.current) {
      observer.observe(introTextRef.current);
    }

    return () => {
      if (introTextRef.current) {
        observer.unobserve(introTextRef.current);
      }
    };
  }, [selectedInsight]);

  return (
    <Modal open={modalIsOpen} onClose={closeModal}>
      <Box sx={{
        width: "100%",
        height: "100%",
        maxHeight: '90vh',
        maxWidth: '90vw',
        overflowY: 'auto',
        padding: '20px',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: 24,
        margin: 'auto',
        top: '50%',
        left: '50%',
        transform: 'translate3d(-50%, -50%, 0)',
        position: 'absolute'
      }}>
        <header>
          <ModalHeader>
            <h2>Insights</h2>
            <ModalActions>
              <ModalBack
                onClick={() => setSelectedInsight(null)} // Set selectedInsight to null to go back to the grid view
                style={{ marginRight: "20px" }} // Add some margin to separate the buttons
              >
                Back
              </ModalBack>
              <ModalClose onClick={closeModal}>
                X
              </ModalClose>
            </ModalActions>
          </ModalHeader>
          <Divider
            style={{ marginBottom: "16px" }}
            sx={{ bgcolor: "black" }}
          />
        </header>
        {selectedInsight ? (
          <>
            {selectedInsight.introText && (
              <div ref={introTextRef}>
                <IntroText>{selectedInsight.introText}</IntroText>
                <StyledDivider />
              </div>
            )}
            <Container introTextHeight={introTextHeight}>
              <iframe
                title={selectedInsight.name}
                width="100%"
                height="100%"
                src={selectedInsight.url}
                style={{ border: "none", borderRadius: "15px" }}
              />
            </Container>
          </>
        ) : (
          <Grid>
          {dataInsights
            .filter(insight => insight.visibility) // Filter insights based on visibility
            .map((insight, index) => (
              <GridItem key={index} onClick={() => handleThumbnailClick(insight)}>
                <h3 style={{ marginBottom: "16px" }}>{insight.name}</h3>
                <img
                  src={imageUrls[insight.name]}
                  alt={insight.name}
                  style={{ width: "100%", height: "auto", borderRadius: "8px", marginBottom: "10px" }}
                />
                <p>Author: {insight.author}</p>
                <p style={{ margin: "0 0 2px 0" }}>Last Updated: {calculateDaysSince(insight.lastUpdated)} days ago</p>
              </GridItem>
            ))}
        </Grid>
        )}
      </Box>
    </Modal>
  );
};

export default InsightModal;
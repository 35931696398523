import { Divider, Typography } from "@mui/material";
import { InputNumber } from "primereact/inputnumber";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  MapContainer,
  Pane,
  TileLayer,
  GeoJSON,
  LayersControl,
} from "react-leaflet";
import styled from "styled-components";
import {
  PrimeButton,
  SecButton,
  siteColours,
  StyledIconButton,
} from "../../../styles/styles";
import { PlatformData } from "../../../imports/Context";
import { convertToGeoJson } from "../../convertors";
import turbineMarkerIcon from "../../../media/point.svg";
import ospMarkerIcon from "../../../media/square.svg";
import L, { Layer } from "leaflet";
import * as turf from "@turf/turf";
import { auth, storage } from "../../../firebase";
import { ref, uploadBytes } from "firebase/storage";
import { useAuthState } from "react-firebase-hooks/auth";
import spinnerIcon from "../../../media/turbineLoading.gif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayPanel } from "primereact/overlaypanel";
import { toast } from "react-hot-toast";

import InitalAngle from "../../../media/InitalAngle.png";

const apiHeaders = {
  "Access-Control-Allow-Origin": "*",
  "Access-Control-Allow-Headers":
    "Origin, X-Requested-With, Content-Type, Accept, Authorization",
  "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
  Accept: "application/json",
  "Content-Type": "application/json",
};

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  font-size: 1.2rem;

  .input {
    padding-bottom: 5px;
    font-family: "Montserrat";
    input {
      padding-left: 5px;
    }

    select {
      padding-left: 5px;
    }
  }
`;
const Title = styled(Typography)`
  color: #00a0c6;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 8px;
`;

function SiteAnchorMenu({ configRef, popup, spinner }) {
  const [user, error] = useAuthState(auth);
  const position = [55.9533, -3.1883];
  const [readytoRender, setReadytoRender] = useState(false);
  const anchorMap = useRef();
  const [loading, setLoading] = useState(true);
  const turbineAngle = useRef();

  const [settings, setSettings] = useState({
    lineLength: 800,
    lineBuffer: 50,
    anchorBuffer: 50,
    noAnchor: 3,
    centreExclude: 300,
    centreExcludeOSP: 100,
    startAngle: 90,
    calledFrom: "api",
  });

  const [anchorGeoJSON, setAnchorGeoJSON] = useState([]);
  const [anchorSystem, setAnchorSystem] = useState({
    anchorBuffersPoint: [],
    anchorExclusion: [],
    moorPyInputs: [],
    radialExclusion: [],
    wtgExclusion: [],
    fullExclusion: [],
  });
  const [anchorStyle, setAnchorStyle] = useState({
    anchorBuffersPoint: [],
    anchorExclusion: "#FF0000",
    fullExclusion: "#00A0C6",
    moorPyInputs: [],
    radialExclusion: "#00A0C6",
    wtgExclusion: "#FFFFFF",
  });

  const handleAnchors = async (type) => {
    const site =
      configRef.current.platformData.siteBuilder[configRef.current.currentSite];
    let turbs = site.siteTurbs;

    if (!turbs[0].hasOwnProperty("angleUsed")) {
      turbs = site.siteTurbs.map((element) => {
        element["angleUsed"] = settings.startAngle;
        return element;
      });
    }

    setAnchorSystem({
      anchorBuffersPoint: [],
      anchorExclusion: [],
      moorPyInputs: [],
      radialExclusion: [],
      wtgExclusion: [],
      fullExclusion: [],
    });
    setAnchorGeoJSON([]);
    setLoading(true);

    try {
      const response = await fetch(
        `https://vekwin2-405606309488.us-central1.run.app/processAnchorPoints`,
        {
          method: "POST",
          headers: apiHeaders,
          body: JSON.stringify(
            convertToGeoJson(
              { ...settings, avoid: type === "auto" },
              [...turbs, ...site.siteOSP],
              "cables"
            )
          ),
        }
      ).catch((e) => {
        console.log(e);
        setLoading(false);
      });

      console.log({ response });

      const anchors = await response.json();

      setAnchorSystem({
        anchorBuffersPoint: JSON.parse(anchors.anchorBuffersPoint),
        anchorExclusion: JSON.parse(anchors.anchorExclusion),
        moorPyInputs: JSON.parse(anchors.moorPyInputs),
        radialExclusion: JSON.parse(anchors.radialExclusion),
        wtgExclusion: JSON.parse(anchors.wtgExclusion),
        fullExclusion: JSON.parse(anchors.fullExclusion),
      });

      const geojson = [];
      Object.keys(anchorSystem).forEach((key) => {
        if (
          key !== "coords" &&
          key !== "moorPyInputs" &&
          key !== "anchorBuffersPoint"
        ) {
          const name = key.split(/(?=[A-Z])/);
          geojson.push(
            // JSON.parse(anchors[key]).type === "FeatureCollection" && (
            <LayersControl.Overlay
              name={` ${name[0].charAt(0).toUpperCase()}${name[0].slice(1)} ${
                name[1]
              }`}
              checked={key === "fullExclusion"}
            >
              <GeoJSON
                key={key}
                data={JSON.parse(anchors[key])}
                pane={key}
                style={{ color: anchorStyle[key] }}
              />
            </LayersControl.Overlay>
            // )
          );
        }

        setLoading(false);
        return geojson;
      });

      setAnchorGeoJSON(geojson);
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again!", {
        icon: "✋",
        duration: 5000,
        id: "anchors",
        style: {
          fontSize: "18px",
          minWidth: "300px",
        },
      });
      setLoading(false);
    }
  };

  const handlePlot = () => {
    const storageRef = ref(
      storage,
      "userSites/" + user.uid + "/" + configRef.current.currentSite + ".geojson"
    );

    const blob = new Blob([JSON.stringify(anchorSystem)], {
      type: "application/json",
    });

    uploadBytes(storageRef, blob).then((snapshot) => {
      configRef.current.setPlatformData((platformData) => ({
        ...platformData,
        siteBuilder: {
          ...platformData.siteBuilder,
          [configRef.current.currentSite]: {
            ...platformData.siteBuilder[configRef.current.currentSite],
            floating:
              "userSites/" +
              user.uid +
              "/" +
              configRef.current.currentSite +
              ".geojson",
          },
        },
      }));

      spinner.remove();
    });
  };

  const renderSiteCables = (map, lines, uid = null) => {
    // Options can be used to customize styling, or any other layer options.
    // const config = configRef.current;

    // Render polylines
    lines.forEach((line, key) => {
      const polyline = L.polyline(line.geo, {
        pane: "site-cables",
        className: uid + "_Cable" + key,
        color: "#00008B",
      }).addTo(map.current);

      //   addLayerInteraction(configRef, polyline, uid);
    });
  };

  const placeTurbine = (locations, siteInfo, map, osp, setNavigation) => {
    const dim = 25;
    const newIcon = L.icon({
      iconUrl: turbineMarkerIcon,
      iconSize: [dim, dim],
      // iconAnchor: [dim / 2, (50 - (dim / 5))],
    });
    const ospIcon = L.icon({
      iconUrl: ospMarkerIcon,
      iconSize: [dim, dim],
      // iconAnchor: [dim / 2, (50 - (dim / 5))],
    });

    var siteTurbines = L.layerGroup();

    const turbineCluster = L.markerClusterGroup({
      spiderfyOnMaxZoom: false,
      showCoverageOnHover: false,
      zoomToBoundsOnClick: true,
      removeOutsideVisibleBounds: true,
      disableClusteringAtZoom: 11,
      pane: "site-builder",
      iconCreateFunction: function () {
        return newIcon;
      },
    }).addTo(map.current);

    for (let i = 0; i < locations.length; i++) {
      const turb = L.circleMarker([locations[i].lat, locations[i].lon], {
        // icon: newIcon,
        zIndexOffset: 10000000,
        pane: "site-builder",
        interactive: true,
        riseOnHover: true,
        color: "black",
        ...(locations[i].cluster
          ? { fillColor: siteColours[locations[i].cluster - 1] }
          : { fillColor: "white" }),
        fillOpacity: 0.9,
        alt: locations[i].ids,
        cluster: locations[i].cluster ? locations[i].cluster : null,
        class: "turbine",
      })
        .on("mouseover", () => {
          turb.bindTooltip("Turbine " + locations[i].ids);
          turb.openTooltip();
          //   }
        })
        .addTo(siteTurbines)
        .addTo(turbineCluster);
    }

    if (osp !== undefined) {
      for (let i = 0; i < osp.length; i++) {
        const ospPoint = L.circleMarker([osp[i].lat, osp[i].lon], {
          // icon: ospIcon,
          zIndexOffset: 10000000,
          pane: "site-builder",
          interactive: true,
          riseOnHover: true,
          color: "white",
          fillColor: siteColours[i],
          fillOpacity: 1,
          alt: osp[i].ids,
          cluster: i + 1,
          class: "osp",
        })
          .on("mouseover", () => {
            ospPoint.bindTooltip("OSP " + osp[i].ids);
            ospPoint.openTooltip();
          })
          .addTo(siteTurbines)
          .addTo(turbineCluster);
      }
    }

    var totalLength = 0;
    for (let i = 0; i < siteInfo.siteCables.length; i++) {
      const element = siteInfo.siteCables[i];

      totalLength = totalLength + element.length;
    }

    var innerSite = L.geoJson(null, {
      // pane: "site-builder",
      style: {
        weight: 3,
        weightH: 5,
        stroke: false,
        color: "#FF0000",
        fillColor: "#009ec6",
        fillOpacity: 0.3,
        fillOpacityH: 0.6,
      },
    }).addTo(map.current);
    // .bindPopup(
    //     "<div style=' display: flex;align-items: center;'><h4>Site Name: </h4>" + siteInfo.siteSettings.siteName + "</div>" +
    //     "<hr></hr>" +
    //     "Number Of Turbines: " + locations.length + "<br></br>" +
    //     "Turbine Spacing: " + (siteInfo.siteSettings.minDist ? (siteInfo.siteSettings.minDist + "m") : "Unknown ") + "<br></br>" +
    //     "Total Cable Length: " + totalLength.toFixed(2) + " km<br></br>", { className: "siteBuilder_infoPopup" }
    // )

    const polyData = turf.concave(siteTurbines.toGeoJSON());
    if (polyData !== null) {
      innerSite.addData(polyData);
    }

    if (siteInfo.siteSettings.buffM > 0) {
      if (
        !(
          siteInfo.siteSettings.fillType === "fillPolygon" ||
          siteInfo.siteSettings.fillType === "placeInPolygon"
        )
      ) {
        const bufferedPoints = turf.buffer(
          siteTurbines.toGeoJSON(),
          siteInfo.siteSettings.buffM / 1000
        );
        var siteBuffer = L.geoJson(null, {
          pane: "site-builder",
          style: {
            weight: 3,
            weightH: 5,
            color: "#FF0000",
            fillColor: "#009ec6",
            fillOpacity: 0.3,
            fillOpacityH: 0.6,
          },
        })
          .addTo(map.current)
          .bringToBack();
        siteBuffer.addData(turf.convex(bufferedPoints)).bringToBack();

        return siteBuffer;
      }
    }
  };

  useEffect(() => {
    handleAnchors("place");
  }, []);

  useEffect(() => {
    if (anchorMap.current) {
      anchorMap.current.fitBounds(
        configRef.current.platformData.siteBuilder[
          configRef.current.currentSite
        ].siteTurbs
      );
      anchorMap.current.eachLayer((layer) => {
        if (
          layer.options.pane === "site-builder" ||
          layer.options.pane === "site-cables" ||
          layer.options.pane === "overlayPane"
        ) {
          anchorMap.current.removeLayer(layer);
        }
      });

      if (
        configRef.current.platformData.siteBuilder[
          configRef.current.currentSite
        ].siteCables.length > 0 &&
        configRef.current.platformData.siteBuilder[
          configRef.current.currentSite
        ].siteTurbs.length > 0
      ) {
        const siteCables = [];
        configRef.current.platformData.siteBuilder[
          configRef.current.currentSite
        ].siteCables.forEach((cable) => {
          siteCables.push(cable);
        });

        renderSiteCables(anchorMap, siteCables);
      }

      placeTurbine(
        configRef.current.platformData.siteBuilder[
          configRef.current.currentSite
        ].siteTurbs,
        configRef.current.platformData.siteBuilder[
          configRef.current.currentSite
        ],
        anchorMap,
        configRef.current.platformData.siteBuilder[
          configRef.current.currentSite
        ].siteOSP
      );
    }
  }, [readytoRender]);

  return (
    <Container className="siteBuilder_options">
      <Title variant="h7" component="h2" align="center">
        SITE ANCHORS
      </Title>

      <div style={{ display: "flex", gap: "16px" }}>
        <div style={{ width: "30%" }}>
          <div className="input" style={{ width: "100%" }}>
            <label>Mooring Line Length (m)</label>
            <InputNumber
              style={{ width: "100%" }}
              min={0}
              value={settings.lineLength}
              placeholder="e.g 100"
              onChange={(e) =>
                setSettings((settings) => ({
                  ...settings,
                  lineLength: e.value,
                }))
              }
            />
          </div>
          <div className="input" style={{ width: "100%" }}>
            <label>Mooring Line Buffer (m)</label>
            <InputNumber
              style={{ width: "100%" }}
              min={0}
              value={settings.lineBuffer}
              placeholder="e.g 100"
              onChange={(e) =>
                setSettings((settings) => ({
                  ...settings,
                  lineBuffer: e.value,
                }))
              }
            />
          </div>
          <div className="input" style={{ width: "100%" }}>
            <label>Anchor Buffer (m)</label>
            <InputNumber
              style={{ width: "100%" }}
              min={0}
              value={settings.anchorBuffer}
              placeholder="e.g 100"
              onChange={(e) =>
                setSettings((settings) => ({
                  ...settings,
                  anchorBuffer: e.value,
                }))
              }
            />
          </div>
          <div className="input" style={{ width: "100%" }}>
            <label>No. of Anchor Points</label>
            <InputNumber
              style={{ width: "100%" }}
              min={3}
              max={6}
              disabled
              value={settings.noAnchor}
              placeholder="e.g 100"
              onChange={(e) =>
                setSettings((settings) => ({
                  ...settings,
                  noAnchor: e.value,
                }))
              }
            />
          </div>
          <div className="input" style={{ width: "100%" }}>
            <label>Turbine Exclusion Zone (m)</label>
            <InputNumber
              style={{ width: "100%" }}
              min={0}
              value={settings.centreExclude}
              placeholder="e.g 100"
              onChange={(e) =>
                setSettings((settings) => ({
                  ...settings,
                  centreExclude: e.value,
                }))
              }
            />
          </div>

          <div className="input" style={{ width: "100%" }}>
            <label>OSP Exclusion Zone (m)</label>
            <InputNumber
              style={{ width: "100%" }}
              min={0}
              value={settings.centreExcludeOSP}
              placeholder="e.g 100"
              onChange={(e) =>
                setSettings((settings) => ({
                  ...settings,
                  centreExcludeOSP: e.value,
                }))
              }
            />
          </div>

          <div
            className="input"
            style={{ width: "100%", position: "relative" }}
          >
            <label>Turbine Angle (°)</label>
            <InputNumber
              style={{ width: "100%" }}
              min={0}
              value={settings.startAngle}
              placeholder="e.g 100"
              onChange={(e) =>
                setSettings((settings) => ({
                  ...settings,
                  startAngle: e.value,
                }))
              }
            />

            <StyledIconButton
              title="Turbine Spacing Explained"
              style={{
                // border: "2px solid red",
                position: "absolute",
                right: "0",
                borderRadius: "8px",
                width: "45px",
                height: "45px",
                zIndex: "0",
              }}
              onClick={(e) => turbineAngle.current.toggle(e)}
            >
              <FontAwesomeIcon color="black" icon={faInfoCircle} />
            </StyledIconButton>
            <OverlayPanel
              ref={turbineAngle}
              style={{
                padding: "16px",
                fontFamily: "Montserrat",
              }}
              // appendTo={document.getElementById("cableModal")}
            >
              <h3>Initial Angle Explained</h3>
              <Divider
                style={{ width: "100%", margin: "8px 0", color: "black" }}
              />
              <p style={{ width: "500px" }}>
                This is the angle in which the mooring line system will be place
                in.<br></br>
                The following depicts the angle system used:
              </p>

              <img src={InitalAngle} />
            </OverlayPanel>
          </div>

          <footer style={{ display: "flex", gap: "8px" }}>
            <SecButton
              title="Auto Adjust Anchors"
              disabled={loading}
              style={{
                // width: "100%",
                fontSize: "14px", // Adjust the font size for a larger button
                // color: "#00A0C6", // Add this line to change the text color
                marginTop: "8px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleAnchors("auto");
              }}
            >
              <FontAwesomeIcon icon={faWandMagicSparkles} />
            </SecButton>
            <PrimeButton
              disabled={loading}
              style={{
                width: "100%",
                fontSize: "14px", // Adjust the font size for a larger button
                // color: "#00A0C6", // Add this line to change the text color
                marginTop: "8px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                handleAnchors("place");
              }}
            >
              Place Anchors
            </PrimeButton>
          </footer>
        </div>

        {loading && (
          <div
            style={{
              position: "absolute",
              right: "5px",
              background: "#ffffffc4",
              width: "544px",
              height: "541px",
              zIndex: "1001",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img width={"100%"} height={"54%"} src={spinnerIcon} />
          </div>
        )}
        <div style={{ width: "70%" }}>
          <MapContainer
            ref={anchorMap}
            center={position}
            preferCanvas={true}
            renderer={0}
            // dragging={false}
            // scrollWheelZoom={false}
            zoom={3}
            style={{ height: "100%", width: "100%" }}
            doubleClickZoom={false}
            maxBoundsViscosity={1}
            maxBounds={[
              [-90, -180],
              [90, 180],
            ]}
            zoomControl={false}
            id="screenshot_map"
            whenReady={() => {
              setReadytoRender(true);
            }}
          >
            <Pane name="map-name-labels" style={{ zIndex: 699 }}></Pane>
            <Pane name="site-builder" style={{ zIndex: 501 }}></Pane>
            <Pane name="site-cables" style={{ zIndex: 502 }}></Pane>
            <Pane name="fullExclusion" style={{ zIndex: 500 }}></Pane>
            <Pane name="wtgExclusion" style={{ zIndex: 502 }}></Pane>
            <Pane name="radialExclusion" style={{ zIndex: 502 }}></Pane>
            <Pane name="anchorExclusion" style={{ zIndex: 502 }}></Pane>
            <Pane name="anchorBuffersPoint" style={{ zIndex: 502 }}></Pane>

            <LayersControl collapsed={false}>{anchorGeoJSON}</LayersControl>
            {/* <LayersControl.Overlay>{anchorGeoJSON}</LayersControl.Overlay> */}

            <TileLayer
              minZoom={3}
              maxZoom={18}
              pane="map-name-labels"
              noWrap={true}
              bounds={[
                [-90, -180],
                [90, 180],
              ]}
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}@2x.png"
            />

            <TileLayer
              minZoom={3}
              maxZoom={18}
              noWrap={true}
              detectRetina={true}
              bounds={[
                [-90, -180],
                [90, 180],
              ]}
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            />
          </MapContainer>
        </div>
      </div>

      <Divider />

      <footer>
        {/* {anchorGeoJSON.length > 0 && ( */}
        <PrimeButton
          disabled={loading}
          style={{
            width: "100%",
            fontSize: "14px", // Adjust the font size for a larger button
            // color: "#00A0C6", // Add this line to change the text color
          }}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();

            spinner.addTo(configRef.current.map);

            handlePlot();
            popup.remove();
          }}
        >
          Display on Main Map
        </PrimeButton>
        {/* )} */}
      </footer>
    </Container>
  );
}

export default SiteAnchorMenu;

/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Typography, Button } from "@mui/material";

import L from "leaflet";
import spinnerIcon from "../../../media/turbineLoading.gif";
import { v4 as uuidv4, validate } from "uuid";

import {
  highlightSelected,
  getSelectedLatLngs,
  extractCentroid,
} from "../drawFunctions";
import { convertToGeoJson, convertToOldSite } from "../../convertors";
import {
  PrimeButton,
  StandardContainerColumn,
  StandardContainerRow,
  StyledIconButton,
} from "../../../styles/styles";

import SiteExample from "../../../media/site-example2.png";
import { InputNumber } from "primereact/inputnumber";

import Select, { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBorderNone,
  faInfoCircle,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import {
  AdsClick,
  Apps,
  CenterFocusStrong,
  Grid4x4,
  Margin,
  NorthEast,
  NorthWest,
  SouthEast,
  SouthWest,
} from "@mui/icons-material";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db } from "../../../firebase";
import { OverlayPanel } from "primereact/overlaypanel";

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  font-size: 1.2rem;

  .input {
    padding-bottom: 5px;
    font-family: "Montserrat";
    input {
      padding-left: 5px;
    }

    select {
      padding-left: 5px;
    }
  }
`;

const Title = styled(Typography)`
  color: #00a0c6;
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 8px;
`;

const Divider = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: -5px;
`;

const FlexCenter = styled.div`
  display: flex;
  justify-content: space-between;

  align-items: center;
  gap: 10px;
`;

const OverlayContainer = styled.div`
  text-align: left;
  display: flex;
  justify-content: center;
`;

const Table = styled.table`
  border-collapse: collapse;
  margin-bottom: 15px;
`;

const Th = styled.th`
  padding: 8px;
  background-color: #f2f2f2;
  text-align: left; /* Center the column headings */
`;

const Td = styled.td`
  padding: 8px;
  border: 1px solid #ddd;
`;

function SiteBuilderMenu({ coords, popup, configRef, callFrom, layer = null }) {
  const [missingInfo, setMissingInfo] = useState(false);
  const setPlatformData = configRef.current.setPlatformData;
  const gridOptions = [
    {
      label: "Square",
      value: "Square",
      icon: <Grid4x4 />,
    },
    {
      label: "Hex",
      value: "Hex",
      icon: <Grid4x4 style={{ transform: "rotate(45deg)" }} />,
    },
  ];
  const placementOptions = [
    // {
    //   label: "Place At Point",
    //   value: "palceAtPoint",
    //   icon: <AdsClick />,
    // },
    {
      label: "Placement in Boundary",
      value: "placeInPolygon",
      icon: <AdsClick />,
    },
    {
      label: "Fill Shape",
      value: "fillPolygon",
      icon: <Apps />,
    },
  ];

  const alignmentOptions = [
    {
      label: "Center of Click/Shape",
      value: "Center",
      icon: <CenterFocusStrong />,
    },
    {
      label: "Point of Click",
      value: "Point",
      icon: <AdsClick />,
    },
    {
      label: "Point of Click is South West",
      value: "South-West",
      icon: <SouthWest />,
    },
    {
      label: "Point of Click is South East",
      value: "South-East",
      icon: <SouthEast />,
    },
    {
      label: "Point of Click is North West",
      value: "North-West",
      icon: <NorthWest />,
    },
    {
      label: "Point of Click is North East",
      value: "North-East",
      icon: <NorthEast />,
    },
  ];

  const [isOffshoreOnshore, setIsOffshoreOnshore] = useState(true);
  const [turbineTypeOptionsOffshore, setTurbineTypeOptionsOffshore] = useState(
    []
  );
  const [turbineTypeOptionsOnshore, setTurbineTypeOptionsOnshore] = useState(
    []
  );

  const turbineAngle = useRef();
  const turbineSpacing = useRef();

  const [siteOptions, setSiteOptions] = useState({
    siteName: "Demo Site",
    alignment: "Center",
    fillType: "palceAtPoint",
    angle: null,
    minDist: 1200,
    form: "Square",
    nrows: 5,
    ncols: 5,
    nturbs: 25,
    buffM: 2500,
    turb: null,
  });
  const apiHeaders = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Authorization",
    "Access-Control-Request-Method": "GET, POST, DELETE, PUT, OPTIONS",
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // Custom Single Option Component
  const CustomOption = (props) => {
    const { data, innerRef, innerProps, isFocused, isSelected } = props;

    const handleClick = (e) => {
      e.stopPropagation(); // Stops the event propagation
      console.log(`Clicked on ${data.label}`);
      innerProps.onClick(e); // Ensure the select logic works
    };

    return (
      <div
        ref={innerRef}
        {...innerProps}
        onClick={handleClick}
        style={{
          display: "flex",
          gap: "8px",
          backgroundColor: isFocused || isSelected ? "#f0f8ff" : "white", // Default focus color
          color: "black", // Default selected text color
          padding: "10px", // Mimic default padding
          cursor: "pointer",
          ...innerProps.style, // Preserve any additional styles passed down
        }}
        className={innerProps.className} // Use React-Select's default classes
      >
        {data.icon}
        {data.label}
      </div>
    );
  };

  const handleSiteCreation = async () => {
    setMissingInfo(false);

    const dim = 100;
    const newIcon = L.icon({
      iconUrl: spinnerIcon,
      iconSize: [dim * 2, dim],
      iconAnchor: [dim, dim / 4],
    });

    const spinner = L.marker(coords, {
      icon: newIcon,
    }).addTo(configRef.current.map);

    const getDominantWind = async (weatherJson) => {
      try {
        const response = await fetch(
          "https://vekdig-hiibq2h6dq-nw.a.run.app/weather",
          {
            method: "POST",
            headers: apiHeaders,
            body: JSON.stringify(weatherJson),
          }
        );

        const wind = await response.json();

        if (wind.domWD.length > 0) {
          return wind.domWD[0];
        }

        return 0;
      } catch (err) {
        console.log(err);
        alert("Sorry! Something went wrong, please try again later");
        spinner.remove();
        return;
      }
    };

    const weatherJson = {
      type: "Feature",
      geometry: {
        type: "Point",
        coordinates: [coords.lng, coords.lat],
      },
      properties: {
        name: "Sample Point",
        dataloc: "gcp",
        weatherdatatype: "vektawindinput",
        windheight: [100],
      },
    };

    var angle = siteOptions.angle;
    if (siteOptions.angle === null || isNaN(siteOptions.angle)) {
      angle = await getDominantWind(weatherJson);
      angle = (angle + 180) % 360;
    }

    var siteInfoJson, coord;
    if (callFrom === "Board") {
      var totalTurbs = siteOptions.nrows * siteOptions.ncols;

      siteInfoJson = {
        alignment: [siteOptions.alignment],
        angle: [angle],
        minDist: [siteOptions.minDist],
        form: [siteOptions.form],
        nturbs: [totalTurbs],
        nrows: [siteOptions.nrows],
        ncols: [siteOptions.ncols],
      };
      coord = [{ lon: coords.lng, lat: coords.lat }];
    } else {
      const formattedCoords = [];
      const layerCoords = getSelectedLatLngs(configRef.current)[0];

      layerCoords.forEach((latlng) => {
        formattedCoords.push({ lon: latlng.lng, lat: latlng.lat });
      });

      if (siteOptions.fillType === "placeInPolygon") {
        var totalTurbs = siteOptions.nrows * siteOptions.ncols;

        siteInfoJson = {
          alignment: [siteOptions.alignment],
          angle: [angle],
          minDist: [siteOptions.minDist],
          buffM: [siteOptions.buffM],
          form: [siteOptions.form],
          nturbs: [totalTurbs],
          accRows: [siteOptions.nrows],
          accCols: [siteOptions.ncols],
          pointIn: [{ lon: coords.lng, lat: coords.lat }],
        };

        coord = [...formattedCoords, formattedCoords[0]];
      } else if (siteOptions.fillType === "fillPolygon") {
        siteInfoJson = {
          angle: [angle],
          minDist: [siteOptions.minDist],
          buffM: [siteOptions.buffM],
          form: [siteOptions.form],
        };

        coord = [...formattedCoords, formattedCoords[0]];
      } else {
        setMissingInfo(true);
        return;
      }
    }

    const getTurbPlacement = async () => {
      try {
        const response = await fetch(
          //   `https://vekwin-hiibq2h6dq-ez.a.run.app/${siteOptions.fillType}`,
          `https://vekwin2-hiibq2h6dq-uc.a.run.app/${siteOptions.fillType}`,
          {
            method: "POST",
            headers: apiHeaders,
            body: JSON.stringify(
              convertToGeoJson(
                siteInfoJson,
                //   { ...siteInfoJson, coord }
                coord,
                siteOptions.fillType
              )
            ),
          }
        ).catch((error) => {
          console.log(error);
        });

        return await response.json();
      } catch (err) {
        console.log(err);
        // alert("Sorry! Something went wrong, please try again later")
        spinner.remove();
        return;
      }
    };

    const turbs = convertToOldSite(await getTurbPlacement());
    spinner.remove();

    if (turbs?.error) {
      window.alert("Error while creating site - Please try again");
    } else {
      if (turbs.length > 0) {
        // setTurbLocations(turbs)
        setPlatformData((platformData) => ({
          ...platformData,
          siteBuilder: {
            ...platformData.siteBuilder,
            [uuidv4()]: {
              siteTurbs: turbs,
              siteName: siteOptions.siteName,
              siteSettings: siteOptions,
              siteOSP: [],
              siteCables: [],
              datetime: Date(),
            },
          },
        }));
      }
    }
  };

  const validateInput = (value, min, max, type) => {
    // If value is an empty string, return empty string
    if (value === null) {
      return min || "";
    }

    if (!(value instanceof type)) {
      if (value < min) {
        return type(min);
      } else if (max && value > max) {
        return type(max);
      }
      return type(value);
    }

    return value;
  };

  //Custom search filter so that it only returns the cable that matches the search string.
  const customFilter = (option, searchText) => {
    searchText = searchText.split(" ");

    if (
      searchText.every((el) =>
        option.data.label.toLowerCase().includes(el.toLowerCase())
      ) ||
      searchText.every((el) =>
        option.data.value.toLowerCase().includes(el.toLowerCase())
      )
    ) {
      return true;
    } else {
      return false;
    }
    // }
  };

  const handleSelectTurbineSelect = (event) => {
    setSiteOptions((siteOptions) => ({
      ...siteOptions,
      turb: event.value, // Assuming turb_hub is an array with a single value
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      let turbines = collection(db, "turbinedb");

      try {
        const q = query(turbines, orderBy("Rated Power"));
        const querySnapshot = await getDocs(q);
        const turbineData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          if (data.ctfromcp_flag) {
            data.Name += " *";
          }
          return data;
        });

        turbineData.forEach((data) => {
          const newOption = {
            label: data.Name,
            value: data.Name.replace(" *", ""),
          };
          const type = data.Type;

          if (type === "Offshore") {
            setTurbineTypeOptionsOffshore((prevOptions) => {
              const exists = prevOptions.some(
                (option) =>
                  option.label === newOption.label &&
                  option.value === newOption.value
              );

              if (
                !exists &&
                !(
                  newOption.label.includes("IEC") ||
                  newOption.label.includes("WTK")
                )
              ) {
                return [...prevOptions, newOption];
              }

              return prevOptions;
            });
          } else if (type === "Onshore") {
            setTurbineTypeOptionsOnshore((prevOptions) => {
              const exists = prevOptions.some(
                (option) =>
                  option.label === newOption.label &&
                  option.value === newOption.value
              );

              if (
                !exists &&
                !(
                  newOption.label.includes("IEC") ||
                  newOption.label.includes("WTK")
                )
              ) {
                return [...prevOptions, newOption];
              }

              return prevOptions;
            });
          }
        });
      } catch (error) {
        console.error("Error fetching turbine data: ", error);
      }
    };

    fetchData();

    if (layer) {
      configRef.current.selected = layer._leaflet_id;
      highlightSelected(configRef);
    }
  }, []);

  return (
    <Container className="siteBuilder_options">
      <Title variant="h7" component="h2" align="center">
        SITE BUILDER OPTIONS
      </Title>

      <div className="input">
        <label>Site Name</label>
        <InputText
          placeholder="Demo Site"
          onChange={(e) => {
            console.log(e);

            setSiteOptions((siteOptions) => ({
              ...siteOptions,
              siteName: e.target.value,
            }));
          }}
        />
      </div>

      {callFrom === "API" && (
        <div style={{ width: "100%" }}>
          <label>Placement Type</label>
          <Select
            className={
              missingInfo && "toggle_missingInfoShake toggle_missingInfo"
            }
            isSearchable
            options={placementOptions}
            components={{ Option: CustomOption }}
            onChange={(e) => {
              setMissingInfo(false);
              setSiteOptions((siteOptions) => ({
                ...siteOptions,
                fillType: e.value,
              }));
            }}
          />
        </div>
      )}

      <FlexCenter>
        <div style={{ width: "100%" }}>
          <label>Grid Format</label>
          <Select
            isSearchable
            defaultValue={gridOptions[0]}
            options={gridOptions}
            components={{ Option: CustomOption }}
            onChange={(e) =>
              setSiteOptions((siteOptions) => ({
                ...siteOptions,
                form: e.value,
              }))
            }
          />
        </div>
        {(siteOptions.fillType === "palceAtPoint" ||
          siteOptions.fillType === "placeInPolygon") && (
          <div style={{ width: "100%" }}>
            <label>Placement Alignment</label>
            <Select
              isSearchable
              defaultValue={alignmentOptions[0]}
              options={alignmentOptions}
              components={{ Option: CustomOption }}
              onChange={(e) =>
                setSiteOptions((siteOptions) => ({
                  ...siteOptions,
                  alignment: e.value,
                }))
              }
            />
          </div>
        )}
      </FlexCenter>

      <Divider />

      {(siteOptions.fillType === "palceAtPoint" ||
        siteOptions.fillType === "placeInPolygon") && (
        <FlexCenter>
          <div className="input" style={{ width: "40%" }}>
            <label>Number of Rows</label>
            <InputNumber
              style={{ width: "100%" }}
              min={1}
              max={300}
              placeholder="e.g. 5"
              onChange={(e) => {
                console.log(e);

                e.value = validateInput(e.value, 1, 300, Number);
                setSiteOptions((siteOptions) => ({
                  ...siteOptions,
                  nrows: e.value,
                }));
              }}
            />
          </div>
          <span style={{ paddingTop: "12px" }}>X</span>
          <div className="input" style={{ width: "40%" }}>
            <label>Number of Columns</label>
            <InputNumber
              style={{ width: "100%" }}
              min={1}
              max={300}
              placeholder="e.g. 5"
              onChange={(e) => {
                console.log(e);

                e.value = validateInput(e.value, 1, 300, Number);
                setSiteOptions((siteOptions) => ({
                  ...siteOptions,
                  ncols: e.value,
                }));
              }}
            />
          </div>
          <span style={{ paddingTop: "12px" }}>
            = &nbsp;<strong>{siteOptions.nrows * siteOptions.ncols}</strong>
          </span>
        </FlexCenter>
      )}

      <div style={{ width: "100%", marginBottom: "8px" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <label className="labelInput" style={{ margin: "0" }}>
            Turbine Selection
          </label>
          <div>
            <div
              style={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                justifyContent: "flex-end",
                marginBottom: "8px",
              }}
            >
              <label style={{ margin: "0" }}>Onshore</label>
              <InputSwitch
                checked={isOffshoreOnshore}
                onChange={(e) => {
                  setIsOffshoreOnshore((temp) => !temp);
                }}
              />
              <label style={{ margin: "0" }}>Offshore</label>
            </div>
          </div>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <Select
            value={
              turbineTypeOptionsOffshore.filter(
                (turbine) => turbine.value === siteOptions.turb
              ) ||
              turbineTypeOptionsOnshore.filter(
                (turbine) => turbine.value === siteOptions.turb
              )
            }
            options={
              isOffshoreOnshore
                ? turbineTypeOptionsOffshore
                : turbineTypeOptionsOnshore
            }
            onChange={(e) =>
              setSiteOptions((siteOptions) => ({
                ...siteOptions,
                turb: e.value, // Assuming turb_hub is an array with a single value
              }))
            }
            filterOption={customFilter}
            formatCreateLabel={(inputValue) => `${inputValue}`}
            components={{ Option: CustomOption }}
            // menuPortalTarget={document.getElementsByClassName(
            //   "leaflet-popup"
            // )[0]}
            // menuPosition={"absolute"}
          />
        </div>
      </div>

      <FlexCenter style={{ overflow: "hidden" }}>
        <StandardContainerColumn style={{ width: "100%", overflow: "hidden" }}>
          <div className="input" style={{ position: "relative" }}>
            <label>Turbine Spacing (m)</label>
            <InputNumber
              style={{ width: "100%" }}
              placeholder="e.g. 1200"
              onChange={(e) => {
                console.log(e);

                e.value = validateInput(e.value, "", null, Number);
                setSiteOptions((siteOptions) => ({
                  ...siteOptions,
                  minDist: e.value,
                }));
              }}
            />

            <StyledIconButton
              title="Turbine Spacing Explained"
              style={{
                // border: "2px solid red",
                position: "absolute",
                right: "0",
                borderRadius: "8px",
                width: "45px",
                height: "45px",
                zIndex: "0",
              }}
              onClick={(e) => turbineSpacing.current.toggle(e)}
            >
              <FontAwesomeIcon color="black" icon={faInfoCircle} />
            </StyledIconButton>
            <OverlayPanel
              ref={turbineSpacing}
              style={{
                padding: "16px",
                fontFamily: "Montserrat",
              }}
              appendTo={document.getElementById("cableModal")}
            >
              <h3>Turbine Spacing Explained</h3>
              <Divider
                style={{ width: "100%", margin: "8px 0", color: "black" }}
              />
              <p style={{ width: "500px" }}>
                Typical spacing for fixed bottom offshore turbines:
                <br></br>
                <span style={{ textAlign: "center", width: "100%" }}>
                  <strong>6-8 x rotor diameter</strong>
                </span>
                <br></br>
                <br></br>
                Typical spacing for floating turbines:
                <br></br>
                <span style={{ textAlign: "center", width: "100%" }}>
                  <strong>6-10 x rotor diameter</strong>
                </span>
                <br></br>
                this is dependant on water depth and mooring line configuration
              </p>

              <OverlayContainer></OverlayContainer>
            </OverlayPanel>
          </div>

          <div className="input">
            <label>Site Inset Buffer (m)</label>
            <InputNumber
              style={{ width: "100%" }}
              placeholder="e.g. 2500"
              onChange={(e) => {
                console.log(e);

                e.value = validateInput(e.value, "", null, Number);
                setSiteOptions((siteOptions) => ({
                  ...siteOptions,
                  buffM: e.value,
                }));
              }}
            />
          </div>

          <div className="input" style={{ position: "relative" }}>
            <label>Turbine Angle (&deg;)</label>
            <InputNumber
              style={{ width: "100%" }}
              min={0}
              max={360}
              placeholder="e.g. Prevailing Wind"
              onChange={(e) => {
                console.log(e);

                e.value = validateInput(e.value, 0, 360, Number);
                setSiteOptions((siteOptions) => ({
                  ...siteOptions,
                  angle: e.value,
                }));
              }}
            />

            <StyledIconButton
              title="Turbine Angle Explained"
              style={{
                // border: "2px solid red",
                position: "absolute",
                right: "0",
                borderRadius: "8px",
                width: "45px",
                height: "45px",
                zIndex: "0",
              }}
              onClick={(e) => turbineAngle.current.toggle(e)}
            >
              <FontAwesomeIcon color="black" icon={faInfoCircle} />
            </StyledIconButton>
            <OverlayPanel
              ref={turbineAngle}
              style={{
                padding: "16px",
                fontFamily: "Montserrat",
              }}
              appendTo={document.getElementById("cableModal")}
            >
              <h3>Turbine Angle Explained</h3>
              <Divider
                style={{ width: "100%", margin: "8px 0", color: "black" }}
              />
              <p style={{ width: "500px" }}>
                This is the angle in which the turbine is positioned.<br></br>
                By default the turbine will automatically be positioned into the
                prevailing wind direction.
              </p>

              <OverlayContainer></OverlayContainer>
            </OverlayPanel>
          </div>
        </StandardContainerColumn>

        <img width={300} src={SiteExample} />
      </FlexCenter>

      <Divider />

      <PrimeButton
        style={{
          width: "100%",
          fontSize: "14px", // Adjust the font size for a larger button
          // color: "#00A0C6", // Add this line to change the text color
        }}
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();

          if (callFrom !== "Board") {
            if (siteOptions.fillType !== "palceAtPoint") {
              handleSiteCreation();
              popup.remove();
            } else {
              setMissingInfo(true);
            }
          } else {
            handleSiteCreation();
            popup.remove();
          }
        }}
      >
        Create Site
      </PrimeButton>
    </Container>
  );
}

export default SiteBuilderMenu;

/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React, { useContext, useRef, useState } from "react";
import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import WindPowerIcon from "@mui/icons-material/WindPower";
import ElectricalServicesIcon from "@mui/icons-material/ElectricalServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDrawPolygon as DrawPolygonIcon,
  faMousePointer as SelectIcon,
  faMapMarkerAlt as LocationIcon,
  faTrash as DeleteIcon, // Import the trash (delete) icon
  faAngleDoubleUp as MinimiseIcon,
  faRuler as Measure,
  faCircleXmark as ClearMeasures,
  faPencil,
  faWandMagicSparkles,
  faPenToSquare,
  faUpload,
  faHammer,
  faChargingStation,
  faObjectGroup,
  faDrawPolygon,
  faSliders,
  faAnchor,
} from "@fortawesome/free-solid-svg-icons";
import { OverlayPanel } from "primereact/overlaypanel";
import CablesIcon from "../../media/cables logo inverse white.png";

import { faSquare as RectangleIcon } from "@fortawesome/free-regular-svg-icons";

import {
  Adjust as PointIcon,
  Grain as MultiSelectIcon,
  Timeline as LineIcon,
  ControlCamera as MoveIcon,
  OpenWith as MultiMoveIcon,
  Draw as DrawIcon,
} from "@mui/icons-material";
import { Box, Divider, Modal, Popover, Popper } from "@mui/material";
import { clearMeasurements } from "./drawFunctions";
import { PrimeButton, SecButton } from "../../styles/styles";
import SiteBuilderUpload from "./popups/SiteBuilderUpload";
import { PlatformData } from "../../imports/Context";

const ToolBox = styled.div`
  position: fixed;
  top: 50%;
  /* right: 10px; */
  transform: translate(0, -50%);
  border-radius: 5px;
  border: 1px solid lightgray;
  /* width:47px; */
  background-color: white;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
`;

const SubToolBox = styled.div`
  top: ${(props) =>
    props.pos
      ? // props.pos.y / 10 +
        //   props.pos.height / 2 -
        //   13 +
        //   "em" +
        //   "%"
        `calc(${props.pos.y}px + ${props.pos.height}px - 13em)`
      : "0px"};
`;

const StyledIconButton = styled(IconButton)`
  /* width:35px;
  height:35px; */
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #f5f5f5 !important;
    transform: scale(1.1);
  }
`;

const CircleNav = styled("a")`
  /* width:35px;
  height:35px; */
  background-color: ${(props) =>
    props.active ? "#009ec641" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #f5f5f5 !important;
    /* transform: scale(1.1); */
  }
`;

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: 1000,
}));

function DrawControlPanel({ setMode, mode, map }) {
  const [minimised, setMinimised] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const { platformData } = useContext(PlatformData);

  const handleMinimise = () => {
    setMode("minimise");
    setMinimised(true);
  };

  const handleModeChange = (selection) => {
    if (selection !== "edit") {
      // console.log(map.pm);
      if (mode === "edit") {
        map.pm.disableGlobalEditMode();
      }
    }

    setMode(selection);
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "50%",
        right: "0",
        transform: "translate(0, -50%)",
        width: "50px",
        height: "100vh",
        zIndex: "1000",
      }}
    >
      <ToolBox className="drawControls">
        {!minimised ? (
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <StyledIconButton
              className="draw_select"
              active={mode === "select"}
              onClick={() => handleModeChange("select")}
              title="Select"
            >
              <FontAwesomeIcon icon={SelectIcon} />
              {/* </StyledIconButton>
            <StyledIconButton active={mode === "multiSelect"} onClick={() => setMode("multiSelect")} title="Multi Select">
            <MultiSelectIcon /> */}
            </StyledIconButton>
            <StyledIconButton
              className="draw_move"
              active={mode === "move"}
              onClick={() => handleModeChange("move")}
              title="Move"
            >
              <MoveIcon />
            </StyledIconButton>
            <Divider />
            {/* <StyledIconButton active={mode === "multiMove"} onClick={() => setMode("multiMove")} title="Multi Move">
            <MultiMoveIcon />
          </StyledIconButton> */}
            <StyledIconButton
              disabled={
                platformData.layerControl.layerOrder[0].tree.length === 0
              }
              className="draw_point"
              active={mode === "point" || mode === "snapPoint"}
              onClick={() => handleModeChange("point")}
              title="Draw Point"
            >
              <PointIcon />
            </StyledIconButton>
            <StyledIconButton
              disabled={
                platformData.layerControl.layerOrder[0].tree.length === 0
              }
              className="draw_line"
              active={mode === "line" || mode === "snapLine"}
              onClick={() => handleModeChange("line")}
              title="Draw Line"
            >
              <LineIcon />
            </StyledIconButton>
            <StyledIconButton
              disabled={
                platformData.layerControl.layerOrder[0].tree.length === 0
              }
              className="draw_rectangle"
              active={mode === "rectangle"}
              onClick={() => handleModeChange("rectangle")}
              title="Draw Rectangle"
            >
              <FontAwesomeIcon icon={RectangleIcon} />
            </StyledIconButton>
            <StyledIconButton
              disabled={
                platformData.layerControl.layerOrder[0].tree.length === 0
              }
              className="draw_polygon"
              active={mode === "polygon" || mode === "snapPolygon"}
              onClick={() => handleModeChange("polygon")}
              title="Draw Polygon"
            >
              <FontAwesomeIcon icon={DrawPolygonIcon} />
            </StyledIconButton>
            <StyledIconButton
              disabled={
                platformData.layerControl.layerOrder[0].tree.length === 0
              }
              className="edit_shape"
              active={mode === "edit"}
              onClick={() => handleModeChange("edit")}
              title="Edit Shape"
            >
              <FontAwesomeIcon icon={faPenToSquare} />
            </StyledIconButton>
            <Divider />

            <StyledIconButton
              disabled={
                platformData.layerControl.layerOrder[0].tree.length === 0
              }
              className="draw_site"
              active={
                mode === "siteBuilder" ||
                mode === "anchors" ||
                mode.includes("cables") ||
                mode.includes("osp") ||
                mode.includes("cluster") ||
                mode.includes("exclusion")
              }
              onClick={() => handleModeChange("siteBuilder")}
              title="Site Builder"
            >
              <WindPowerIcon id={"draw_site"} fontSize="medium" />
            </StyledIconButton>
            <StyledIconButton
              disabled={
                platformData.layerControl.layerOrder[0].tree.length === 0
              }
              className="draw_location"
              active={mode === "locationMarker"}
              onClick={() => handleModeChange("locationMarker")}
              title="Location Marker"
            >
              <FontAwesomeIcon icon={LocationIcon} />
            </StyledIconButton>
            <Divider />
            <StyledIconButton
              className="draw_delete"
              active={mode === "delete"}
              onClick={() => handleModeChange("delete")}
              title="Delete Layer"
            >
              <FontAwesomeIcon icon={DeleteIcon} />
            </StyledIconButton>

            <StyledIconButton
              active={mode === "minimise"}
              onClick={handleMinimise}
              title="Minimise Tools"
            >
              <FontAwesomeIcon icon={MinimiseIcon} />
            </StyledIconButton>
          </div>
        ) : (
          <StyledIconButton
            active={mode === "maximise"}
            onClick={() => setMinimised(false)}
            title="Show Draw Tools"
          >
            <DrawIcon />
          </StyledIconButton>
        )}

        <SiteBuilderUpload
          uploadOpen={uploadOpen}
          setUploadOpen={setUploadOpen}
        />
      </ToolBox>

      <SubToolBox
        className={
          mode === "siteBuilder" ||
          mode === "anchors" ||
          mode.includes("cables") ||
          mode.includes("osp") ||
          mode.includes("cluster") ||
          mode.includes("exclusion")
            ? "cn-wrapper opened-nav"
            : "cn-wrapper"
        }
        id="cn-wrapper"
        pos={
          document.getElementById("draw_site")?.getBoundingClientRect() || null
        }
      >
        <ul className="first-controls">
          <li>
            <CircleNav
              active={mode.includes("cables")}
              onClick={(e) => handleModeChange("cables")}
              title="Place Cables"
            >
              {/* <FontAwesomeIcon color="black" icon={faSliders} /> */}
              <img
                src={CablesIcon}
                width={23}
                style={{ filter: "brightness(0.45)" }}
              />
            </CircleNav>
          </li>
          <li>
            <CircleNav
              active={mode === "exclusion"}
              onClick={(e) => handleModeChange("exclusion")}
              title="Add Exclusion Zones"
            >
              <FontAwesomeIcon color="black" icon={faDrawPolygon} />
            </CircleNav>
          </li>
          <li>
            <CircleNav
              active={mode.includes("cluster")}
              onClick={(e) => handleModeChange("cluster")}
              title="Add Turbine Cluster"
            >
              <FontAwesomeIcon color="black" icon={faObjectGroup} />
            </CircleNav>
          </li>
          <li>
            <CircleNav
              active={mode.includes("osp")}
              onClick={(e) => handleModeChange("osp")}
              title="Place OSP"
            >
              {/* <FontAwesomeIcon color="black" icon={faChargingStation} /> */}
              <ElectricalServicesIcon style={{ fontSize: "xx-large" }} />
            </CircleNav>
          </li>
          <li>
            <CircleNav
              active={mode === "anchors"}
              onClick={(e) => handleModeChange("anchors")}
              title="Apply Anchors"
            >
              <FontAwesomeIcon color="black" icon={faAnchor} />
            </CircleNav>
          </li>
          <li>
            <CircleNav
              active={mode === "siteBuilder"}
              onClick={(e) => handleModeChange("siteBuilder")}
              title="Build a Site"
            >
              <FontAwesomeIcon color="black" icon={faHammer} />
            </CircleNav>
          </li>
        </ul>

        <StyledIconButton
          onClick={() => setUploadOpen(true)}
          title="Upload a Site"
          active={true}
          style={{
            position: "absolute",
            right: "5em",
            top: "5em",
            width: "100px",
            height: "100px",
            border: "2px solid lightgray",
            /* padding: 8px 8px 8px 0; */
            justifyContent: "left",
            padding: "22px",
          }}
        >
          <FontAwesomeIcon icon={faUpload} />
        </StyledIconButton>

        <div
          id="hidden-hammer"
          style={{
            position: "absolute",
            right: "14em",
            top: "1em",
            width: "20px",
            height: "20px",
            justifyContent: "left",
            // border: "2px solid red",
          }}
        ></div>
        <StyledPopper
          placement="top-start"
          open={mode === "siteBuilder"}
          anchorEl={document.getElementById("hidden-hammer")}
          style={{ zIndex: "1000" }}
        >
          <Box
            style={{
              border: "1 solid ligthtgray",
              borderRadius: "32px 32px 8px 32px",
            }}
            sx={{
              p: 2,
              bgcolor: "background.paper",
            }}
          >
            <label>Click map to build</label>
          </Box>
        </StyledPopper>

        <div
          id="hidden-anchors"
          style={{
            position: "absolute",
            right: "20em",
            top: "4em",
            width: "20px",
            height: "20px",
            justifyContent: "left",
            // border: "2px solid red",
          }}
        ></div>
        <StyledPopper
          placement="top-start"
          open={mode === "anchors"}
          anchorEl={document.getElementById("hidden-anchors")}
          style={{ zIndex: "1000" }}
        >
          <Box
            style={{
              border: "1 solid ligthtgray",
              borderRadius: "32px 32px 8px 32px",
            }}
            sx={{
              p: 2,
              bgcolor: "background.paper",
            }}
          >
            <label>Select site to make floating</label>
          </Box>
        </StyledPopper>

        <div
          id="hidden-osp"
          style={{
            position: "absolute",
            right: "23em",
            top: "9em",
            width: "20px",
            height: "20px",
            justifyContent: "left",
            // border: "2px solid red",
          }}
        ></div>
        <StyledPopper
          placement="left"
          open={mode.includes("osp")}
          anchorEl={document.getElementById("hidden-osp")}
          style={{ zIndex: "1000" }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: "8px",
              border: "1 solid ligthtgray",
              borderRadius: "32px 32px 8px 32px",
            }}
            sx={{
              p: 2,
              bgcolor: "background.paper",
            }}
          >
            <StyledIconButton
              active={mode === "siteBuilder_draw_osp"}
              onClick={() => handleModeChange("siteBuilder_draw_osp")}
              title="OSP Draw"
            >
              <FontAwesomeIcon icon={faPencil} />
            </StyledIconButton>
            <StyledIconButton
              active={mode === "siteBuilder_auto_osp"}
              onClick={() => handleModeChange("siteBuilder_auto_osp")}
              title="Automatic OSP Placement"
            >
              <FontAwesomeIcon icon={faWandMagicSparkles} />
            </StyledIconButton>
          </Box>
        </StyledPopper>

        <div
          id="hidden-cluster"
          style={{
            position: "absolute",
            right: "23em",
            top: "15em",
            width: "20px",
            height: "20px",
            justifyContent: "left",
            // border: "2px solid red",
          }}
        ></div>
        <StyledPopper
          placement="left"
          open={mode.includes("cluster")}
          anchorEl={document.getElementById("hidden-cluster")}
          style={{ zIndex: "1000" }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: "8px",
              border: "1 solid ligthtgray",
              borderRadius: "32px 50% 50% 32px",
            }}
            sx={{
              p: 2,
              bgcolor: "background.paper",
            }}
          >
            <PrimeButton
              style={{
                width: "100%",
                fontSize: "14px", // Adjust the font size for a larger button
                // color: "#00A0C6", // Add this line to change the text color
              }}
              onClick={(e) => {
                document.getElementById("upgrade-vd-plus").click();
              }}
            >
              Upgrade Now
            </PrimeButton>
          </Box>
        </StyledPopper>

        <div
          id="hidden-exclusion"
          style={{
            position: "absolute",
            right: "20em",
            bottom: "4em",
            width: "20px",
            height: "20px",
            justifyContent: "left",
            // border: "2px solid red",
          }}
        ></div>
        <StyledPopper
          placement="left-end"
          open={mode.includes("exclusion")}
          anchorEl={document.getElementById("hidden-exclusion")}
          style={{ zIndex: "1000" }}
        >
          <Box
            style={{
              border: "1 solid ligthtgray",
              borderRadius: "32px 8px 32px 32px",
            }}
            sx={{
              p: 2,
              bgcolor: "background.paper",
            }}
          >
            <PrimeButton
              style={{
                width: "100%",
                fontSize: "14px", // Adjust the font size for a larger button
                // color: "#00A0C6", // Add this line to change the text color
              }}
              onClick={(e) => {
                document.getElementById("upgrade-vd-plus").click();
              }}
            >
              Upgrade Now
            </PrimeButton>
          </Box>
        </StyledPopper>

        <div
          id="hidden-cables"
          style={{
            position: "absolute",
            right: "14em",
            bottom: "1em",
            width: "20px",
            height: "20px",
            justifyContent: "left",
            // border: "2px solid red",
          }}
        ></div>
        <StyledPopper
          placement="bottom-start"
          open={mode.includes("cables")}
          anchorEl={document.getElementById("hidden-cables")}
          style={{ zIndex: "1000" }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              gap: "8px",
              border: "1 solid ligthtgray",
              borderRadius: "32px 8px 32px 32px",
            }}
            sx={{
              p: 2,
              bgcolor: "background.paper",
            }}
          >
            <StyledIconButton
              active={mode === "siteBuilder_draw_cables"}
              onClick={() => handleModeChange("siteBuilder_draw_cables")}
              title="Cable Draw"
            >
              <FontAwesomeIcon icon={faPencil} />
            </StyledIconButton>
            <StyledIconButton
              active={mode === "siteBuilder_auto_cables"}
              onClick={() => handleModeChange("siteBuilder_auto_cables")}
              title="Automatic Cable Placement"
            >
              <FontAwesomeIcon icon={faWandMagicSparkles} />
            </StyledIconButton>
          </Box>
        </StyledPopper>
      </SubToolBox>
    </div>
  );
}

export default DrawControlPanel;

import React, { useRef, useState } from "react";
import styled from "styled-components";

import IconButton from "@mui/material/IconButton";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImages as Screenshot,
  faClose,
} from "@fortawesome/free-solid-svg-icons";

import { Box, Divider, Modal, Avatar, Badge } from "@mui/material";
import MapPDF from "./MapPDF";
import "leaflet.sync";
import { StandardContainerRow } from "../../../styles/styles";

const MapScreenshot = styled.div`
  position: fixed;
  top: 10px;
  right: 472px;

  border-radius: 5px;
  border: 1px solid lightgray;
  /* width:47px; */
  background-color: white;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 1000;
`;

const StyledIconButton = styled(IconButton)`
  /* width:35px;
  height:35px; */
  background-color: ${(props) =>
    props.active ? "#e0e0e0" : "transparent"} !important;
  transition: background-color 0.2s ease, transform 0.2s ease;

  &:hover {
    background-color: #f5f5f5 !important;
    transform: scale(1.1);
  }
`;

function MapOutput({ map }) {
  const [getMap, setGetMap] = useState(false);
  const mapSceenshot = useRef();

  return (
    <>
      <MapScreenshot>
        <StyledIconButton onClick={() => setGetMap(true)}>
          <FontAwesomeIcon icon={Screenshot} />
        </StyledIconButton>
      </MapScreenshot>

      <Modal
        open={getMap}
        onClose={() => setGetMap(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className="modal-popups mapOutput_popup"
            sx={{
              inset: "2%",
              overflow: "hidden",
            }}
          >
            <header>
              <StandardContainerRow style={{ overflow: "hidden" }}>
                <h2>Map Drawing</h2>
                <StyledIconButton
                  onClick={() => {
                    setGetMap(null);
                    map.unsync(mapSceenshot.current);
                  }}
                >
                  <FontAwesomeIcon icon={faClose} />
                </StyledIconButton>
              </StandardContainerRow>
            </header>
            <Divider
              style={{ marginBottom: "8px" }}
              sx={{ bgcolor: "black" }}
            />

            <MapPDF mainMap={map} ref={mapSceenshot} />
          </Box>
        </div>
      </Modal>
    </>
  );
}

export default MapOutput;

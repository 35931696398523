/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import $ from "jquery";
import L from "leaflet";
import jstree from "jstree";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, auth, db, storage } from "../../../firebase";
import { ref, uploadBytes,getStorage, getDownloadURL} from "firebase/storage";

import * as GeoProvider from "../../../providers/GeoProvider";
import * as HelperFunctions from "./helperFuntions";
import { useLocation, useSearchParams } from "react-router-dom";
import { PlatformData } from "../../../imports/Context";

import Joyride, { STATUS } from "react-joyride";
import { Player } from "video-react";
import layerTree from "../../../media/JSTree.mp4";
import { list, list_uk } from "../../../data/BaseLayers";

import { Panel, PanelGroup } from "react-resizable-panels";
// import ResizeHandle from "../common/ResizeHandle";

import LayerOptions from "./layerFilter/LayerOptions";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass as SearchIcon,
  faArrowUp,
  faCircleHalfStroke,
  faCircleQuestion,
  faEyeLowVision,
  faFileArrowUp,
  faFolderTree,
  faInfo,
  faInfoCircle,
  faMagnifyingGlassLocation,
  faQuestion,
  faQuestionCircle,
  faTrash,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import {
  ModalClose,
  PrimeButton,
  SecButton,
  StyledIconButton,
} from "../../../styles/styles";
import { getFunctions, httpsCallable } from "firebase/functions";
import { doc, getDoc } from "firebase/firestore";
import { BeatLoader } from "react-spinners";
import { Box, Divider, Modal } from "@mui/material";
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import InsightModal from './InsightModal';



function LayerControl() {

  const storage = getStorage();

  const { platformData, setPlatformData } = useContext(PlatformData);
  const [reset, setReset] = useState({ tree: false, reload: false });

  const jsTree = useRef();
  const jsTreeUK = useRef();

  const location = useLocation();

  const [control, setControl] = useState(null);
  const [userUpload, setUserUpload] = useState(false);
  const [layerTree, setLayerTree] = useState({ base: null });
  const [searchParams, setSearchParams] = useSearchParams();
  const [userConfirm, setUserConfirm] = useState(null);
  const [drowpdownVisable, setDropdownVisable] = useState(false);
  const [uploadComplete, setUploadComplete] = useState(false);

  const [opacityVal, setOpacityVal] = useState(100);
  const [selectedLayer, setSelectedLayer] = useState(null);

  const [baseLayers, setBaseLayers] = useState([]);

  const [user, loading, error] = useAuthState(auth);

  const [runJoyride, setRunJoyride] = useState(false);


  ////////////////////////////// INSIGHTS //////////////////////////////

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const [dataInsights, setDataInsights] = useState([]);
  const [insights, setInsights] = useState(false);
  const [selectedInsight, setSelectedInsight] = useState(null);
  // Sort the dataInsights array by the lastUpdated field
  const sortedInsights = dataInsights
  .filter(insight => insight.visibility) // Filter insights based on visibility
  .sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated)); // Sort by lastUpdated  // Get the most recent insight
  
  const [insightsImageUrl, setInsightsImageUrl] = useState("");
  const mostRecentInsight = sortedInsights[0];
  
  useEffect(() => {
    if (mostRecentInsight) {
      const imageRef = ref(storage, `appInsightsThumbnails/${mostRecentInsight.name}.png`);
      getDownloadURL(imageRef)
        .then((url) => {
          setInsightsImageUrl(url);
        })
        .catch((error) => {
          console.error("Error getting image URL:", error);
        });
    }
  }, [mostRecentInsight]);

  ///// MODAL functions //////

  const openGridModal = () => {
    setSelectedInsight(null); // Ensure the grid is shown initially
    setModalIsOpen(true);
  };

  const openModalWithInsight = (insight) => {
    setSelectedInsight(insight);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedInsight(null);
  };

  const handleThumbnailClick = (insight) => {
    setSelectedInsight(insight);
  };

  const calculateDaysSince = (date) => {
    const todaysDate = new Date();
    const givenDate = new Date(date);
    const timeDifference = todaysDate - givenDate;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    // if more than 100 days then return 100+
    if (daysDifference > 100) {
      return "100+";
    }

    return daysDifference;
  };

  //// fetch the insights data from firebase
  useEffect(() => {
    const fetchData = async () => {
      const db = getFirestore();
      const dataInsightsCollection = collection(db, 'dataInsights');
      const dataInsightsSnapshot = await getDocs(dataInsightsCollection);
      const insightsEntries = dataInsightsSnapshot.docs.map(doc => doc.data());
      setDataInsights(insightsEntries);
    };

    fetchData();
  }, []);

  ////////////////////////////// JOYRIDE //////////////////////////////


  const [{ widgetRun, steps }, setWidgetJoyride] = useState({
    widgetRun: false,
    steps: [
      {
        content: (
          <div>
            <h2>Layer Control</h2>
            <p>
              Visulise and interrogate key data layers to better understand an
              area of intrest
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "bottom",
        target: ".project_layers",
        disableBeacon: true,
      },
      {
        title: "Layer Tree",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/layerControl.gif"
            />
            <br></br>
            <p>
              Control layers that display on the map & the order they display.
            </p>
          </div>
        ),
        locale: { skip: <strong aria-label="skip">SKIP</strong> },
        placement: "bottom",
        target: ".project_layers",
      },
      {
        title: "Upload Shapefile",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/uploadLayer.gif"
            />
            <br></br>
            <p>
              Clicking on this button will make the uploader popup appear.
              Choose your shapefile(s) and after a successful upload it will be
              displayed on the map.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".uploadLayer",
      },
      {
        title: "Create Group",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/newGroup.gif"
            />
            <br></br>
            <p>
              Add a new group to the layer tree to set up project layers as you
              wish.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".createGroup",
      },
      {
        title: "Query Layer",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/layerQuery.gif"
            />
            <br></br>
            <p>
              Select one layer then click this button to be able to query and
              view the layer data.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".queryLayer",
      },
      {
        title: "Zoom to Layer",
        content: (
          <p>
            Select one layer then click this button to be able to zoom to the
            layer on the map.
          </p>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".layerZoom",
      },
      {
        title: "Delete Layer",
        content: (
          <p>
            Select one or multiple layer(s) then click this button to be able to
            delete the layer.<br></br>
            Base layers are not able to be deleted.
          </p>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".layerDelete",
      },
      {
        title: "Layer Opacity",
        content: (
          <div>
            <img
              style={{ borderRadius: "8px", width: "-webkit-fill-available" }}
              src="/video/layerOpacity.gif"
            />
            <br></br>
            <p>
              Select one layer then click this button to change the layer
              opacity on the map.
            </p>
          </div>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".layerOpacity",
      },
      {
        title: "Hide/Show Layer(s)",
        content: (
          <p>
            Select one or multiple layer(s) then click on one of the options
            provided to hide/show the layers.
          </p>
        ),
        locale: { skip: <strong>SKIP</strong> },
        placement: "bottom",
        target: ".showHideController",
      },
    ],
  });

  const activeLegends = (tree, jsTree) => {
    var legendControl = document.getElementById("activeLayers_legend");
    if (legendControl) {
      legendControl.innerHTML = "";

      if (tree.length > 0) {
        var legendString = "<h4>Layer Legends</h4>";
        for (let i = 0; i < tree.length; i++) {
          if (
            $(jsTree).jstree().get_node(tree[i]).type === "legend" ||
            $(jsTree).jstree().get_node(tree[i]).type === "cog"
          ) {
            var layerLegend = tree[i].id;

            var url = tree[i].legendURL
              ? tree[i].legendURL
              : `https://${process.env.REACT_APP_GEO_SERVER_URL}wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=${layerLegend}`;

            if (layerLegend === "Bathymetry:GLOBAL_Bathymetry") {
              layerLegend = "Bathymetry:0_90E_Northern_Hemisphere";
            }
            legendString =
              legendString +
              "<div style='display:flex;flex-direction:column;align-items:center;'><div style='width: 100%;display:flex;justify-content: space-between;align-items: center;'><h5 style='width:90%;'>" +
              layerLegend.split(":")[1].replace(/-|_/g, " ") +
              "</h5><a title='Full Screen' href='" +
              url +
              "' target='_blank'><svg xmlns='http://www.w3.org/2000/svg' width='15' viewBox='0 0 448 512'><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d='M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z'/></svg></a></div><img class='" +
              layerLegend
                .split(":")[1]
                .replace(/-|_|\(|\)/g, "")
                .toLowerCase() +
              "' src='" +
              url +
              "'/></div>";
          }
        }
        legendControl.innerHTML = legendString;
      }
    }
  };

  useEffect(() => {
    const functions = getFunctions(app, "us-central1");
    const autoTree = httpsCallable(functions, "generateLayerTree");

    autoTree({ toolAccess: "VD" }).then((results) => {
      setBaseLayers(results.data.tree);
    });
  }, []);

  useEffect(() => {
    if (platformData.layerControl.layerOrder[0].layer.length === 0) {
      GeoProvider.getLayerNames()
        .then((data) => {
          const functions = getFunctions(app, "us-central1");
          const autoTree = httpsCallable(functions, "generateLayerTree");

          autoTree({ toolAccess: "VD" }).then((results) => {
            const jstreeData = HelperFunctions.formatLayers(
              data.layer,
              results.data.tree,
              results.data.countries
            );
            setBaseLayers(results.data.tree);

            HelperFunctions.createJSTree(jstreeData, "#projectJSTree");
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      HelperFunctions.createJSTree({}, "#projectJSTree");
      setLayerTree({ base: null });
    }

    HelperFunctions.jsTreeEvents(
      platformData,
      setPlatformData,
      setLayerTree,
      activeLegends,
      removeQueryParams,
      setControl,
      setSelectedLayer,
      "#projectJSTree"
    );
  }, [reset.reload]);

  useEffect(() => {
    if (
      platformData.layerControl.layerOrder[0].layer.length > 0 &&
      JSON.stringify($("#projectJSTree").jstree().get_json("#")) !==
      JSON.stringify(layerTree.base)
    ) {
      $("#projectJSTree").jstree().destroy(true);

      HelperFunctions.createJSTree(
        platformData.layerControl.layerOrder[0].tree,
        "#projectJSTree"
      );
      HelperFunctions.jsTreeEvents(
        platformData,
        setPlatformData,
        setLayerTree,
        activeLegends,
        removeQueryParams,
        setControl,
        setSelectedLayer,
        "#projectJSTree"
      );
    }
  }, [layerTree]);

  useEffect(() => {
    if (
      selectedLayer &&
      control === "opacity" &&
      platformData.layerControl.activeLayers.find(
        (layer) => layer.id === selectedLayer
      )
    ) {
      var temp = [...platformData.layerControl.activeLayers];

      for (let i = 0; i < platformData.layerControl.activeLayers.length; i++) {
        const element = platformData.layerControl.activeLayers[i];

        if (element.id === selectedLayer) {
          temp[i].opacity = opacityVal;
        }
      }

      setPlatformData((platformData) => ({
        ...platformData,
        layerControl: {
          ...platformData.layerControl,
          activeLayers: temp,
        },
      }));
    }
  }, [selectedLayer, control, opacityVal]);

  /**
   *
   * Load from saved project
   *
   */
  useEffect(() => {
    if (searchParams.get("projectName")) {
      if (
        platformData.layerControl.layerOrder[0].layer.length > 0 &&
        JSON.stringify($("#projectJSTree").jstree().get_json("#")) !==
        JSON.stringify(platformData.layerControl.layerOrder[0].tree)
      ) {
        setLayerTree({ base: platformData.layerControl.layerOrder[0].tree });
      }
    }
  }, [searchParams, platformData.layerControl.layerOrder[0].tree]);

  /**
   * Close project rest of the jsTree
   */
  useEffect(() => {
    if (
      layerTree.base !== null &&
      platformData.layerControl.layerOrder[0].layer.length === 0
    ) {
      $("#projectJSTree").jstree().destroy(true);

      setReset((reset) => ({ ...reset, reload: !reset.reload }));
    }
  }, [platformData.layerControl.layerOrder[0].tree]);

  useEffect(() => {
    if (uploadComplete) {
      if (platformData.uploadedLayers.data.length > 0) {
        if (!$("#projectJSTree").jstree().get_node("Uploaded Layers")) {
          $("#projectJSTree")
            .jstree()
            .create_node(
              "#",
              {
                id: "Uploaded Layers",
                parent: "#",
                text: "Uploaded Layers",
                state: {
                  opened: true,
                },
                type: "group",
                a_attr: { type: "group" },
              },
              "last"
            );
        }

        for (
          let i = 0;
          i < platformData.uploadedLayers.layerNames.length;
          i++
        ) {
          if (
            !$("#projectJSTree")
              .jstree()
              .get_node(platformData.uploadedLayers.layerNames[i])
          ) {
            $("#projectJSTree")
              .jstree()
              .create_node(
                "Uploaded Layers",
                {
                  id: platformData.uploadedLayers.layerNames[i],
                  parent: "Uploaded Layers",
                  text: platformData.uploadedLayers.layerNames[i].replace(
                    ".zip",
                    ""
                  ),
                  icon: "glyphicon glyphicon-open",
                  state: {
                    checked: true,
                  },
                  type: "upload",
                  a_attr: { type: "upload" },
                },
                "last"
              );
          }
        }

        var layers = HelperFunctions.getLayerOrder();
        var tree = $("#projectJSTree").jstree("get_checked");

        const formattedTree = [];
        for (let i = 0; i < tree.length; i++) {
          const node = $("#projectJSTree").jstree().get_node(tree[i]);
          if (node.type !== "group") {
            formattedTree.push({
              type: node.type,
              name: node.text,
              id: node.id,
              opacity: 100,
              range: [null, null],
              query: false,
              ...(node.a_attr.geoserver && {
                url: node.a_attr.geoserver.includes("gwc")
                  ? node.a_attr.geoserver
                  : node.a_attr.geoserver + node.id.split(":")[0] + "/wms?",
              }),
              ...(node.a_attr.legendURL && {
                legendURL: node.a_attr.legendURL,
              }),
            });
          }
        }

        setPlatformData({
          ...platformData,
          layerControl: { activeLayers: formattedTree, layerOrder: [layers] },
        });
        setUploadComplete(false);
      }
    }
  }, [uploadComplete]);

  const handleUploadedLayer = (e) => {
    var file = document.getElementById("uploadeShapefile").files[0];
    var reader = new FileReader();
    reader.onload = function () {
      const storageRef = ref(
        storage,
        "userUploads/" + user.uid + "/" + file.name.replaceAll(" ", "_")
      );

      uploadBytes(storageRef, file).then((snapshot) => {
        if (!platformData.uploadedLayers.layerNames.includes(file.name)) {
          HelperFunctions.getUploadedLayerData(
            user.uid,
            file.name.replaceAll(" ", "_")
          )
            .then((data) => {
              setUploadComplete(true);

              for (let i = 0; i < data.features.length; i++) {
                data.features[i].properties["layerName"] = file.name.replaceAll(
                  " ",
                  "_"
                );
              }

              setPlatformData({
                ...platformData,
                uploadedLayers: {
                  ...platformData.uploadedLayers,
                  data: [...platformData.uploadedLayers.data, data],
                  layerNames: [
                    ...platformData.uploadedLayers.layerNames,
                    file.name.replaceAll(" ", "_"),
                  ],
                  shown: [...platformData.uploadedLayers.shown, true],
                },
              });
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    };
    reader.readAsDataURL(file);
  };

  const removeQueryParams = () => {
    const param = searchParams.get("controlBtn");

    if (window.location.search.includes("controlBtn")) {
      // param.delete('controlBtn');
      setSearchParams({});
    }
  };

  const handleControlType = (event, newControlType) => {
    setControl(newControlType);

    switch (newControlType) {
      case "upload":
        setUserUpload(true);
        setSearchParams({ ...searchParams, controlBtn: "upload" });
        break;
      case "group":
        removeQueryParams();
        HelperFunctions.addNewGroup();

        setControl(null);
        break;
      case "query":
        setControl("query");
        setSearchParams({ ...searchParams, controlBtn: "query" });
        break;
      case "zoom":
        setControl("zoom");
        setSearchParams({ ...searchParams, controlBtn: "zoom" });
        break;
      case "delete":
        removeQueryParams();

        setUserConfirm(HelperFunctions.deleteLayers(baseLayers["Global"]));
        setControl("delete");
        break;
      case "opacity":
        removeQueryParams();

        setControl("opacity");
        break;
      case "showHide":
        setControl("showHide");
        break;
      default:
        removeQueryParams();
        setControl(null);
        break;
    }
  };

  const handleTutorial = () => {
    setRunJoyride(true);
  };

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRunJoyride(false);
    }
  };

  return (

    <div className="layersControls widgets">

      <Joyride
        continuous
        callback={handleJoyrideCallback}
        run={runJoyride}
        steps={steps}
        showSkipButton
        showProgress
        styles={{
          options: {
            primaryColor: "#009ec6",
            zIndex: 10000,
          },
        }}
      />

      <div className="project_layers">

        <div className="project_heading">
          <div style={{ display: "flex" }}>
            <h2 style={{ margin: "0" }}>Layer Control</h2>
            <StyledIconButton className="widgetTour" onClick={handleTutorial}>
              <FontAwesomeIcon icon={faQuestion} />
            </StyledIconButton>
          </div>

          <ToggleButtonGroup
            id="layer_controlBtns"
            className="layer_controlBtns"
            value={control}
            exclusive
            onChange={handleControlType}
          >
            {user && !user.isAnonymous ? (
              <ToggleButton
                title="Upload Layer"
                className="uploadLayer"
                value="upload"
              >
                <FontAwesomeIcon
                  color={control === "upload" ? "white" : "black"}
                  icon={faFileArrowUp}
                />
              </ToggleButton>
            ) : null}
            <div className="vl"></div>
            <ToggleButton
              title="Create Group"
              className="createGroup"
              value="group"
            >
              <FontAwesomeIcon
                color={control === "group" ? "white" : "black"}
                icon={faFolderTree}
              />
            </ToggleButton>

            <ToggleButton
              id="layerControl_query"
              className="queryLayer"
              title="Query Layer"
              value="query"
            >
              <FontAwesomeIcon
                color={control === "query" ? "white" : "black"}
                icon={faInfoCircle}
              />
            </ToggleButton>
            <ToggleButton title="Zoom To" className="layerZoom" value="zoom">
              <FontAwesomeIcon
                color={control === "zoom" ? "white" : "black"}
                icon={faMagnifyingGlassLocation}
              />
            </ToggleButton>
            <div className="vl"></div>
            <ToggleButton
              title="Delete Layer"
              className="layerDelete"
              value="delete"
            >
              <FontAwesomeIcon
                color={control === "delete" ? "white" : "black"}
                icon={faTrash}
              />
            </ToggleButton>
            <ToggleButton
              title="Layer Opacity"
              className="layerOpacity"
              value="opacity"
            >
              <FontAwesomeIcon
                color={control === "opacity" ? "white" : "black"}
                icon={faCircleHalfStroke}
              />
            </ToggleButton>
            <div
              className="showHideController"
              onMouseEnter={() => setDropdownVisable(true)}
              onMouseLeave={() => setDropdownVisable(false)}
            >
              <ToggleButton
                title="Show/Hide"
                className="showHide"
                value="showHide"
              >
                <FontAwesomeIcon
                  color={control === "showHide" ? "white" : "black"}
                  size="xs"
                  icon={faEyeLowVision}
                />
              </ToggleButton>

              {drowpdownVisable && (
                <div className="showHideSubOptions">
                  <a
                    href="#"
                    onClick={() => {
                      $("#projectJSTree").jstree(true).check_all();
                      // $("#projectJSTreeUK").jstree(true).check_all()
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      width="15"
                      height="15"
                    >
                      {/* <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --> */}
                      <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                    </svg>
                    Show All
                  </a>
                  <a
                    href="#"
                    onClick={() =>
                      setSearchParams({ controlBtn: "showSelected" })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                      width="15"
                      height="15"
                    >
                      {/* <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --> */}
                      <path d="M572.52 241.4C518.29 135.59 410.93 64 288 64S57.68 135.64 3.48 241.41a32.35 32.35 0 0 0 0 29.19C57.71 376.41 165.07 448 288 448s230.32-71.64 284.52-177.41a32.35 32.35 0 0 0 0-29.19zM288 400a144 144 0 1 1 144-144 143.93 143.93 0 0 1-144 144zm0-240a95.31 95.31 0 0 0-25.31 3.79 47.85 47.85 0 0 1-66.9 66.9A95.78 95.78 0 1 0 288 160z" />
                    </svg>
                    Show Selected
                  </a>
                  <a
                    href="#"
                    onClick={() => {
                      $("#projectJSTree").jstree(true).uncheck_all();
                      // $("#projectJSTreeUK").jstree(true).uncheck_all()
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      width="15"
                      height="15"
                    >
                      {/* <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --> */}
                      <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                    </svg>
                    Hide All
                  </a>
                  <a
                    href="#"
                    onClick={() =>
                      setSearchParams({ controlBtn: "hideSelected" })
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                      width="15"
                      height="15"
                    >
                      {/* <!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --> */}
                      <path d="M320 400c-75.85 0-137.25-58.71-142.9-133.11L72.2 185.82c-13.79 17.3-26.48 35.59-36.72 55.59a32.35 32.35 0 0 0 0 29.19C89.71 376.41 197.07 448 320 448c26.91 0 52.87-4 77.89-10.46L346 397.39a144.13 144.13 0 0 1-26 2.61zm313.82 58.1l-110.55-85.44a331.25 331.25 0 0 0 81.25-102.07 32.35 32.35 0 0 0 0-29.19C550.29 135.59 442.93 64 320 64a308.15 308.15 0 0 0-147.32 37.7L45.46 3.37A16 16 0 0 0 23 6.18L3.37 31.45A16 16 0 0 0 6.18 53.9l588.36 454.73a16 16 0 0 0 22.46-2.81l19.64-25.27a16 16 0 0 0-2.82-22.45zm-183.72-142l-39.3-30.38A94.75 94.75 0 0 0 416 256a94.76 94.76 0 0 0-121.31-92.21A47.65 47.65 0 0 1 304 192a46.64 46.64 0 0 1-1.54 10l-73.61-56.89A142.31 142.31 0 0 1 320 112a143.92 143.92 0 0 1 144 144c0 21.63-5.29 41.79-13.9 60.11z" />
                    </svg>
                    Hide Selected
                  </a>
                </div>
              )}
            </div>
          </ToggleButtonGroup>
        </div>

        <hr />

        <span
          style={
            control === "opacity"
              ? { display: "flex", margin: "16px 0" }
              : { display: "none" }
          }
        >
          <div style={{ width: "80%" }}>
            <label>Layer Opacity (%)</label>
            <Slider
              min={0}
              step={10}
              max={100}
              onChange={(e) => setOpacityVal(e)}
              value={
                platformData.layerControl.activeLayers.find(
                  (layer) => layer.id === selectedLayer
                )
                  ? platformData.layerControl.activeLayers.find(
                    (layer) => layer.id === selectedLayer
                  ).opacity
                  : opacityVal
              }
            />
          </div>
          <input
            style={{
              width: "20%",
              border: "none",
              borderRadius: "8px",
              background: "#f5f5f5",
              marginLeft: "15px",
            }}
            type="number"
            onChange={(e) => setOpacityVal(e.target.value)}
            value={
              platformData.layerControl.activeLayers.find(
                (layer) => layer.id === selectedLayer
              )
                ? platformData.layerControl.activeLayers.find(
                  (layer) => layer.id === selectedLayer
                ).opacity
                : opacityVal
            }
          />
        </span>

        <div className="layersControls tree inputSection">
          <div className="inputSection_heading">
            <h4>Layers</h4>
          </div>

          <div className="projectTree">
            <div
              id="projectJSTree"
              className="projectJSTree"
              style={{ fontFamily: "Montserrat", fontSize: "14px" }}
              ref={jsTree}
            ></div>
            {platformData.layerControl.layerOrder[0].layer.length === 0 && (
              <BeatLoader
                style={{
                  display: "inherit",
                  textAlign: "center",
                  margin: "16px 0",
                }}
                color="#00A0C6"
              />
            )}


          </div>
        </div>


        <div className="insightSearch inputSection">
          <div className="inputSection_heading">
            <div style={{ display: "flex", paddingLeft: "10px" }}>
              <FontAwesomeIcon icon={faInfoCircle} size="5x" />
              <h4>Insights</h4>
            </div>
            <button
              className={
                insights ? "inputControlsHide open" : "inputControlsHide"
              }
              onClick={() => setInsights(!insights)}
              style={{ marginLeft: "10px", outline: "none" }}
            >
              <span className="left-bar"></span>
              <span className="right-bar"></span>
            </button>
          </div>

          {/* /////////////////////////////////// INSIGHTS MODAL ////////////////////////////////////// */}


          {insights && (
            <div className="insightSearch_parameters">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  id="insightsResultsBox"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginLeft: "5px",
                    width: "100%",
                  }}
                >

                  {/* Most recent Insight */}
                  <img
                    onClick={() => openModalWithInsight(mostRecentInsight)}
                    src={insightsImageUrl}
                    alt="insightImage"
                    style={{
                      width: "100%",
                      height: "auto",
                      borderRadius: "16px",
                      cursor: "pointer",
                      transform: hover ? "scale(1.05)" : "scale(1)",
                      boxShadow: hover
                        ? "0 4px 8px rgba(0, 0, 0, 0.2)"
                        : "none",
                      transition: "transform 0.2s, box-shadow 0.2s",
                    }}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                  />
                  <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: "10px" }}>
                    <div>
                      <p
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "black",
                          margin: "0 0 2px 0",
                          padding: "10px"
                        }}
                      >
                        Latest Insight: {mostRecentInsight.name}
                      </p>
                    </div>
                    <SecButton onClick={openGridModal} style={{ alignSelf: "flex-start", marginTop:"40px" }}>More</SecButton>
                  </div>
                </div>
              </div>
              {/* Modal */}
              <InsightModal
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
                selectedInsight={selectedInsight}
                setSelectedInsight={setSelectedInsight}
                dataInsights={dataInsights}
                handleThumbnailClick={handleThumbnailClick}
                calculateDaysSince={calculateDaysSince}
              />
            </div>
          )}
        </div>
      </div>
   

      <Modal
        open={userConfirm}
        onClose={() => setUserConfirm(null)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className="modal-popups deleteLayer"
            sx={{
              inset: "auto 35%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <header>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2>Confirm Layer Delete</h2>
                <ModalClose
                  onClick={() => {
                    setControl(null);
                    setUserConfirm(null);
                  }}
                >
                  X
                </ModalClose>
              </div>
              <Divider
                style={{ marginBottom: "16px" }}
                sx={{ bgcolor: "black" }}
              />
            </header>

            <div class="popup_body">
              <SecButton onClick={() => setUserConfirm(null)}>Cancel</SecButton>
              <PrimeButton
                onClick={() => {
                  setUserConfirm(null);

                  $("#projectJSTree")
                    .jstree(true)
                    .delete_node(
                      $("#projectJSTree").jstree(true).get_selected()
                    );
                  setControl(null);
                }}
              >
                Confirm
              </PrimeButton>
            </div>
          </Box>
        </div>
      </Modal>

      <Modal
        open={userConfirm === false}
        onClose={() => setUserConfirm(null)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className="modal-popups deleteWarning"
            sx={{
              inset: "auto 35%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <header>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2>Warning</h2>
                <ModalClose
                  onClick={() => {
                    setControl(null);
                    setUserConfirm(null);
                  }}
                >
                  X
                </ModalClose>
              </div>
              <Divider
                style={{ marginBottom: "16px" }}
                sx={{ bgcolor: "black" }}
              />
            </header>

            <div class="popup_body">
              <h4>Base layers cannot be deleted.</h4>
            </div>

            <footer style={{ display: "flex", justifyContent: "flex-end" }}>
              <PrimeButton
                id="cancelDelete"
                class="confirmDelete"
                onClick={() => {
                  setControl(null);
                  setUserConfirm(null);
                }}
              >
                Okay
              </PrimeButton>
            </footer>
          </Box>
        </div>
      </Modal>

      <Modal
        open={userUpload}
        onClose={() => setUserUpload(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            className="modal-popups uploadCustomLayer"
            sx={{
              inset: "auto 35%",
              overflow: "hidden",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <header>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h2>Upload Custom Layer(s)</h2>
                <ModalClose
                  onClick={() => {
                    setUserUpload(false);
                    setControl(null);
                  }}
                >
                  X
                </ModalClose>
              </div>
              <Divider
                style={{ marginBottom: "16px" }}
                sx={{ bgcolor: "black" }}
              />
            </header>

            <div class="guidance">
              <p>
                <br />
                <strong>Shapefile</strong> - This must be a zipped folder that
                contains the .cpg, .bdf, .prj, .shp and .shx files that make up
                the shapefile. <br />
              </p>
            </div>

            <div class="uploadBody">
              <div class="shapefileUpload">
                <label>Upload Custom Shapefile(s) (.zip)</label>
                <input
                  id="uploadeShapefile"
                  onChange={handleUploadedLayer}
                  accept="application/zip"
                  type="file"
                />
              </div>
            </div>

            <footer style={{ display: "flex", justifyContent: "flex-end" }}>
              <PrimeButton
                onClick={() => {
                  setUserUpload(false);
                  setControl(null);
                }}
              >
                Done
              </PrimeButton>
            </footer>
          </Box>
        </div>
      </Modal>


    </div>



  );
}

export default LayerControl;

/**
 *
 * This code is written, owned and maintained by
 * Vekta Group Energy Division.
 *
 * © 2023, Vekta Group Energy Division.
 *
 */

export const siteProforma = [
  ["ids", "lat", "lon"],
  ["0", "53.48703999", "-3.855390803"],
  ["1", "53.4775504", "-3.8553455"],
  ["2", "53.48712085", "-3.828706805"],
  ["3", "53.48755215", "-3.808138834"],
  ["4", "53.47814356", "-3.807323363"],
  ["osp1", "53.44775706", "-3.728759182"],
];

export const turbineType = [
  {
    turb_hub: [140],
    turb_diameter: [164],
    turbinetype: "V164-9500",
    _row: "V164/9500",
  },
  {
    turb_hub: [143],
    turb_diameter: [230],
    turbinetype: "15MWgeneric",
    _row: "15MW generic",
  },
  {
    turb_hub: [125],
    turb_diameter: [185],
    turbinetype: "DongfangD10000-185directdriv",
    _row: "Dongfang D10000-185 direct driv",
  },
  {
    turb_hub: [null],
    turb_diameter: [236],
    turbinetype: "VestasV236-15.0",
    _row: "Vestas V236-15.0",
  },
  {
    turb_hub: [null],
    turb_diameter: [225],
    turbinetype: "bewindBW14-xM225",
    _row: "bewind BW 14.xM225",
  },
  {
    turb_hub: [null],
    turb_diameter: [263],
    turbinetype: "NREL_Reference_18MW",
    _row: "NREL_Reference_18MW",
  },
];

export const turbineTypeNew = [
  { turb_hub: [140], turb_diameter: [164], turbinetype: "NREL_Reference_6MW" },
  { turb_hub: [140], turb_diameter: [164], turbinetype: "NREL_Reference_8MW" },
  { turb_hub: [143], turb_diameter: [230], turbinetype: "NREL_Reference_10MW" },
  { turb_hub: [125], turb_diameter: [185], turbinetype: "NREL_Reference_12MW" },
  {
    turb_hub: [null],
    turb_diameter: [236],
    turbinetype: "NREL_Reference_15MW",
  },
  {
    turb_hub: [null],
    turb_diameter: [225],
    turbinetype: "NREL_Reference_18MW",
  },
];

export const powerCurveExample = [
  { WS: 0, Power: 0 },
  { WS: 0.5, Power: 0 },
  { WS: 1, Power: 0 },
  { WS: 1.5, Power: 0 },
  { WS: 2, Power: 0 },
  { WS: 2.5, Power: 0 },
  { WS: 3, Power: 0 },
  { WS: 3.5, Power: 0 },
  { WS: 4, Power: 0 },
  { WS: 4.5, Power: 289 },
  { WS: 5, Power: 625 },
  { WS: 5.5, Power: 1010 },
  { WS: 6, Power: 1446 },
  { WS: 6.5, Power: 1934 },
  { WS: 7, Power: 2476 },
  { WS: 7.5, Power: 3074 },
  { WS: 8, Power: 3728 },
  { WS: 8.5, Power: 4440 },
  { WS: 9, Power: 5211 },
  { WS: 9.5, Power: 6043 },
  { WS: 10, Power: 6937 },
  { WS: 10.5, Power: 7893 },
  { WS: 11, Power: 8914 },
  { WS: 11.5, Power: 10000 },
  { WS: 12, Power: 10000 },
  { WS: 12.5, Power: 10000 },
  { WS: 13, Power: 10000 },
  { WS: 13.5, Power: 10000 },
  { WS: 14, Power: 10000 },
  { WS: 14.5, Power: 10000 },
  { WS: 15, Power: 10000 },
  { WS: 15.5, Power: 10000 },
  { WS: 16, Power: 10000 },
  { WS: 16.5, Power: 10000 },
  { WS: 17, Power: 10000 },
  { WS: 17.5, Power: 10000 },
  { WS: 18, Power: 10000 },
  { WS: 18.5, Power: 10000 },
  { WS: 19, Power: 10000 },
  { WS: 19.5, Power: 10000 },
  { WS: 20, Power: 10000 },
  { WS: 20.5, Power: 10000 },
  { WS: 21, Power: 10000 },
  { WS: 21.5, Power: 10000 },
  { WS: 22, Power: 10000 },
  { WS: 22.5, Power: 10000 },
  { WS: 23, Power: 10000 },
  { WS: 23.5, Power: 10000 },
  { WS: 24, Power: 10000 },
  { WS: 24.5, Power: 10000 },
  { WS: 25, Power: 10000 },
  { WS: 25.5, Power: 10000 },
  { WS: 26, Power: 10000 },
  { WS: 26.5, Power: 10000 },
  { WS: 27, Power: 10000 },
  { WS: 27.5, Power: 10000 },
  { WS: 28, Power: 10000 },
  { WS: 28.5, Power: 10000 },
  { WS: 29, Power: 10000 },
  { WS: 29.5, Power: 10000 },
  { WS: 30, Power: 10000 },
  { WS: 30.5, Power: 9359 },
  { WS: 31, Power: 8753 },
  { WS: 31.5, Power: 8179 },
  { WS: 32, Power: 7635 },
  { WS: 32.5, Power: 7118 },
  { WS: 33, Power: 6628 },
  { WS: 33.5, Power: 6162 },
  { WS: 34, Power: 5719 },
  { WS: 34.5, Power: 5297 },
  { WS: 35, Power: 4895 },
  { WS: 35.5, Power: 4512 },
  { WS: 36, Power: 4147 },
  { WS: 36.5, Power: 3798 },
  { WS: 37, Power: 3465 },
  { WS: 37.5, Power: 3147 },
  { WS: 38, Power: 2842 },
  { WS: 38.5, Power: 2551 },
  { WS: 39, Power: 0 },
  { WS: 39.5, Power: 0 },
  { WS: 40, Power: 0 },
  { WS: 40.5, Power: 0 },
  { WS: 41, Power: 0 },
  { WS: 41.5, Power: 0 },
  { WS: 42, Power: 0 },
  { WS: 42.5, Power: 0 },
  { WS: 43, Power: 0 },
  { WS: 43.5, Power: 0 },
  { WS: 44, Power: 0 },
  { WS: 44.5, Power: 0 },
  { WS: 45, Power: 0 },
  { WS: 45.5, Power: 0 },
  { WS: 46, Power: 0 },
  { WS: 46.5, Power: 0 },
  { WS: 47, Power: 0 },
  { WS: 47.5, Power: 0 },
  { WS: 48, Power: 0 },
  { WS: 48.5, Power: 0 },
  { WS: 49, Power: 0 },
  { WS: 49.5, Power: 0 },
  { WS: 50, Power: 0 },
];

export const windfarmturbines = [
  {
    "Alpha Ventus": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
        ],
        lon: [
          6.6233, 6.6183, 6.6063, 6.6072, 6.6067, 6.6059, 6.5942, 6.5935,
          6.5945, 6.6198, 6.6193, 6.5938, 6.6188,
        ],
        lat: [
          54, 54.0217, 54.0143, 54.0003, 54.0075, 54.0217, 54.0075, 54.0217,
          54.0003, 54.0003, 54.0075, 54.0143, 54.0143,
        ],
      },
    ],
    "Windplanblauw (Offshore)": [
      {
        ids: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24,
        ],
        lon: [
          5.610610495283708, 5.620530561508616, 5.600679867860833,
          5.529252977053545, 5.522027860337006, 5.634085942958876,
          5.6241899327961, 5.614300963431987, 5.604392296595421,
          5.594517576466701, 5.584623745598748, 5.5747582455635145,
          5.564873299512926, 5.5557500170037315, 5.54835659167145,
          5.541192746667122, 5.534028817843733, 5.590733063364837,
          5.580804699055789, 5.570861750235252, 5.560923998194653,
          5.551571135357534, 5.544009233771475, 5.536462754887283,
        ],
        lat: [
          52.61671626833454, 52.618393235702484, 52.61504919161239,
          52.59263967056381, 52.588148060110186, 52.61135729854057,
          52.60968477358133, 52.60803429302742, 52.60635984023045,
          52.60472746458741, 52.60305007812435, 52.60140035194173,
          52.59952029105992, 52.596703049584235, 52.59236994091966,
          52.587924850028706, 52.58346995231103, 52.61339577739236,
          52.61173649584016, 52.610070592584414, 52.60819396828292,
          52.60564687554652, 52.60179815706635, 52.59712147419068,
        ],
      },
    ],
    Westermeerwind: [
      {
        ids: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
          38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
        ],
        lon: [
          5.585794026919842, 5.585879773789287, 5.576954555652065,
          5.585957474031716, 5.577042062721205, 5.586047998585997,
          5.57713376074193, 5.586126872294841, 5.57721045515595,
          5.586215049916234, 5.57729662112058, 5.586296438196057,
          5.577350768215098, 5.58638008958971, 5.577714458993796,
          5.586721233048877, 5.577544138721294, 5.577460152051515,
          5.586460639679218, 5.586635737636526, 5.57760163857708,
          5.577801463148717, 5.5865562771944335, 5.586809494489302,
          5.5868859374462545, 5.577969185031236, 5.578023583582819,
          5.577857538080963, 5.578105055681675, 5.5781943229504805,
          5.5869707623063505, 5.593669663141667, 5.597717284184142,
          5.587053743147777, 5.587146363177851, 5.587228673467024,
          5.578281075648306, 5.589590358505177, 5.601772448939499,
          5.605837755797694, 5.609896440952355, 5.613959652334756,
          5.618019427136858, 5.622089763136955, 5.626158338937359,
          5.630217946101396, 5.634286018987638, 5.638354846245137,
        ],
        lat: [
          52.694684899292675, 52.69843269965863, 52.69844057864761,
          52.70217454887336, 52.7021820925862, 52.70588429539889,
          52.705898293177206, 52.70962497114718, 52.709641399677416,
          52.71337226859899, 52.7133889485863, 52.717086457533206,
          52.71709777310249, 52.720826881824394, 52.7320443828415,
          52.73576938443083, 52.72458298027453, 52.720843561811726,
          52.724570239781684, 52.73203097179643, 52.728298342675224,
          52.73579033918877, 52.72830152779839, 52.739504947218194,
          52.74322609313209, 52.74324453331904, 52.746988058914404,
          52.7395348706125, 52.750737619480056, 52.754436972445696,
          52.746972468574484, 52.77864516609629, 52.78262313352339,
          52.750717083817264, 52.754429596370905, 52.75816876337662,
          52.7581843537165, 52.77469988809155, 52.786599927484076,
          52.79055216246842, 52.794537170694205, 52.7985055827517,
          52.80247013913377, 52.806433689687424, 52.81040872344844,
          52.8143717710879, 52.818344122639886, 52.82231345670677,
        ],
      },
    ],
    "Windpark Fryslân": [
      {
        ids: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
          38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
          55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71,
          72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88,
          89,
        ],
        lon: [
          5.2728431829009414, 5.261614869228737, 5.250712946866116,
          5.239570380063356, 5.228890242858712, 5.225324162153527,
          5.27587944350651, 5.235755189195884, 5.247001859236036,
          5.283712248203131, 5.253825063695388, 5.265067458964949,
          5.22990369877141, 5.243044092195561, 5.2585025847613736,
          5.25479241914067, 5.243067896800568, 5.232069666370478,
          5.221980788615666, 5.218652418684513, 5.228609532921411,
          5.299431837052822, 5.239263770045285, 5.2507959277075145,
          5.277491786400986, 5.266517276756872, 5.288604765085523,
          5.291638930215299, 5.2747252554395345, 5.306913272368405,
          5.2855975896839595, 5.296412675529724, 5.314083236162048,
          5.2807086770199305, 5.2694784355099955, 5.262909035078735,
          5.30414514884535, 5.2714755915791605, 5.215560753698952,
          5.259182692384883, 5.247231523382993, 5.235994827807616,
          5.225441760255051, 5.293762988298511, 5.283172537457802,
          5.212811740915129, 5.301350622327277, 5.311228695217268,
          5.308209282237073, 5.2984336362038675, 5.287598349971461,
          5.2758109633576, 5.263798271186403, 5.252008537639624,
          5.240710235075937, 5.219633352812906, 5.227201121731185,
          5.237917887852717, 5.248948891524207, 5.26032372086388,
          5.272053775260077, 5.283680229333015, 5.294873925745121,
          5.304742024170339, 5.301228917093197, 5.29097198217977,
          5.279746937449801, 5.268179994889409, 5.256879764487962,
          5.245803163263189, 5.234993525654488, 5.242625919046247,
          5.253614175011563, 5.264752299405615, 5.275919089908541,
          5.287249578980437, 5.297804658189732, 5.2940215700114095,
          5.283211764764644, 5.272372874313874, 5.261309684134205,
          5.267614970796473, 5.222498119679556, 5.279774765368358,
          5.291113133429235, 5.2328894165008535, 5.243887730749976,
          5.255498929759142, 5.232379629149846,
        ],
        lat: [
          52.976219783484, 52.97738637676767, 52.97869604913855,
          52.980227171391256, 52.981884273648674, 52.987632079930854,
          52.97112744202994, 52.98589132627977, 52.9842086592177,
          52.97519752657294, 52.97330589866473, 52.972141317037824,
          53.014650641896495, 52.97464197403061, 52.9826836557543,
          52.988467336582076, 52.99015327258642, 52.99189209839978,
          52.9936192733677, 52.99959246902622, 52.997969145838596,
          52.98364757461923, 52.99632243714113, 52.99464605650643,
          52.98570323637255, 52.98696177913407, 52.98459095782144,
          52.979354447632815, 52.991525054859814, 52.98806366412527,
          52.99026634446022, 52.98910888745101, 52.99262425764198,
          52.980280480295434, 52.981411869585806, 52.99301409995857,
          52.99382052286291, 52.99791944115281, 53.00550925447642,
          52.999525162343765, 53.001120238517665, 53.00265286951295,
          53.00411358377899, 52.99510127766779, 52.99642947404466,
          53.011210038281824, 52.99981366745098, 52.998401232947224,
          53.00431072614165, 53.00588694303341, 53.007514624810675,
          53.00918312645642, 53.01077233529811, 53.01222299128033,
          53.013513385273896, 53.015669378408205, 53.02021848871755,
          53.01929773665395, 53.018195935481785, 53.01690554148822,
          53.01540912031468, 53.013747659467626, 53.01198502904923,
          53.010193648702995, 53.01617807611181, 53.017966774249054,
          53.01963007911479, 53.02115508257816, 53.022465593139366,
          53.023574938024346, 53.02457716218683, 53.02879191837859,
          53.02757235146684, 53.02647968656915, 53.02518753237593,
          53.02365129716227, 53.02197986185045, 53.02742256685713,
          53.029059217270785, 53.03039671756018, 53.03146608076706,
          53.00442086434933, 53.010074960954064, 53.00279913372333,
          53.00125418133037, 53.0088210282393, 53.007469530171626,
          53.005999428174015, 52.97619229084157,
        ],
      },
    ],
    Nysted: [
      {
        ids: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
          38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
          55, 56,
        ],
        lon: [
          11.733316423845027, 11.73329077522132, 11.680419322564404,
          11.720067987688994, 11.680490736379454, 11.706856431906886,
          11.706865065267154, 11.759719335022567, 11.759659320595858,
          11.693622244986216, 11.720073687383147, 11.720076453411195,
          11.680405073329013, 11.733265042778584, 11.759679353344438,
          11.693630794527452, 11.693656443151184, 11.746505097031472,
          11.706873866265484, 11.746476430922629, 11.706853665878839,
          11.693673626052686, 11.733307958122822, 11.68039074027459,
          11.746519346266867, 11.693682259412952, 11.680462154089613,
          11.759759316700721, 11.68044798867325, 11.720079387077305,
          11.706870764961309, 11.720082236924384, 11.759739367771173,
          11.733256493237347, 11.746490763977052, 11.74653367932129,
          11.680433655618828, 11.733299324762557, 11.75963937166631,
          11.706862299239106, 11.720065054022882, 11.706867998933262,
          11.759699386093018, 11.74654792855668, 11.693639344068714,
          11.746447932451815, 11.73327367613885, 11.680476570963068,
          11.720085086771464, 11.693647977428983, 11.693665076511449,
          11.733282141861054, 11.759779349449303, 11.706859365572996,
          11.746462265506239, 11.72007075371704,
        ],
        lat: [
          54.53220737104922, 54.54524408033111, 54.546306486558365,
          54.55965634374282, 54.56815207316145, 54.539245152229846,
          54.55231723314312, 54.54252834370291, 54.55552959753439,
          54.536227834725466, 54.55095332985875, 54.54660194864525,
          54.54193725189112, 54.55828062197495, 54.551195818317545,
          54.54059128587951, 54.55368088497043, 54.54388629583601,
          54.56538914641834, 54.552565588934165, 54.53488781986509,
          54.56240736818342, 54.53655288493051, 54.53756818486192,
          54.53954656546797, 54.5667705678804, 54.55941377146497,
          54.53386078526925, 54.55504478825482, 54.54225048361271,
          54.56103189787262, 54.53789901858017, 54.53819464830511,
          54.56262621967525, 54.54822585856605, 54.53520691891891,
          54.55067546976852, 54.54089848263081, 54.55986346057025,
          54.54795981695934, 54.564007724956326, 54.556674565507876,
          54.546862122919755, 54.53086727236984, 54.54495448557647,
          54.56124488203226, 54.553935191912686, 54.56378292231321,
          54.53354763736666, 54.549317685273444, 54.5580440846674,
          54.54958967803144, 54.52952692223338, 54.54360248459458,
          54.55690523548322, 54.55530479489126,
        ],
      },
    ],
    "Rødsand 2": [
      {
        ids: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
          38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
          55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71,
        ],
        lon: [
          11.618381839692091, 11.598891232604643, 11.609396188032974,
          11.617906418144088, 11.589122292093975, 11.579683179473191,
          11.537611976693398, 11.542705072518714, 11.591067564182481,
          11.573752060968587, 11.60895245007896, 11.551447229890641,
          11.560923055247315, 11.6000513718229, 11.570441795948256,
          11.555978738203322, 11.533335864970411, 11.565670732842877,
          11.58239606625432, 11.524236722342323, 11.547799844724665,
          11.515659688462932, 11.577048663486721, 11.609280266312084,
          11.593028929525076, 11.601212516869511, 11.585109958863853,
          11.538900359030206, 11.5301796593304, 11.497929029584839,
          11.542938927617257, 11.521963550201663, 11.560527848512692,
          11.552881457342664, 11.6023743324684, 11.594975375080054,
          11.616955658867113, 11.60962434343736, 11.50680772815946,
          11.513380732809082, 11.572937759075273, 11.58036152689701,
          11.568936489957338, 11.536123769784952, 11.587825024939804,
          11.48980679777165, 11.519940577870726, 11.504710156890267,
          11.496685825706153, 11.542069556620108, 11.511507796544976,
          11.550035633577181, 11.557995088830749, 11.528253749438223,
          11.481149381440815, 11.526516767643585, 11.479849180620532,
          11.47291206609705, 11.534561047757279, 11.488140391601718,
          11.503566362383223, 11.49511723634626, 11.471728541368947,
          11.478626763861712, 11.493741682216438, 11.510448240164806,
          11.486802220760069, 11.501410955982152, 11.518291941335548,
          11.464831324704562, 11.485525824544794,
        ],
        lat: [
          54.53530272407217, 54.537071892375025, 54.53597352778314,
          54.54429424306348, 54.53832431634721, 54.539667851606865,
          54.54039649044976, 54.546959939729796, 54.546571522336706,
          54.54884729286733, 54.544766479488295, 54.54505875645198,
          54.54303343718814, 54.545587570723164, 54.54121322309497,
          54.551993272585456, 54.549485071879815, 54.550187726822834,
          54.54761112978731, 54.55189587487057, 54.55352338900977,
          54.55451086102264, 54.55649058273325, 54.55353378256972,
          54.5548183930501, 54.554112133888694, 54.555554240329705,
          54.55566136105227, 54.55770503668403, 54.56048816381376,
          54.56214819973726, 54.55991735620764, 54.55893617062216,
          54.560095723107175, 54.56263678087327, 54.563056462765175,
          54.56228616586353, 54.56230971901144, 54.55745466923626,
          54.56246947808593, 54.564893859759835, 54.56412431722948,
          54.557607052235944, 54.563504978403984, 54.563497350872105,
          54.56351444995457, 54.56749317175297, 54.56512159606906,
          54.56773264272665, 54.56930441720972, 54.56974513767858,
          54.56803095466057, 54.56665825037787, 54.56532385139263,
          54.56691968193783, 54.57250714241232, 54.573787645760156,
          54.57038769437589, 54.57072967602536, 54.570695645498475,
          54.571950584041616, 54.57447144142105, 54.577163121987155,
          54.58015948473364, 54.58057698733069, 54.576167938623364,
          54.57717787413675, 54.578440859306916, 54.57437773174357,
          54.57416642396455, 54.583155344565895,
        ],
      },
    ],
    "Avedøre Holme": [
      {
        ids: [1, 2, 3],
        lon: [12.46110525791781, 12.468284441804968, 12.454269145327544],
        lat: [55.60232503303083, 55.60182832144877, 55.60278905519053],
      },
    ],
    Lillgrund: [
      {
        ids: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
          38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
        ],
        lon: [
          12.77651803453682, 12.767760957377247, 12.764167467848722,
          12.780843767126612, 12.7681976545326, 12.763635971368473,
          12.763861193106749, 12.767929433631055, 12.772270169827522,
          12.773113724562906, 12.76883317043121, 12.78149487336512,
          12.764552448661478, 12.772911050144161, 12.77716914077533,
          12.77266319726732, 12.785347447520763, 12.785550121939506,
          12.768630328374428, 12.78124693666925, 12.789402779914186,
          12.785122141963456, 12.768360095816092, 12.793458028488544,
          12.789177474356878, 12.764327143104166, 12.77694383521802,
          12.789673180110556, 12.780571187635417, 12.793210259430737,
          12.78099916761144, 12.793660870545354, 12.801546229774857,
          12.801794082651696, 12.771964733275867, 12.784851741767085,
          12.797490981200495, 12.788907074160505, 12.784626436209777,
          12.797265675643189, 12.77604269680785, 12.788479094184455,
          12.79774193538151, 12.80587196236465, 12.776245455045625,
          12.79298495387343, 12.78030078743902, 12.771739427718558,
        ],
        lat: [
          55.51414632199804, 55.52079157865305, 55.50992125606539,
          55.51285609564253, 55.5126892957694, 55.51802437713934,
          55.51396074666179, 55.516749489666644, 55.515435626344214,
          55.49925226679193, 55.50055389253574, 55.500732594711415,
          55.50186825877238, 55.50329737326348, 55.50203413663618,
          55.50735488495171, 55.507520678996485, 55.503501556424794,
          55.50458609087641, 55.50476479305206, 55.51025083249817,
          55.511577687770526, 55.50865617541938, 55.51303194797115,
          55.5143458951126, 55.50588754898213, 55.50604068635316,
          55.50623204520257, 55.51690983547436, 55.517088370011955,
          55.508834793776025, 55.50898784732798, 55.51858076787202,
          55.51451177297642, 55.519499089183675, 55.51562145313756,
          55.5158000714942, 55.51837666852975, 55.519652142735616,
          55.5198562420779, 55.522254053118765, 55.522317755582876,
          55.51177591978057, 55.51727972886141, 55.51821087448497,
          55.5211699377623, 55.520978662731856, 55.52355484067227,
        ],
      },
    ],
    Middelgrunden: [
      {
        ids: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        ],
        lon: [
          12.668351250463246, 12.668828264572852, 12.669272840717184,
          12.670218235576131, 12.670749229142162, 12.67069642315218,
          12.670430549183491, 12.670591397905412, 12.67070866073081,
          12.670757946321459, 12.669267141023028, 12.66884611802661,
          12.66964566777034, 12.66836021909964, 12.670595924133126,
          12.669966275566736, 12.670440523648267, 12.670232652449585,
          12.669962168434182, 12.669629574516248,
        ],
        lat: [
          55.70695297758453, 55.705335018814964, 55.70370624739025,
          55.69881549070757, 55.69061304408101, 55.688961138603574,
          55.69717439788522, 55.69553623872901, 55.69389573263988,
          55.69225329871301, 55.67914869601542, 55.67751715856269,
          55.70207856561303, 55.67589391919413, 55.687320883971516,
          55.70044929127414, 55.6856866643098, 55.68404146435489,
          55.68240288610349, 55.68077143246981,
        ],
      },
    ],
    "Nissum Bredning": [
      {
        ids: [1, 2, 3, 4],
        lon: [
          8.241502062999729, 8.256050029423818, 8.251750196914827,
          8.234069158903537,
        ],
        lat: [
          56.66483502823505, 56.66925312939782, 56.677079899124145,
          56.672524837663545,
        ],
      },
    ],
    Rønland: [
      {
        ids: [1, 2, 3, 4, 5, 6, 7, 8],
        lon: [
          8.225453735907593, 8.222814442236313, 8.216226936894202,
          8.2202180639093, 8.217535854893782, 8.224101986382806,
          8.221484066564614, 8.218844772893334,
        ],
        lat: [
          56.65318367990969, 56.65807904663911, 56.670399857570985,
          56.66299494903138, 56.66793624859024, 56.65560169133716,
          56.660543242353114, 56.66547129850497,
        ],
      },
    ],
    "Vesterhav Nord": [
      {
        ids: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21,
        ],
        lon: [
          8.024854844035843, 8.024948218437203, 8.024966993900307,
          8.025471333014252, 8.025695465105116, 8.025228425460284,
          8.025209733816212, 8.025975672128197, 8.026069046529557,
          8.025732848393263, 8.026218579682165, 8.02627457079538,
          8.025135051058925, 8.025265892567491, 8.025321883680675,
          8.025303192036603, 8.025863522263764, 8.026125121461803,
          8.026498786705275, 8.026517394530314, 8.026442627953998,
        ],
        lat: [
          56.656727464752414, 56.643942128746666, 56.650340454534195,
          56.59924713881649, 56.59287102507241, 56.63757364253447,
          56.63121429259676, 56.5737362297747, 56.567366151000854,
          56.58646297627723, 56.554612330951095, 56.54821844757228,
          56.6247921622042, 56.61843063297166, 56.612109169236234,
          56.60565292449783, 56.58011552864198, 56.56097436309785,
          56.53543755397524, 56.52905079521411, 56.541771842022484,
        ],
      },
    ],
    "Vesterhav Syd": [
      {
        ids: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        ],
        lon: [
          7.9531480814816, 7.953204072594786, 7.95333483028429,
          7.953372129753434, 7.953484279617868, 7.953465587973795,
          7.953484279617868, 7.953484279617868, 7.953502971261969,
          7.95342828850468, 7.9532787553520725, 7.953316138640219,
          7.953745794996906, 7.953801869929124, 7.953372129753434,
          7.953540270731082, 7.953390821397506, 7.953502971261969,
          7.953708495527764, 7.953727019533802,
        ],
        lat: [
          56.13715206248937, 56.13095833895832, 56.124847093354546,
          56.10641344425727, 56.069478168828006, 56.07560877662817,
          56.10018367854257, 56.09405709405595, 56.05101803291676,
          56.04474795024779, 56.118734758103294, 56.112558971845054,
          56.02642226006342, 56.02021076689761, 56.087960684420786,
          56.06332576807964, 56.057161967942925, 56.08179043021862,
          56.03872739683631, 56.032570050765045,
        ],
      },
    ],
    "Hywind Tampen": [
      {
        ids: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
        lon: [
          2.2316975203385714, 2.2387899515278775, 2.2458822988981524,
          2.2600668260006107, 2.2529745624493955, 2.280037464682806,
          2.2729486377118633, 2.258771067589038, 2.265859894559952,
          2.251682240618095, 2.287126291653749,
        ],
        lat: [
          61.35691512475095, 61.343866177890455, 61.33081178279286,
          61.30468664788648, 61.317751939458184, 61.31417529755503,
          61.32724084058384, 61.3533555819303, 61.34030093537558,
          61.36640486406694, 61.3011042224701,
        ],
      },
    ],
    "Gunfleet Sands 1 & 2": [
      {
        ids: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
          38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
        ],
        lon: [
          1.2061224652874787, 1.2028953487466367, 1.2170743437930298,
          1.200121944624584, 1.2139297889984562, 1.2280776871840546,
          1.2389221935099497, 1.1941149698962474, 1.2358726894973415,
          1.2497784345002854, 1.197023490297454, 1.2110029960483077,
          1.2248471340629123, 1.2329054957739345, 1.1881509943312096,
          1.1910991611344173, 1.201897566992869, 1.204971294705615,
          1.2190098928738566, 1.20794091918097, 1.2219451515461799,
          1.2468302676970495, 1.2717518451267438, 1.2129469270323059,
          1.2158984465968103, 1.2299486956104315, 1.2548241725726825,
          1.209924748024065, 1.2607817778913102, 1.2826360816736724,
          1.2269295340873327, 1.2378320431832037, 1.2408232929687133,
          1.1990071515025134, 1.2438572904604541, 1.2936274389431617,
          1.2348924259212026, 1.2686619403408486, 1.223910624021329,
          1.2458689472220783, 1.2518115489340573, 1.243003258346107,
          1.2209656423413264, 1.2796942851168753, 1.2318187820274886,
          1.2578582538834266, 1.24892448620497, 1.2906148991235682,
        ],
        lat: [
          51.7080307925326, 51.71144071838166, 51.71222057065293,
          51.714787025585615, 51.71571096277244, 51.716509255230655,
          51.72075846104449, 51.72178675292581, 51.7242549719533,
          51.72502392775048, 51.718321338877765, 51.71912742650597,
          51.72000341920663, 51.7276808234184, 51.72865387855783,
          51.72519273928038, 51.729467090803745, 51.72601944639039,
          51.72684414184362, 51.72262678726188, 51.72341694927406,
          51.72848095989535, 51.73356282397042, 51.733752254982136,
          51.730330259192534, 51.731192757029085, 51.736203961660415,
          51.73717869318044, 51.72931353433756, 51.737840444436,
          51.73456018662904, 51.73881836107923, 51.73541522457176,
          51.7329050960284, 51.731937656764096, 51.742155364370696,
          51.74228226638474, 51.73704575619612, 51.7379865410083,
          51.74647715746598, 51.73963945231412, 51.749967130490354,
          51.74145773856955, 51.741349528199564, 51.745768467552644,
          51.732802752990644, 51.743070752016266, 51.74557518086547,
        ],
      },
    ],
    Rampion: [
      {
        ids: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
          38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
          55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71,
          72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88,
          89, 90, 91, 92, 93, 94, 95, 96, 97, 98, 99, 100, 101, 102, 103, 104,
          105, 106, 107, 108, 109, 110, 111, 112, 113, 114, 115, 116,
        ],
        lon: [
          -0.19417076791555132, -0.20103722299529636, -0.19646665501380764,
          -0.20644455637059878, -0.20451328206038968, -0.2052642167656984,
          -0.21524203430345779, -0.21322518076183883, -0.21479142318884215,
          -0.21738780151588344, -0.19839784550498507, -0.22358923639782802,
          -0.20217447961786658, -0.2257564612823728, -0.21015664982905946,
          -0.21243116307419999, -0.22936126638020934, -0.22487661290625735,
          -0.21110087122153232, -0.20908376622284663, -0.21833202290835635,
          -0.22858870636468967, -0.2224733536283452, -0.22039195943227696,
          -0.23775121618075445, -0.2397468635073494, -0.21908304143272514,
          -0.2212717239893891, -0.2521278565039893, -0.25021803986589924,
          -0.24193546224498158, -0.2436520760149108, -0.23073455739614704,
          -0.24099132467151205, -0.2387812682617607, -0.235348040721874,
          -0.24729988027604577, -0.24532577444060166, -0.251355464126533,
          -0.2535012313389587, -0.2577927657637815, -0.2592303459770733,
          -0.2572562401416576, -0.2336313431329131, -0.2549817268964886,
          -0.23163586344440998, -0.23669970642765747, -0.2864386742305669,
          -0.26819965292500564, -0.24354470383525498, -0.27957213533179015,
          -0.2777053178569986, -0.24667752396538845, -0.24910232473445149,
          -0.2879407112792478, -0.29012947765497193, -0.2556469147323525,
          -0.2967812721944369, -0.3069521249622653, -0.26536724020459695,
          -0.28208276678935823, -0.26751300741702266, -0.29233953406472324,
          -0.2754952614472188, -0.2945282166213872, -0.2859665216248004,
          -0.3047635262246331, -0.3003861611113052, -0.3025104706515833,
          -0.30832558361626644, -0.3106644698778212, -0.29821893622676043,
          -0.29600887981698065, -0.29384165493243586, -0.32538443295501907,
          -0.32302392138328173, -0.31268140723847176, -0.31875392844960965,
          -0.3164150421880549, -0.3018452828157479, -0.30414125373303597,
          -0.30643722465032397, -0.32027742317043817, -0.32240173271071626,
          -0.314998835827879, -0.3171660607124238, -0.32883903434796485,
          -0.3312422098068453, -0.32081386497353037, -0.32456895759526105,
          -0.32677918164307584, -0.34124156883575546, -0.3389670555905866,
          -0.33504030159187437, -0.33272287300246717, -0.3516056244717447,
          -0.3494169419150807, -0.34722825935841684, -0.344932204622097,
          -0.314398021008401, -0.33714315346003326, -0.3121664231074704,
          -0.3100207397140764, -0.3487946694234836, -0.3510262673243858,
          -0.3426149436707533, -0.3304698174294174, -0.32834542407007916,
          -0.35383722237267534, -0.33334514549406435, -0.33519050529673683,
          -0.3437521164742918, -0.36188384941922885, -0.3553392594213562,
          -0.35778543404350677, -0.3597380822068317,
        ],
        lat: [
          50.667239649555476, 50.687241468917456, 50.67369933087419,
          50.676051795818886, 50.669021223075, 50.70086113956504,
          50.70322609354549, 50.69630817358023, 50.67122432250514,
          50.67754763025923, 50.68091958608689, 50.69785765220089,
          50.66245199028178, 50.7046257875564, 50.65762317586352,
          50.66432894985941, 50.68653453920378, 50.67311452548977,
          50.68923988227208, 50.68246956762176, 50.65273937616043,
          50.65471197325331, 50.66665476035203, 50.659568364133,
          50.68150414001422, 50.688206645067865, 50.68440017137969,
          50.69125179049085, 50.69697419960641, 50.690136997368725,
          50.695030520079506, 50.702016584917516, 50.66147264871499,
          50.66340417448231, 50.65656219455981, 50.674882352688115,
          50.68393799323873, 50.67700364474325, 50.665294628924045,
          50.67212185669695, 50.685718896205984, 50.66056136820196,
          50.65367806549685, 50.69985556264135, 50.64686181801712,
          50.69305959936728, 50.64974628235621, 50.68465841781647,
          50.68762217495958, 50.66987793739833, 50.664424252098506,
          50.65744631770656, 50.65193664129354, 50.65845298427772,
          50.65941874716137, 50.66620599325612, 50.67888001758767,
          50.686629757623855, 50.688519373875295, 50.64886182393336,
          50.67106112685034, 50.65550104561806, 50.67312827181096,
          50.65069855037574, 50.67991333861092, 50.652426479714975,
          50.681708155537464, 50.6682188234842, 50.675018390976625,
          50.66326805237478, 50.670149930156356, 50.66141825016341,
          50.65460317615012, 50.64784149486004, 50.68550138581863,
          50.6788256190361, 50.676949246191676, 50.665213156825224,
          50.65834401953646, 50.64294294300735, 50.649664642619314,
          50.656494049687005, 50.63996292497207, 50.646725779728605,
          50.68365233797858, 50.69044972617614, 50.66723956573645,
          50.67393041994469, 50.67201305959374, 50.65354202720833,
          50.66043899241563, 50.675779886879916, 50.66922523859822,
          50.65540574337898, 50.648712290780736, 50.67777863551069,
          50.67093875106399, 50.66409786078893, 50.65741907652124,
          50.6516373235312, 50.6622752997629, 50.64477992090688,
          50.63805771838075, 50.63888786207106, 50.645718861700374,
          50.65064415182417, 50.64185438524224, 50.63515892098724,
          50.68456328321545, 50.68079712648152, 50.68773097206278,
          50.682551123539604, 50.679682249540434, 50.65928262505382,
          50.6669539942953, 50.67292417246871,
        ],
      },
    ],
    "Amrumbank West": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
        ],
        lon: [
          7.7351, 7.6535, 7.7284, 7.6785, 7.666, 7.641, 7.7035, 7.7159, 7.7409,
          7.7535, 7.7659, 7.691, 7.6535, 7.666, 7.641, 7.7034, 7.6785, 7.7534,
          7.691, 7.7409, 7.7659, 7.7159, 7.7284, 7.641, 7.6535, 7.6785, 7.666,
          7.691, 7.7034, 7.7409, 7.7534, 7.6785, 7.7034, 7.7159, 7.7409, 7.7534,
          7.7659, 7.6909, 7.7284, 7.641, 7.6535, 7.6785, 7.666, 7.7034, 7.7409,
          7.6909, 7.7159, 7.7284, 7.7534, 7.7783, 7.7659, 7.641, 7.6535, 7.6785,
          7.7034, 7.7159, 7.7409, 7.7658, 7.7284, 7.7534, 7.666, 7.7783, 7.6909,
          7.666, 7.6785, 7.6909, 7.7034, 7.641, 7.7159, 7.6535, 7.7284, 7.7409,
          7.7783, 7.7534, 7.7658, 7.7659, 7.7284, 7.641, 7.666, 7.6535, 7.7159,
        ],
        lat: [
          54.52, 54.5064, 54.5064, 54.5064, 54.5064, 54.5064, 54.5064, 54.5064,
          54.5064, 54.5064, 54.5064, 54.5064, 54.5118, 54.5118, 54.5118,
          54.5118, 54.5118, 54.5118, 54.5118, 54.5118, 54.5118, 54.5118,
          54.5118, 54.5172, 54.5172, 54.5172, 54.5172, 54.5172, 54.5172,
          54.5172, 54.5172, 54.5226, 54.5226, 54.5226, 54.5226, 54.5226,
          54.5226, 54.5226, 54.5226, 54.528, 54.528, 54.528, 54.528, 54.528,
          54.528, 54.528, 54.528, 54.528, 54.528, 54.528, 54.528, 54.5334,
          54.5334, 54.5334, 54.5334, 54.5334, 54.5334, 54.5334, 54.5334,
          54.5334, 54.5334, 54.5334, 54.5334, 54.5388, 54.5388, 54.5388,
          54.5388, 54.5388, 54.5388, 54.5388, 54.5388, 54.5388, 54.5388,
          54.5388, 54.5388, 54.5172, 54.5172, 54.5226, 54.5226, 54.5226,
          54.5172,
        ],
      },
    ],
    "Anholt Havmøllepark": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
          "92",
          "93",
          "94",
          "95",
          "96",
          "97",
          "98",
          "99",
          "100",
          "101",
          "102",
          "103",
          "104",
          "105",
          "106",
          "107",
          "108",
          "109",
          "110",
          "111",
        ],
        lon: [
          11.1838, 11.1868, 11.187, 11.187, 11.2038, 11.1846, 11.2135, 11.1855,
          11.2337, 11.2436, 11.2236, 11.214, 11.186, 11.1865, 11.2141, 11.187,
          11.2535, 11.2136, 11.2685, 11.2834, 11.241, 11.2734, 11.2635, 11.1868,
          11.2116, 11.3033, 11.2383, 11.2934, 11.2352, 11.2129, 11.1864, 11.29,
          11.313, 11.2321, 11.2635, 11.2101, 11.3143, 11.2287, 11.1856, 11.3231,
          11.2819, 11.2585, 11.1848, 11.2535, 11.2744, 11.3053, 11.184, 11.2968,
          11.2251, 11.2672, 11.1828, 11.2055, 11.1815, 11.2727, 11.2885,
          11.2607, 11.2381, 11.2649, 11.2177, 11.2137, 11.2576, 11.2215,
          11.2805, 11.2432, 11.2027, 11.2275, 11.2466, 11.1801, 11.2058,
          11.1961, 11.2373, 11.252, 11.1768, 11.1692, 11.2291, 11.1785, 11.1749,
          11.2251, 11.1647, 11.2096, 11.1974, 11.1622, 11.1872, 11.2417,
          11.1569, 11.2103, 11.1713, 11.1541, 11.2068, 11.1669, 11.1597, 11.148,
          11.1614, 11.2016, 11.2213, 11.1969, 11.194, 11.2139, 11.2001, 11.1736,
          11.1881, 11.1619, 11.1908, 11.1587, 11.2034, 11.1512, 11.1854,
          11.1721, 11.1653, 11.1685, 11.191,
        ],
        lat: [
          56.5079, 56.5358, 56.5469, 56.5525, 56.5173, 56.5135, 56.5218, 56.519,
          56.5313, 56.5359, 56.5266, 56.5299, 56.5246, 56.5302, 56.5377,
          56.5414, 56.5407, 56.5456, 56.5575, 56.5548, 56.5445, 56.5501,
          56.5454, 56.5581, 56.5613, 56.564, 56.5528, 56.5594, 56.5612, 56.5534,
          56.5647, 56.5676, 56.5687, 56.5695, 56.5649, 56.5691, 56.5772,
          56.5778, 56.5713, 56.5734, 56.5736, 56.5723, 56.5779, 56.5796,
          56.5799, 56.5811, 56.5838, 56.5852, 56.5857, 56.5862, 56.5897,
          56.5867, 56.5956, 56.5986, 56.5895, 56.5928, 56.6017, 56.6037, 56.601,
          56.6084, 56.6089, 56.5935, 56.594, 56.5944, 56.5946, 56.6163, 56.6176,
          56.6015, 56.6231, 56.6103, 56.6268, 56.6132, 56.6133, 56.6348,
          56.6366, 56.6074, 56.619, 56.6415, 56.6452, 56.6162, 56.6379, 56.6505,
          56.6556, 56.622, 56.6608, 56.6611, 56.6297, 56.6659, 56.6662, 56.6401,
          56.6556, 56.6761, 56.6763, 56.6305, 56.6464, 56.6813, 56.6863,
          56.6563, 56.6763, 56.6782, 56.6965, 56.6971, 56.6496, 56.7021,
          56.6712, 56.6711, 56.7016, 56.7019, 56.692, 56.6867, 56.6914,
        ],
      },
    ],
    "Arcadis Ost 1": [
      {
        ids: ["osp", "1", "2", "3", "4", "5", "6", "7", "8", "9"],
        lon: [
          13.6771, 13.594, 13.6337, 13.6797, 13.6335, 13.613, 13.6511, 13.6144,
          13.5945, 13.6299,
        ],
        lat: [
          54.8173, 54.8328, 54.8358, 54.8098, 54.8202, 54.8277, 54.8174,
          54.8395, 54.8433, 54.846,
        ],
      },
    ],
    "Arklow Bank Wind Park": [
      {
        ids: ["1", "2", "3", "4", "5", "6", "7"],
        lon: [-5.9467, -5.9427, -5.9517, -5.9441, -5.9439, -5.9479, -5.95],
        lat: [52.791, 52.8078, 52.7744, 52.8021, 52.7965, 52.7855, 52.7801],
      },
    ],
    "Arkonabecken Südost": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
        ],
        lon: [
          14.0979, 14.1656, 14.1464, 14.1562, 14.1568, 14.1589, 14.1593,
          14.1645, 14.1601, 14.1484, 14.1411, 14.1216, 14.1232, 14.1098, 14.133,
          14.0955, 14.0952, 14.0813, 14.1418, 14.1397, 14.113, 14.1274, 14.1328,
          14.1182, 14.1226, 14.1361, 14.1499, 14.1502, 14.1385, 14.1258,
          14.1313, 14.0877, 14.0727, 14.1179, 14.0817, 14.1022, 14.1049,
          14.1082, 14.0717, 14.0852,
        ],
        lat: [
          54.7869, 54.795, 54.786, 54.7802, 54.7712, 54.7659, 54.8069, 54.8016,
          54.7897, 54.7603, 54.7656, 54.7609, 54.7689, 54.7653, 54.7588,
          54.7666, 54.7725, 54.7708, 54.7775, 54.7719, 54.7745, 54.7758,
          54.7828, 54.781, 54.7878, 54.7904, 54.7934, 54.8013, 54.8039, 54.8072,
          54.7979, 54.7876, 54.7908, 54.7953, 54.7769, 54.7788, 54.792, 54.7847,
          54.7828, 54.7953,
        ],
      },
    ],
    "Åsgard A": [
      {
        ids: ["1", "2", "3", "4"],
        lon: [6.7258, 6.7258, 6.7259, 6.7258],
        lat: [65.0641, 65.0641, 65.0641, 65.0641],
      },
    ],
    "Åsgard B": [
      {
        ids: ["1", "2", "3", "4", "5"],
        lon: [6.7897, 6.7897, 6.7898, 6.7897, 6.7897],
        lat: [65.11, 65.1101, 65.1101, 65.11, 65.11],
      },
    ],
    Balder: [
      {
        ids: ["1", "2", "3"],
        lon: [2.3859, 2.386, 2.3859],
        lat: [59.191, 59.191, 59.191],
      },
    ],
    "Baltic Eagle": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
        ],
        lon: [
          13.8994, 13.922, 13.8954, 13.8932, 13.8705, 13.869, 13.8491, 13.8472,
          13.8274, 13.8257, 13.802, 13.802, 13.8241, 13.8224, 13.8019, 13.8019,
          13.8208, 13.8019, 13.8203, 13.8434, 13.8417, 13.8156, 13.8363,
          13.8194, 13.8019, 13.8381, 13.8398, 13.8454, 13.8672, 13.8654,
          13.8638, 13.8604, 13.8584, 13.8628, 13.8792, 13.8832, 13.8913,
          13.8893, 13.8813, 13.8853, 13.8874, 13.8569, 13.8771, 13.9149,
          13.9126, 13.9071, 13.9095, 13.9026, 13.9001, 13.8978, 13.9048,
        ],
        lat: [
          54.821, 54.7891, 54.7945, 54.8024, 54.7993, 54.8066, 54.8036, 54.8112,
          54.8081, 54.8146, 54.8139, 54.8212, 54.8214, 54.828, 54.8286, 54.8359,
          54.8348, 54.8433, 54.8422, 54.8266, 54.8344, 54.8564, 54.8574,
          54.8488, 54.8505, 54.8498, 54.8421, 54.8191, 54.814, 54.8212, 54.8288,
          54.8438, 54.8511, 54.8358, 54.8524, 54.8381, 54.8098, 54.8167, 54.845,
          54.831, 54.8237, 54.8584, 54.8595, 54.8103, 54.8172, 54.833, 54.8263,
          54.8469, 54.8537, 54.8605, 54.8398,
        ],
      },
    ],
    "BARD Offshore 1": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
        ],
        lon: [
          6.0107, 5.9394, 5.9525, 5.9786, 5.9656, 6.0179, 5.9916, 5.9393,
          5.9591, 5.9393, 5.9624, 5.9818, 5.9393, 5.9559, 6.0045, 5.9917,
          5.9786, 6.0045, 5.9949, 6.0181, 5.9754, 5.9884, 5.9721, 6.0181,
          5.9495, 5.9395, 6.0181, 5.9526, 6.0181, 5.9395, 5.9395, 6.0182,
          5.9689, 5.9819, 5.9522, 5.9982, 6.0182, 6.0047, 5.9596, 6.0082,
          5.9851, 6.0047, 5.9395, 6.0181, 5.9563, 5.9658, 5.9786, 5.9396,
          5.9918, 5.9984, 6.0181, 6.0181, 6.0049, 5.9885, 6.0082, 5.995, 5.9529,
          5.9396, 5.9853, 5.9721, 5.9507, 5.9396, 5.9618, 5.9754, 5.9689,
          6.0181, 5.9729, 5.9928, 6.0049, 6.0181, 5.9952, 5.982, 6.0181, 6.0017,
          6.0181, 5.984, 5.9396, 6.0061, 6.0181, 6.0062, 6.0182,
        ],
        lat: [
          54.3516, 54.3028, 54.3035, 54.305, 54.3043, 54.3071, 54.3057, 54.3181,
          54.3207, 54.3105, 54.3125, 54.3137, 54.3258, 54.3289, 54.3063,
          54.3308, 54.3217, 54.3315, 54.3226, 54.3227, 54.3301, 54.339, 54.3382,
          54.3303, 54.3453, 54.3334, 54.3456, 54.3372, 54.338, 54.3411, 54.3495,
          54.3532, 54.3464, 54.3554, 54.3555, 54.3144, 54.315, 54.34, 54.3628,
          54.363, 54.3472, 54.3483, 54.3564, 54.3685, 54.371, 54.3546, 54.3636,
          54.364, 54.3728, 54.3564, 54.3761, 54.3609, 54.3712, 54.381, 54.3821,
          54.3646, 54.3791, 54.3793, 54.3891, 54.3799, 54.3922, 54.3717,
          54.3971, 54.3717, 54.3881, 54.3838, 54.402, 54.4026, 54.3903, 54.3914,
          54.4118, 54.3973, 54.4143, 54.3984, 54.3991, 54.4069, 54.3873,
          54.4063, 54.4067, 54.4167, 54.422,
        ],
      },
    ],
    "Barrow Wind Farm": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
        ],
        lon: [
          -3.2701, -3.3012, -3.285, -3.3068, -3.2906, -3.2744, -3.3123, -3.29,
          -3.2962, -3.2694, -3.3236, -3.318, -3.3018, -3.28, -3.2856, -3.2956,
          -3.275, -3.3186, -3.3074, -3.3023, -3.3292, -3.2968, -3.2806, -3.2862,
          -3.313, -3.303, -3.2918, -3.3135, -3.308, -3.2973, -3.2912,
        ],
        lat: [
          53.9875, 53.9804, 53.981, 53.9833, 53.984, 53.9846, 53.9863, 53.9745,
          53.987, 53.9913, 53.9922, 53.9893, 53.99, 53.9877, 53.9906, 53.9774,
          53.9943, 53.9989, 53.9929, 53.9995, 53.9952, 53.9965, 53.9972,
          54.0002, 53.9959, 54.0091, 54.0031, 54.0054, 54.0025, 54.0061,
          53.9936,
        ],
      },
    ],
    "Beatrice Wind Farm": [
      {
        ids: [
          "osp",
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
        ],
        lon: [
          -2.8957, -2.8805, -2.9357, -2.9169, -2.8284, -2.9373, -2.9628, -2.978,
          -2.9594, -2.9321, -2.9441, -2.9476, -2.966, -2.9991, -2.9102, -2.925,
          -2.9508, -2.9523, -2.9744, -2.9368, -2.9287, -2.9133, -2.8912,
          -2.8723, -2.9815, -2.9405, -2.918, -2.9335, -2.8961, -2.8876, -2.9146,
          -2.8841, -2.9559, -2.8656, -2.9111, -2.9264, -2.9216, -2.8468,
          -2.9065, -2.8771, -2.8925, -2.9028, -2.869, -2.843, -2.8583, -2.9487,
          -2.8892, -2.9046, -2.8499, -2.87, -2.8618, -2.8552, -2.8737, -2.923,
          -2.8055, -2.8208, -2.9011, -2.8851, -2.8667, -2.8819, -2.9298,
          -2.9079, -2.8323, -2.8479, -2.8631, -2.8395, -2.8359, -2.8247,
          -2.8509, -2.8022, -2.8174, -2.7987, -2.8785, -2.8106, -2.7957,
          -2.8142, -2.8289, -2.8446, -2.8222, -2.8561, -2.7801, -2.7766,
          -2.8597, -2.8063, -2.8408, -2.8257,
        ],
        lat: [
          58.2568, 58.2499, 58.1911, 58.1945, 58.2503, 58.1814, 58.2149,
          58.2216, 58.2254, 58.2014, 58.2186, 58.2081, 58.2055, 58.2077,
          58.2154, 58.222, 58.2, 58.246, 58.2321, 58.2392, 58.2117, 58.205,
          58.2189, 58.2226, 58.2113, 58.2289, 58.2429, 58.2495, 58.2093,
          58.2293, 58.2531, 58.2397, 58.2356, 58.243, 58.2635, 58.2702, 58.2325,
          58.2468, 58.2257, 58.2602, 58.2669, 58.2357, 58.2328, 58.2569,
          58.2639, 58.2564, 58.2773, 58.2843, 58.2363, 58.2811, 58.2535,
          58.2742, 58.2706, 58.2807, 58.2642, 58.271, 58.2945, 58.2878, 58.2914,
          58.2983, 58.26, 58.2738, 58.2881, 58.295, 58.3018, 58.2674, 58.2778,
          58.2606, 58.2847, 58.2745, 58.2814, 58.2852, 58.3088, 58.3021,
          58.2953, 58.2916, 58.2984, 58.3054, 58.319, 58.3225, 58.2884, 58.2986,
          58.3121, 58.3125, 58.3157, 58.309,
        ],
      },
    ],
    Beleolico: [
      {
        ids: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
        lon: [
          17.1377, 17.1405, 17.1364, 17.1335, 17.1293, 17.1412, 17.1427,
          17.1444, 17.146,
        ],
        lat: [
          40.4909, 40.4859, 40.4816, 40.4865, 40.4822, 40.4962, 40.4994,
          40.5022, 40.505,
        ],
      },
    ],
    Belwind: [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
        ],
        lon: [
          2.8173, 2.8364, 2.795, 2.775, 2.812, 2.792, 2.809, 2.7899, 2.7785,
          2.7834, 2.7884, 2.7934, 2.7984, 2.8018, 2.7968, 2.7918, 2.7869,
          2.7818, 2.7852, 2.7902, 2.7953, 2.8002, 2.8052, 2.8086, 2.8035,
          2.7987, 2.7936, 2.7886, 2.7971, 2.8021, 2.807, 2.8154, 2.8104, 2.8055,
          2.8005, 2.7954, 2.8088, 2.8138, 2.8188, 2.8038, 2.7988, 2.8022,
          2.8072, 2.8121, 2.8172, 2.8222, 2.8256, 2.8207, 2.8157, 2.8106,
          2.8057, 2.829, 2.8239, 2.8188, 2.8139, 2.7851, 2.7802,
        ],
        lat: [
          51.6946, 51.6883, 51.6354, 51.6458, 51.6638, 51.6742, 51.7027,
          51.6379, 51.6515, 51.6488, 51.6463, 51.6438, 51.6411, 51.6467,
          51.6494, 51.652, 51.6546, 51.6572, 51.6628, 51.6602, 51.6577, 51.655,
          51.6524, 51.6582, 51.6608, 51.6633, 51.6659, 51.6685, 51.6717, 51.669,
          51.6664, 51.6696, 51.6721, 51.6747, 51.6773, 51.6799, 51.6803,
          51.6778, 51.6752, 51.683, 51.6856, 51.6913, 51.6887, 51.6861, 51.6836,
          51.6809, 51.6866, 51.6891, 51.6917, 51.6943, 51.697, 51.6923, 51.6949,
          51.6975, 51.7001, 51.6408, 51.6436,
        ],
      },
    ],
    "Borkum Riffgrund 2": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
        ],
        lon: [
          6.4742, 6.5533, 6.604, 6.5941, 6.617, 6.6171, 6.617, 6.4685, 6.4737,
          6.4525, 6.4413, 6.4226, 6.423, 6.4341, 6.457, 6.4737, 6.4737, 6.4798,
          6.48, 6.5085, 6.5923, 6.5812, 6.5697, 6.5142, 6.5251, 6.5367, 6.5012,
          6.482, 6.4749, 6.4665, 6.4799, 6.4914, 6.4915, 6.5141, 6.5396, 6.5372,
          6.514, 6.5145, 6.5142, 6.4638, 6.4534, 6.445, 6.4392, 6.4312, 6.4241,
          6.4343, 6.4455, 6.4569, 6.4341, 6.4236, 6.5477, 6.5588, 6.4891,
          6.4915, 6.4914, 6.4571, 6.4685,
        ],
        lat: [
          53.9571, 53.9328, 53.9321, 53.9877, 53.9876, 53.9741, 53.9809,
          53.9401, 53.991, 53.9909, 53.991, 53.9873, 53.9807, 53.9806, 53.9803,
          53.9804, 53.9739, 53.9673, 53.961, 53.9531, 53.9305, 53.9289, 53.9273,
          53.9194, 53.9209, 53.9226, 53.9175, 53.9217, 53.9269, 53.9331,
          53.9331, 53.9469, 53.9398, 53.9329, 53.9317, 53.94, 53.9468, 53.9399,
          53.9261, 53.947, 53.9538, 53.9489, 53.9533, 53.9606, 53.9672, 53.9673,
          53.9672, 53.9672, 53.9739, 53.9739, 53.9241, 53.9257, 53.9164,
          53.9262, 53.9331, 53.94, 53.9606,
        ],
      },
    ],
    "Borkum Riffgrund 3": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
        ],
        lon: [
          6.1719, 6.2288, 6.084, 6.287, 6.2443, 6.2157, 6.1823, 6.139, 6.1525,
          6.1734, 6.1402, 6.1357, 6.1717, 6.2506, 6.1642, 6.1519, 6.2639,
          6.1324, 6.2868, 6.2591, 6.1773, 6.0938, 6.2367, 6.2446, 6.2863,
          6.1446, 6.2884, 6.1184, 6.1544, 6.2279, 6.2891, 6.0934, 6.259, 6.1945,
          6.1574, 6.2877, 6.2232, 6.2045, 6.1996, 6.1018, 6.2773, 6.2645,
          6.2061, 6.1695, 6.269, 6.1165, 6.1923, 6.2425, 6.179, 6.2492, 6.2325,
          6.2123, 6.2219, 6.1105, 6.2101, 6.2715, 6.1881, 6.104, 6.287, 6.2564,
          6.2768, 6.2292, 6.2671, 6.2471, 6.1375, 6.107, 6.0886, 6.1269, 6.1001,
          6.12, 6.2374,
        ],
        lat: [
          54.0679, 54.0285, 54.0559, 54.0552, 54.0519, 54.0614, 54.0498,
          54.0682, 54.0692, 54.0285, 54.0405, 54.0593, 54.0197, 54.0199,
          54.0468, 54.0281, 54.0573, 54.0284, 54.0703, 54.0658, 54.0593,
          54.0394, 54.0596, 54.077, 54.0774, 54.0511, 54.0387, 54.0402, 54.0198,
          54.0403, 54.0212, 54.0651, 54.0778, 54.0292, 54.057, 54.047, 54.0714,
          54.0743, 54.0523, 54.0574, 54.0213, 54.0201, 54.0405, 54.0379,
          54.0455, 54.028, 54.0629, 54.0287, 54.0721, 54.0418, 54.0681, 54.0286,
          54.0521, 54.0663, 54.0699, 54.0328, 54.0433, 54.0214, 54.0632,
          54.0289, 54.0674, 54.0761, 54.0725, 54.0707, 54.0198, 54.0481,
          54.0484, 54.0491, 54.0287, 54.059, 54.0198,
        ],
      },
    ],
    "Borkum Riffgrund I": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
        ],
        lon: [
          6.5656, 6.5483, 6.6169, 6.6169, 6.4914, 6.4911, 6.56, 6.4912, 6.5141,
          6.5141, 6.5141, 6.5141, 6.5141, 6.5142, 6.5256, 6.5256, 6.5256,
          6.5256, 6.5027, 6.4913, 6.5256, 6.537, 6.5027, 6.5028, 6.537, 6.5256,
          6.5256, 6.537, 6.537, 6.5026, 6.5026, 6.537, 6.5484, 6.5484, 6.5369,
          6.5485, 6.5484, 6.56, 6.537, 6.537, 6.5599, 6.5484, 6.5598, 6.5484,
          6.5599, 6.5714, 6.5714, 6.5599, 6.5598, 6.5598, 6.5712, 6.5485,
          6.5712, 6.5485, 6.5599, 6.5827, 6.5827, 6.5713, 6.5713, 6.5826,
          6.5942, 6.5827, 6.5827, 6.5828, 6.5941, 6.5941, 6.5828, 6.5941,
          6.5941, 6.5941, 6.6055, 6.6169, 6.5713, 6.5713, 6.6169, 6.6056,
          6.6055, 6.6055, 6.6055,
        ],
        lat: [
          53.9639, 53.9403, 53.9403, 53.9608, 53.9739, 53.9944, 53.9944,
          53.9876, 53.9944, 53.9876, 53.9808, 53.974, 53.9672, 53.9604, 53.9944,
          53.9877, 53.9741, 53.9673, 53.9808, 53.9807, 53.9537, 53.9944, 53.974,
          53.9672, 53.9673, 53.9809, 53.9605, 53.9606, 53.9877, 53.9944,
          53.9876, 53.9538, 53.9674, 53.9741, 53.947, 53.9944, 53.9471, 53.9877,
          53.9809, 53.9741, 53.9606, 53.9606, 53.9538, 53.9538, 53.9741,
          53.9877, 53.9809, 53.9674, 53.9471, 53.9403, 53.9471, 53.9877,
          53.9403, 53.9809, 53.9809, 53.9674, 53.9606, 53.9742, 53.9674,
          53.9403, 53.9743, 53.9539, 53.9471, 53.981, 53.9471, 53.9403, 53.9742,
          53.9675, 53.9607, 53.9539, 53.9403, 53.954, 53.9606, 53.9538, 53.9471,
          53.9676, 53.9607, 53.9539, 53.9471,
        ],
      },
    ],
    "Borssele I": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
        ],
        lon: [
          3.0563, 2.9944, 3.01, 3.0174, 3.0371, 3.04, 3.0336, 3.0596, 3.0229,
          3.0363, 2.9991, 3.0116, 3.0319, 3.018, 3.0361, 3.0572, 3.0529, 2.9883,
          2.9791, 3.0488, 3.0636, 3.0735, 3.07, 3.0879, 3.0605, 3.0931, 3.0805,
          3.0451, 3.0878, 3.0629, 3.0814, 3.0706, 3.1142, 3.1168, 3.0984, 3.077,
          3.1196, 3.0996, 3.0857, 3.1079, 3.1013, 3.1118, 3.1222, 3.1275,
          3.0996, 3.1248, 3.1129,
        ],
        lat: [
          51.7168, 51.7346, 51.742, 51.7282, 51.7218, 51.7759, 51.7867, 51.7296,
          51.7784, 51.7332, 51.7593, 51.7693, 51.7637, 51.7537, 51.7438,
          51.7798, 51.7657, 51.7499, 51.739, 51.7523, 51.7428, 51.7705, 51.7549,
          51.7589, 51.7905, 51.7472, 51.7371, 51.7962, 51.7773, 51.7997,
          51.7236, 51.712, 51.7418, 51.7332, 51.7316, 51.7864, 51.7236, 51.7671,
          51.708, 51.7592, 51.7177, 51.7503, 51.7145, 51.6954, 51.7036, 51.7056,
          51.6997,
        ],
      },
    ],
    "Borssele II": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
        ],
        lon: [
          3.0959, 3.0806, 3.0648, 3.1182, 3.1001, 3.1208, 3.0819, 3.1108, 3.08,
          3.1005, 3.1037, 3.1065, 3.1094, 3.1124, 3.1153, 3.0995, 3.0677,
          3.0715, 3.0751, 3.0871, 3.0852, 3.0906, 3.0676, 3.0755, 3.0655,
          3.0648, 3.0836, 3.092, 3.0954, 3.0476, 3.0502, 3.0546, 3.0629, 3.0588,
          3.0627, 3.0548, 3.0446, 3.0469, 3.0292, 3.0298, 3.0471, 3.0493,
          3.0262, 3.0337, 3.0367, 3.0373, 3.0414,
        ],
        lat: [
          51.6815, 51.6858, 51.6906, 51.6617, 51.6687, 51.6714, 51.6714,
          51.6767, 51.6576, 51.6023, 51.6122, 51.6216, 51.6317, 51.6417,
          51.6516, 51.6578, 51.6197, 51.6316, 51.5839, 51.5926, 51.6131,
          51.6255, 51.5944, 51.6445, 51.6063, 51.6776, 51.6021, 51.6357, 51.647,
          51.6125, 51.6258, 51.6389, 51.575, 51.6514, 51.665, 51.5845, 51.6712,
          51.6839, 51.607, 51.6206, 51.5981, 51.6958, 51.5941, 51.6351, 51.585,
          51.6464, 51.6592,
        ],
      },
    ],
    "Borssele III": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
        ],
        lon: [
          2.8999, 2.9204, 2.9387, 2.9202, 2.9538, 2.9022, 2.9234, 3.0071,
          3.0078, 3.0095, 3.0031, 3.0143, 2.9859, 3.0333, 2.9873, 3.0244,
          3.0289, 2.995, 2.9696, 2.9672, 2.9662, 2.9881, 2.9921, 2.9475, 2.9684,
          3.0079, 2.9804, 2.9441, 2.941, 2.9773, 2.9542, 2.9614, 2.9478, 2.9586,
          2.926, 3.0172, 3.0213,
        ],
        lat: [
          51.6803, 51.683, 51.7114, 51.6971, 51.7231, 51.6668, 51.6682, 51.6024,
          51.6139, 51.6255, 51.6686, 51.6382, 51.7009, 51.7014, 51.6151,
          51.6764, 51.6891, 51.6827, 51.6876, 51.7197, 51.6274, 51.6335,
          51.6546, 51.6395, 51.6452, 51.6944, 51.7163, 51.6856, 51.6978,
          51.6698, 51.6553, 51.6998, 51.67, 51.711, 51.6513, 51.6498, 51.6625,
        ],
      },
    ],
    "Borssele IV": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
        ],
        lon: [
          2.9603, 2.9805, 2.9971, 2.9774, 2.964, 2.9235, 2.8884, 2.9093, 2.9324,
          2.9048, 2.9433, 2.9493, 2.9244, 2.8325, 2.8836, 2.8532, 2.8673,
          2.9033, 2.8869, 2.9414, 2.814, 2.8323, 2.8523, 2.8826, 2.8932, 2.912,
          2.7747, 2.7937, 2.8147, 2.8394, 2.856, 2.8087, 2.8977, 2.9156, 2.7908,
          2.8237, 2.8401, 2.8703, 2.8599, 2.8777,
        ],
        lat: [
          51.779, 51.7829, 51.7865, 51.7722, 51.7617, 51.74, 51.7651, 51.7601,
          51.7607, 51.7677, 51.7762, 51.7498, 51.7721, 51.754, 51.7489, 51.7577,
          51.7527, 51.7441, 51.7344, 51.7311, 51.7498, 51.7261, 51.717, 51.7218,
          51.711, 51.7264, 51.7428, 51.7458, 51.7389, 51.7429, 51.7296, 51.7221,
          51.697, 51.7115, 51.7331, 51.7132, 51.7029, 51.7036, 51.6922, 51.6815,
        ],
      },
    ],
    "Bozcaada Rüzgar Enerji Santrali": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
        ],
        lon: [
          25.9696, 25.9755, 25.9747, 25.974, 25.9732, 25.9725, 25.9718, 25.971,
          25.9703, 25.9693, 25.9686, 25.9678, 25.9671, 25.9663, 25.9655,
          25.9648, 25.9641,
        ],
        lat: [
          39.8325, 39.8297, 39.83, 39.8304, 39.8307, 39.8311, 39.8314, 39.8318,
          39.8321, 39.8339, 39.8343, 39.8346, 39.835, 39.8354, 39.8357, 39.836,
          39.8364,
        ],
      },
    ],
    Brage: [
      {
        ids: ["1", "2", "3"],
        lon: [3.0455, 3.0455, 3.0454],
        lat: [60.542, 60.542, 60.5419],
      },
    ],
    "Burbo Bank Offshore Wind Farm": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
        ],
        lon: [
          -3.3368, -3.3051, -3.3196, -3.3378, -3.353, -3.3726, -3.3634, -3.352,
          -3.3406, -3.3288, -3.3175, -3.3057, -3.29, -3.2865, -3.2694, -3.2522,
          -3.2659, -3.2698, -3.2496, -3.2867, -3.3053, -3.2822, -3.2656,
          -3.2493, -3.2329, -3.2164, -3.2008, -3.1878, -3.2131, -3.2315,
          -3.2368, -3.2344, -3.1849, -3.1911, -3.1974, -3.2035, -3.2097,
          -3.2158, -3.2221, -3.1755, -3.1816, -3.1877, -3.1939, -3.2001,
          -3.2064, -3.2126, -3.1501, -3.1563, -3.1597, -3.1659, -3.172, -3.1783,
          -3.1844, -3.1907, -3.1968, -3.203, -3.2188,
        ],
        lat: [
          53.4929, 53.5017, 53.5016, 53.5016, 53.5015, 53.5014, 53.4963,
          53.4901, 53.4838, 53.4774, 53.4712, 53.4852, 53.4933, 53.5017,
          53.5017, 53.5019, 53.4916, 53.483, 53.4747, 53.4742, 53.4644, 53.4645,
          53.4646, 53.4646, 53.4647, 53.4648, 53.4648, 53.468, 53.4728, 53.483,
          53.4928, 53.5019, 53.4747, 53.478, 53.4814, 53.4848, 53.4881, 53.4915,
          53.495, 53.4782, 53.4816, 53.485, 53.4884, 53.4917, 53.4951, 53.4985,
          53.4819, 53.4853, 53.4784, 53.4818, 53.4851, 53.4886, 53.4919,
          53.4953, 53.4987, 53.5021, 53.5018,
        ],
      },
    ],
    DanTysk: [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
        ],
        lon: [
          7.1998, 7.2072, 7.1917, 7.2443, 7.2258, 7.226, 7.1819, 7.2421, 7.2062,
          7.175, 7.2056, 7.1939, 7.1704, 7.1884, 7.24, 7.1703, 7.2363, 7.188,
          7.2154, 7.2256, 7.1863, 7.2378, 7.1927, 7.2169, 7.1902, 7.169, 7.2169,
          7.1932, 7.1697, 7.2285, 7.1687, 7.2112, 7.2305, 7.1857, 7.1685,
          7.2081, 7.1695, 7.213, 7.1887, 7.232, 7.2265, 7.1871, 7.2101, 7.224,
          7.2221, 7.2058, 7.1978, 7.2027, 7.1804, 7.2004, 7.195, 7.1824, 7.1765,
          7.1746, 7.1843, 7.1786, 7.1896, 7.1937,
        ],
        lat: [
          55.0649, 55.0716, 55.0737, 55.0818, 55.0817, 55.074, 55.083, 55.091,
          55.0823, 55.0759, 55.0921, 55.1023, 55.1033, 55.0928, 55.1009,
          55.1114, 55.1149, 55.0788, 55.1192, 55.0913, 55.124, 55.1075, 55.1094,
          55.1099, 55.1308, 55.1366, 55.1016, 55.1162, 55.1195, 55.1461,
          55.1452, 55.1374, 55.1379, 55.153, 55.1538, 55.1556, 55.128, 55.1278,
          55.1379, 55.1305, 55.1547, 55.1459, 55.1466, 55.1631, 55.1715,
          55.1651, 55.1906, 55.1736, 55.1768, 55.1821, 55.1992, 55.1696,
          55.1914, 55.1988, 55.2251, 55.1841, 55.2171, 55.2085,
        ],
      },
    ],
    "Deutsche Bucht": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
        ],
        lon: [
          5.7864, 5.8168, 5.7554, 5.744, 5.7515, 5.7673, 5.807, 5.7975, 5.7932,
          5.7764, 5.7678, 5.771, 5.7755, 5.7801, 5.7984, 5.805, 5.8079, 5.7651,
          5.7935, 5.7606, 5.778, 5.8025, 5.794, 5.7993, 5.7923, 5.8176, 5.8084,
          5.8093, 5.809, 5.8187, 5.8046, 5.8059,
        ],
        lat: [
          54.3027, 54.3365, 54.3096, 54.304, 54.3004, 54.2933, 54.276, 54.3144,
          54.3076, 54.3115, 54.315, 54.3036, 54.29, 54.3202, 54.3213, 54.3108,
          54.2913, 54.308, 54.2903, 54.2983, 54.2974, 54.2961, 54.3006, 54.2796,
          54.2836, 54.3133, 54.3183, 54.3256, 54.2841, 54.2899, 54.331, 54.3031,
        ],
      },
    ],
    "Dogger Bank A Wind Farm": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
          "92",
          "93",
          "94",
          "95",
        ],
        lon: [
          1.86, 1.7992, 1.8196, 1.8406, 2.0406, 2.0252, 2.0097, 1.9944, 1.9784,
          1.9573, 1.937, 1.9167, 1.8755, 1.8963, 1.855, 1.8135, 1.8342, 1.7728,
          1.793, 1.9248, 1.9599, 2.0011, 1.9802, 1.8902, 1.9105, 1.9315, 1.9458,
          2.0702, 1.9664, 1.9938, 2.0497, 1.8967, 2.0147, 2.0288, 2.0432,
          2.0871, 2.1025, 2.1181, 2.0632, 2.0843, 2.1336, 2.1489, 2.1052,
          2.1955, 2.1724, 2.1499, 2.211, 2.2086, 2.1902, 2.0386, 2.126, 2.0162,
          2.1641, 2.1797, 2.0606, 1.9274, 1.9051, 1.8607, 1.994, 1.8384, 1.9718,
          1.9496, 1.8826, 1.6603, 1.6382, 1.8159, 1.6378, 1.6376, 2.1276,
          2.1057, 1.7704, 1.7146, 1.7939, 1.7716, 1.8056, 1.6732, 1.7849,
          1.8752, 1.8338, 1.7294, 1.7087, 1.8545, 1.6377, 1.7643, 1.75, 2.0832,
          1.6943, 1.7438, 1.6374, 1.6905, 1.6699, 1.6499, 1.6374, 1.7519,
          1.7314, 1.7109,
        ],
        lat: [
          54.7833, 54.7387, 54.7538, 54.7683, 54.7003, 54.691, 54.6818, 54.673,
          54.6626, 54.6666, 54.6714, 54.6764, 54.6864, 54.6813, 54.6912, 54.701,
          54.6961, 54.7109, 54.7059, 54.704, 54.6877, 54.7171, 54.7024, 54.7206,
          54.7353, 54.75, 54.7191, 54.8069, 54.7336, 54.7937, 54.7928, 54.7666,
          54.8092, 54.7777, 54.7466, 54.7277, 54.7373, 54.7464, 54.7607,
          54.7759, 54.7554, 54.7647, 54.7905, 54.7925, 54.8296, 54.8293,
          54.8017, 54.8144, 54.8218, 54.8291, 54.8052, 54.8288, 54.7738,
          54.7827, 54.8292, 54.8284, 54.8285, 54.8283, 54.8286, 54.828, 54.8286,
          54.8285, 54.8282, 54.8264, 54.8263, 54.828, 54.8021, 54.7763, 54.8287,
          54.8291, 54.8011, 54.8028, 54.8278, 54.8275, 54.7844, 54.7732,
          54.7696, 54.7519, 54.7224, 54.7717, 54.7569, 54.7372, 54.7893,
          54.7549, 54.7864, 54.8291, 54.7881, 54.7405, 54.7636, 54.7305,
          54.7354, 54.7401, 54.7507, 54.7158, 54.7207, 54.7256,
        ],
      },
    ],
    Draugen: [
      {
        ids: ["1", "2", "3", "4", "5"],
        lon: [7.7805, 7.7805, 7.7805, 7.7805, 7.7805],
        lat: [64.3527, 64.3527, 64.3527, 64.3527, 64.3527],
      },
    ],
    "Dudgeon Offshore Wind Farm": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
        ],
        lon: [
          1.4248, 1.3977, 1.4025, 1.4171, 1.4073, 1.4263, 1.4133, 1.3776,
          1.3905, 1.3796, 1.3687, 1.3589, 1.3681, 1.3572, 1.3484, 1.3388, 1.346,
          1.329, 1.335, 1.3187, 1.3197, 1.3225, 1.3206, 1.3202, 1.3479, 1.37,
          1.3362, 1.3922, 1.426, 1.4294, 1.3594, 1.3819, 1.404, 1.4152, 1.3944,
          1.3832, 1.3723, 1.3611, 1.3494, 1.3384, 1.3223, 1.3335, 1.34, 1.3227,
          1.3625, 1.374, 1.351, 1.3963, 1.3857, 1.4299, 1.4078, 1.419, 1.4262,
          1.4171, 1.4437, 1.4432, 1.4445, 1.4351, 1.4076, 1.3985, 1.3894,
          1.3796, 1.3613, 1.3707, 1.3522, 1.3431,
        ],
        lat: [
          53.2258, 53.2263, 53.2364, 53.217, 53.2217, 53.2126, 53.2312, 53.2348,
          53.2415, 53.2465, 53.2394, 53.2437, 53.2519, 53.257, 53.2487, 53.2529,
          53.262, 53.2574, 53.2677, 53.2623, 53.2687, 53.2906, 53.2834, 53.2761,
          53.2738, 53.264, 53.2794, 53.2533, 53.2378, 53.2495, 53.269, 53.2588,
          53.2485, 53.2435, 53.2648, 53.2701, 53.2751, 53.2805, 53.2854,
          53.2911, 53.2977, 53.3088, 53.3027, 53.3051, 53.2924, 53.2868,
          53.2974, 53.2767, 53.2816, 53.2611, 53.2715, 53.2665, 53.2755,
          53.2797, 53.2595, 53.2523, 53.2668, 53.2712, 53.2838, 53.288, 53.2923,
          53.2964, 53.3054, 53.3008, 53.3095, 53.314,
        ],
      },
    ],
    "East Anglia ONE": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
          "92",
          "93",
          "94",
          "95",
          "96",
          "97",
          "98",
          "99",
          "100",
          "101",
          "102",
        ],
        lon: [
          2.5061, 2.5108, 2.4493, 2.4586, 2.5335, 2.53, 2.5277, 2.5228, 2.5196,
          2.5164, 2.5125, 2.5094, 2.4956, 2.4861, 2.5304, 2.535, 2.5381, 2.5416,
          2.5488, 2.5287, 2.5256, 2.522, 2.515, 2.5084, 2.5046, 2.5013, 2.4979,
          2.4755, 2.4794, 2.4862, 2.4897, 2.5442, 2.5407, 2.5367, 2.5581,
          2.5615, 2.5683, 2.5625, 2.5649, 2.5572, 2.559, 2.5317, 2.5522, 2.5461,
          2.5496, 2.5357, 2.5454, 2.5426, 2.5391, 2.5531, 2.5026, 2.4923,
          2.5564, 2.4992, 2.4784, 2.4887, 2.4815, 2.462, 2.4285, 2.4337, 2.4652,
          2.4747, 2.455, 2.4684, 2.4725, 2.44, 2.4363, 2.4528, 2.4428, 2.4577,
          2.447, 2.4616, 2.4459, 2.4642, 2.4666, 2.4565, 2.4595, 2.4529, 2.471,
          2.4768, 2.4734, 2.547, 2.5241, 2.48, 2.4868, 2.4898, 2.4964, 2.4999,
          2.5279, 2.4831, 2.4933, 2.5494, 2.5207, 2.5172, 2.5138, 2.4507,
          2.4933, 2.4842, 2.5032, 2.5068, 2.5116, 2.5278, 2.5184,
        ],
        lat: [
          52.2485, 52.1814, 52.2354, 52.2775, 52.1981, 52.2038, 52.2105,
          52.2166, 52.2229, 52.229, 52.2355, 52.2421, 52.2677, 52.2869, 52.3187,
          52.3127, 52.306, 52.2997, 52.2871, 52.2649, 52.2713, 52.2777, 52.2904,
          52.3032, 52.3096, 52.3161, 52.3226, 52.2456, 52.2391, 52.2261,
          52.2198, 52.1783, 52.1844, 52.1908, 52.3291, 52.3228, 52.3091,
          52.2614, 52.3165, 52.2741, 52.2671, 52.2582, 52.2807, 52.2331,
          52.2265, 52.252, 52.2931, 52.239, 52.2455, 52.2201, 52.2548, 52.2741,
          52.2136, 52.2612, 52.2996, 52.2805, 52.2932, 52.2711, 52.2132,
          52.2071, 52.2647, 52.3061, 52.2835, 52.2582, 52.2516, 52.1935,
          52.2001, 52.1679, 52.1874, 52.162, 52.1811, 52.155, 52.2416, 52.2099,
          52.2029, 52.223, 52.2162, 52.2289, 52.1964, 52.1837, 52.1906, 52.1717,
          52.1558, 52.1776, 52.1649, 52.1592, 52.2073, 52.2007, 52.15, 52.2322,
          52.2142, 52.1652, 52.1622, 52.1687, 52.1751, 52.1747, 52.1522,
          52.1722, 52.1941, 52.1879, 52.2968, 52.3254, 52.2842,
        ],
      },
    ],
    "Ekofisk J": [
      {
        ids: ["1", "2", "3", "4", "5", "6"],
        lon: [3.2201, 3.2201, 3.2201, 3.2201, 3.2201, 3.2201],
        lat: [56.5465, 56.5465, 56.5465, 56.5465, 56.5465, 56.5465],
      },
    ],
    "Ekofisk K": [
      {
        ids: ["1", "2", "3", "4", "5"],
        lon: [3.2045, 3.2046, 3.2046, 3.2045, 3.2045],
        lat: [56.5652, 56.5652, 56.5652, 56.5652, 56.5652],
      },
    ],
    "Eldfisk E": [
      {
        ids: ["1", "2", "3", "4", "5", "6", "7"],
        lon: [3.2635, 3.2635, 3.2635, 3.2636, 3.2636, 3.2636, 3.2635],
        lat: [56.3745, 56.3745, 56.3745, 56.3745, 56.3745, 56.3745, 56.3746],
      },
    ],
    "EnBW Baltic 1": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
        ],
        lon: [
          12.63, 12.6424, 12.6547, 12.63, 12.63, 12.63, 12.6424, 12.63, 12.6424,
          12.63, 12.6919, 12.6671, 12.63, 12.6795, 12.6671, 12.6424, 12.6795,
          12.6551, 12.6424, 12.6547, 12.6671, 12.6547,
        ],
        lat: [
          54.6128, 54.6166, 54.6203, 54.5894, 54.6109, 54.584, 54.595, 54.5947,
          54.6004, 54.6001, 54.6307, 54.6182, 54.6055, 54.6217, 54.6236,
          54.6058, 54.6271, 54.6045, 54.6112, 54.6149, 54.6128, 54.6096,
        ],
      },
    ],
    "EnBW Baltic 2": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
        ],
        lon: [
          13.1544, 13.1572, 13.1459, 13.1869, 13.1758, 13.1783, 13.1671,
          13.1899, 13.1645, 13.2093, 13.1984, 13.212, 13.2018, 13.2223, 13.2197,
          13.2047, 13.2145, 13.172, 13.1837, 13.1954, 13.1928, 13.1769, 13.1745,
          13.181, 13.1483, 13.1382, 13.1694, 13.1594, 13.1862, 13.1622, 13.153,
          13.1651, 13.1678, 13.1555, 13.1441, 13.1268, 13.1161, 13.1582,
          13.1345, 13.147, 13.1373, 13.1297, 13.1045, 13.1239, 13.1215, 13.1132,
          13.1411, 13.1322, 13.1506,
        ],
        lat: [
          54.9996, 54.9939, 54.9937, 55.0015, 55.0016, 54.9959, 54.9962,
          54.9954, 55.0018, 55.0024, 55.0011, 54.9968, 54.9944, 54.9983,
          55.0033, 54.988, 54.991, 54.9854, 54.9848, 54.9831, 54.9894, 54.9746,
          54.9801, 54.9903, 54.9884, 54.9858, 54.9908, 54.9883, 54.9791,
          54.9826, 54.978, 54.9768, 54.9711, 54.9728, 54.9731, 54.962, 54.9602,
          54.9676, 54.9693, 54.9667, 54.964, 54.9801, 54.9582, 54.9675, 54.9731,
          54.9661, 54.9793, 54.9745, 54.9832,
        ],
      },
    ],
    "EnBW Hohe See": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
        ],
        lon: [
          6.328, 6.3159, 6.3138, 6.2974, 6.2881, 6.2787, 6.2862, 6.2936, 6.2868,
          6.2749, 6.2693, 6.2599, 6.2658, 6.2718, 6.278, 6.343, 6.3283, 6.3174,
          6.3004, 6.2908, 6.2839, 6.2899, 6.2959, 6.3019, 6.3005, 6.3181,
          6.3292, 6.3381, 6.3488, 6.3604, 6.3498, 6.343, 6.3324, 6.325, 6.3187,
          6.3182, 6.34, 6.3477, 6.3554, 6.366, 6.3678, 6.3722, 6.3649, 6.3506,
          6.329, 6.3244, 6.3304, 6.3428, 6.355, 6.3546, 6.3561, 6.3606, 6.3365,
          6.36, 6.3425, 6.3486, 6.3596, 6.3652, 6.3707, 6.3692, 6.3826, 6.3784,
          6.3846, 6.3806, 6.3786, 6.3766, 6.3683, 6.3746, 6.3726, 6.3707,
          6.3314, 6.3372,
        ],
        lat: [
          54.4444, 54.4731, 54.4857, 54.4817, 54.4762, 54.4708, 54.4679, 54.461,
          54.4549, 54.462, 54.4653, 54.4599, 54.456, 54.4522, 54.4483, 54.4678,
          54.4655, 54.4567, 54.4573, 54.4505, 54.4445, 54.4406, 54.4367,
          54.4328, 54.4431, 54.4469, 54.4519, 54.4576, 54.4643, 54.457, 54.4505,
          54.4445, 54.4394, 54.4342, 54.4284, 54.4224, 54.432, 54.4374, 54.444,
          54.4532, 54.4425, 54.4493, 54.4362, 54.4295, 54.4248, 54.4184,
          54.4145, 54.4177, 54.4096, 54.3987, 54.424, 54.3948, 54.4105, 54.4178,
          54.4066, 54.4027, 54.4053, 54.4133, 54.4325, 54.4257, 54.4352,
          54.4454, 54.4415, 54.4289, 54.4226, 54.4162, 54.3896, 54.4099,
          54.4036, 54.3973, 54.475, 54.4714,
        ],
      },
    ],
    "European Offshore Wind Deployment Centre": [
      {
        ids: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"],
        lon: [
          -2.0127, -1.9742, -2.0106, -2.0112, -1.9989, -2.0002, -1.988, -2.0023,
          -1.9896, -1.9759, -1.986,
        ],
        lat: [
          57.2236, 57.2301, 57.208, 57.2159, 57.2118, 57.22, 57.2244, 57.2283,
          57.2335, 57.24, 57.216,
        ],
      },
    ],
    "Forsmarks Kärnkraftverk": [
      {
        ids: ["1", "2", "5", "6", "7", "8"],
        lon: [18.1728, 18.1725, 18.1737, 18.1755, 18.1744, 18.1747],
        lat: [60.4034, 60.4036, 60.4039, 60.4027, 60.4024, 60.4022],
      },
    ],
    "Galloper Wind Farm": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
        ],
        lon: [
          2.0203, 1.9696, 1.9878, 2.0061, 2.0232, 2.0411, 2.0254, 2.024, 2.0269,
          2.0134, 2.0306, 2.051, 2.0296, 2.0338, 2.0142, 2.0155, 2.0328, 2.0316,
          2.052, 2.0204, 2.0107, 2.0066, 2.0082, 2.0048, 2.0709, 2.0866, 2.0221,
          2.024, 2.0256, 2.069, 2.0741, 2.0724, 2.057, 2.0551, 2.0881, 2.0898,
          2.0932, 2.0603, 2.0793, 2.0759, 2.098, 2.082, 2.0654, 2.0808, 2.0634,
          2.0966, 2.0671, 2.095, 1.9873, 1.9889, 2.0358, 2.0166, 1.9718, 2.0201,
          2.0029, 2.0045, 2.0186,
        ],
        lat: [
          51.9517, 51.7329, 51.739, 51.7444, 51.7435, 51.7498, 51.7577, 51.7504,
          51.7645, 51.7942, 51.7932, 51.8136, 51.7861, 51.8145, 51.8015,
          51.8083, 51.8076, 51.8004, 51.8207, 51.9064, 51.8975, 51.917, 51.9266,
          51.9074, 51.9225, 51.9215, 51.916, 51.9256, 51.9353, 51.9128, 51.9418,
          51.9321, 51.9332, 51.9236, 51.9311, 51.9407, 51.96, 51.9525, 51.9707,
          51.9514, 51.9872, 51.9902, 51.9814, 51.9803, 51.9718, 51.9792,
          51.9911, 51.9697, 51.9867, 51.9963, 51.9932, 51.9749, 51.9877,
          51.9946, 51.9857, 51.9953, 51.9847,
        ],
      },
    ],
    "Gemini I": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
        ],
        lon: [
          6.0416, 5.9968, 5.9934, 6.0312, 6.0344, 6.0407, 6.0376, 5.9899,
          6.0274, 6.0281, 6.0249, 6.024, 6.0186, 6.0217, 6.0122, 6.009, 6.0059,
          6.0027, 6.0154, 6.0505, 6.047, 6.0435, 6.0206, 6.054, 6.04, 6.0365,
          6.033, 6.0295, 6.026, 6.0225, 6.019, 6.0155, 6.0641, 6.0674, 6.0609,
          6.0172, 6.0576, 6.0544, 6.0511, 6.0479, 6.0414, 6.0446, 6.0381,
          6.0348, 6.0316, 6.0138, 6.0283, 6.0806, 6.0735, 6.077, 6.0699, 6.0627,
          6.0663, 6.0555, 6.0104, 6.0519, 6.0483, 6.0591, 6.0872, 6.0411,
          6.0906, 6.0939, 6.0447, 6.0772, 6.0806, 6.0839, 6.0739, 6.0639,
          6.0606, 6.0706, 6.0672, 6.007, 6.0572, 6.0539, 6.0002, 6.0036,
        ],
        lat: [
          54.037, 54.0579, 54.0637, 54.0212, 54.0157, 54.0047, 54.0102, 54.0696,
          54.0048, 54.0267, 54.0322, 54.0107, 54.0431, 54.0377, 54.0541,
          54.0596, 54.0651, 54.0706, 54.0486, 54.0107, 54.0168, 54.0229,
          54.0166, 54.0046, 54.029, 54.035, 54.0411, 54.0472, 54.0533, 54.0594,
          54.0654, 54.0715, 54.0102, 54.0046, 54.0159, 54.0225, 54.0215,
          54.0272, 54.0329, 54.0385, 54.0498, 54.0442, 54.0555, 54.0612,
          54.0668, 54.0284, 54.0725, 54.0045, 54.017, 54.0108, 54.0233, 54.0358,
          54.0295, 54.0484, 54.0343, 54.0546, 54.0609, 54.0421, 54.0161,
          54.0734, 54.0103, 54.0044, 54.0671, 54.0335, 54.0277, 54.0219,
          54.0394, 54.0569, 54.0627, 54.0452, 54.0511, 54.0402, 54.0685,
          54.0743, 54.052, 54.0461,
        ],
      },
    ],
    "Gemini II": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
        ],
        lon: [
          5.8885, 5.878, 5.8609, 5.8539, 5.8481, 5.8718, 5.8424, 5.8366, 5.8308,
          5.9031, 5.8985, 5.8939, 5.8892, 5.8846, 5.88, 5.8701, 5.8656, 5.8653,
          5.8605, 5.8557, 5.8508, 5.846, 5.8831, 5.8787, 5.8744, 5.87, 5.8156,
          5.8656, 5.8593, 5.8849, 5.8679, 5.8736, 5.8792, 5.8218, 5.8906,
          5.8613, 5.9115, 5.9074, 5.9156, 5.8951, 5.8962, 5.9033, 5.8992,
          5.8923, 5.8884, 5.8531, 5.8845, 5.8805, 5.8766, 5.9281, 5.9242,
          5.9203, 5.9163, 5.9124, 5.9072, 5.9097, 5.9034, 5.8468, 5.8918,
          5.9315, 5.9346, 5.8995, 5.8957, 5.9407, 5.9376, 5.9285, 5.9254,
          5.8406, 5.9223, 5.9162, 5.9101, 5.9132, 5.9193, 5.9071, 5.8281,
          5.8343,
        ],
        lat: [
          54.0326, 54.0054, 54.0313, 54.0364, 54.0416, 54.0105, 54.0467,
          54.0519, 54.0571, 54.0053, 54.0102, 54.0151, 54.0199, 54.0248,
          54.0296, 54.0343, 54.0155, 54.0391, 54.0439, 54.0487, 54.0535,
          54.0583, 54.0349, 54.0399, 54.0448, 54.0497, 54.0558, 54.0547,
          54.0205, 54.0106, 54.0262, 54.021, 54.0158, 54.0508, 54.0054, 54.0596,
          54.0103, 54.0153, 54.0053, 54.0303, 54.0356, 54.0203, 54.0253,
          54.0407, 54.0457, 54.0256, 54.0508, 54.0558, 54.0609, 54.0052,
          54.0109, 54.0166, 54.0222, 54.0279, 54.0394, 54.0336, 54.0451,
          54.0306, 54.0621, 54.021, 54.0158, 54.0507, 54.0564, 54.0052, 54.0105,
          54.0263, 54.0316, 54.0357, 54.0369, 54.0475, 54.0581, 54.0528,
          54.0422, 54.0634, 54.0457, 54.0407,
        ],
      },
    ],
    "Global Tech I": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
        ],
        lon: [
          6.3686, 6.3901, 6.3199, 6.4158, 6.3182, 6.3768, 6.3183, 6.4017,
          6.3879, 6.374, 6.3599, 6.3459, 6.3319, 6.3178, 6.3334, 6.3486, 6.3642,
          6.3793, 6.3947, 6.4104, 6.4099, 6.3946, 6.379, 6.364, 6.3488, 6.3334,
          6.3182, 6.3182, 6.3329, 6.3475, 6.3622, 6.3769, 6.3919, 6.4066,
          6.4053, 6.3906, 6.3761, 6.3616, 6.3471, 6.3328, 6.318, 6.3182, 6.3323,
          6.3466, 6.3608, 6.3752, 6.3892, 6.4036, 6.3332, 6.3464, 6.3598, 6.373,
          6.3861, 6.4003, 6.3295, 6.3597, 6.3431, 6.3319, 6.4016, 6.3459,
          6.3738, 6.3875, 6.3702, 6.3566, 6.3981, 6.3945, 6.3389, 6.3486,
          6.3837, 6.3528, 6.3669, 6.381, 6.3794, 6.3921, 6.3872, 6.3577, 6.3723,
          6.3669, 6.3631, 6.3771, 6.3915,
        ],
        lat: [
          54.5133, 54.4616, 54.4981, 54.5408, 54.5409, 54.4616, 54.5041,
          54.5407, 54.5409, 54.5407, 54.5408, 54.5409, 54.5408, 54.5347,
          54.5347, 54.5349, 54.5347, 54.5347, 54.5346, 54.5349, 54.5285,
          54.5286, 54.5287, 54.5287, 54.5286, 54.5287, 54.5287, 54.5226,
          54.5225, 54.5226, 54.5224, 54.5225, 54.5225, 54.5224, 54.5163,
          54.5164, 54.5163, 54.5164, 54.5164, 54.5163, 54.5163, 54.5103,
          54.5104, 54.5104, 54.5103, 54.5102, 54.5103, 54.5103, 54.4981,
          54.4981, 54.498, 54.498, 54.498, 54.4981, 54.492, 54.504, 54.4919,
          54.5041, 54.504, 54.5042, 54.504, 54.504, 54.4919, 54.4919, 54.492,
          54.4859, 54.4858, 54.4797, 54.4919, 54.4858, 54.4858, 54.4859,
          54.4677, 54.4676, 54.4737, 54.4736, 54.4737, 54.4677, 54.4797,
          54.4797, 54.4797,
        ],
      },
    ],
    "Gode Wind 3": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
        ],
        lon: [
          7.1089, 7.0955, 7.129, 7.1087, 7.1019, 7.1206, 7.1049, 7.1339, 7.0747,
          7.1194, 7.1113, 7.1051, 7.1171, 7.1087, 7.1267, 7.0985,
        ],
        lat: [
          54.0444, 54.0814, 54.0279, 54.0933, 54.0599, 54.0543, 54.0068,
          54.0114, 54.0173, 54.0091, 54.0852, 54.0489, 54.0662, 54.038, 54.0354,
          54.0707,
        ],
      },
    ],
    "Gode Wind I": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
        ],
        lon: [
          7.0088, 6.9528, 6.942, 7.0672, 7.0095, 6.9577, 6.9428, 6.9438, 6.9506,
          6.9442, 6.9675, 6.9546, 6.9661, 6.9729, 6.9568, 6.9426, 6.984, 6.9504,
          6.991, 6.9798, 6.9564, 6.9437, 6.9516, 6.9428, 6.9598, 6.9584, 6.9678,
          6.9434, 7.0289, 7.0318, 6.9782, 7.0155, 6.9968, 6.9687, 6.9742,
          7.0058, 6.956, 6.9812, 6.9917, 7.0139, 7.0218, 7.0337, 6.9882, 7.0045,
          6.964, 6.942, 7.047, 6.9831, 7.0559, 7.0453, 7.0337, 7.0153, 6.9973,
          6.9773, 6.9641, 6.9649,
        ],
        lat: [
          54.0245, 53.9819, 53.9938, 53.9983, 54.031, 54.0598, 54.0649, 54.0515,
          54.0471, 54.0575, 54.0548, 54.0533, 54.0483, 54.043, 54.0427, 54.0376,
          54.0461, 54.0327, 54.0426, 54.0385, 54.0214, 54.0206, 54.011, 54.0283,
          54.0159, 54.0275, 54.0208, 54.0139, 54.0197, 54.0108, 54.0138, 54.008,
          54.0021, 54.0364, 54.0319, 54.0115, 53.9952, 53.9957, 54.0218,
          54.0206, 54.0012, 54.0042, 53.9924, 53.9977, 54.0053, 54.0016, 54.009,
          54.0039, 54.0009, 53.9953, 53.9936, 53.991, 53.9884, 53.9854, 53.9835,
          53.9906,
        ],
      },
    ],
    "Gode Wind II": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
        ],
        lon: [
          7.0383, 6.9416, 6.9839, 7.0054, 6.9946, 6.9841, 6.9739, 6.9657,
          6.9747, 6.9845, 6.9948, 7.0052, 7.0157, 7.0262, 7.0476, 7.0367,
          7.0262, 7.0162, 7.0061, 6.9962, 6.9857, 6.9973, 7.0178, 7.0277,
          7.0382, 7.0486, 7.0593, 7.0699, 7.0805, 7.0699, 7.0595, 7.0489,
          7.0385, 7.0284, 7.0184, 7.0082, 7.0072, 6.9628, 6.9525, 6.9417,
          6.9591, 6.9628, 6.9732,
        ],
        lat: [
          54.0553, 54.0922, 54.0923, 54.0923, 54.0861, 54.0799, 54.0739,
          54.0671, 54.0622, 54.0679, 54.0737, 54.0797, 54.086, 54.0922, 54.0923,
          54.0862, 54.0798, 54.0741, 54.0681, 54.0621, 54.0561, 54.0502,
          54.0624, 54.0683, 54.0743, 54.0806, 54.0867, 54.0931, 54.0868,
          54.0807, 54.0745, 54.0681, 54.0622, 54.0563, 54.0504, 54.0443,
          54.0564, 54.0921, 54.0863, 54.0797, 54.0727, 54.0798, 54.0858,
        ],
      },
    ],
    Grane: [
      {
        ids: ["1", "2", "3"],
        lon: [2.4858, 2.4859, 2.4858],
        lat: [59.1646, 59.1646, 59.1646],
      },
    ],
    "Greater Gabbard Wind Farm": [
      {
        ids: [
          "osp",
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
          "92",
          "93",
          "94",
          "95",
          "96",
          "97",
          "98",
          "99",
          "100",
          "101",
          "102",
          "103",
          "104",
          "105",
          "106",
          "107",
          "108",
          "109",
          "110",
          "111",
          "112",
          "113",
          "114",
          "115",
          "116",
          "117",
          "118",
          "119",
          "120",
          "121",
          "122",
          "123",
          "124",
          "125",
          "126",
          "127",
          "128",
          "129",
          "130",
          "131",
          "132",
          "133",
          "134",
          "135",
          "136",
          "137",
          "138",
          "139",
          "140",
        ],
        lon: [
          1.9433, 1.9578, 1.9094, 1.9412, 1.867, 1.9556, 1.9224, 1.9372, 1.8972,
          1.9128, 1.9699, 1.8982, 1.8588, 1.9513, 1.8722, 1.9268, 1.8502,
          1.8947, 1.9028, 1.9985, 1.8866, 1.9842, 1.9174, 1.9819, 1.9563,
          1.9324, 1.8574, 1.9267, 1.9119, 1.966, 1.8629, 1.9417, 1.8774, 1.8551,
          1.9709, 1.9963, 1.9618, 1.9377, 1.908, 1.868, 1.9471, 1.9525, 1.9287,
          1.9672, 1.9765, 1.8638, 1.9138, 1.873, 1.9192, 1.8783, 1.923, 1.8825,
          1.8929, 1.9727, 1.9579, 1.934, 1.9874, 1.9246, 1.9876, 1.8876, 1.898,
          1.978, 1.9634, 1.9392, 1.9928, 1.9296, 1.9689, 1.9447, 1.8886, 1.982,
          1.8688, 1.9595, 1.9984, 1.903, 1.9837, 1.9734, 1.9501, 1.9486, 1.8739,
          1.9639, 1.9408, 1.8789, 1.9081, 1.8841, 1.8748, 1.9133, 1.9542, 1.998,
          1.9473, 1.9354, 1.9849, 1.9185, 1.8893, 1.9699, 1.9841, 1.9543,
          1.9907, 1.9083, 1.8934, 1.9578, 1.9985, 1.9237, 1.8987, 1.9758,
          1.9336, 1.9952, 1.9738, 1.9592, 1.9538, 1.9855, 1.9395, 1.9691,
          1.9824, 1.9443, 1.922, 1.9647, 1.9983, 1.9776, 1.9441, 1.9601, 1.9932,
          1.9487, 1.9821, 1.9269, 1.9705, 1.9984, 1.9402, 1.9875, 1.9532,
          1.9758, 1.9854, 1.9495, 1.9449, 1.9317, 1.9986, 1.9626, 1.9979,
          1.9482, 1.9864, 1.9986, 1.9942, 1.9673,
        ],
        lat: [
          51.9141, 51.7864, 51.8722, 51.8723, 51.8763, 51.8762, 51.8781, 51.88,
          51.8805, 51.8642, 51.8801, 51.8603, 51.8617, 51.8846, 51.8851,
          51.8685, 51.8696, 51.8718, 51.8894, 51.888, 51.8635, 51.8842, 51.8925,
          51.8925, 51.8935, 51.8959, 51.8816, 51.8871, 51.8836, 51.8881,
          51.8904, 51.8904, 51.894, 51.8963, 51.8971, 51.8964, 51.9025, 51.9047,
          51.8981, 51.8993, 51.8993, 51.9082, 51.9104, 51.9115, 51.9061,
          51.9134, 51.907, 51.9081, 51.9159, 51.9169, 51.9014, 51.9027, 51.9204,
          51.9203, 51.9171, 51.9194, 51.9237, 51.9247, 51.902, 51.9116, 51.9293,
          51.9291, 51.9259, 51.9282, 51.9324, 51.9336, 51.9346, 51.9369,
          51.9347, 51.9148, 51.9222, 51.9403, 51.9412, 51.9381, 51.9379,
          51.9453, 51.9457, 51.9227, 51.9312, 51.9509, 51.951, 51.94, 51.947,
          51.9489, 51.9543, 51.9558, 51.9315, 51.9617, 51.9619, 51.9423,
          51.9642, 51.9646, 51.9579, 51.9612, 51.9526, 51.9775, 51.9774,
          51.9676, 51.9435, 51.9697, 51.9516, 51.9734, 51.9524, 51.9775,
          51.7467, 51.7551, 51.7574, 51.7591, 51.751, 51.7594, 51.7547, 51.7483,
          51.7512, 51.7354, 51.7671, 51.7669, 51.7635, 51.7647, 51.7627,
          51.7415, 51.7699, 51.7705, 51.7726, 51.7749, 51.7748, 51.7778,
          51.7753, 51.7804, 51.7784, 51.7826, 51.7894, 51.7914, 51.7834,
          51.7827, 51.7862, 51.7945, 51.7939, 51.7434, 51.7972, 51.8019,
          51.8086, 51.8024,
        ],
      },
    ],
    "Gullfaks A": [
      {
        ids: ["1", "2", "3", "4", "5", "6"],
        lon: [2.187, 2.1871, 2.187, 2.187, 2.187, 2.187],
        lat: [61.1754, 61.1754, 61.1754, 61.1754, 61.1754, 61.1754],
      },
    ],
    "Gullfaks C": [
      {
        ids: ["1", "2", "3", "4", "5"],
        lon: [2.2722, 2.2722, 2.2722, 2.2722, 2.2723],
        lat: [61.2144, 61.2144, 61.2144, 61.2144, 61.2144],
      },
    ],
    "Gwynt y Môr Offshore Wind Farm": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
          "92",
          "93",
          "94",
          "95",
          "96",
          "97",
          "98",
          "99",
          "100",
          "101",
          "102",
          "103",
          "104",
          "105",
          "106",
          "107",
          "108",
          "109",
          "110",
          "111",
          "112",
          "113",
          "114",
          "115",
          "116",
          "117",
          "118",
          "119",
          "120",
          "121",
          "122",
          "123",
          "124",
          "125",
          "126",
          "127",
          "128",
          "129",
          "130",
          "131",
          "132",
          "133",
          "134",
          "135",
          "136",
          "137",
          "138",
          "139",
          "140",
          "141",
          "142",
          "143",
          "144",
          "145",
          "146",
          "147",
          "148",
          "149",
          "150",
          "151",
          "152",
          "153",
          "154",
          "155",
          "156",
          "157",
          "158",
          "159",
          "160",
        ],
        lon: [
          -3.517, -3.4893, -3.4621, -3.5699, -3.6153, -3.559, -3.6535, -3.5645,
          -3.6916, -3.5106, -3.5, -3.5329, -3.5214, -3.5323, -3.5654, -3.5538,
          -3.5646, -3.5484, -3.6758, -3.5431, -3.5052, -3.5897, -3.6112, -3.516,
          -3.6327, -3.4728, -3.4837, -3.4783, -3.4891, -3.5268, -3.5107,
          -3.5215, -3.4944, -3.5001, -3.5701, -3.5648, -3.4838, -3.5054,
          -3.5324, -3.5162, -3.5432, -3.5379, -3.5485, -3.5377, -3.5485,
          -3.5002, -3.5109, -3.5541, -3.5594, -3.5703, -3.5542, -3.5651,
          -3.5593, -3.4946, -3.5381, -3.5489, -3.5597, -3.527, -3.5704, -3.5217,
          -3.5326, -3.5328, -3.5436, -3.5056, -3.5164, -3.5382, -3.5274,
          -3.5433, -3.5543, -3.5707, -3.5437, -3.5272, -3.5949, -3.5117,
          -3.6058, -3.5219, -3.5491, -3.6003, -3.6109, -3.5653, -3.5545,
          -3.6005, -3.6163, -3.6271, -3.5598, -3.6219, -3.5948, -3.6108,
          -3.6217, -3.6433, -3.654, -3.6165, -3.6056, -3.5895, -3.6379, -3.6325,
          -3.5892, -3.6, -3.5946, -3.6649, -3.6702, -3.681, -3.6053, -3.6163,
          -3.6107, -3.6215, -3.643, -3.589, -3.6538, -3.5998, -3.6486, -3.6269,
          -3.5944, -3.6052, -3.6593, -3.6322, -3.6376, -3.6484, -3.6378, -3.616,
          -3.6809, -3.5888, -3.6105, -3.6212, -3.6646, -3.6755, -3.6864,
          -3.6537, -3.6645, -3.6268, -3.5996, -3.5942, -3.6049, -3.6592, -3.67,
          -3.6753, -3.6482, -3.6589, -3.5887, -3.632, -3.5995, -3.6429, -3.6157,
          -3.632, -3.6427, -3.6861, -3.6699, -3.6103, -3.6263, -3.6371, -3.6265,
          -3.6374, -3.5939, -3.6315, -3.6211, -3.5991, -3.61, -3.6208, -3.6047,
          -3.6155,
        ],
        lat: [
          53.485, 53.4686, 53.4521, 53.4403, 53.4181, 53.4404, 53.4343, 53.4347,
          53.4507, 53.4462, 53.4464, 53.4908, 53.4461, 53.446, 53.4906, 53.4459,
          53.4459, 53.4404, 53.4676, 53.446, 53.4518, 53.4901, 53.49, 53.4518,
          53.479, 53.4519, 53.4519, 53.4575, 53.4575, 53.4516, 53.4574, 53.4573,
          53.4518, 53.4574, 53.4514, 53.4571, 53.4631, 53.463, 53.4572, 53.4629,
          53.4572, 53.4628, 53.4627, 53.4516, 53.4516, 53.4686, 53.4685,
          53.4571, 53.4627, 53.4626, 53.4683, 53.4683, 53.4515, 53.4631,
          53.4739, 53.4739, 53.4739, 53.4629, 53.4738, 53.4684, 53.4684,
          53.4796, 53.4796, 53.4741, 53.4741, 53.4852, 53.4852, 53.4684,
          53.4795, 53.485, 53.4908, 53.474, 53.4848, 53.4798, 53.4848, 53.4797,
          53.485, 53.4793, 53.4792, 53.4794, 53.4906, 53.4901, 53.4736, 53.4735,
          53.485, 53.4791, 53.4737, 53.468, 53.4679, 53.4678, 53.4678, 53.4847,
          53.4736, 53.4794, 53.4732, 53.4679, 53.4682, 53.4681, 53.4625,
          53.4677, 53.462, 53.462, 53.4625, 53.4624, 53.4568, 53.4568, 53.4567,
          53.457, 53.4566, 53.4569, 53.4622, 53.4623, 53.4513, 53.4513, 53.4621,
          53.4567, 53.4511, 53.451, 53.4622, 53.4512, 53.4508, 53.4458, 53.4456,
          53.4456, 53.4565, 53.4564, 53.4563, 53.4454, 53.4454, 53.4511,
          53.4457, 53.4402, 53.4401, 53.4508, 53.4509, 53.4452, 53.4399,
          53.4398, 53.4346, 53.4455, 53.4346, 53.4455, 53.4401, 53.4344,
          53.4343, 53.4454, 53.4396, 53.4345, 53.4288, 53.4287, 53.4399,
          53.4399, 53.429, 53.4232, 53.4344, 53.4234, 53.4233, 53.4233, 53.4289,
          53.4289,
        ],
      },
    ],
    Heidrun: [
      {
        ids: ["1", "2", "3", "4"],
        lon: [7.3158, 7.3159, 7.3159, 7.3159],
        lat: [65.3255, 65.3254, 65.3255, 65.3255],
      },
    ],
    Heimdal: [
      {
        ids: ["1", "2", "3", "4", "5", "6", "7"],
        lon: [2.2269, 2.2268, 2.2269, 2.2269, 2.2268, 2.2269, 2.2269],
        lat: [59.5741, 59.5741, 59.5741, 59.5741, 59.5741, 59.5741, 59.5741],
      },
    ],
    "Heizkraftwerk Wedel": [
      {
        ids: ["1", "2", "3", "4"],
        lon: [9.7244, 9.7246, 9.7286, 9.7286],
        lat: [53.5665, 53.5672, 53.5662, 53.5664],
      },
    ],
    "Hollandse Kust (noord) V": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
        ],
        lon: [
          4.2472, 4.2692, 4.3454, 4.2295, 4.3573, 4.1437, 4.2124, 4.2843,
          4.2678, 4.1588, 4.1914, 4.1737, 4.251, 4.2653, 4.2873, 4.2792, 4.2901,
          4.2975, 4.3575, 4.3508, 4.2979, 4.3591, 4.3431, 4.3364, 4.3436,
          4.2942, 4.3148, 4.3272, 4.2774, 4.2952, 4.3026, 4.2618, 4.2538,
          4.2371, 4.2497, 4.2643, 4.2455, 4.3109, 4.3217, 4.3171, 4.2834,
          4.2081, 4.1808, 4.2751, 4.2243, 4.2162, 4.1858, 4.2001, 4.2423,
          4.1921, 4.2692, 4.2265, 4.2578, 4.1757, 4.1589, 4.1538, 4.2025, 4.189,
          4.2144, 4.24, 4.1488, 4.2182, 4.164, 4.1898, 4.2347, 4.3291, 4.358,
          4.3577, 4.3312, 4.3189,
        ],
        lat: [
          52.6835, 52.7506, 52.7068, 52.7209, 52.7207, 52.6424, 52.7035,
          52.6501, 52.6498, 52.6432, 52.6486, 52.6438, 52.6489, 52.6639,
          52.6915, 52.7063, 52.662, 52.7579, 52.7288, 52.7523, 52.7108, 52.7451,
          52.7166, 52.7665, 52.7596, 52.7834, 52.7339, 52.7228, 52.781, 52.7696,
          52.7465, 52.7686, 52.7618, 52.7482, 52.7364, 52.7191, 52.755, 52.7864,
          52.7803, 52.7119, 52.7267, 52.7248, 52.6971, 52.7378, 52.7379,
          52.7314, 52.7045, 52.7181, 52.7065, 52.7117, 52.6853, 52.6874,
          52.6946, 52.6895, 52.6649, 52.6574, 52.6894, 52.6702, 52.6682, 52.666,
          52.6499, 52.6565, 52.6723, 52.6596, 52.6478, 52.7734, 52.737, 52.7127,
          52.7025, 52.699,
        ],
      },
    ],
    "Hollandse Kust (zuid) I": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
        ],
        lon: [
          4.0901, 4.0961, 4.1221, 4.0936, 4.0616, 4.1181, 4.1013, 4.0733,
          4.0758, 3.9415, 3.9958, 4.0202, 4.1585, 3.9384, 4.1393, 3.9352,
          4.0221, 3.9513, 3.9709, 4.0721, 4.0565, 4.0464, 4.0082, 3.9845,
          4.1249, 4.066, 4.0973, 4.0448, 3.9652, 4.0258, 3.945, 3.9605, 3.9915,
          4.0155, 3.9478,
        ],
        lat: [
          52.3709, 52.3872, 52.4163, 52.4026, 52.3977, 52.4041, 52.4128,
          52.3914, 52.4085, 52.3656, 52.345, 52.3471, 52.3652, 52.3559, 52.3737,
          52.3459, 52.363, 52.345, 52.3445, 52.3535, 52.3661, 52.3511, 52.3866,
          52.3864, 52.3603, 52.3781, 52.3568, 52.3838, 52.3855, 52.3871,
          52.3765, 52.3729, 52.3606, 52.376, 52.385,
        ],
      },
    ],
    "Hollandse Kust (zuid) II": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
        ],
        lon: [
          3.9753, 3.9615, 3.9373, 4.005, 4.0441, 4.0269, 3.9884, 3.9731, 4.0099,
          3.9419, 3.9723, 3.9591, 3.9441, 3.9355, 4.0321, 4.0398, 3.9509,
          3.9324, 3.9377, 4.0025, 3.9536, 3.9806, 3.9515, 4.0323, 4.0358,
          3.9988, 4.0014, 4.0243, 4.0145, 4.031, 3.9407, 3.9566, 3.9276, 3.9759,
          3.9265,
        ],
        lat: [
          52.2953, 52.3025, 52.3026, 52.2766, 52.2704, 52.2676, 52.262, 52.2598,
          52.2654, 52.2652, 52.2715, 52.2577, 52.2564, 52.2857, 52.2777, 52.285,
          52.2725, 52.2939, 52.2766, 52.2865, 52.2831, 52.309, 52.3096, 52.2933,
          52.3024, 52.3068, 52.2973, 52.3087, 52.3187, 52.3182, 52.317, 52.3193,
          52.3102, 52.3194, 52.3201,
        ],
      },
    ],
    "Hollandse Kust (zuid) III": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
        ],
        lon: [
          4.0224, 3.9488, 3.9515, 3.9895, 3.9543, 3.9976, 3.9617, 3.9573,
          3.9755, 3.9966, 3.9781, 3.9844, 3.9743, 3.9998, 4.0165, 3.9945,
          4.0641, 4.0566, 4.0282, 4.0039, 4.0245, 4.0415, 4.0805, 4.093, 4.1047,
          4.0184, 3.9695, 4.0175, 3.9886, 4.0429, 4.0911, 4.0218, 4.0551,
          4.0468, 4.0725,
        ],
        lat: [
          52.2178, 52.2408, 52.2317, 52.2285, 52.2225, 52.2063, 52.204, 52.2126,
          52.2155, 52.2169, 52.2054, 52.1886, 52.1953, 52.1974, 52.1933,
          52.1817, 52.2293, 52.2202, 52.1987, 52.1887, 52.2081, 52.2096,
          52.2337, 52.2396, 52.2459, 52.2412, 52.2483, 52.2518, 52.2414,
          52.2249, 52.2494, 52.2313, 52.261, 52.2488, 52.2563,
        ],
      },
    ],
    "Hollandse Kust (zuid) IV": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
        ],
        lon: [
          4.1363, 4.094, 4.1091, 4.0791, 4.0861, 4.1092, 4.0909, 4.1185, 4.1214,
          4.1547, 4.1331, 4.1031, 4.148, 4.1415, 4.1662, 4.1919, 4.1994, 4.1455,
          4.1321, 4.1729, 4.1879, 4.2085, 4.1574, 4.1784, 4.1586, 4.2566,
          4.2403, 4.2107, 4.2284, 4.0721, 4.1758, 4.2061, 4.2137, 4.2411,
          4.2505,
        ],
        lat: [
          52.3179, 52.2773, 52.2677, 52.3206, 52.3054, 52.3205, 52.2915,
          52.3012, 52.259, 52.2903, 52.2682, 52.3395, 52.333, 52.2983, 52.3024,
          52.3319, 52.3401, 52.2801, 52.3433, 52.3266, 52.377, 52.3806, 52.3126,
          52.3418, 52.3468, 52.4191, 52.4202, 52.3906, 52.382, 52.3353, 52.3131,
          52.4036, 52.4181, 52.3957, 52.4092,
        ],
      },
    ],
    "Horns Rev 1": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
        ],
        lon: [
          7.8749, 7.805, 7.8758, 7.8847, 7.8038, 7.8126, 7.8215, 7.8303, 7.8392,
          7.8481, 7.8569, 7.8658, 7.8746, 7.8835, 7.8025, 7.8114, 7.8202,
          7.8291, 7.838, 7.8468, 7.8557, 7.8645, 7.8734, 7.8822, 7.8013, 7.8102,
          7.819, 7.8279, 7.8367, 7.8456, 7.8545, 7.8633, 7.8722, 7.881, 7.8001,
          7.8089, 7.8178, 7.8266, 7.8355, 7.8444, 7.8532, 7.8621, 7.871, 7.8798,
          7.7988, 7.8077, 7.8166, 7.8254, 7.8343, 7.8431, 7.852, 7.8609, 7.8697,
          7.8404, 7.8138, 7.7976, 7.8065, 7.8493, 7.8581, 7.867, 7.8508, 7.8596,
          7.8685, 7.8774, 7.7964, 7.8052, 7.8141, 7.823, 7.8786, 7.8318, 7.8407,
          7.8227, 7.8316, 7.8153, 7.8242, 7.8496, 7.833, 7.8419, 7.8584, 7.8673,
          7.8761,
        ],
        lat: [
          55.5089, 55.4683, 55.469, 55.4691, 55.4733, 55.4734, 55.4735, 55.4736,
          55.4736, 55.4737, 55.4738, 55.4739, 55.474, 55.4741, 55.4783, 55.4784,
          55.4785, 55.4785, 55.4786, 55.4787, 55.4788, 55.4789, 55.479, 55.479,
          55.4833, 55.4834, 55.4834, 55.4835, 55.4836, 55.4837, 55.4838,
          55.4839, 55.4839, 55.484, 55.4883, 55.4883, 55.4884, 55.4885, 55.4886,
          55.4887, 55.4888, 55.4888, 55.4889, 55.489, 55.4932, 55.4933, 55.4934,
          55.4935, 55.4936, 55.4937, 55.4937, 55.4938, 55.4939, 55.4687,
          55.4684, 55.4982, 55.4983, 55.4687, 55.4688, 55.4689, 55.4987,
          55.4988, 55.4989, 55.499, 55.5032, 55.5033, 55.5034, 55.5035, 55.494,
          55.5035, 55.5036, 55.4685, 55.4686, 55.4984, 55.4985, 55.5037,
          55.4986, 55.4986, 55.5038, 55.5039, 55.504,
        ],
      },
    ],
    "Horns Rev 2": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
        ],
        lon: [
          7.6226, 7.5468, 7.5554, 7.5726, 7.5812, 7.564, 7.5543, 7.563, 7.5889,
          7.5975, 7.5716, 7.5802, 7.5801, 7.5715, 7.5455, 7.598, 7.5897, 7.5628,
          7.5983, 7.5542, 7.5457, 7.5974, 7.5888, 7.5721, 7.5993, 7.5907,
          7.5635, 7.5549, 7.5651, 7.548, 7.5894, 7.5808, 7.5844, 7.5759, 7.5463,
          7.6013, 7.5928, 7.5506, 7.5957, 7.5791, 7.5822, 7.5707, 7.5736,
          7.5566, 7.5992, 7.5541, 7.5874, 7.6114, 7.6074, 7.5748, 7.5624, 7.604,
          7.5585, 7.5956, 7.5911, 7.583, 7.6084, 7.5717, 7.6007, 7.6215, 7.5638,
          7.5797, 7.5675, 7.6141, 7.5853, 7.6274, 7.5776, 7.6035, 7.5667, 7.559,
          7.593, 7.6161, 7.6066, 7.5876, 7.5699, 7.5992, 7.6272, 7.6061, 7.6203,
          7.5918, 7.5989, 7.5918, 7.5847, 7.5769, 7.6068, 7.6204, 7.5843, 7.634,
          7.6132, 7.6, 7.6136, 7.5932,
        ],
        lat: [
          55.5994, 55.5565, 55.557, 55.5581, 55.5586, 55.5575, 55.5648, 55.565,
          55.5657, 55.5659, 55.5652, 55.5654, 55.5723, 55.5724, 55.5727,
          55.5783, 55.5591, 55.5725, 55.5596, 55.5726, 55.5646, 55.5721,
          55.5722, 55.5796, 55.5845, 55.5853, 55.58, 55.5804, 55.5875, 55.5889,
          55.5787, 55.5792, 55.5927, 55.5938, 55.5808, 55.5906, 55.5917,
          55.5969, 55.598, 55.6008, 55.586, 55.6021, 55.5867, 55.5882, 55.6043,
          55.6048, 55.5994, 55.6084, 55.6026, 55.6092, 55.6035, 55.5967,
          55.6125, 55.6123, 55.6059, 55.6076, 55.6163, 55.6182, 55.6185,
          55.6195, 55.6201, 55.6162, 55.5948, 55.622, 55.623, 55.6248, 55.6252,
          55.6104, 55.6109, 55.5959, 55.6208, 55.6141, 55.6245, 55.6143,
          55.6275, 55.627, 55.6328, 55.6331, 55.6275, 55.6296, 55.6359, 55.6386,
          55.6414, 55.6346, 55.6419, 55.6358, 55.6321, 55.6298, 55.6303,
          55.6449, 55.6389, 55.648,
        ],
      },
    ],
    "Horns Rev 3": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
        ],
        lon: [
          7.6861, 7.5991, 7.6269, 7.5719, 7.5997, 7.7729, 7.5996, 7.5616,
          7.7427, 7.6043, 7.7145, 7.6942, 7.5736, 7.6014, 7.5687, 7.7471,
          7.7194, 7.7019, 7.6749, 7.7521, 7.7244, 7.6901, 7.7101, 7.687, 7.636,
          7.682, 7.7086, 7.6321, 7.7092, 7.7304, 7.6825, 7.641, 7.6275, 7.6552,
          7.6688, 7.657, 7.6831, 7.6638, 7.683, 7.6847, 7.6569, 7.6874, 7.6318,
          7.6596, 7.6012, 7.5534, 7.5507, 7.629, 7.5756, 7.604,
        ],
        lat: [
          55.6902, 55.7083, 55.7093, 55.6973, 55.6984, 55.6825, 55.7182,
          55.7167, 55.6843, 55.6787, 55.683, 55.6683, 55.6874, 55.6885, 55.707,
          55.6742, 55.6732, 55.6558, 55.6523, 55.6647, 55.6636, 55.6342,
          55.6437, 55.679, 55.6701, 55.6432, 55.7069, 55.6797, 55.6969, 55.6543,
          55.7114, 55.6605, 55.6994, 55.7203, 55.6616, 55.6906, 55.7015,
          55.6711, 55.7214, 55.7313, 55.7302, 55.7411, 55.739, 55.74, 55.7281,
          55.7258, 55.736, 55.7292, 55.7373, 55.7379,
        ],
      },
    ],
    "Hornsea 1 Offshore Wind Farm": [
      {
        ids: [
          "osp",
          "osp",
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
          "92",
          "93",
          "94",
          "95",
          "96",
          "97",
          "98",
          "99",
          "100",
          "101",
          "102",
          "103",
          "104",
          "105",
          "106",
          "107",
          "108",
          "109",
          "110",
          "111",
          "112",
          "113",
          "114",
          "115",
          "116",
          "117",
          "118",
          "119",
          "120",
          "121",
          "122",
          "123",
          "124",
          "125",
          "126",
          "127",
          "128",
          "129",
          "130",
          "131",
          "132",
          "133",
          "134",
          "135",
          "136",
          "137",
          "138",
          "139",
          "140",
          "141",
          "142",
          "143",
          "144",
          "145",
          "146",
          "147",
          "148",
          "149",
          "150",
          "151",
          "152",
          "153",
          "154",
          "155",
          "156",
          "157",
          "158",
          "159",
          "160",
          "161",
          "162",
          "163",
          "164",
          "165",
          "166",
          "167",
          "168",
          "169",
          "170",
          "171",
          "172",
          "173",
          "174",
        ],
        lon: [
          2.1027, 1.8953, 1.7144, 2.1605, 2.1726, 2.1818, 2.1483, 2.1226,
          2.1356, 2.0952, 2.0834, 2.1089, 2.1002, 2.1426, 2.1308, 2.0575,
          2.0453, 2.0717, 2.0495, 2.0371, 2.1189, 2.1071, 2.0354, 2.0085,
          1.9956, 2.0937, 2.0812, 2.0044, 1.9924, 1.9827, 1.97, 1.9564, 1.9444,
          2.0703, 1.9473, 1.9347, 2.0268, 1.9327, 2.0156, 1.9598, 1.8753,
          1.9804, 1.9684, 1.8971, 1.8718, 1.8593, 1.8847, 1.9168, 1.904, 1.8493,
          1.8362, 1.9222, 1.9096, 1.841, 1.8283, 1.8859, 1.8189, 1.8088, 2.0164,
          1.9288, 1.8467, 1.8884, 1.833, 1.8623, 1.8139, 2.0143, 1.997, 1.8081,
          1.9724, 1.9095, 1.9342, 1.8034, 1.9784, 1.8493, 1.8285, 1.8544,
          1.9492, 1.8353, 1.8046, 1.8078, 1.8738, 1.8943, 1.6514, 1.6656,
          1.6805, 1.7031, 1.7236, 1.7451, 1.7654, 1.7837, 2.0283, 2.0424,
          2.0584, 2.0727, 2.0869, 2.1009, 2.1285, 2.143, 2.157, 2.1758, 2.1996,
          1.6704, 2.2038, 2.2297, 1.656, 1.7548, 1.7952, 2.0471, 2.0949, 2.1357,
          2.1751, 2.2198, 1.6803, 1.7096, 1.7444, 2.0834, 2.1227, 2.1659,
          2.2088, 1.734, 1.7578, 1.7827, 2.0343, 2.0721, 2.1117, 2.1544, 2.1745,
          2.1985, 1.6993, 1.7286, 1.7652, 1.7859, 2.06, 2.1055, 2.1468, 1.66,
          1.6897, 1.7236, 1.7701, 2.0214, 2.0601, 1.7056, 1.7324, 1.7576,
          1.7843, 2.1458, 1.6642, 1.696, 1.745, 1.79, 1.6865, 1.7091, 1.7325,
          1.7585, 1.7773, 1.6752, 1.7199, 1.7644, 1.7943, 2.1152, 1.7415,
          1.6988, 1.7153, 1.7504, 1.7088, 1.7263, 1.7604, 2.0792, 1.7185,
          1.7392, 1.7711, 2.0911, 1.6889, 1.708, 1.7307, 1.7515, 1.7822,
        ],
        lat: [
          53.8775, 53.8778, 53.8908, 53.8996, 53.8885, 53.88, 53.9108, 53.9342,
          53.9224, 53.9222, 53.933, 53.9467, 53.9547, 53.8789, 53.8897, 53.9188,
          53.9298, 53.9437, 53.8882, 53.8995, 53.9005, 53.9113, 53.9389,
          53.8878, 53.8995, 53.8857, 53.8971, 53.8537, 53.8646, 53.9112,
          53.9228, 53.8974, 53.9083, 53.9071, 53.868, 53.8794, 53.9089, 53.919,
          53.9191, 53.8566, 53.8579, 53.8756, 53.8865, 53.9136, 53.8984,
          53.9102, 53.9249, 53.858, 53.8696, 53.8816, 53.8934, 53.8908, 53.9022,
          53.8581, 53.8697, 53.886, 53.909, 53.8715, 53.8428, 53.8471, 53.9216,
          53.8461, 53.934, 53.8698, 53.8827, 53.8337, 53.8338, 53.9188, 53.8452,
          53.8348, 53.8342, 53.8922, 53.834, 53.8347, 53.8365, 53.8461, 53.8342,
          53.8474, 53.8361, 53.847, 53.8347, 53.8345, 53.8356, 53.8355, 53.8374,
          53.8354, 53.8354, 53.8353, 53.8352, 53.8351, 53.832, 53.8311, 53.8301,
          53.8292, 53.8283, 53.8291, 53.8257, 53.826, 53.8239, 53.8227, 53.8268,
          53.8495, 53.8381, 53.8361, 53.8624, 53.8545, 53.8584, 53.8525, 53.847,
          53.8472, 53.8458, 53.8452, 53.8666, 53.8682, 53.8639, 53.8572,
          53.8592, 53.8575, 53.8553, 53.8733, 53.872, 53.8697, 53.8643, 53.8676,
          53.8692, 53.8681, 53.865, 53.8647, 53.8495, 53.8511, 53.8451, 53.8466,
          53.8408, 53.837, 53.8371, 53.8852, 53.8862, 53.8827, 53.8811, 53.876,
          53.8785, 53.8989, 53.8953, 53.8924, 53.8937, 53.8914, 53.9092,
          53.9076, 53.9038, 53.9044, 53.9162, 53.9165, 53.9151, 53.917, 53.9159,
          53.9263, 53.9265, 53.9275, 53.93, 53.9192, 53.9774, 53.9711, 53.9735,
          53.9693, 53.962, 53.9619, 53.9604, 53.9521, 53.9531, 53.9503, 53.9508,
          53.9412, 53.9342, 53.9372, 53.9421, 53.9392, 53.9408,
        ],
      },
    ],
    "Hornsea 2 Offshore Wind Farm": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
          "92",
          "93",
          "94",
          "95",
          "96",
          "97",
          "98",
          "99",
          "100",
          "101",
          "102",
          "103",
          "104",
          "105",
          "106",
          "107",
          "108",
          "109",
          "110",
          "111",
          "112",
          "113",
          "114",
          "115",
          "116",
          "117",
          "118",
          "119",
          "120",
          "121",
          "122",
          "123",
          "124",
          "125",
          "126",
          "127",
          "128",
          "129",
          "130",
          "131",
          "132",
          "133",
          "134",
          "135",
          "136",
          "137",
          "138",
          "139",
          "140",
          "141",
          "142",
          "143",
          "144",
          "145",
          "146",
          "147",
          "148",
          "149",
          "150",
          "151",
          "152",
          "153",
          "154",
          "155",
          "156",
          "157",
          "158",
          "159",
          "160",
          "161",
          "162",
          "163",
          "164",
          "165",
        ],
        lon: [
          1.6307, 2.0544, 2.0297, 2.0027, 1.945, 1.9142, 1.8945, 1.8715, 1.8512,
          1.8275, 1.8058, 1.7716, 1.7539, 1.7264, 1.7093, 1.6813, 1.4745,
          1.4545, 1.5318, 1.4833, 1.4505, 1.4958, 1.4659, 1.4476, 1.4956,
          1.4699, 1.4987, 1.4755, 1.454, 1.5139, 1.4888, 1.4648, 1.4849, 1.4646,
          1.4575, 1.528, 1.4746, 1.5231, 1.6065, 1.5431, 1.5, 1.6328, 1.617,
          1.5872, 1.5942, 1.6061, 1.5922, 1.6261, 1.6148, 1.6363, 1.628, 1.6391,
          1.6431, 1.5742, 1.5588, 1.6282, 1.5169, 1.5026, 1.531, 1.5323, 1.5255,
          1.5738, 1.5445, 1.5594, 1.5735, 1.5653, 1.6013, 1.6357, 1.6271,
          1.5891, 1.5572, 1.6521, 1.6108, 1.5881, 1.5994, 1.5866, 1.636, 1.6275,
          1.6179, 1.6453, 1.6018, 1.6148, 1.6427, 1.6545, 1.6385, 1.7764,
          1.7863, 1.7978, 1.8093, 1.8235, 1.8259, 1.7041, 1.7335, 1.6831,
          1.8374, 1.8485, 1.6475, 1.6792, 1.6985, 1.6786, 1.8595, 1.8767, 1.9,
          1.8852, 1.9379, 1.9603, 1.933, 1.9707, 1.9513, 1.9249, 1.9138, 1.9172,
          1.9091, 1.965, 1.9894, 1.9973, 2.0519, 2.0258, 1.975, 2.0807, 1.9872,
          2.0892, 2.0028, 2.039, 2.014, 1.5966, 1.5477, 1.5607, 1.5889, 1.5822,
          1.5624, 1.5154, 1.5482, 1.6139, 1.5779, 1.4875, 1.4965, 1.5701,
          1.5347, 1.4571, 1.4676, 1.4564, 1.4586, 1.5359, 1.5137, 1.5257,
          1.5031, 1.4547, 1.4869, 1.4717, 1.5442, 1.4671, 1.4583, 1.5562,
          1.5654, 1.4961, 1.5388, 1.5173, 1.502, 1.4683, 1.4583, 1.5911, 2.0062,
          1.5081, 1.4573, 1.6307,
        ],
        lat: [
          53.9394, 53.9748, 53.9787, 53.9809, 53.9833, 53.9854, 53.986, 53.9862,
          53.9862, 53.9877, 53.9905, 53.9951, 53.9977, 54.0005, 54.002, 54.0027,
          54.0069, 54.0012, 53.9559, 53.9992, 53.9854, 54.0064, 53.9718,
          54.0073, 53.9881, 53.9875, 53.9619, 53.9632, 53.9618, 53.9483,
          53.9514, 53.9524, 53.9344, 53.9319, 53.9383, 53.9356, 53.9231,
          53.9205, 53.8887, 53.9221, 53.9208, 53.8414, 53.8362, 53.8426,
          53.8363, 53.8461, 53.9015, 53.8476, 53.8577, 53.8614, 53.8694,
          53.8777, 53.9002, 53.9361, 53.9498, 53.8875, 53.9874, 54.0003,
          53.9747, 53.9997, 54.0059, 53.9626, 53.9888, 53.9753, 53.9908,
          53.9982, 53.9378, 53.9066, 53.9145, 53.9488, 54.0053, 53.9875,
          54.0044, 54.0048, 53.9945, 53.979, 54.0021, 53.9895, 53.9508, 53.9533,
          53.9653, 53.9806, 53.9758, 53.9651, 53.9321, 53.9851, 53.9761,
          53.9659, 53.9556, 53.9427, 53.9782, 53.982, 53.9867, 53.9595, 53.9678,
          53.9575, 53.9244, 53.9429, 53.9459, 53.989, 53.9479, 53.9697, 53.9487,
          53.9621, 53.952, 53.9316, 53.985, 53.9833, 53.9398, 53.9637, 53.9739,
          53.933, 53.9404, 53.9652, 53.9807, 53.9358, 53.9616, 53.9476, 53.9555,
          53.9742, 53.9447, 53.9648, 53.9686, 53.9735, 53.9584, 53.916, 53.8364,
          53.8461, 53.881, 53.8677, 53.865, 53.8457, 53.8572, 53.9267, 53.9146,
          53.8504, 53.8628, 53.8375, 53.8694, 53.838, 53.8553, 53.8653, 53.8761,
          53.8472, 53.867, 53.8363, 53.8365, 53.8518, 53.838, 53.8367, 53.8813,
          53.8892, 53.8969, 53.8908, 53.9023, 53.8827, 53.9067, 53.885, 53.8989,
          53.9074, 53.9164, 53.8596, 53.9279, 53.9771, 53.9793, 53.9664,
        ],
      },
    ],
    "Humber Gateway Wind Farm": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
        ],
        lon: [
          0.2528, 0.2718, 0.289, 0.2955, 0.287, 0.2781, 0.2935, 0.2806, 0.2671,
          0.2756, 0.2847, 0.2694, 0.302, 0.3083, 0.2911, 0.2998, 0.2647, 0.2823,
          0.2735, 0.2711, 0.2799, 0.2887, 0.2864, 0.2776, 0.2688, 0.26, 0.2576,
          0.2666, 0.2753, 0.2839, 0.2928, 0.3017, 0.3104, 0.3192, 0.3282,
          0.3256, 0.3168, 0.308, 0.2992, 0.2905, 0.2817, 0.2729, 0.2641, 0.2553,
          0.2617, 0.2705, 0.2793, 0.2881, 0.2969, 0.3057, 0.3216, 0.3233,
          0.2975, 0.3297, 0.3063, 0.3152, 0.3033, 0.2946, 0.3127, 0.3039,
          0.2952, 0.3098, 0.3185, 0.3274, 0.325, 0.3163, 0.3145, 0.3075, 0.3209,
          0.3122, 0.2922, 0.2858, 0.277, 0.2681,
        ],
        lat: [
          53.6585, 53.616, 53.6146, 53.6192, 53.6198, 53.6205, 53.6243, 53.6153,
          53.6266, 53.6265, 53.6251, 53.6212, 53.6237, 53.6283, 53.6298, 53.629,
          53.632, 53.6305, 53.6313, 53.6365, 53.6358, 53.6351, 53.6404, 53.6412,
          53.6419, 53.6426, 53.6479, 53.6471, 53.6464, 53.6457, 53.6449,
          53.6442, 53.6435, 53.6428, 53.6421, 53.6474, 53.6481, 53.6488,
          53.6496, 53.6503, 53.6511, 53.6518, 53.6525, 53.6532, 53.6578,
          53.6571, 53.6564, 53.6556, 53.6549, 53.6542, 53.6375, 53.6527,
          53.6344, 53.6573, 53.6336, 53.6328, 53.6595, 53.6602, 53.6382, 53.639,
          53.6397, 53.6641, 53.6633, 53.6626, 53.6679, 53.6686, 53.6534,
          53.6694, 53.658, 53.6587, 53.6655, 53.6609, 53.6617, 53.6624,
        ],
      },
    ],
    "Hywind Wind Farm": [
      {
        ids: ["1", "2", "3", "4", "5"],
        lon: [-1.3323, -1.3525, -1.3717, -1.3521, -1.3723],
        lat: [57.4842, 57.4784, 57.4972, 57.4909, 57.4848],
      },
    ],
    Kaskasi: [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
        ],
        lon: [
          7.7286, 7.6838, 7.6431, 7.6409, 7.6409, 7.6434, 7.6979, 7.7136,
          7.7297, 7.7532, 7.7527, 7.7533, 7.7367, 7.7254, 7.6988, 7.6838,
          7.7136, 7.6703, 7.6438, 7.6556, 7.7156, 7.6995, 7.734, 7.7261, 7.6698,
          7.6545, 7.6533, 7.671, 7.685, 7.6976, 7.6833, 7.6687, 7.6558, 7.699,
          7.7128, 7.7158, 7.7322, 7.6711, 7.6545,
        ],
        lat: [
          54.4885, 54.4988, 54.4999, 54.4928, 54.4876, 54.4824, 54.5, 54.5,
          54.5, 54.4931, 54.4999, 54.4866, 54.4781, 54.478, 54.4777, 54.4777,
          54.4779, 54.4778, 54.4771, 54.4773, 54.4946, 54.4952, 54.4838,
          54.4871, 54.4945, 54.495, 54.4894, 54.4888, 54.4914, 54.484, 54.4827,
          54.483, 54.4835, 54.4901, 54.4829, 54.489, 54.4946, 54.4999, 54.4999,
        ],
      },
    ],
    "Kentish Flats Offshore Wind Farm": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
        ],
        lon: [
          1.0815, 1.0915, 1.1014, 1.1114, 1.1214, 1.1314, 1.1252, 1.0882,
          1.0752, 1.079, 1.099, 1.1052, 1.0853, 1.0727, 1.0953, 1.0708, 1.1023,
          1.1134, 1.1089, 1.1189, 1.0628, 1.083, 1.0964, 1.1063, 1.1152, 1.0567,
          1.0665, 1.0927, 1.0765, 1.0864, 1.1374, 1.1062, 1.1177, 1.1281,
          1.0892, 1.0617, 1.0778, 1.0439, 1.0496, 1.0718, 1.0588, 1.0366,
          1.0541, 1.0558, 1.0481,
        ],
        lat: [
          51.4479, 51.4488, 51.4499, 51.451, 51.452, 51.4529, 51.4579, 51.4586,
          51.4529, 51.4589, 51.4609, 51.4559, 51.4539, 51.4638, 51.4549,
          51.4564, 51.4672, 51.4673, 51.4619, 51.4629, 51.4628, 51.4652,
          51.4718, 51.4729, 51.4569, 51.4678, 51.4688, 51.4659, 51.4698,
          51.4708, 51.448, 51.4449, 51.4458, 51.4469, 51.4433, 51.4459, 51.4418,
          51.4601, 51.4557, 51.4469, 51.4574, 51.4658, 51.4613, 51.4506,
          51.4664,
        ],
      },
    ],
    "Kincardine Offshore Windfarm": [
      {
        ids: ["1", "2", "3", "4", "5", "6"],
        lon: [-1.8402, -1.8551, -1.8478, -1.8665, -1.8739, -1.8812],
        lat: [57.0024, 57.0184, 57.0104, 56.989, 56.9971, 57.0051],
      },
    ],
    "Kriegers Flak A": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
        ],
        lon: [
          12.8153, 12.7623, 12.8229, 12.8135, 12.8161, 12.7562, 12.8196, 12.824,
          12.8039, 12.8174, 12.8347, 12.9038, 12.8106, 12.7678, 12.8683,
          12.7612, 12.7881, 12.7622, 12.8721, 12.7654, 12.8449, 12.7758,
          12.7617, 12.7684,
        ],
        lat: [
          55.0335, 54.9946, 55.0097, 55.0448, 54.9986, 55.0045, 55.0196,
          55.0664, 54.9731, 55.0561, 54.9713, 54.9716, 54.9842, 55.0535,
          54.9703, 55.0133, 55.0586, 55.023, 54.9799, 55.0423, 54.9897, 54.9748,
          55.0327, 54.9847,
        ],
      },
    ],
    "Kriegers Flak B": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
        ],
        lon: [
          13.0016, 13.0219, 12.8687, 12.9303, 13.093, 12.9568, 12.9525, 13.0438,
          13.0055, 13.0531, 12.9527, 13.0255, 12.8868, 12.9425, 13.0602,
          13.0757, 13.0227, 13.0096, 12.9629, 13.0678, 13.0443, 13.1154,
          13.0543, 12.988, 12.9518, 13.0041, 13.0797, 13.0491, 13.0151, 12.9224,
          13.0526, 12.9721, 12.9665, 13.1049, 12.9381, 13.0908, 13.1046,
          12.9994, 13.0383, 12.9974, 12.9965, 12.9867, 12.9834, 12.9704,
          12.9629, 12.8933, 13.015, 12.9192,
        ],
        lat: [
          55.0793, 55.0713, 55.0763, 55.0565, 55.0341, 55.0961, 55.0876,
          54.9946, 54.9928, 55.0582, 55.0695, 54.9797, 55.0733, 55.0375,
          55.0133, 54.9886, 55.0162, 55.0459, 55.0821, 55.0496, 55.0406,
          55.0196, 55.027, 55.0174, 55.0315, 55.033, 55.0419, 55.004, 54.9855,
          55.077, 54.9837, 55.0236, 55.0537, 55.0265, 55.0918, 54.9986, 55.0103,
          54.9997, 55.0651, 55.009, 54.9855, 55.0854, 55.0661, 55.0916, 55.0441,
          55.082, 55.0578, 55.0876,
        ],
      },
    ],
    Kristin: [
      {
        ids: ["1", "2", "3"],
        lon: [6.5505, 6.5505, 6.5505],
        lat: [64.994, 64.994, 64.994],
      },
    ],
    Kyndbyværket: [
      {
        ids: ["1", "2", "3"],
        lon: [11.8928, 11.8885, 11.8906],
        lat: [55.8157, 55.816, 55.8164],
      },
    ],
    "Lincs Wind Farm": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
        ],
        lon: [
          0.4906, 0.4598, 0.5003, 0.5025, 0.4732, 0.4869, 0.5073, 0.5097,
          0.4944, 0.4807, 0.4671, 0.512, 0.4894, 0.4644, 0.492, 0.4836, 0.4971,
          0.4757, 0.5147, 0.4856, 0.5171, 0.4697, 0.4997, 0.5024, 0.504, 0.4908,
          0.4883, 0.4748, 0.4907, 0.5039, 0.4771, 0.4771, 0.5177, 0.5038,
          0.5174, 0.5177, 0.5157, 0.5176, 0.5177, 0.5037, 0.5039, 0.4901,
          0.4764, 0.5174, 0.5037, 0.4897, 0.476, 0.4894, 0.4752, 0.4895, 0.4753,
          0.5167, 0.5036, 0.5051, 0.4782, 0.4622, 0.5177, 0.5035, 0.4749,
          0.5033, 0.4893, 0.491, 0.4748, 0.489, 0.5032, 0.5176, 0.4909, 0.4899,
          0.5031, 0.4907, 0.4786, 0.4793, 0.4791, 0.4603, 0.4603, 0.4602,
        ],
        lat: [
          53.1884, 53.2411, 53.2473, 53.2416, 53.2433, 53.2453, 53.2306,
          53.2252, 53.227, 53.2251, 53.2236, 53.2192, 53.2391, 53.2295, 53.2331,
          53.2192, 53.2209, 53.2371, 53.2131, 53.213, 53.2075, 53.2179, 53.2148,
          53.2087, 53.2026, 53.2009, 53.207, 53.2049, 53.1947, 53.1961, 53.1986,
          53.1921, 53.2017, 53.1899, 53.1858, 53.1915, 53.1965, 53.18, 53.1743,
          53.1772, 53.1835, 53.1823, 53.1831, 53.1686, 53.1709, 53.176, 53.1768,
          53.1697, 53.1702, 53.1636, 53.1639, 53.163, 53.1647, 53.2362, 53.2312,
          53.2353, 53.1571, 53.1583, 53.1575, 53.152, 53.1572, 53.1448, 53.1522,
          53.1511, 53.1458, 53.1515, 53.1398, 53.1283, 53.1393, 53.1346,
          53.1377, 53.1286, 53.1331, 53.2033, 53.2081, 53.2128,
        ],
      },
    ],
    "London Array Wind Farm": [
      {
        ids: [
          "osp",
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
          "92",
          "93",
          "94",
          "95",
          "96",
          "97",
          "98",
          "99",
          "100",
          "101",
          "102",
          "103",
          "104",
          "105",
          "106",
          "107",
          "108",
          "109",
          "110",
          "111",
          "112",
          "113",
          "114",
          "115",
          "116",
          "117",
          "118",
          "119",
          "120",
          "121",
          "122",
          "123",
          "124",
          "125",
          "126",
          "127",
          "128",
          "129",
          "130",
          "131",
          "132",
          "133",
          "134",
          "135",
          "136",
          "137",
          "138",
          "139",
          "140",
          "141",
          "142",
          "143",
          "144",
          "145",
          "146",
          "147",
          "148",
          "149",
          "150",
          "151",
          "152",
          "153",
          "154",
          "155",
          "156",
          "157",
          "158",
          "159",
          "160",
          "161",
          "162",
          "163",
          "164",
          "165",
          "166",
          "167",
          "168",
          "169",
          "170",
          "171",
          "172",
          "173",
          "174",
          "175",
        ],
        lon: [
          1.5349, 1.437, 1.5007, 1.4242, 1.4576, 1.5288, 1.4343, 1.5714, 1.4318,
          1.4104, 1.4184, 1.5524, 1.569, 1.5551, 1.5051, 1.5454, 1.5437, 1.4815,
          1.5146, 1.5383, 1.4742, 1.5619, 1.5217, 1.4504, 1.498, 1.4903, 1.5549,
          1.4672, 1.4364, 1.524, 1.5072, 1.4839, 1.4434, 1.4601, 1.5311, 1.5478,
          1.4293, 1.517, 1.5644, 1.4529, 1.5004, 1.5407, 1.4768, 1.5573, 1.4861,
          1.4625, 1.4933, 1.4458, 1.4695, 1.5334, 1.5099, 1.5501, 1.4789,
          1.4955, 1.472, 1.5264, 1.5667, 1.5433, 1.5194, 1.4247, 1.5123, 1.4484,
          1.5597, 1.536, 1.5028, 1.4886, 1.4554, 1.5691, 1.4578, 1.4648, 1.5293,
          1.5051, 1.4983, 1.4743, 1.4815, 1.5462, 1.4413, 1.5217, 1.5526,
          1.5621, 1.5384, 1.5313, 1.4435, 1.491, 1.4672, 1.4272, 1.4506, 1.555,
          1.5146, 1.4201, 1.5075, 1.4839, 1.5479, 1.4602, 1.4129, 1.4768,
          1.5171, 1.5408, 1.5004, 1.5643, 1.4531, 1.4698, 1.4368, 1.4224, 1.51,
          1.5242, 1.5574, 1.4935, 1.4461, 1.5337, 1.4863, 1.439, 1.5432, 1.4318,
          1.4626, 1.5503, 1.5266, 1.5194, 1.5029, 1.5362, 1.5124, 1.4791,
          1.4958, 1.4556, 1.4722, 1.4484, 1.4888, 1.4414, 1.4651, 1.4816,
          1.4982, 1.4739, 1.529, 1.4582, 1.5053, 1.5456, 1.5219, 1.5382, 1.4509,
          1.4674, 1.4606, 1.548, 1.4439, 1.5314, 1.4769, 1.4533, 1.5148, 1.4911,
          1.4935, 1.4699, 1.5244, 1.5077, 1.541, 1.5166, 1.4627, 1.5337, 1.5267,
          1.5101, 1.5006, 1.4864, 1.4839, 1.5028, 1.4794, 1.5197, 1.4722,
          1.5363, 1.4963, 1.5125, 1.492, 1.5078, 1.4888, 1.5292, 1.5055, 1.4813,
          1.522, 1.4984, 1.5144,
        ],
        lat: [
          51.6024, 51.6302, 51.6983, 51.5977, 51.5785, 51.5759, 51.6508,
          51.6241, 51.6044, 51.6337, 51.6123, 51.575, 51.5779, 51.657, 51.5767,
          51.5788, 51.6745, 51.5776, 51.5836, 51.5826, 51.5814, 51.5817,
          51.5797, 51.5821, 51.5806, 51.5847, 51.5856, 51.5853, 51.59, 51.5903,
          51.5874, 51.5882, 51.5862, 51.5892, 51.5865, 51.5894, 51.5938,
          51.5942, 51.5924, 51.593, 51.5912, 51.5932, 51.5921, 51.5962, 51.5989,
          51.5998, 51.595, 51.5968, 51.5959, 51.5972, 51.598, 51.6001, 51.6028,
          51.6055, 51.6066, 51.6009, 51.603, 51.604, 51.6048, 51.6082, 51.6086,
          51.6074, 51.6068, 51.6077, 51.6018, 51.6095, 51.6037, 51.614, 51.6142,
          51.6104, 51.6116, 51.6124, 51.6164, 51.6172, 51.6133, 51.6142,
          51.6113, 51.6153, 51.6107, 51.6175, 51.6184, 51.6222, 51.6219,
          51.6201, 51.621, 51.6189, 51.618, 51.6213, 51.6192, 51.6227, 51.6231,
          51.624, 51.6251, 51.6248, 51.6266, 51.6278, 51.6299, 51.629, 51.6269,
          51.628, 51.6287, 51.6316, 51.6255, 51.6333, 51.6337, 51.626, 51.6319,
          51.6307, 51.6325, 51.6328, 51.6346, 51.6363, 51.6397, 51.6401,
          51.6354, 51.6358, 51.6366, 51.6405, 51.6375, 51.6434, 51.6443,
          51.6384, 51.6414, 51.6393, 51.6422, 51.6431, 51.6452, 51.647, 51.6461,
          51.649, 51.652, 51.6532, 51.6472, 51.65, 51.6481, 51.6502, 51.6511,
          51.6542, 51.6537, 51.6567, 51.6603, 51.6608, 51.6576, 51.6579,
          51.6635, 51.6643, 51.655, 51.6558, 51.6665, 51.6673, 51.6618, 51.6588,
          51.6643, 51.6658, 51.6712, 51.6685, 51.6724, 51.6694, 51.6626,
          51.6703, 51.6597, 51.6733, 51.6742, 51.6762, 51.6779, 51.6792, 51.677,
          51.68, 51.6913, 51.6945, 51.6809, 51.683, 51.6839, 51.685, 51.6868,
          51.6877, 51.6904,
        ],
      },
    ],
    Luchterduinen: [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
        ],
        lon: [
          4.1738, 4.1648, 4.1251, 4.1839, 4.1544, 4.205, 4.1569, 4.141, 4.1462,
          4.1665, 4.1617, 4.1331, 4.154, 4.1741, 4.1515, 4.1712, 4.149, 4.1695,
          4.1364, 4.1565, 4.144, 4.1639, 4.1307, 4.1419, 4.1889, 4.1785, 4.1766,
          4.1491, 4.1752, 4.1483, 4.1946, 4.186, 4.1385, 4.1614, 4.1787, 4.1696,
          4.1682, 4.182, 4.1591, 4.1625, 4.1715, 4.1554, 4.2002, 4.1853,
        ],
        lat: [
          52.4038, 52.3773, 52.3908, 52.4047, 52.4229, 52.4309, 52.38, 52.3855,
          52.3922, 52.3938, 52.3869, 52.3881, 52.3896, 52.3913, 52.3989,
          52.4005, 52.3827, 52.3843, 52.404, 52.4055, 52.4014, 52.403, 52.3974,
          52.4104, 52.4113, 52.398, 52.407, 52.4079, 52.4159, 52.4166, 52.4176,
          52.4206, 52.3948, 52.4206, 52.423, 52.4094, 52.4183, 52.4136, 52.3963,
          52.4119, 52.4254, 52.4142, 52.4237, 52.4278,
        ],
      },
    ],
    "Lynn and Inner Dowsing Wind Farms": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
        ],
        lon: [
          0.4786, 0.4778, 0.4692, 0.4598, 0.4684, 0.4588, 0.4594, 0.4594,
          0.4497, 0.4503, 0.4696, 0.4697, 0.4309, 0.44, 0.4591, 0.4689, 0.4307,
          0.4397, 0.4393, 0.4492, 0.4305, 0.4492, 0.4308, 0.4326, 0.4602,
          0.4499, 0.4404, 0.4603, 0.4466, 0.44, 0.4464, 0.4467, 0.4328, 0.4301,
          0.4326, 0.46, 0.4465, 0.4604, 0.4334, 0.4466, 0.4322, 0.4599, 0.4461,
          0.4328, 0.4603, 0.4465, 0.4327, 0.4326, 0.4328, 0.4466, 0.4601,
          0.4463, 0.4322, 0.4466,
        ],
        lat: [
          53.1421, 53.1467, 53.1354, 53.1284, 53.1443, 53.1463, 53.1328,
          53.1373, 53.1349, 53.1257, 53.1307, 53.1262, 53.1259, 53.1324,
          53.1418, 53.14, 53.1343, 53.1414, 53.1459, 53.1438, 53.1433, 53.1395,
          53.1301, 53.1695, 53.1741, 53.1303, 53.1277, 53.1791, 53.1791,
          53.1368, 53.1743, 53.1839, 53.1839, 53.1389, 53.1886, 53.1933,
          53.1936, 53.1694, 53.1933, 53.1694, 53.1791, 53.1839, 53.2032,
          53.1743, 53.1889, 53.208, 53.208, 53.203, 53.2129, 53.1885, 53.1983,
          53.1984, 53.1985, 53.213,
        ],
      },
    ],
    "Lysebotn 1 kraftverk": [
      {
        ids: ["1", "2", "3"],
        lon: [6.6153, 6.6163, 6.6158],
        lat: [59.0539, 59.0541, 59.054],
      },
    ],
    "Meerwind Süd | Ost": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
        ],
        lon: [
          7.6995, 7.6413, 7.7665, 7.7621, 7.7525, 7.6892, 7.7483, 7.6413,
          7.6526, 7.6414, 7.6605, 7.6521, 7.671, 7.6412, 7.6621, 7.6792, 7.6413,
          7.6606, 7.6896, 7.6994, 7.6609, 7.6414, 7.6413, 7.7376, 7.7128,
          7.6961, 7.6604, 7.6939, 7.7258, 7.6608, 7.7127, 7.6779, 7.6715,
          7.7267, 7.6413, 7.6413, 7.7578, 7.74, 7.7108, 7.6765, 7.6626, 7.6412,
          7.7278, 7.6882, 7.7463, 7.7659, 7.7095, 7.7271, 7.7451, 7.6745,
          7.6413, 7.6606, 7.7079, 7.7653, 7.6533, 7.6904, 7.6639, 7.7648,
          7.7257, 7.6755, 7.7462, 7.6885, 7.6738, 7.7643, 7.7064, 7.7451,
          7.7246, 7.6862, 7.7636, 7.7428, 7.6973, 7.7631, 7.7053, 7.7164,
          7.7245, 7.7626, 7.7484, 7.729, 7.7579, 7.7413, 7.7387,
        ],
        lat: [
          54.3993, 54.347, 54.3859, 54.4251, 54.4338, 54.3666, 54.3825, 54.3515,
          54.3522, 54.3559, 54.3566, 54.3585, 54.36, 54.3604, 54.3629, 54.3629,
          54.3649, 54.3673, 54.3721, 54.3699, 54.3722, 54.3694, 54.3788,
          54.3802, 54.3738, 54.3762, 54.3823, 54.3821, 54.3774, 54.3771,
          54.3784, 54.3813, 54.3756, 54.3827, 54.3743, 54.3832, 54.3843,
          54.3865, 54.3844, 54.3864, 54.3875, 54.3877, 54.389, 54.3895, 54.3904,
          54.3909, 54.3915, 54.3955, 54.3957, 54.3971, 54.3923, 54.3918,
          54.3986, 54.3958, 54.395, 54.3951, 54.3993, 54.4007, 54.4014, 54.3918,
          54.4017, 54.4015, 54.403, 54.4056, 54.4068, 54.4068, 54.4077, 54.4078,
          54.4105, 54.4136, 54.412, 54.4154, 54.4153, 54.4196, 54.4153, 54.4203,
          54.4186, 54.4242, 54.4293, 54.4249, 54.4299,
        ],
      },
    ],
    "Merkur-Offshore": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
        ],
        lon: [
          6.5606, 6.5045, 6.5687, 6.5672, 6.5035, 6.619, 6.5406, 6.5644, 6.5162,
          6.6188, 6.5396, 6.5519, 6.5291, 6.5164, 6.5041, 6.5163, 6.5285,
          6.5158, 6.5036, 6.5298, 6.5165, 6.5297, 6.5549, 6.5421, 6.5427,
          6.5556, 6.543, 6.5561, 6.5683, 6.568, 6.5677, 6.6065, 6.5921, 6.5795,
          6.5667, 6.5545, 6.5416, 6.5291, 6.5285, 6.5413, 6.5541, 6.5666,
          6.5791, 6.5917, 6.6058, 6.6094, 6.5911, 6.5787, 6.566, 6.5534, 6.541,
          6.5404, 6.5531, 6.5656, 6.5781, 6.5908, 6.6021, 6.5904, 6.5775,
          6.5654, 6.5525, 6.5401, 6.5648, 6.5771, 6.5523, 6.5281, 6.5412,
        ],
        lat: [
          54.0372, 54.0076, 54.0043, 54.0338, 54.0299, 54.0435, 54.0736,
          54.0857, 54.0431, 54.0363, 54.0891, 54.0892, 54.0075, 54.0075,
          54.0149, 54.0151, 54.0151, 54.0225, 54.0224, 54.0273, 54.0347,
          54.0352, 54.0347, 54.0356, 54.028, 54.0192, 54.0201, 54.0117, 54.0117,
          54.0192, 54.0265, 54.0362, 54.0363, 54.0381, 54.0413, 54.0428,
          54.0435, 54.0436, 54.0516, 54.0509, 54.0503, 54.0486, 54.0456,
          54.0437, 54.0437, 54.0503, 54.0511, 54.0531, 54.056, 54.0582, 54.0586,
          54.0662, 54.066, 54.0635, 54.0606, 54.0584, 54.0566, 54.0657, 54.0681,
          54.071, 54.0737, 54.0814, 54.0783, 54.0757, 54.0815, 54.0589, 54.0078,
        ],
      },
    ],
    Mermaid: [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
        ],
        lon: [
          2.7238, 2.7503, 2.7137, 2.7537, 2.781, 2.7803, 2.718, 2.7063, 2.739,
          2.7486, 2.774, 2.7215, 2.7471, 2.7407, 2.7244, 2.7287, 2.7192, 2.7141,
          2.7102, 2.7668, 2.7641, 2.7318, 2.7383, 2.7112, 2.7292, 2.7089, 2.723,
          2.7581,
        ],
        lat: [
          51.712, 51.709, 51.7278, 51.7387, 51.7232, 51.7155, 51.6933, 51.706,
          51.7358, 51.7296, 51.7275, 51.7325, 51.7166, 51.7044, 51.7044,
          51.6986, 51.7098, 51.6975, 51.7017, 51.7318, 51.7131, 51.7115,
          51.7216, 51.7204, 51.7261, 51.7135, 51.7185, 51.7232,
        ],
      },
    ],
    "Moneypoint Power Station": [
      {
        ids: ["1", "2", "3", "4", "5", "6", "7", "8"],
        lon: [
          -9.4151, -9.4325, -9.4365, -9.4308, -9.4363, -9.4234, -9.4244,
          -9.4254,
        ],
        lat: [
          52.605, 52.6104, 52.6075, 52.6066, 52.6107, 52.6066, 52.6068, 52.607,
        ],
      },
    ],
    "Moray East Wind Farm": [
      {
        ids: [
          "osp",
          "osp",
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
          "92",
          "93",
          "94",
          "95",
          "96",
          "97",
          "98",
          "99",
          "100",
        ],
        lon: [
          -2.7373, -2.7639, -2.6587, -2.5794, -2.5801, -2.5797, -2.5799,
          -2.6056, -2.6056, -2.6056, -2.6323, -2.6328, -2.6323, -2.6323,
          -2.6319, -2.6321, -2.6851, -2.6592, -2.6592, -2.6844, -2.6848,
          -2.6846, -2.685, -2.6844, -2.6848, -2.6846, -2.7112, -2.711, -2.7109,
          -2.711, -2.7116, -2.711, -2.7104, -2.711, -2.7377, -2.7373, -2.7637,
          -2.7637, -2.7898, -2.7902, -2.7897, -2.8163, -2.8166, -2.8161, -2.842,
          -2.8425, -2.8425, -2.8427, -2.8691, -2.8686, -2.7898, -2.7897, -2.79,
          -2.79, -2.7904, -2.79, -2.8166, -2.8163, -2.8166, -2.8163, -2.8161,
          -2.8163, -2.6321, -2.711, -2.6848, -2.7637, -2.7898, -2.7897, -2.79,
          -2.7898, -2.79, -2.8161, -2.8161, -2.8163, -2.8163, -2.8424, -2.6844,
          -2.631, -2.8425, -2.6846, -2.7104, -2.6056, -2.632, -2.7108, -2.6319,
          -2.6319, -2.658, -2.6026, -2.7635, -2.6843, -2.6581, -2.6845, -2.6582,
          -2.7636, -2.6844, -2.7103, -2.7106, -2.7372, -2.7105, -2.6842,
          -2.7107, -2.7106, -2.6841,
        ],
        lat: [
          58.2166, 58.1258, 58.1651, 58.1547, 58.1752, 58.1851, 58.1952,
          58.1752, 58.1651, 58.1545, 58.1347, 58.1448, 58.1549, 58.1654,
          58.1855, 58.1954, 58.196, 58.1245, 58.1349, 58.1758, 58.1659, 58.1549,
          58.1454, 58.1351, 58.1252, 58.1149, 58.1155, 58.1253, 58.1356,
          58.1453, 58.1557, 58.1658, 58.1758, 58.1963, 58.1462, 58.105, 58.1053,
          58.1159, 58.1057, 58.1156, 58.1259, 58.0857, 58.1057, 58.1157, 58.116,
          58.106, 58.0958, 58.0856, 58.0761, 58.0858, 58.187, 58.177, 58.1667,
          58.1562, 58.1462, 58.1363, 58.1363, 58.1466, 58.1564, 58.1669,
          58.1766, 58.1869, 58.2057, 58.2166, 58.2061, 58.207, 58.2374, 58.2272,
          58.2168, 58.207, 58.1969, 58.1971, 58.2072, 58.2174, 58.2274, 58.1973,
          58.2366, 58.2364, 58.2075, 58.2264, 58.2267, 58.2256, 58.2262,
          58.2364, 58.2563, 58.2462, 58.2562, 58.2365, 58.2473, 58.2464,
          58.2667, 58.2667, 58.2465, 58.2577, 58.2566, 58.2569, 58.247, 58.2775,
          58.2671, 58.2769, 58.2772, 58.2873, 58.2872,
        ],
      },
    ],
    "Neart na Gaoithe Wind Farm": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
        ],
        lon: [
          -2.2974, -2.272, -2.1653, -2.332, -2.168, -2.2022, -2.2, -2.2503,
          -2.1919, -2.2641, -2.207, -2.2119, -2.2581, -2.2878, -2.2233, -2.1595,
          -2.1939, -2.1838, -2.2316, -2.2468, -2.3049, -2.1875, -2.3254,
          -2.2687, -2.2045, -2.261, -2.2496, -2.3336, -2.2236, -2.298, -2.1897,
          -2.2155, -2.2793, -2.2728, -2.3111, -2.3247, -2.3082, -2.3097,
          -2.2387, -2.2078, -2.2831, -2.289, -2.3023, -2.2311, -2.1739, -2.2494,
          -2.2197, -2.2637, -2.2453, -2.2498, -2.2756, -2.2101, -2.2705,
          -2.1759,
        ],
        lat: [
          56.2359, 56.2729, 56.2132, 56.2937, 56.2554, 56.2458, 56.2869,
          56.2346, 56.279, 56.2658, 56.2138, 56.2773, 56.2971, 56.2277, 56.3098,
          56.2253, 56.2388, 56.271, 56.2363, 56.3324, 56.2859, 56.2138, 56.2849,
          56.2887, 56.2699, 56.2812, 56.2899, 56.2748, 56.2291, 56.2787,
          56.2546, 56.3022, 56.2208, 56.2351, 56.3095, 56.2579, 56.2445,
          56.2525, 56.3248, 56.2946, 56.2643, 56.2498, 56.3021, 56.3174,
          56.2401, 56.2136, 56.2849, 56.2282, 56.2671, 56.3145, 56.3131,
          56.2533, 56.2137, 56.2635,
        ],
      },
    ],
    "Njord A": [
      {
        ids: ["1", "2", "3", "4"],
        lon: [7.2001, 7.2001, 7.2001, 7.2001],
        lat: [64.2707, 64.2707, 64.2707, 64.2707],
      },
    ],
    Nobelwind: [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
        ],
        lon: [
          2.8254, 2.8016, 2.7664, 2.7677, 2.8339, 2.8662, 2.8012, 2.7853,
          2.7966, 2.8046, 2.808, 2.814, 2.8096, 2.8056, 2.8211, 2.8162, 2.8107,
          2.8139, 2.8203, 2.825, 2.8259, 2.8171, 2.8205, 2.8299, 2.8405, 2.8469,
          2.8341, 2.8239, 2.8321, 2.8272, 2.8305, 2.8402, 2.8485, 2.8533,
          2.8598, 2.8551, 2.848, 2.8435, 2.8391, 2.8338, 2.8466, 2.8515, 2.8556,
          2.8614, 2.8064, 2.7979, 2.7916, 2.7791, 2.7727, 2.7695, 2.7994,
        ],
        lat: [
          51.69, 51.6213, 51.6528, 51.6489, 51.6467, 51.6722, 51.7112, 51.6848,
          51.63, 51.6298, 51.6263, 51.6317, 51.6343, 51.6375, 51.6365, 51.6394,
          51.6427, 51.6483, 51.6454, 51.6424, 51.6495, 51.6538, 51.6594,
          51.6545, 51.6519, 51.6569, 51.6593, 51.665, 51.6673, 51.6709, 51.6766,
          51.6691, 51.6649, 51.662, 51.6671, 51.6699, 51.6725, 51.6756, 51.6788,
          51.6822, 51.6839, 51.681, 51.6783, 51.6751, 51.708, 51.706, 51.6952,
          51.6743, 51.6635, 51.6583, 51.6251,
        ],
      },
    ],
    Nordergründe: [
      {
        ids: ["1", "2", "3", "4", "5"],
        lon: [8.1763, 8.1641, 8.1738, 8.1711, 8.166],
        lat: [53.8255, 53.841, 53.8291, 53.8333, 53.8364],
      },
    ],
    "Nordsee One": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
        ],
        lon: [
          6.8365, 6.7689, 6.7696, 6.7803, 6.7812, 6.8038, 6.7923, 6.7916,
          6.8029, 6.802, 6.8193, 6.8145, 6.818, 6.83, 6.827, 6.8322, 6.8456,
          6.8444, 6.8571, 6.8695, 6.8819, 6.8945, 6.8695, 6.8818, 6.8945,
          6.8946, 6.9031, 6.8909, 6.8822, 6.7476, 6.7585, 6.8284, 6.8154,
          6.8695, 6.8427, 6.8579, 6.7923, 6.7858, 6.8777, 6.865, 6.852, 6.7584,
          6.7477, 6.774, 6.7711, 6.7478, 6.841, 6.7611, 6.804, 6.7809, 6.7477,
          6.7477, 6.7695, 6.7593, 6.7476,
        ],
        lat: [
          53.9824, 53.9926, 53.9856, 53.9907, 53.9836, 53.9794, 53.9813,
          53.9887, 53.9868, 53.9944, 53.9919, 53.9846, 53.9776, 53.9783,
          53.9853, 53.9925, 53.9873, 53.9929, 53.9936, 53.9946, 53.9956,
          53.9974, 53.9862, 53.9884, 53.9901, 53.9827, 53.9769, 53.9749,
          53.9809, 53.9939, 53.9877, 53.971, 53.9701, 53.9791, 53.9792, 53.9806,
          53.9741, 53.9662, 53.973, 53.9709, 53.9689, 53.9802, 53.9822, 53.9634,
          53.9689, 53.9614, 53.9717, 53.9626, 53.9721, 53.9762, 53.973, 53.9673,
          53.9783, 53.9709, 53.9882,
        ],
      },
    ],
    "Nordsee Ost": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
        ],
        lon: [
          7.6792, 7.6405, 7.7392, 7.6827, 7.6404, 7.6967, 7.6404, 7.6545,
          7.6686, 7.6827, 7.7108, 7.6405, 7.6968, 7.7249, 7.6405, 7.6546,
          7.6687, 7.7109, 7.6405, 7.7249, 7.6405, 7.6547, 7.6687, 7.6827,
          7.6968, 7.7108, 7.6405, 7.725, 7.6405, 7.6547, 7.6687, 7.6968, 7.6829,
          7.7109, 7.6405, 7.7253, 7.6547, 7.6689, 7.683, 7.6972, 7.7113, 7.6404,
          7.6545, 7.6404, 7.6686, 7.6404, 7.6546, 7.6828, 7.7392,
        ],
        lat: [
          54.4416, 54.4708, 54.4719, 54.4173, 54.4223, 54.4229, 54.4277,
          54.4279, 54.428, 54.4281, 54.4285, 54.4331, 54.4337, 54.434, 54.4385,
          54.4386, 54.4388, 54.4393, 54.4439, 54.4449, 54.4493, 54.4494,
          54.4495, 54.4497, 54.4498, 54.45, 54.4547, 54.4557, 54.4601, 54.4602,
          54.4604, 54.4607, 54.4605, 54.4609, 54.4654, 54.4663, 54.471, 54.4712,
          54.4713, 54.4715, 54.4716, 54.4062, 54.4062, 54.4116, 54.4118,
          54.4169, 54.417, 54.4389, 54.4612,
        ],
      },
    ],
    Norne: [
      {
        ids: ["1", "2", "3", "4"],
        lon: [8.0866, 8.0866, 8.0866, 8.0866],
        lat: [66.0271, 66.027, 66.027, 66.027],
      },
    ],
    "North Hoyle Offshore Wind Farm": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
        ],
        lon: [
          -3.4714, -3.4508, -3.4458, -3.4273, -3.4241, -3.4221, -3.4693,
          -3.4744, -3.4232, -3.4252, -3.437, -3.436, -3.4262, -3.4478, -3.4468,
          -3.4498, -3.4626, -3.4617, -3.4606, -3.4349, -3.4339, -3.4704,
          -3.4724, -3.439, -3.4381, -3.4488, -3.4595, -3.4576, -3.4586, -3.4734,
        ],
        lat: [
          53.4137, 53.4257, 53.4103, 53.4285, 53.4193, 53.4131, 53.4075, 53.423,
          53.4162, 53.4224, 53.4209, 53.4179, 53.4254, 53.4165, 53.4134,
          53.4226, 53.4243, 53.4212, 53.4182, 53.4148, 53.4117, 53.4106,
          53.4168, 53.4271, 53.424, 53.4195, 53.4151, 53.4089, 53.412, 53.4199,
        ],
      },
    ],
    "North Wall Power Station": [
      {
        ids: ["1", "2", "3", "4", "5", "6"],
        lon: [-6.2112, -6.211, -6.2108, -6.2111, -6.2112, -6.2109],
        lat: [53.3496, 53.3496, 53.3495, 53.3494, 53.3494, 53.3494],
      },
    ],
    Norther: [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
        ],
        lon: [
          2.9915, 3.0491, 3.0337, 3.067, 3.0795, 3.049, 3.0588, 3.0402, 3.0661,
          3.043, 3.0735, 3.0592, 3.063, 3.0195, 3.0452, 3.0513, 3.0109, 2.9968,
          3.0233, 3.0405, 3.0312, 3.0252, 3.0069, 3.0312, 3.0071, 2.9816,
          2.9923, 3.0065, 3.0009, 3.0178, 2.9854, 2.9681, 2.9708, 3.019, 2.9582,
          2.9566, 2.9973, 2.9854, 2.9388, 2.9844, 2.9518, 2.9742, 2.9463,
          2.9692, 2.9737,
        ],
        lat: [
          51.5135, 51.5625, 51.5715, 51.5429, 51.5444, 51.5508, 51.5564,
          51.5664, 51.5507, 51.5561, 51.538, 51.5357, 51.5303, 51.5478, 51.5168,
          51.5429, 51.5333, 51.5382, 51.5413, 51.5221, 51.5276, 51.5335,
          51.5452, 51.5098, 51.5204, 51.525, 51.5185, 51.5039, 51.5112, 51.5053,
          51.5105, 51.5168, 51.5262, 51.5142, 51.5002, 51.5062, 51.4988,
          51.5017, 51.5127, 51.494, 51.5139, 51.5021, 51.5079, 51.4956, 51.4903,
        ],
      },
    ],
    "Northwester 2": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
        ],
        lon: [
          2.7593, 2.7701, 2.7512, 2.7615, 2.7576, 2.7409, 2.734, 2.7289, 2.7235,
          2.7358, 2.7467, 2.7594, 2.754, 2.765, 2.7731, 2.7671, 2.7778, 2.7819,
          2.7389, 2.745, 2.7491, 2.7443, 2.7652, 2.7531,
        ],
        lat: [
          51.6882, 51.689, 51.6583, 51.675, 51.6686, 51.684, 51.676, 51.6831,
          51.6883, 51.6929, 51.6995, 51.7027, 51.6957, 51.6963, 51.6941,
          51.7069, 51.7031, 51.7092, 51.6709, 51.6668, 51.6776, 51.6905,
          51.6813, 51.6852,
        ],
      },
    ],
    Northwind: [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
        ],
        lon: [
          2.9177, 2.886, 2.859, 2.9111, 2.9435, 2.8683, 2.8762, 2.912, 2.8831,
          2.8617, 2.8635, 2.8685, 2.8832, 2.8773, 2.872, 2.8682, 2.8727, 2.8771,
          2.8815, 2.888, 2.896, 2.8898, 2.8852, 2.881, 2.8774, 2.8875, 2.8919,
          2.8965, 2.903, 2.9045, 2.9001, 2.8957, 2.8912, 2.8906, 2.8942, 2.8994,
          2.9038, 2.9082, 2.9148, 2.902, 2.9062, 2.9106, 2.9149, 2.9194, 2.9238,
          2.929, 2.935, 2.9275, 2.923, 2.9186, 2.9143, 2.91, 2.9033, 2.9092,
          2.9135, 2.918, 2.9224, 2.9269, 2.9312, 2.9355, 2.9393, 2.9348, 2.9305,
          2.926, 2.9216, 2.9172, 2.923, 2.9163, 2.9119, 2.9075, 2.9031, 2.8986,
          2.8951,
        ],
        lat: [
          51.6198, 51.6235, 51.6013, 51.6096, 51.6258, 51.597, 51.5977, 51.6447,
          51.6196, 51.5984, 51.6051, 51.6017, 51.6005, 51.6027, 51.6057,
          51.6089, 51.6126, 51.6093, 51.6066, 51.6041, 51.6056, 51.608, 51.6107,
          51.6132, 51.6164, 51.6163, 51.6137, 51.611, 51.6084, 51.6122, 51.6149,
          51.6175, 51.6201, 51.6271, 51.624, 51.6214, 51.6187, 51.616, 51.6134,
          51.6337, 51.6301, 51.6275, 51.625, 51.6222, 51.6196, 51.6175, 51.6209,
          51.6235, 51.6261, 51.6287, 51.6314, 51.634, 51.6376, 51.6409, 51.6379,
          51.6353, 51.6326, 51.6299, 51.6273, 51.6246, 51.6284, 51.6311,
          51.6336, 51.6364, 51.639, 51.6416, 51.6144, 51.6172, 51.6198, 51.6225,
          51.6251, 51.6278, 51.6308,
        ],
      },
    ],
    "Offshore-Windpark-Albatros": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
        ],
        lon: [
          6.2706, 6.2162, 6.2334, 6.2472, 6.2581, 6.2698, 6.2972, 6.2831,
          6.2586, 6.2479, 6.2349, 6.2474, 6.2357, 6.2029, 6.2334, 6.2111,
          6.2206,
        ],
        lat: [
          54.4869, 54.4963, 54.4755, 54.4819, 54.4932, 54.4943, 54.4943,
          54.4868, 54.4724, 54.4661, 54.4898, 54.4954, 54.4971, 54.4951,
          54.4827, 54.4898, 54.4837,
        ],
      },
    ],
    "Offshore Windpark Egmond aan Zee": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
        ],
        lon: [
          4.4355, 4.3668, 4.4492, 4.4431, 4.4369, 4.4308, 4.4037, 4.4098,
          4.4159, 4.4221, 4.4281, 4.4599, 4.4425, 4.3965, 4.4149, 4.4087,
          4.4026, 4.4444, 4.3975, 4.4313, 4.4374, 4.4139, 4.4331, 4.4262, 4.42,
          4.4252, 4.4191, 4.4077, 4.4538, 4.3729, 4.3792, 4.3852, 4.3913,
          4.4486, 4.4548, 4.421,
        ],
        lat: [
          52.5796, 52.6274, 52.5819, 52.5863, 52.5907, 52.5952, 52.6008,
          52.5964, 52.592, 52.5875, 52.5832, 52.602, 52.6007, 52.6199, 52.6067,
          52.6111, 52.6155, 52.6132, 52.6053, 52.6227, 52.6183, 52.6213,
          52.6075, 52.6125, 52.6169, 52.6271, 52.6315, 52.6257, 52.6065, 52.623,
          52.6186, 52.6141, 52.6097, 52.5962, 52.5918, 52.6022,
        ],
      },
    ],
    "Ormonde Wind Farm": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
        ],
        lon: [
          -3.4112, -3.4311, -3.4199, -3.4372, -3.4258, -3.4432, -3.4554,
          -3.4096, -3.4266, -3.4439, -3.4615, -3.4154, -3.4326, -3.4499,
          -3.4677, -3.4212, -3.456, -3.4385, -3.4271, -3.462, -3.4444, -3.4329,
          -3.4503, -3.4388, -3.4446, -3.4147, -3.4319, -3.4493, -3.4037,
          -3.4207, -3.4379,
        ],
        lat: [
          54.0785, 54.0712, 54.0743, 54.0744, 54.0775, 54.0776, 54.0839,
          54.0868, 54.0871, 54.0872, 54.087, 54.0902, 54.0904, 54.0904, 54.0902,
          54.0936, 54.0937, 54.0937, 54.0969, 54.0969, 54.097, 54.1003, 54.1003,
          54.1037, 54.1071, 54.0805, 54.0807, 54.0808, 54.0834, 54.0838,
          54.0839,
        ],
      },
    ],
    "Oseberg C": [
      {
        ids: ["1", "2", "3", "4"],
        lon: [2.7742, 2.7742, 2.7742, 2.7742],
        lat: [60.6078, 60.6078, 60.6078, 60.6078],
      },
    ],
    "Oskarshamns kärnkraftverk": [
      {
        ids: ["1", "2", "3"],
        lon: [16.6742, 16.668, 16.6666],
        lat: [57.4163, 57.4131, 57.4127],
      },
    ],
    "Parc éolien de Fécamp": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
        ],
        lon: [
          0.2685, 0.2827, 0.2465, 0.2317, 0.2172, 0.2022, 0.1875, 0.173, 0.1766,
          0.1912, 0.206, 0.2207, 0.2353, 0.2501, 0.2647, 0.2683, 0.2536, 0.2389,
          0.2243, 0.2097, 0.1948, 0.1802, 0.1838, 0.1984, 0.2131, 0.2285,
          0.2426, 0.2573, 0.2719, 0.2756, 0.2609, 0.2468, 0.2184, 0.2021,
          0.1874, 0.191, 0.2057, 0.2205, 0.2351, 0.2644, 0.2793, 0.2829, 0.2682,
          0.2388, 0.224, 0.2094, 0.1943, 0.2131, 0.2277, 0.2424, 0.257, 0.2717,
          0.2865, 0.2901, 0.2754, 0.2607, 0.246, 0.2313, 0.2166, 0.2679, 0.2937,
          0.279, 0.2643, 0.2497, 0.235, 0.2574, 0.2428, 0.2282, 0.2135, 0.1987,
          0.184, 0.2609,
        ],
        lat: [
          49.9022, 49.9578, 49.8621, 49.8597, 49.8572, 49.8546, 49.8522,
          49.8496, 49.8592, 49.8618, 49.8643, 49.8668, 49.8694, 49.8719,
          49.8743, 49.8839, 49.8813, 49.8789, 49.8764, 49.8738, 49.8714,
          49.8688, 49.8783, 49.8809, 49.8834, 49.886, 49.8884, 49.891, 49.8934,
          49.903, 49.9005, 49.8981, 49.8937, 49.8905, 49.8879, 49.8975, 49.9001,
          49.9025, 49.9051, 49.91, 49.9125, 49.9221, 49.9195, 49.9146, 49.912,
          49.9095, 49.9069, 49.9191, 49.9217, 49.9242, 49.9267, 49.9292,
          49.9317, 49.9412, 49.9387, 49.9361, 49.9337, 49.9312, 49.9287,
          49.9553, 49.9508, 49.9483, 49.9457, 49.9433, 49.9407, 49.8552,
          49.8528, 49.8502, 49.8477, 49.8452, 49.8427, 49.8647,
        ],
      },
    ],
    "Parc éolien de Saint-Brieuc": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
        ],
        lon: [
          -2.533, -2.4909, -2.5154, -2.499, -2.4894, -2.5256, -2.5298, -2.5201,
          -2.479, -2.4697, -2.5177, -2.5275, -2.5143, -2.5224, -2.5036, -2.5117,
          -2.5012, -2.5247, -2.5093, -2.5166, -2.5059, -2.5578, -2.4931,
          -2.5712, -2.4824, -2.5374, -2.5273, -2.5796, -2.5689, -2.4593,
          -2.5346, -2.5481, -2.5877, -2.5982, -2.56, -2.5458, -2.5493, -2.5561,
          -2.5889, -2.5669, -2.5773, -2.5435, -2.5643, -2.5539, -2.562, -2.5728,
          -2.575, -2.5854, -2.55, -2.5831, -2.5931, -2.5517, -2.5959, -2.6069,
          -2.5405, -2.5597, -2.5808, -2.5705, -2.5363, -2.547, -2.5574, -2.5382,
          -2.5679,
        ],
        lat: [
          48.8492, 48.8224, 48.8891, 48.8446, 48.8385, 48.8963, 48.8656,
          48.8582, 48.8163, 48.8087, 48.8741, 48.8807, 48.8203, 48.8427,
          48.8136, 48.836, 48.8291, 48.8273, 48.8512, 48.8051, 48.7981, 48.8326,
          48.8068, 48.8234, 48.8001, 48.8185, 48.8115, 48.8458, 48.8388, 48.802,
          48.8337, 48.8254, 48.868, 48.8747, 48.8171, 48.8409, 48.8105, 48.8476,
          48.8532, 48.8543, 48.8613, 48.8563, 48.87, 48.8631, 48.8853, 48.8922,
          48.8767, 48.8834, 48.8935, 48.8988, 48.9061, 48.8785, 48.8903,
          48.8968, 48.8723, 48.9007, 48.9144, 48.9077, 48.9028, 48.9095,
          48.9164, 48.8875, 48.9231,
        ],
      },
    ],
    "Parc éolien de Saint-Nazaire": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
        ],
        lon: [
          -2.6038, -2.6603, -2.6905, -2.6727, -2.6543, -2.6775, -2.6638,
          -2.6475, -2.6877, -2.6642, -2.6759, -2.6525, -2.6408, -2.5712,
          -2.6867, -2.559, -2.6741, -2.6329, -2.5754, -2.5972, -2.5319, -2.6089,
          -2.6212, -2.6907, -2.6597, -2.5462, -2.6452, -2.5853, -2.6598,
          -2.6305, -2.5272, -2.5154, -2.5439, -2.5704, -2.6144, -2.5598,
          -2.6442, -2.6755, -2.598, -2.6945, -2.5082, -2.555, -2.6819, -2.6283,
          -2.5391, -2.5811, -2.6688, -2.525, -2.6116, -2.5646, -2.6551, -2.5124,
          -2.5001, -2.6415, -2.6279, -2.6141, -2.6003, -2.5851, -2.6987,
          -2.6872, -2.5884, -2.5763, -2.5642, -2.5528, -2.6748, -2.6504,
          -2.6381, -2.6009, -2.6132, -2.6257, -2.6626, -2.5673, -2.5396,
          -2.5923, -2.5745, -2.5441, -2.5578, -2.5478, -2.5273, -2.5145,
          -2.5313,
        ],
        lat: [
          47.1528, 47.2088, 47.202, 47.2059, 47.2008, 47.1982, 47.1952, 47.1927,
          47.1924, 47.1855, 47.1888, 47.1824, 47.1841, 47.1787, 47.1797,
          47.1757, 47.1766, 47.1767, 47.1704, 47.1669, 47.1688, 47.1701,
          47.1735, 47.1694, 47.1731, 47.1723, 47.1694, 47.1631, 47.1619,
          47.1655, 47.1608, 47.165, 47.1635, 47.1602, 47.1617, 47.1664, 47.1584,
          47.1657, 47.1573, 47.1582, 47.1577, 47.1574, 47.1557, 47.1548,
          47.1545, 47.1531, 47.153, 47.1517, 47.1508, 47.1502, 47.1503, 47.1499,
          47.1487, 47.1476, 47.1447, 47.1417, 47.1392, 47.1361, 47.1473,
          47.1452, 47.1275, 47.1253, 47.123, 47.1206, 47.1431, 47.1386, 47.1364,
          47.1297, 47.1319, 47.1341, 47.1409, 47.1327, 47.1276, 47.1464,
          47.1428, 47.1372, 47.1399, 47.1471, 47.134, 47.1409, 47.1441,
        ],
      },
    ],
    "Poolbeg Power Station": [
      {
        ids: ["1", "2", "3"],
        lon: [-6.1864, -6.1865, -6.1863],
        lat: [53.3402, 53.3393, 53.3397],
      },
    ],
    "Prinses Amaliawindpark": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
        ],
        lon: [
          4.2354, 4.2408, 4.2124, 4.2033, 4.196, 4.2261, 4.2193, 4.1888, 4.2174,
          4.2469, 4.2329, 4.2105, 4.2245, 4.2102, 4.2173, 4.203, 4.2246, 4.1886,
          4.1888, 4.2102, 4.2387, 4.2458, 4.2387, 4.2318, 4.2458, 4.1958,
          4.2317, 4.2105, 4.2316, 4.196, 4.2458, 4.2035, 4.2031, 4.2246, 4.2528,
          4.2334, 4.2388, 4.197, 4.2528, 4.2179, 4.2402, 4.2176, 4.204, 4.2267,
          4.2599, 4.1964, 4.2319, 4.2249, 4.253, 4.2602, 4.2533, 4.2254, 4.2185,
          4.2392, 4.2397, 4.211, 4.2461, 4.2116, 4.2047, 4.2465, 4.2323,
        ],
        lat: [
          52.5855, 52.6046, 52.6054, 52.5757, 52.5779, 52.6, 52.6027, 52.5801,
          52.5761, 52.5969, 52.5973, 52.5735, 52.5789, 52.5784, 52.5812,
          52.5807, 52.5739, 52.5853, 52.5907, 52.5836, 52.5741, 52.5767,
          52.5792, 52.5716, 52.5718, 52.583, 52.5816, 52.589, 52.5765, 52.5883,
          52.5817, 52.5914, 52.586, 52.5841, 52.5792, 52.6024, 52.5843, 52.5994,
          52.5743, 52.5919, 52.5996, 52.5865, 52.5969, 52.6052, 52.5816,
          52.5938, 52.5868, 52.5893, 52.5842, 52.5866, 52.5893, 52.5947,
          52.5973, 52.5895, 52.5946, 52.5944, 52.5868, 52.5999, 52.6025,
          52.5919, 52.5921,
        ],
      },
    ],
    "Race Bank Wind Farm": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
        ],
        lon: [
          0.9312, 0.9246, 0.9197, 0.9127, 0.9065, 0.8792, 0.8912, 0.9009,
          0.9007, 0.9113, 0.8781, 0.868, 0.889, 0.9074, 0.9175, 0.891, 0.889,
          0.8545, 0.8661, 0.8773, 0.894, 0.9001, 0.9047, 0.8903, 0.8802, 0.8814,
          0.8653, 0.8542, 0.8419, 0.8545, 0.8402, 0.8915, 0.8785, 0.8693,
          0.8265, 0.8437, 0.8267, 0.8538, 0.8676, 0.8159, 0.816, 0.8686, 0.8799,
          0.8325, 0.8444, 0.8568, 0.8567, 0.8445, 0.8323, 0.8182, 0.845, 0.8565,
          0.8129, 0.8322, 0.8083, 0.8209, 0.8312, 0.8182, 0.8445, 0.8043,
          0.7951, 0.7824, 0.7792, 0.8217, 0.7962, 0.7834, 0.7942, 0.8131,
          0.7848, 0.8139, 0.8279, 0.7714, 0.7968, 0.7718, 0.8346, 0.7986,
          0.7833, 0.7723, 0.8272, 0.8012, 0.7736, 0.8233, 0.8147, 0.7735,
          0.7971, 0.8115, 0.8042, 0.7845, 0.8187, 0.7891, 0.7766,
        ],
        lat: [
          53.2616, 53.2554, 53.2488, 53.2414, 53.2347, 53.2271, 53.2231, 53.228,
          53.2444, 53.2588, 53.2352, 53.2306, 53.2323, 53.251, 53.2658, 53.2395,
          53.2474, 53.2349, 53.238, 53.2437, 53.2543, 53.262, 53.2696, 53.2664,
          53.2604, 53.2531, 53.2466, 53.2426, 53.2392, 53.2504, 53.2468,
          53.2746, 53.2692, 53.2646, 53.2441, 53.2545, 53.2525, 53.2588, 53.273,
          53.2481, 53.257, 53.2808, 53.2781, 53.259, 53.2635, 53.2677, 53.2772,
          53.2718, 53.2677, 53.2639, 53.2805, 53.2849, 53.2707, 53.2752,
          53.2843, 53.2865, 53.2825, 53.2779, 53.2889, 53.2766, 53.2813,
          53.2818, 53.2893, 53.2943, 53.2963, 53.2967, 53.2888, 53.3014,
          53.3042, 53.3095, 53.3056, 53.296, 53.3042, 53.3035, 53.2989, 53.3118,
          53.3116, 53.3116, 53.3152, 53.3203, 53.3195, 53.3251, 53.3188,
          53.3267, 53.3288, 53.3285, 53.335, 53.3271, 53.3349, 53.3347, 53.3341,
        ],
      },
    ],
    Rentel: [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
        ],
        lon: [
          2.9189, 2.9554, 2.9617, 2.9686, 2.975, 2.9815, 2.9869, 2.9944, 3.001,
          2.9874, 2.9803, 2.9735, 2.9527, 2.9458, 2.936, 2.9515, 2.9673, 2.9754,
          2.9647, 2.9569, 2.9492, 2.9411, 2.9337, 2.9249, 2.9134, 2.9212,
          2.9274, 2.9483, 2.9551, 2.9469, 2.9391, 2.9313, 2.9236, 2.9162,
          2.9076, 2.8998, 2.8907, 2.8974, 2.9049, 2.9135, 2.921, 2.9297, 2.9385,
        ],
        lat: [
          51.5756, 51.618, 51.6141, 51.6108, 51.607, 51.6032, 51.5988, 51.5953,
          51.5913, 51.5886, 51.5927, 51.5976, 51.6095, 51.613, 51.6074, 51.5984,
          51.5895, 51.5852, 51.5803, 51.5847, 51.5891, 51.5939, 51.5985, 51.603,
          51.5985, 51.5948, 51.5907, 51.5788, 51.5747, 51.5693, 51.5735,
          51.5778, 51.5822, 51.5865, 51.5907, 51.595, 51.5908, 51.5862, 51.5814,
          51.577, 51.5722, 51.5683, 51.564,
        ],
      },
    ],
    "Rhyl Flats Wind Farm": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
        ],
        lon: [
          -3.6393, -3.6099, -3.6153, -3.6529, -3.6905, -3.686, -3.6242, -3.6459,
          -3.6224, -3.6436, -3.6506, -3.6582, -3.6646, -3.6452, -3.6517,
          -3.6841, -3.6648, -3.6314, -3.6387, -3.6719, -3.6789, -3.6711,
          -3.6776, -3.6295, -3.6365,
        ],
        lat: [
          53.3687, 53.3816, 53.3731, 53.3913, 53.3802, 53.389, 53.3849, 53.3897,
          53.3747, 53.3795, 53.381, 53.373, 53.3744, 53.3701, 53.3715, 53.3788,
          53.3842, 53.3865, 53.3881, 53.3858, 53.3875, 53.3759, 53.3773,
          53.3763, 53.3779,
        ],
      },
    ],
    Riffgat: [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
        ],
        lon: [
          6.4846, 6.4408, 6.449, 6.4571, 6.4653, 6.4735, 6.4387, 6.4816, 6.4469,
          6.4898, 6.4551, 6.4979, 6.4632, 6.5061, 6.4714, 6.4367, 6.5142,
          6.4795, 6.4448, 6.4877, 6.453, 6.4958, 6.4611, 6.504, 6.4693, 6.5121,
          6.4774, 6.4856, 6.4937, 6.5019, 6.51,
        ],
        lat: [
          53.6909, 53.682, 53.6831, 53.6843, 53.6854, 53.6866, 53.6873, 53.6877,
          53.6884, 53.6889, 53.6895, 53.69, 53.6907, 53.6912, 53.6918, 53.6925,
          53.6923, 53.693, 53.6936, 53.6941, 53.6948, 53.6953, 53.6959, 53.6964,
          53.6971, 53.6976, 53.6982, 53.6994, 53.7005, 53.7017, 53.7028,
        ],
      },
    ],
    "Robin Rigg": [
      {
        ids: [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
          21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37,
          38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54,
          55, 56, 57, 58, 59, 60,
        ],
        lon: [
          -3.705681343933151, -3.689924371795314, -3.7120819328345083,
          -3.6962767647534345, -3.7031312337116167, -3.7093406314016026,
          -3.693493134709485, -3.7005546366760877, -3.717667800747392,
          -3.702130266834644, -3.724151621947271, -3.708324744736956,
          -3.730887067880388, -3.7149509744717477, -3.698757808092779,
          -3.7215278347968592, -3.705483363380182, -3.7281625302538544,
          -3.734776270952921, -3.7122076613821093, -3.6962939476549366,
          -3.7188270179563, -3.703017323647486, -3.72545207422462,
          -3.709488236716482, -3.6978433424565647, -3.7318705165797605,
          -3.7162646701561357, -3.70043251234685, -3.738898910028809,
          -3.72254875060338, -3.706741906141673, -3.7291872179168033,
          -3.7135976323853583, -3.7015762230348916, -3.7364331217532087,
          -3.719958574884715, -3.7042820690173532, -3.743142248510395,
          -3.7265585692625796, -3.7110057802860577, -3.717367309518607,
          -3.733255542260139, -3.7082875290868738, -3.7401303792430554,
          -3.7241616802310777, -3.7145241679621677, -3.731056549962574,
          -3.72163445260523, -3.7368001652932032, -3.728341902981754,
          -3.7188049735509305, -3.683851766765145, -3.6926422038993105,
          -3.6913609461802253, -3.6862095123088063, -3.6953771350858062,
          -3.6988203370904675, -3.6886491490464834, -3.6938638662868755,
        ],
        lat: [
          54.764010495874345, 54.7644553234758, 54.76557967196749,
          54.76591922286505, 54.7676205815712, 54.76916603687833,
          54.76991755831689, 54.77149343993252, 54.73532167206334,
          54.736549034145035, 54.73681549484693, 54.73785309064078,
          54.73832658435105, 54.73935303221369, 54.74039205293107,
          54.740799161968226, 54.74180976803384, 54.742144457427564,
          54.74364733266657, 54.74340123998941, 54.743962743682985,
          54.74496605749287, 54.74555756839982, 54.746413863628014,
          54.747157589896595, 54.75282719302219, 54.74788069668337,
          54.748739925577695, 54.74910889695539, 54.74972119498224,
          54.750319998144946, 54.75080656762418, 54.75182186755556,
          54.75249694603718, 54.75846678893444, 54.7534171951866,
          54.75405824314129, 54.75463542099383, 54.7551038855622,
          54.7556669818174, 54.75625765453405, 54.757808474259214,
          54.757753321336295, 54.76011928114509, 54.75888496208374,
          54.75952785405719, 54.76171184274806, 54.76117347310721,
          54.76349567938147, 54.76263083461202, 54.765176753881946,
          54.76725085582222, 54.762414832967245, 54.76069486643602,
          54.75125868748134, 54.75895595680363, 54.75692208799859,
          54.76222674306001, 54.75523095521431, 54.74750778581119,
        ],
      },
    ],
    "Rørvikbekken mikrokraftverk": [
      {
        ids: ["1", "2", "3", "4"],
        lon: [10.1334, 10.1333, 10.1333, 10.1334],
        lat: [63.5095, 63.5095, 63.5095, 63.5095],
      },
    ],
    "Samsø Havvind": [
      {
        ids: ["1", "2", "3", "4", "5", "6", "7", "8", "9"],
        lon: [
          10.5847, 10.5847, 10.5847, 10.5847, 10.5847, 10.5847, 10.5847,
          10.5847, 10.5847,
        ],
        lat: [
          55.711, 55.7137, 55.7245, 55.7353, 55.7164, 55.7299, 55.7326, 55.7218,
          55.7272,
        ],
      },
    ],
    Sandbank: [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
        ],
        lon: [
          6.8553, 6.8224, 6.8378, 6.8192, 6.8312, 6.8348, 6.8162, 6.8255,
          6.8449, 6.8408, 6.8324, 6.8391, 6.8483, 6.851, 6.8357, 6.8607, 6.8453,
          6.8287, 6.8674, 6.8521, 6.8481, 6.8643, 6.8706, 6.862, 6.8778, 6.8808,
          6.8688, 6.8836, 6.887, 6.8716, 6.875, 6.8904, 6.894, 6.8592, 6.8738,
          6.8578, 6.8558, 6.8653,
        ],
        lat: [
          55.1989, 55.2642, 55.2659, 55.273, 55.2837, 55.2746, 55.2818, 55.2551,
          55.2484, 55.2572, 55.2377, 55.22, 55.2393, 55.2307, 55.2288, 55.2045,
          55.2025, 55.2465, 55.1867, 55.1848, 55.1935, 55.1956, 55.1779,
          55.1583, 55.1604, 55.1514, 55.1407, 55.1427, 55.1339, 55.132, 55.1232,
          55.1252, 55.1164, 55.1671, 55.1692, 55.2131, 55.176, 55.1495,
        ],
      },
    ],
    "Scroby Sands Wind Farm": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
        ],
        lon: [
          1.777, 1.7778, 1.7845, 1.7852, 1.7933, 1.7787, 1.7935, 1.786, 1.7953,
          1.787, 1.7944, 1.7862, 1.7936, 1.7998, 1.7989, 1.7929, 1.7864, 1.7789,
          1.7874, 1.795, 1.7871, 1.7804, 1.7795, 1.7973, 1.7965, 1.7813, 1.7799,
          1.79, 1.7821, 1.781,
        ],
        lat: [
          52.6292, 52.6326, 52.6292, 52.6325, 52.6324, 52.6358, 52.6357,
          52.6358, 52.6424, 52.6392, 52.639, 52.6592, 52.6592, 52.6592, 52.6558,
          52.6558, 52.6559, 52.6593, 52.6463, 52.6459, 52.6424, 52.6425,
          52.6392, 52.6492, 52.6524, 52.6459, 52.6559, 52.6525, 52.6492,
          52.6526,
        ],
      },
    ],
    "Seagreen Wind Farm": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
          "92",
          "93",
          "94",
          "95",
          "96",
          "97",
          "98",
          "99",
          "100",
          "101",
          "102",
          "103",
          "104",
          "105",
          "106",
          "107",
          "108",
          "109",
          "110",
          "111",
          "112",
          "113",
          "114",
        ],
        lon: [
          -1.759, -1.7447, -1.5559, -1.527, -1.6001, -1.6141, -1.5999, -1.9038,
          -1.6857, -1.9165, -1.6443, -1.7879, -1.8303, -1.8909, -1.5859,
          -1.7309, -1.931, -1.5564, -1.6865, -1.7165, -1.933, -1.5269, -1.5573,
          -1.5566, -1.7732, -1.875, -1.7863, -1.556, -1.8175, -1.8187, -1.5993,
          -1.7312, -1.6284, -1.7587, -1.6435, -1.5709, -1.7019, -1.8607,
          -1.6585, -1.5558, -1.5716, -1.5126, -1.6287, -1.6426, -1.9327,
          -1.6136, -1.7304, -1.5419, -1.585, -1.6153, -1.6141, -1.8177, -1.8015,
          -1.8892, -1.8444, -1.5128, -1.8029, -1.9198, -1.673, -1.6726, -1.7021,
          -1.5852, -1.6293, -1.6717, -1.6581, -1.6281, -1.8012, -1.7311,
          -1.7595, -1.6432, -1.5415, -1.5417, -1.774, -1.86, -1.8155, -1.7549,
          -1.6868, -1.6569, -1.888, -1.6589, -1.7168, -1.9341, -1.6439, -1.6425,
          -1.7017, -1.6578, -1.5991, -1.6428, -1.7711, -1.701, -1.8755, -1.6573,
          -1.8314, -1.7435, -1.7162, -1.6869, -1.9181, -1.8588, -1.6876,
          -1.5275, -1.9338, -1.6297, -1.4986, -1.5706, -1.8469, -1.6144,
          -1.6572, -1.5416, -1.5554, -1.814, -1.6003, -1.5421, -1.6871, -1.5992,
          -1.7161,
        ],
        lat: [
          56.5849, 56.6068, 56.6111, 56.561, 56.6121, 56.5495, 56.5987, 56.6063,
          56.5339, 56.5171, 56.6669, 56.5945, 56.5419, 56.6688, 56.6341,
          56.6422, 56.5219, 56.5842, 56.5741, 56.6508, 56.6158, 56.5343, 56.624,
          56.5976, 56.5763, 56.6099, 56.5271, 56.544, 56.631, 56.5353, 56.5451,
          56.6691, 56.5548, 56.5979, 56.6132, 56.589, 56.646, 56.6186, 56.6449,
          56.5305, 56.6427, 56.5428, 56.6088, 56.5461, 56.6024, 56.5365,
          56.6154, 56.5793, 56.5943, 56.6572, 56.5767, 56.6444, 56.5869,
          56.6152, 56.5199, 56.5562, 56.6262, 56.6378, 56.6498, 56.6229,
          56.6594, 56.5805, 56.6352, 56.5558, 56.618, 56.5413, 56.5322, 56.6557,
          56.6251, 56.5864, 56.5391, 56.5659, 56.6299, 56.6048, 56.5237, 56.573,
          56.6009, 56.5376, 56.5213, 56.6717, 56.6643, 56.6694, 56.6401,
          56.5328, 56.6326, 56.5913, 56.5715, 56.5596, 56.6046, 56.579, 56.6369,
          56.5644, 56.5956, 56.5264, 56.6374, 56.5879, 56.5976, 56.5247,
          56.6546, 56.5745, 56.6561, 56.6621, 56.5376, 56.5622, 56.654, 56.6307,
          56.551, 56.5525, 56.5573, 56.6195, 56.6255, 56.6061, 56.6277, 56.5317,
          56.624,
        ],
      },
    ],
    Seastar: [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
        ],
        lon: [
          2.8444, 2.8413, 2.8163, 2.853, 2.8818, 2.8635, 2.8405, 2.9052, 2.8792,
          2.8231, 2.8279, 2.8717, 2.8307, 2.8534, 2.8479, 2.8741, 2.8626,
          2.8934, 2.886, 2.854, 2.8425, 2.8352, 2.8671, 2.8918, 2.8854, 2.8552,
          2.8793, 2.8721, 2.8661, 2.8986, 2.86,
        ],
        lat: [
          51.6242, 51.6082, 51.6152, 51.6455, 51.6307, 51.6162, 51.6355, 51.649,
          51.6645, 51.6122, 51.6255, 51.6225, 51.6087, 51.6118, 51.6319,
          51.6341, 51.6246, 51.6396, 51.6434, 51.6187, 51.6183, 51.622, 51.6379,
          51.6568, 51.6609, 51.6282, 51.6476, 51.6516, 51.655, 51.6529, 51.6417,
        ],
      },
    ],
    "Sheringham Shoal Offshore Wind Farm": [
      {
        ids: [
          "osp",
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
        ],
        lon: [
          1.1206, 1.1758, 1.0804, 1.0905, 1.0953, 1.1, 1.1048, 1.1095, 1.1142,
          1.0994, 1.0852, 1.1237, 1.1006, 1.1042, 1.1089, 1.1136, 1.1243, 1.119,
          1.1291, 1.1101, 1.0899, 1.0947, 1.1202, 1.1249, 1.1148, 1.1196,
          1.1391, 1.1439, 1.1054, 1.1297, 1.1344, 1.1397, 1.135, 1.1492, 1.1539,
          1.1338, 1.1309, 1.1356, 1.1445, 1.1208, 1.1107, 1.1154, 1.1593, 1.164,
          1.1255, 1.1303, 1.1504, 1.1552, 1.141, 1.1457, 1.1693, 1.1741, 1.1558,
          1.1605, 1.1403, 1.151, 1.1451, 1.1498, 1.1545, 1.1841, 1.1611, 1.1652,
          1.17, 1.1747, 1.18, 1.1848, 1.1599, 1.1646, 1.1712, 1.1759, 1.1942,
          1.1658, 1.1895, 1.1948, 1.1794, 1.1996, 1.1854, 1.1706, 1.1807,
          1.1901, 1.1753, 1.2043, 1.1813, 1.1907, 1.2049, 1.186, 1.2143, 1.1955,
          1.2096, 1.2002,
        ],
        lat: [
          53.1441, 53.1267, 53.173, 53.1706, 53.1634, 53.1561, 53.1489, 53.1416,
          53.1343, 53.144, 53.1658, 53.1198, 53.1682, 53.1367, 53.1295, 53.1222,
          53.1319, 53.1271, 53.1247, 53.1537, 53.1585, 53.1513, 53.1513,
          53.1441, 53.1465, 53.1392, 53.1223, 53.115, 53.161, 53.1368, 53.1295,
          53.1344, 53.1416, 53.1199, 53.1126, 53.1174, 53.161, 53.1538, 53.1271,
          53.1634, 53.1658, 53.1586, 53.1175, 53.1102, 53.1562, 53.1489,
          53.1441, 53.1368, 53.1586, 53.1514, 53.115, 53.1078, 53.149, 53.1417,
          53.1465, 53.1562, 53.1392, 53.132, 53.1247, 53.1054, 53.1538, 53.1344,
          53.1272, 53.1199, 53.1248, 53.1175, 53.1296, 53.1223, 53.1514,
          53.1441, 53.103, 53.1465, 53.1102, 53.1151, 53.1126, 53.1078, 53.1296,
          53.1393, 53.1369, 53.1223, 53.132, 53.1006, 53.149, 53.1345, 53.1127,
          53.1417, 53.0981, 53.1272, 53.1054, 53.1199,
        ],
      },
    ],
    "Sleipner A": [
      {
        ids: ["1", "2", "3", "4", "5", "6", "7", "8"],
        lon: [1.9072, 1.9071, 1.9071, 1.9072, 1.9072, 1.9072, 1.9072, 1.9072],
        lat: [
          58.3666, 58.3666, 58.3667, 58.3667, 58.3667, 58.3666, 58.3667,
          58.3666,
        ],
      },
    ],
    "Sleipner T": [
      {
        ids: ["1", "2", "3"],
        lon: [1.9046, 1.9046, 1.9045],
        lat: [58.368, 58.368, 58.368],
      },
    ],
    "Snorre A": [
      {
        ids: ["1", "2", "3", "4", "5", "6", "7", "8"],
        lon: [2.1417, 2.1418, 2.1418, 2.1418, 2.1418, 2.1417, 2.1418, 2.1418],
        lat: [
          61.4489, 61.4489, 61.4489, 61.4489, 61.4489, 61.4489, 61.4489,
          61.4488,
        ],
      },
    ],
    "Statfjord A": [
      {
        ids: ["1", "2", "3", "4", "5", "6"],
        lon: [1.8523, 1.8524, 1.8524, 1.8524, 1.8524, 1.8524],
        lat: [61.2552, 61.2552, 61.2552, 61.2551, 61.2552, 61.2552],
      },
    ],
    "Statfjord B": [
      {
        ids: ["1", "2", "3", "4", "5"],
        lon: [1.8291, 1.829, 1.8291, 1.8291, 1.8291],
        lat: [61.2064, 61.2064, 61.2064, 61.2064, 61.2064],
      },
    ],
    "Statfjord C": [
      {
        ids: ["1", "2", "3", "4", "5", "6", "7"],
        lon: [1.8823, 1.8823, 1.8823, 1.8823, 1.8823, 1.8823, 1.8823],
        lat: [61.2805, 61.2805, 61.2805, 61.2805, 61.2805, 61.2805, 61.2805],
      },
    ],
    "Tarbert Power Station": [
      {
        ids: ["1", "2", "3", "4"],
        lon: [-9.3649, -9.3639, -9.3635, -9.3645],
        lat: [52.5893, 52.5889, 52.5888, 52.5892],
      },
    ],
    "Teesside Offshore Wind Farm": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
        ],
        lon: [
          -1.1194, -1.0903, -1.0862, -1.0788, -1.0829, -1.087, -1.0912, -1.1152,
          -1.0953, -1.1068, -1.0994, -1.1028, -1.1111, -1.0987, -1.0945,
          -1.1036, -1.0841, -1.08, -1.1121, -1.1006, -1.0676, -1.0759, -1.0717,
          -1.0968, -1.0883, -1.0923, -1.1078,
        ],
        lat: [
          54.6502, 54.6371, 54.6353, 54.6383, 54.6401, 54.642, 54.6439, 54.6484,
          54.6457, 54.6446, 54.6476, 54.6428, 54.6465, 54.6409, 54.639, 54.6495,
          54.647, 54.6452, 54.6531, 54.6545, 54.6396, 54.6433, 54.6414, 54.6527,
          54.649, 54.6507, 54.6514,
        ],
      },
    ],
    "Thanet Wind Farm": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
          "92",
          "93",
          "94",
          "95",
          "96",
          "97",
          "98",
          "99",
          "100",
        ],
        lon: [
          1.6221, 1.6519, 1.6378, 1.6755, 1.6236, 1.6614, 1.6802, 1.6661,
          1.6418, 1.6473, 1.6282, 1.6706, 1.6333, 1.6565, 1.6709, 1.6569, 1.619,
          1.6805, 1.6663, 1.6145, 1.6758, 1.6239, 1.6522, 1.6285, 1.6381,
          1.6427, 1.6617, 1.6097, 1.6476, 1.6333, 1.6193, 1.6713, 1.6571,
          1.6052, 1.6429, 1.6288, 1.6808, 1.6147, 1.6666, 1.6524, 1.6761,
          1.6005, 1.6101, 1.6383, 1.6196, 1.6715, 1.5959, 1.6479, 1.6054,
          1.6337, 1.615, 1.629, 1.662, 1.6573, 1.6242, 1.5913, 1.6432, 1.6009,
          1.6669, 1.6385, 1.6526, 1.6481, 1.5867, 1.6244, 1.6623, 1.5961,
          1.6104, 1.6198, 1.582, 1.6339, 1.5773, 1.6294, 1.6576, 1.5915, 1.6435,
          1.6058, 1.6152, 1.6011, 1.5869, 1.653, 1.6389, 1.6106, 1.6247, 1.5822,
          1.5919, 1.6437, 1.647, 1.6343, 1.5965, 1.6201, 1.606, 1.6154, 1.6203,
          1.6346, 1.6296, 1.6251, 1.6109, 1.5967, 1.6013, 1.6392, 1.6062,
        ],
        lat: [
          51.4374, 51.4078, 51.4086, 51.4094, 51.4093, 51.4103, 51.4063, 51.407,
          51.4053, 51.411, 51.406, 51.4038, 51.4117, 51.4045, 51.4127, 51.4135,
          51.4124, 51.4152, 51.4158, 51.4157, 51.4184, 51.4181, 51.4166,
          51.4148, 51.4174, 51.4141, 51.4191, 51.4189, 51.4198, 51.4206,
          51.4213, 51.4215, 51.4223, 51.422, 51.423, 51.4238, 51.4241, 51.4245,
          51.4248, 51.4255, 51.4272, 51.4253, 51.4277, 51.4263, 51.4302,
          51.4305, 51.4285, 51.4287, 51.4309, 51.4295, 51.4334, 51.4327,
          51.4279, 51.4312, 51.427, 51.4316, 51.4319, 51.4341, 51.4336, 51.4351,
          51.4344, 51.4375, 51.4348, 51.4359, 51.4368, 51.4374, 51.4366, 51.439,
          51.4381, 51.4383, 51.4412, 51.4415, 51.4401, 51.4406, 51.4408,
          51.4397, 51.4422, 51.443, 51.4437, 51.4433, 51.444, 51.4455, 51.4447,
          51.447, 51.4494, 51.4497, 51.4457, 51.4472, 51.4462, 51.448, 51.4486,
          51.4511, 51.4568, 51.456, 51.4504, 51.4535, 51.4543, 51.4551, 51.4519,
          51.4528, 51.4575,
        ],
      },
    ],
    "Thorntonbank Wind Farm": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
        ],
        lon: [
          2.955, 2.9786, 2.9355, 2.9282, 2.9067, 2.9605, 2.9083, 2.885, 3.0101,
          2.9902, 2.9535, 3.0036, 3.0162, 2.9787, 2.9869, 2.9954, 2.9871,
          2.9786, 2.9702, 2.9792, 2.9877, 2.9961, 3.0046, 3.0097, 3.0032,
          2.9968, 2.9504, 2.9398, 2.9531, 2.9472, 2.9423, 2.932, 2.921, 2.9296,
          2.9413, 2.9345, 2.9242, 2.9138, 2.9264, 2.9165, 2.9237, 2.9184,
          2.9087, 2.8994, 2.9158, 2.9104, 2.9009, 2.8923, 2.901, 2.8927, 2.9723,
          2.9661, 2.9599, 2.9623, 2.9706,
        ],
        lat: [
          51.5328, 51.5448, 51.5453, 51.5208, 51.5338, 51.5343, 51.5573,
          51.5467, 51.5773, 51.5784, 51.5588, 51.5809, 51.5639, 51.5548,
          51.5501, 51.5555, 51.5602, 51.5649, 51.5696, 51.5745, 51.5698,
          51.5651, 51.5603, 51.5675, 51.5712, 51.5747, 51.5296, 51.5247,
          51.5376, 51.5401, 51.5336, 51.5289, 51.5251, 51.548, 51.5427, 51.5375,
          51.5331, 51.5295, 51.5414, 51.5373, 51.5505, 51.5453, 51.5416,
          51.5381, 51.554, 51.5492, 51.5458, 51.5423, 51.5537, 51.5502, 51.5483,
          51.5518, 51.5553, 51.564, 51.5594,
        ],
      },
    ],
    Tor: [
      {
        ids: ["1", "2", "3", "4"],
        lon: [3.3256, 3.3257, 3.3256, 3.3256],
        lat: [56.6414, 56.6415, 56.6415, 56.6415],
      },
    ],
    "Trianel Windpark Borkum": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
        ],
        lon: [
          6.4669, 6.4482, 6.4711, 6.4156, 6.4896, 6.4874, 6.4568, 6.4733,
          6.4161, 6.4171, 6.4166, 6.4309, 6.4448, 6.4473, 6.4431, 6.4436,
          6.4442, 6.4304, 6.4293, 6.4299, 6.4611, 6.4615, 6.4716, 6.4477, 6.472,
          6.4573, 6.4579, 6.4584, 6.4748, 6.4753, 6.462, 6.4724, 6.4883, 6.4887,
          6.4602, 6.4606, 6.4758, 6.4738, 6.4743, 6.4891, 6.4878,
        ],
        lat: [
          54.0465, 54.0002, 54.08, 54.0881, 54.0002, 54.0393, 54.0881, 54.0449,
          54.0788, 54.0613, 54.0701, 54.0608, 54.061, 54.0164, 54.0879, 54.0789,
          54.07, 54.0698, 54.0877, 54.0788, 54.0159, 54.0079, 54.0718, 54.0082,
          54.0635, 54.0791, 54.0701, 54.0611, 54.018, 54.009, 54.0002, 54.0553,
          54.0236, 54.0157, 54.0318, 54.0238, 54.0002, 54.0359, 54.027, 54.0079,
          54.0314,
        ],
      },
    ],
    "Trianel Windpark Borkum Phase II": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
        ],
        lon: [
          6.4176, 6.4181, 6.4332, 6.4357, 6.4524, 6.4401, 6.4186, 6.4506,
          6.4298, 6.4327, 6.4434, 6.4197, 6.419, 6.4322, 6.4201, 6.5113, 6.521,
          6.4986, 6.4844, 6.4929, 6.5021, 6.5102, 6.4873, 6.4894, 6.5044,
          6.4977, 6.5203, 6.509, 6.4752, 6.4861, 6.5206, 6.4205,
        ],
        lat: [
          54.0521, 54.0439, 54.045, 54.053, 54.0522, 54.0391, 54.0358, 54.0446,
          54.0335, 54.0238, 54.0299, 54.017, 54.0277, 54.0144, 54.0086, 54.0677,
          54.073, 54.0476, 54.0507, 54.0568, 54.0622, 54.0553, 54.0644, 54.0806,
          54.0813, 54.0885, 54.0888, 54.0887, 54.0882, 54.0884, 54.0811,
          54.0013,
        ],
      },
    ],
    "Triton Knoll Wind Farm": [
      {
        ids: [
          "osp",
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
        ],
        lon: [
          0.8904, 0.7986, 0.9478, 0.9659, 0.953, 0.9288, 0.9184, 0.9078, 0.8975,
          0.8765, 0.8663, 0.8555, 0.845, 0.8344, 0.8636, 0.9058, 0.8979, 0.9245,
          0.9167, 0.9094, 0.9015, 0.8823, 0.875, 0.9432, 0.9358, 0.9278, 0.9208,
          0.9126, 0.8936, 0.8407, 0.7911, 0.8066, 0.8141, 0.8219, 0.8136,
          0.8029, 0.7924, 0.7819, 0.7716, 0.7613, 0.7508, 0.74, 0.7298, 0.7188,
          0.7083, 0.6981, 0.7064, 0.73, 0.7376, 0.7799, 0.772, 0.7647, 0.7568,
          0.749, 0.7224, 0.8096, 0.8253, 0.8596, 0.844, 0.8288, 0.863, 0.8705,
          0.8785, 0.8972, 0.8895, 0.8819, 0.8665, 0.8739, 0.8474, 0.94, 0.9323,
          0.9513, 0.9588, 0.9665, 0.9743, 0.982, 0.821, 0.7629, 0.7763, 0.8028,
          0.816, 0.8294, 0.8429, 0.8562, 0.7943, 0.768, 0.7413, 0.7148, 0.723,
          0.7362, 0.7499, 0.7895,
        ],
        lat: [
          53.4505, 53.4853, 53.4212, 53.4107, 53.4094, 53.4115, 53.4152,
          53.4184, 53.4222, 53.4288, 53.4321, 53.4355, 53.4389, 53.4426,
          53.4486, 53.4352, 53.4426, 53.4449, 53.4524, 53.4603, 53.4678,
          53.4585, 53.4659, 53.4548, 53.4621, 53.4697, 53.4772, 53.4851,
          53.4756, 53.4718, 53.4929, 53.4775, 53.47, 53.4622, 53.4496, 53.4523,
          53.4559, 53.4594, 53.4626, 53.4659, 53.4692, 53.4726, 53.4763,
          53.4797, 53.4828, 53.4866, 53.4993, 53.4968, 53.4893, 53.4757,
          53.4834, 53.4911, 53.4984, 53.5064, 53.5043, 53.5023, 53.4873,
          53.4813, 53.4968, 53.5122, 53.5065, 53.4983, 53.491, 53.5007, 53.5085,
          53.5161, 53.5314, 53.5236, 53.5216, 53.4293, 53.4369, 53.4462, 53.439,
          53.431, 53.4235, 53.4158, 53.5198, 53.5281, 53.5289, 53.5307, 53.5319,
          53.5328, 53.5338, 53.5349, 53.5181, 53.5161, 53.5139, 53.5124,
          53.5252, 53.5258, 53.5269, 53.53,
        ],
      },
    ],
    "Troll B": [
      {
        ids: ["1", "2", "3", "4"],
        lon: [3.5013, 3.5013, 3.5013, 3.5013],
        lat: [60.7738, 60.7738, 60.7738, 60.7738],
      },
    ],
    "Troll C": [
      {
        ids: ["1", "2", "3", "4"],
        lon: [3.6098, 3.6099, 3.6099, 3.6098],
        lat: [60.8858, 60.8858, 60.8858, 60.8858],
      },
    ],
    Ula: [
      {
        ids: ["1", "2", "3"],
        lon: [2.8469, 2.847, 2.847],
        lat: [57.111, 57.111, 57.111],
      },
    ],
    Valhall: [
      {
        ids: ["1", "2", "3", "4", "5", "6", "7", "8"],
        lon: [3.3928, 3.3929, 3.3929, 3.3929, 3.3928, 3.3929, 3.3929, 3.3928],
        lat: [
          56.2781, 56.2781, 56.278, 56.2781, 56.2781, 56.278, 56.278, 56.2781,
        ],
      },
    ],
    "Veja Mate": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
        ],
        lon: [
          5.8815, 5.8278, 5.9101, 5.9246, 5.9099, 5.9099, 5.829, 5.8765, 5.8518,
          5.9095, 5.9267, 5.8276, 5.9019, 5.9143, 5.9094, 5.9094, 5.9096,
          5.9097, 5.9099, 5.91, 5.8909, 5.8725, 5.8563, 5.8401, 5.8402, 5.8566,
          5.8562, 5.8725, 5.8724, 5.8912, 5.8911, 5.8911, 5.9102, 5.9102,
          5.9101, 5.873, 5.8491, 5.84, 5.8282, 5.8285, 5.8286, 5.8288, 5.8404,
          5.864, 5.8891, 5.8399, 5.8397, 5.8401, 5.8567, 5.8566, 5.8567, 5.8569,
          5.8729, 5.8909, 5.8725, 5.8911, 5.891, 5.8724, 5.8724, 5.8911, 5.8914,
          5.891, 5.8983, 5.8865, 5.8749, 5.8627, 5.8392, 5.8511,
        ],
        lat: [
          54.3219, 54.2749, 54.275, 54.3035, 54.3041, 54.3113, 54.3415, 54.3621,
          54.3516, 54.3622, 54.3829, 54.2814, 54.3724, 54.3777, 54.3549,
          54.3476, 54.3405, 54.3333, 54.326, 54.3183, 54.3107, 54.3103, 54.3105,
          54.3133, 54.3048, 54.3028, 54.2951, 54.3029, 54.295, 54.3029, 54.2953,
          54.2873, 54.2967, 54.2894, 54.2812, 54.2814, 54.2814, 54.2891,
          54.2947, 54.3084, 54.3215, 54.3347, 54.3466, 54.3567, 54.3673,
          54.3216, 54.3298, 54.3386, 54.3352, 54.3182, 54.3439, 54.3259,
          54.3413, 54.357, 54.3501, 54.3338, 54.326, 54.3335, 54.326, 54.3491,
          54.3415, 54.3183, 54.2749, 54.2749, 54.2748, 54.2749, 54.2749,
          54.2748,
        ],
      },
    ],
    "Veslefrikk A": [
      {
        ids: ["1", "2", "3"],
        lon: [2.8963, 2.8963, 2.8962],
        lat: [60.7822, 60.7822, 60.7822],
      },
    ],
    Visund: [
      {
        ids: ["1", "2", "3", "4"],
        lon: [2.4572, 2.4573, 2.4573, 2.4573],
        lat: [61.3696, 61.3696, 61.3696, 61.3696],
      },
    ],
    "Walney Wind Farm": [
      {
        ids: [
          "osp",
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
          "92",
          "93",
          "94",
          "95",
          "96",
          "97",
          "98",
          "99",
          "100",
          "101",
          "102",
          "103",
          "104",
          "105",
          "106",
          "107",
          "108",
          "109",
          "110",
          "111",
          "112",
          "113",
          "114",
          "115",
          "116",
          "117",
          "118",
          "119",
          "120",
          "121",
          "122",
          "123",
          "124",
          "125",
          "126",
          "127",
          "128",
          "129",
          "130",
          "131",
          "132",
          "133",
          "134",
          "135",
          "136",
          "137",
          "138",
          "139",
          "140",
          "141",
          "142",
          "143",
          "144",
          "145",
          "146",
          "147",
          "148",
          "149",
          "150",
          "151",
          "152",
          "153",
          "154",
          "155",
          "156",
          "157",
          "158",
          "159",
          "160",
          "161",
          "162",
          "163",
          "164",
          "165",
          "166",
          "167",
          "168",
          "169",
          "170",
          "171",
          "172",
          "173",
          "174",
          "175",
          "176",
          "177",
          "178",
          "179",
          "180",
          "181",
          "182",
          "183",
          "184",
          "185",
          "186",
          "187",
          "188",
          "189",
        ],
        lon: [
          -3.5024, -3.576, -3.5507, -3.6465, -3.592, -3.5471, -3.5577, -3.6482,
          -3.6417, -3.5951, -3.6447, -3.5777, -3.573, -3.5795, -3.5563, -3.614,
          -3.5596, -3.6176, -3.5748, -3.6217, -3.6019, -3.5906, -3.5774,
          -3.6088, -3.5821, -3.5722, -3.6136, -3.5624, -3.6276, -3.6524,
          -3.6224, -3.5834, -3.5418, -3.6386, -3.5677, -3.5629, -3.636, -3.635,
          -3.6261, -3.613, -3.6546, -3.5656, -3.6314, -3.6263, -3.5851, -3.6536,
          -3.5685, -3.5884, -3.604, -3.5689, -3.601, -3.6021, -3.4974, -3.461,
          -3.52, -3.4837, -3.5423, -3.4473, -3.4887, -3.5059, -3.4696, -3.5112,
          -3.475, -3.5337, -3.5147, -3.5285, -3.4782, -3.5166, -3.5027, -3.5595,
          -3.4644, -3.4922, -3.5509, -3.5093, -3.5455, -3.5252, -3.5231,
          -3.4954, -3.4559, -3.4816, -3.5403, -3.537, -3.4869, -3.5008, -3.5543,
          -3.4731, -3.5317, -3.518, -3.5212, -3.5437, -3.5074, -3.5298, -3.5159,
          -3.5384, -3.5246, -3.5331, -3.5042, -3.5264, -3.4903, -3.549, -3.5127,
          -3.5351, -3.4987, -3.6436, -3.5776, -3.5907, -3.643, -3.6297, -3.6487,
          -3.6339, -3.6441, -3.6558, -3.6685, -3.661, -3.6037, -3.6166, -3.6246,
          -3.5899, -3.6078, -3.5577, -3.5819, -3.5753, -3.5747, -3.6104,
          -3.5603, -3.5933, -3.5452, -3.612, -3.6535, -3.5978, -3.627, -3.7569,
          -3.7121, -3.7275, -3.7408, -3.7278, -3.7134, -3.7546, -3.7711,
          -3.7695, -3.7378, -3.7123, -3.7277, -3.7289, -3.7718, -3.7437,
          -3.8696, -3.7626, -3.7573, -3.76, -3.7602, -3.7415, -3.7568, -3.9168,
          -3.8739, -3.8597, -3.8862, -3.917, -3.9021, -3.9041, -3.8889, -3.8505,
          -3.8303, -3.8453, -3.8736, -3.9031, -3.8898, -3.8567, -3.8719,
          -3.8251, -3.9173, -3.8404, -3.8573, -3.888, -3.8252, -3.8562, -3.8413,
          -3.8086, -3.8398, -3.8753, -3.8479, -3.827, -3.8387, -3.7957, -3.8622,
          -3.8114, -3.8243, -3.7979, -3.812, -3.8262, -3.7839,
        ],
        lat: [
          54.0446, 54.0795, 54.0836, 54.0648, 54.0798, 54.0718, 54.0574,
          54.1102, 54.1167, 54.063, 54.1003, 54.0658, 54.0548, 54.0461, 54.0762,
          54.0559, 54.0883, 54.1052, 54.0849, 54.1204, 54.0836, 54.0498,
          54.0975, 54.0646, 54.076, 54.0721, 54.0766, 54.0684, 54.1099, 54.0878,
          54.066, 54.0596, 54.0791, 54.0599, 54.0616, 54.0501, 54.0676, 54.0963,
          54.0582, 54.1158, 54.1038, 54.0806, 54.1254, 54.1008, 54.0899,
          54.0958, 54.0929, 54.0694, 54.1113, 54.0418, 54.073, 54.0531, 54.0261,
          54.0271, 54.0278, 54.0288, 54.0294, 54.0299, 54.0216, 54.0305,
          54.0315, 54.0233, 54.0243, 54.025, 54.035, 54.0322, 54.0358, 54.0161,
          54.0188, 54.0384, 54.0387, 54.0333, 54.0339, 54.0422, 54.0412,
          54.0205, 54.0395, 54.045, 54.0344, 54.0477, 54.0484, 54.0366, 54.0405,
          54.0377, 54.0456, 54.0433, 54.0439, 54.0467, 54.0584, 54.0601,
          54.0612, 54.0629, 54.0656, 54.0673, 54.07, 54.0745, 54.0495, 54.0511,
          54.0522, 54.0529, 54.0539, 54.0557, 54.0567, 54.092, 54.0078, 54.012,
          54.0498, 54.0242, 54.0404, 54.0388, 54.0281, 54.0324, 54.0364,
          54.0464, 54.016, 54.0202, 54.0325, 54.0222, 54.0272, 54.0259, 54.0371,
          54.0179, 54.028, 54.0375, 54.0157, 54.032, 54.0191, 54.0462, 54.0564,
          54.0419, 54.0492, 54.0675, 54.0742, 54.0713, 54.069, 54.0866, 54.0829,
          54.0761, 54.0796, 54.0894, 54.079, 54.0927, 54.0975, 54.1077, 54.1004,
          54.1024, 54.0991, 54.1168, 54.1355, 54.1071, 54.1264, 54.1311,
          54.0962, 54.1137, 54.1099, 54.1053, 54.1041, 54.1251, 54.1091,
          54.1199, 54.1146, 54.0972, 54.1092, 54.1085, 54.1197, 54.1322,
          54.1247, 54.1245, 54.1293, 54.1168, 54.135, 54.1181, 54.1151, 54.1335,
          54.1261, 54.1345, 54.1284, 54.1318, 54.1381, 54.1384, 54.1453,
          54.1461, 54.1526, 54.138, 54.1435, 54.1424, 54.1358, 54.1487, 54.1531,
          54.1574, 54.1442,
        ],
      },
    ],
    "West of Duddon Sands Wind Farm": [
      {
        ids: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
          "65",
          "66",
          "67",
          "68",
          "69",
          "70",
          "71",
          "72",
          "73",
          "74",
          "75",
          "76",
          "77",
          "78",
          "79",
          "80",
          "81",
          "82",
          "83",
          "84",
          "85",
          "86",
          "87",
          "88",
          "89",
          "90",
          "91",
          "92",
          "93",
          "94",
          "95",
          "96",
          "97",
          "98",
          "99",
          "100",
          "101",
          "102",
          "103",
          "104",
          "105",
          "106",
          "107",
          "108",
        ],
        lon: [
          -3.5537, -3.5464, -3.5406, -3.5291, -3.5235, -3.5349, -3.4881,
          -3.4944, -3.5118, -3.506, -3.5176, -3.5002, -3.4742, -3.4894, -3.501,
          -3.5183, -3.5241, -3.4952, -3.5067, -3.5126, -3.4836, -3.5299,
          -3.4961, -3.5357, -3.5192, -3.525, -3.5077, -3.5134, -3.5018, -3.5216,
          -3.543, -3.4969, -3.4912, -3.5322, -3.5143, -3.5085, -3.5027, -3.4463,
          -3.4304, -3.5004, -3.5075, -3.4966, -3.4894, -3.4838, -3.4782,
          -3.4439, -3.4383, -3.4324, -3.426, -3.4198, -3.4122, -3.4216, -3.4274,
          -3.4673, -3.4729, -3.4786, -3.4856, -3.4747, -3.4676, -3.462, -3.4564,
          -3.4509, -3.4547, -3.4453, -3.4368, -3.422, -3.4608, -3.4165, -3.4659,
          -3.4948, -3.4396, -3.4113, -3.4167, -3.4431, -3.4491, -3.434, -3.4395,
          -3.4221, -3.4504, -3.4629, -3.4109, -3.3895, -3.3949, -3.4285,
          -3.4559, -3.4178, -3.4114, -3.4288, -3.4343, -3.4047, -3.3972,
          -3.4452, -3.4513, -3.4233, -3.395, -3.4006, -3.4057, -3.4449, -3.4004,
          -3.4234, -3.429, -3.4397, -3.4071, -3.4125, -3.4181, -3.4059, -3.4345,
          -3.3896,
        ],
        lat: [
          54, 53.9941, 53.9893, 53.9798, 53.9751, 53.9846, 53.9462, 53.9513,
          53.9656, 53.9608, 53.9703, 53.9561, 53.9459, 53.9583, 53.9679,
          53.9822, 53.987, 53.9631, 53.9727, 53.9775, 53.9536, 53.9917, 53.975,
          53.9965, 53.9941, 53.9988, 53.9846, 53.9893, 53.9798, 54.0073,
          54.0024, 53.987, 53.9822, 54.0048, 54.0013, 53.9965, 53.9917, 53.9453,
          53.945, 54.0044, 54.0105, 54.0128, 54.0068, 54.002, 53.9972, 53.9679,
          53.9631, 53.9581, 53.9526, 53.9474, 53.9524, 53.9605, 53.9654,
          53.9997, 54.0045, 54.0093, 54.0154, 54.0178, 54.0118, 54.0069,
          54.0021, 53.9973, 53.9656, 53.9925, 53.9505, 53.9725, 53.9704,
          53.9676, 53.9752, 53.9995, 53.9876, 53.9748, 53.9796, 53.9559,
          53.9609, 53.995, 53.9999, 53.9845, 54.0095, 54.0205, 53.9628, 53.9673,
          53.9722, 53.9901, 54.0144, 53.9925, 53.9868, 54.0022, 54.0071,
          53.9574, 53.9623, 54.0168, 54.0236, 53.9973, 53.9842, 53.9891,
          53.9699, 54.0047, 53.977, 54.0094, 54.0143, 54.0119, 53.9948, 53.9997,
          54.0046, 53.9819, 54.0192, 53.9793,
        ],
      },
    ],
    "Westermost Rough Wind Farm": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
        ],
        lon: [
          0.1331, 0.1312, 0.1398, 0.0895, 0.0979, 0.1143, 0.123, 0.12, 0.1121,
          0.145, 0.1372, 0.1539, 0.1284, 0.1062, 0.1038, 0.126, 0.1176, 0.1595,
          0.1513, 0.1318, 0.1678, 0.1878, 0.1795, 0.1821, 0.1458, 0.1622,
          0.1706, 0.1542, 0.1965, 0.1934, 0.2103, 0.202, 0.1847, 0.1683, 0.1602,
          0.1763,
        ],
        lat: [
          53.8045, 53.777, 53.7701, 53.8117, 53.8049, 53.7912, 53.784, 53.8041,
          53.8108, 53.7828, 53.7899, 53.776, 53.7972, 53.7984, 53.8177, 53.8168,
          53.8237, 53.789, 53.7959, 53.8297, 53.7821, 53.8009, 53.8077, 53.7879,
          53.8357, 53.8221, 53.815, 53.8287, 53.7939, 53.8139, 53.7999, 53.8068,
          53.8209, 53.8347, 53.8415, 53.8281,
        ],
      },
    ],
    Wikinger: [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
        ],
        lon: [
          14.074, 14.0796, 14.0897, 14.0981, 14.1042, 14.0959, 14.0882, 14.0734,
          14.0685, 14.0822, 14.0933, 14.0928, 14.0798, 14.0665, 14.0793,
          14.0911, 14.0783, 14.0809, 14.0681, 14.0655, 14.0663, 14.053, 14.0531,
          14.0553, 14.0426, 14.0404, 14.0394, 14.0396, 14.0532, 14.0549,
          14.0572, 14.0618, 14.0675, 14.0767, 14.0852, 14.0786, 14.0686,
          14.0597, 14.0476, 14.053, 14.0431, 14.0404,
        ],
        lat: [
          54.8346, 54.8499, 54.8552, 54.8508, 54.8457, 54.8412, 54.8461,
          54.8438, 54.8376, 54.8393, 54.8327, 54.827, 54.8309, 54.8293, 54.8248,
          54.8208, 54.819, 54.8133, 54.8119, 54.8172, 54.8229, 54.8211, 54.815,
          54.8092, 54.8075, 54.8132, 54.8195, 54.8256, 54.8272, 54.8357,
          54.8413, 54.8468, 54.8522, 54.8563, 54.8605, 54.8657, 54.8613,
          54.8566, 54.8455, 54.8511, 54.8396, 54.8339,
        ],
      },
    ],
    "Windpark Butendiek": [
      {
        ids: [
          "osp",
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31",
          "32",
          "33",
          "34",
          "35",
          "36",
          "37",
          "38",
          "39",
          "40",
          "41",
          "42",
          "43",
          "44",
          "45",
          "46",
          "47",
          "48",
          "49",
          "50",
          "51",
          "52",
          "53",
          "54",
          "55",
          "56",
          "57",
          "58",
          "59",
          "60",
          "61",
          "62",
          "63",
          "64",
        ],
        lon: [
          7.7998, 7.7996, 7.7996, 7.7996, 7.7911, 7.7907, 7.7999, 7.7997,
          7.7909, 7.7918, 7.7809, 7.781, 7.7999, 7.7998, 7.7945, 7.7995, 7.783,
          7.7882, 7.7864, 7.7942, 7.7885, 7.7807, 7.7838, 7.7705, 7.7703,
          7.7999, 7.7733, 7.7759, 7.7806, 7.7693, 7.7702, 7.7594, 7.7588,
          7.7595, 7.7831, 7.7611, 7.7773, 7.7723, 7.7787, 7.7835, 7.7893,
          7.7592, 7.7666, 7.7726, 7.7727, 7.7675, 7.7647, 7.7627, 7.7504,
          7.7485, 7.7779, 7.7618, 7.7488, 7.7521, 7.795, 7.784, 7.7785, 7.7557,
          7.7677, 7.7534, 7.7621, 7.7483, 7.7482, 7.7894, 7.7563,
        ],
        lat: [
          55.0196, 55.0539, 55.0472, 55.0238, 55.0357, 55.0428, 55.0156,
          55.0088, 55.0215, 55.0276, 55.0266, 55.02, 55.04, 55.0328, 55.0026,
          54.9972, 55.0137, 55.0082, 55.0538, 54.9911, 54.9963, 55.0333,
          55.0473, 55.0188, 55.0256, 54.9855, 55.0456, 55.0522, 55.0398,
          55.0321, 55.0387, 55.0377, 55.0312, 55.0177, 55.0019, 55.0116,
          55.0069, 55.0124, 55.0588, 54.9901, 54.9847, 55.0246, 55.006, 55.0006,
          54.9887, 54.994, 55.0509, 55.0445, 55.0102, 55.0164, 54.9955, 54.9995,
          55.0364, 55.0427, 54.9791, 54.9783, 54.9836, 55.0045, 54.9825,
          55.0495, 54.9878, 55.023, 55.0297, 54.9728, 54.9932,
        ],
      },
    ],
  },
];
